<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="card border-5 mb-3">
                                    <div class="col-md-12 col-sm-12 px-0 about-box">
                                        <div class="col-md-8 px-0 float-left">
                                            <h6 class="m-0 text-left p-2">
                                                <a href="javascript:" @click="getPrevUrl()" title="Back" class="p-2">
                                                    <i class="fa fa-arrow-left"></i>
                                                    <span> Back</span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div class="col-md-4 px-0 pull-right py-2 text-right pr-2">
                                            <router-link class="link" title="View Client" :to="{ name: 'viewclient'}"><i class="fa fa-eye fa_custom fa-lg px-1"></i></router-link>
                                        </div>
                                    </div>
                                    <div class="user-card pl-5 pr-5 pb-2 pt-3">
                                        <div class="information">
                                         <!-- <div class="userimage-progress"> -->
                                            <div class="progress-circle">
                                             <!-- <vue-circle v-if="showProgress" :progress="clientinfo.profileCompletion" :size="50" :reverse="true" line-cap="round" :fill="fill" empty-fill="rgba(0, 0, 0, .1)" :animation-start-value="0.0" :start-angle="0" insert-mode="append" :thickness="5" :show-percent="true" @vue-circle-progress="progress" @vue-circle-end="progress_end"> -->
                                            <div class="imgUp">
                                                <!-- <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' +  (clientinfo.image===''?clientinfo.imagePath:clientinfo.image) + ')' }"></div> -->
                                                 <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + clientinfo.imagePath + ')' }"></div>
                                                <!-- <div class="imagePreview" v-bind:style="{ backgroundImage: 'url(' + (clientinfo.image===''?clientinfo.imagePath:clientinfo.image) + ')' }"></div> -->
                                               <label class="upload-icon uploadleft"><i class="ti-camera"></i><input ref="client_profile_photo" id="client_profile_photo" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="upload_photo"></label>           
                                               <label class="delete-icon deleteclient"><i class="ti-close closefont" @click="delete_photo"></i></label>
                                            </div>
                                            <!-- </div> -->
                                            </div>
                                            <div class="name">{{clientinfo.clientName}}</div>
                                            <div class="position"><i class="ti-email"></i> {{clientinfo.accountName}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="user-card p-0">
                                    <h5 class="about-box m-0 text-left p-2">Company Info</h5>
                                    <div class="p-3 float-left w-100">
                                        <div class="informations">
                                            <span v-if="clientAddress.length == 0" class="badge p-2 mb-1">No records found</span>
                                            <span v-if="clientAddress.length != 0 && addressinfo.physicaladdress.phoneNumber != ''"><i class="fa fa-phone pr-2" aria-hidden="true"></i>{{addressinfo.physicaladdress.phoneNumber}}</span>
                                            <span v-if="addressinfo.physicaladdress.phoneNumber != ''"><hr></span>
                                            <span v-if="clientAddress.length != 0 && addressinfo.physicaladdress != null"><i class="ti-location-pin pr-2"></i>
                                                <span v-if="addressinfo.physicaladdress.addressLine1 != ''">{{addressinfo.physicaladdress.addressLine1}} </span>
                                                <span v-if="addressinfo.physicaladdress.addressLine2 != ''">,{{addressinfo.physicaladdress.addressLine2}}</span>
                                                <span v-if="addressinfo.physicaladdress.city != ''"> , {{addressinfo.physicaladdress.city}}</span>

                                                <span v-if="addressinfo.physicaladdress.stateName != ''">,{{addressinfo.physicaladdress.stateName}}</span><br>
                                                <span v-if="addressinfo.physicaladdress.countryId != ''">{{getCountryName(addressinfo.physicaladdress.countryId)}}</span>
                                                <span v-if="addressinfo.physicaladdress.zipCode != ''"> - {{addressinfo.physicaladdress.zipCode}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card border-5">
                                    <div class="user-card p-0">
                                        <h5 class="about-box m-0 text-left p-2">About Me</h5>
                                        <div class="p-3 float-left w-100">
                                            <h5>Website</h5>
                                            <div class="informations">
                                                <span v-if="clientinfo.url == ''" class="badge p-2 mb-1">No records found</span>
                                                <span v-if="clientinfo.url != ''">{{clientinfo.url}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="row no-gutters">
                            <b-tabs v-model="currentClientTab" small card class="tab-list driver-tab-list">
                                <b-tab title="Client Details">
                                    <ClientInfo :clientinfo="clientinfo" ref="clientinfocomponent"></ClientInfo>
                                </b-tab>
                                <b-tab title="Physical Address">
                                    <AddressInfo :addressinfo="addressinfo" ref="addressinfocomponent"></AddressInfo>
                                </b-tab>
                                <b-tab title="Client Contacts">
                                    <ClientContacts :clientcontactinfo="clientcontactinfo" ref="clientcontactinfocomponent" @refresh-client="loadClientById(clientid)"></ClientContacts>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
                 <div class="row">
                 <div class="col-sm-12">
                <div class="mr-5 pull-right">
                    <a v-if="currentClientTab != 2" href="Javascript:void(0);" @click="saveCurrentTab" class="btn btn-primary btn-sm float-right">Save</a>
                    <a v-if="currentClientTab != 2" href="Javascript:void(0);" class="btn btn-secondary btn-sm mr-2">
                        <router-link :to="{ name: 'viewclients' }" class="text-white">Cancel</router-link>
                    </a>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ClientInfo from './blocks/addclient/ClientInfo';
import AddressInfo from './blocks/addclient/AddressInfo';
import ClientContacts from './blocks/addclient/ClientContacts';
import ContractDetails from './blocks/addclient/ContractDetails';
import VueCircle from 'vue2-circle-progress';
export default {
    computed: {
        isValidCity() {
    return (city) => /^[A-Za-z\s]+$/.test(city);
  },
        clientid() {
            return this.$route.params.clientid;
        }
    },
    components: {
        ClientInfo,
        AddressInfo,
        ClientContacts,
        ContractDetails,
        VueCircle
    },
    data() {
        return {
            currentClientTab: 0,
            maxtabs: 3,
            clientinfo: {
                id:"",
                clientCode: "",
                dunsNumber: "",
                clientName: "",
                accountName: "",
                clientGroup: "",
                accountManager: "",
                url: "",
                backupaccountManager: "",
                jobUrl: "",
                accountExecutive: "",
                status: "",
                description: "",
                image: '',
                imageName: '',
                profileCompletion: 0,
                imagePath: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
            },
            addressinfo: {
                physicaladdress: {
                    id:"",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "",
                    faxNumber: "",
                    countryCode:"+1",
                    phoneNumber: "",
                    phoneNo:"",
                    sameAsAbove: ""
                },
                billingaddress: {
                    id:"",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "",
                    faxNumber: "",
                    sameAsAbove: ""
                }
            },
            deleteparams:{
            id: 0,
            type:'Client'
            },
            clientcontactinfo: [],
            clientcontractinfo: [],
            clientAddress: [],
            countrieslist: this.$store.getters.getCountries,
            usstates: this.$store.getters.getUSStates,
            showProgress: false,
        }
    },
    mounted() {
        this.$refs.clientinfocomponent.validateClientInfo()
        this.loadClientById(this.clientid);
    },
    methods: {
       
          progress(event, progress, stepValue) {
            // console.log(stepValue);
        },
        progress_end(event) {
            //  console.log("Circle progress end");
        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        getCountryName: function(countryCode) {
            if (!countryCode)
                return "";
            let country = this.countrieslist.filter(obj => {
                return obj.country_Id === countryCode;
            });
            return country[0].name;
        },
        getStateName: function(stateId) {
            if (!stateId)
                return "";
            let state = this.usstates.filter(obj => {
                return obj.id === stateId;
            });
            return state[0].stateName;
        },
        loadClientById(clientId) {
            this.showProgress = false;
            axios.get(this.$store.getters.getAPIBasePath + '/clientresource/view/client/' + clientId)
                .then(response => {
                    let result = response.data;
                    console.log("result",result);

                    if (!result.status) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: result.message,
                            position: 'topRight'
                        });
                        //this.$router.push('/candidates');
                    }
                    else {
                        let self = this;
                        this.clientinfo = result.clients;
                        this.clientinfo = {  
                            id: result.clients.id,    
                            clientName: result.clients.clientName,
                            accountName: result.clients.accountName,
                            dunsNumber: result.clients.dunsNumber,
                            url:result.clients.url,
                            status: (result.clients.status == null? '' : result.clients.status),
                            statusText:result.clients.statusText,
                            description:result.clients.description,
                            imagePath: (result.clients.imagePath == '' ? 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : result.clients.imagePath),
                            imageName: result.clients.imageName,
                            image : (result.clients.imagePath == '' ? ' https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png' : result.clients.imagePath)
                        };
                        this.clientcontactinfo = result.clients.clientContact;
                        this.clientcontractinfo = result.clients.clientContract;
                        this.clientAddress = result.clients.clientAddress;  
                        if(this.clientAddress!= null && this.clientAddress.length >0){
                            this.clientbilling = this.clientAddress.slice(0, 1);
                            this.clientphysical = this.clientAddress.slice(0,2);
                            this.clientpl=this.clientphysical.slice(-1);
                            this.billid=this.clientbilling[0].id;
                            this.phyid=this.clientpl[0].id;
                            this.addressinfo.physicaladdress.id=this.phyid;
                            this.addressinfo.billingaddress.id=this.billid;
                        }
                        this.showProgress = true;
                        this.clientinfo.profileCompletion = this.clientinfo.profileCompletion == null ? 0 : this.clientinfo.profileCompletion;
                  
                        result.clients.clientAddress.forEach(function(data) {
                            if (data.addressType.toLowerCase() === "physical") {
                                /*  let phoneNo = data.phoneNumber;
                                if (phoneNo != null && phoneNo.startsWith("+")) {
                                    let phoneData = phoneNo.split(" ");
                                    data.countryCode = phoneData[0];
                                    data.phoneNo = phoneData[1];           
                                } */
                                self.addressinfo.physicaladdress = data;
                            } else {
                                self.addressinfo.billingaddress = data;
                            }
                        }); 
                }
                })
                // .catch(error => {
                //     iziToast.error({
                //         title: 'Error Occured',
                //         message: 'Couldnt load client information. Please try again',
                //         position: 'topRight'
                //     });
                //     // this.$router.push('/candidates');
                // });
        },
             createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = (e) => {
                this.clientinfo.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
            delete_photo: function() {
            this.deleteparams.id=this.clientid;
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/delete/candidate/photo',this.deleteparams)
                .then(response => {
                    if(response.data.status=="OK"){
                        iziToast.success({ title: 'Photo update', message: response.data.message, position: 'topRight' });
                        this.clientinfo.image="";
                        this.clientinfo.imageName="";
                        this.clientinfo.imagePath="";
                        this.loadClientById(this.clientid);
                    }
                    else if(response.data.status=="EXPECTATION_FAILED"){
                    console.log(response);
                    iziToast.info({ title: 'Photo update', message: this.$config.CliProPicDelErr, position: 'topRight' });}
                })
                .catch(function(error) {
                    console.log(error);
                });
           
        },
        upload_photo: function() {
            console.log(this.$refs.client_profile_photo.files[0].name);
            if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(this.$refs.client_profile_photo.files[0].name)) {
                iziToast.error({
                    title: 'Photo update',
                    message: this.$config.FileFormatErr,
                    position: 'topRight'
                });
                return false;
            }
            this.createImage(this.$refs.client_profile_photo.files[0]);
            //this.ProfileData.imagePath=this.$refs.client_profile_photo.files[0];
            //this.profilePhoto = this.$refs.addcandidate_profile_photo1.files[0];
            let formData = new FormData();
            formData.append('file', this.$refs.client_profile_photo.files[0]);
            // formData.append('id', this.$store.getters.currentUser.clientid);
            formData.append('id', this.clientid);
            console.log(this.clientid);
            formData.append('type', 'client');
            axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadprofilephoto',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response=> {
                    iziToast.success({ title: 'Photo update', message: this.$config.ProfileUpSuc, position: 'topRight' });
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Photo update', message: this.$config.ProfileUpErr, position: 'topRight' });
                });
        },
        saveCurrentTab() {
            switch (this.currentClientTab) {
                case 0:
                    if (this.$refs.clientinfocomponent.validateClientInfo()) {
                        this.saveClientInfo();
                    }
                    break;
                case 1:
                    this.saveAddressInfo();
                    break;
                case 2:
                    this.saveClientContactInfo();
                    break;
                case 3:
                    this.saveClientContractInfo();
                    break;
                default:
                    break;
            }
        },
        saveClientInfo() {
            /* let clientinfodata = {
                 clientCode: this.clientinfo.clientCode,
                 dunsNumber: this.clientinfo.dunsNumber,
                 clientName: this.clientinfo.clientName,
                 accountName: this.clientinfo.accountName,
                 //clientGroup:this.clientinfo.clientGroup,
                 //accountManager:this.clientinfo.accountManager,
                 url: this.clientinfo.url,
                 //backupaccountManager:this.clientinfo.backupaccountManager,
                 jobUrl: this.clientinfo.jobUrl,
                 //accountExecutive:this.clientinfo.accountExecutive,
                 status: this.clientinfo.status,
                 description: this.clientinfo.description
             };*/
            axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/client/' + this.clientid + '/clientdetails', this.clientinfo)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliPerInfoUpSuc,
                        position: 'topRight'
                    });
                    this.$router.push('/client/view/' + this.clientid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveAddressInfo() {

            /* if (this.addressinfo.physicaladdress.phoneNo != null && this.addressinfo.physicaladdress.phoneNo != '') {
                this.addressinfo.physicaladdress.phoneNumber = this.addressinfo.physicaladdress.countryCode + " " + this.addressinfo.physicaladdress.phoneNo;                
            } */

            //this.addressinfo.physicaladdress.phoneNumber = this.addressinfo.physicaladdress.countryCode+" "+this.addressinfo.physicaladdress.phoneNumber;
            if(!this.$refs.addressinfocomponent.validatePhoneNo()){ 
                let contactupdate = {
                    
                    clientAddress: {
                        physicalAddress: this.addressinfo.physicaladdress,
                        billingAddress: this.addressinfo.billingaddress
                    }
                        
                };
                console.log("pladd",this.addressinfo.physicaladdress)

                axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/client/' + this.clientid + '/address', contactupdate)
                    .then(response => {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.CliAdrsSuc,
                            position: 'topRight'
                        });
                        this.$router.push('/client/view/' + this.clientid);
                    })
                    .catch(error => {
                        console.log(error);
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.CliAdrsUpErr,
                            position: 'topRight'
                        });
                    });
            }
        },
        saveClientContactInfo() {
            let clientcontactinfodata = this.newclientcontactinfo;
            axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/client/' + this.clientid + '/contact', clientcontactinfodata)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliConInfoUpSuc,
                        position: 'topRight'
                    });
                    this.$router.push('/client/view/' + this.clientid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliConUpErr,
                        position: 'topRight'
                    });
                });
        },
        saveClientContractInfo() {
            let clientcontractinfodata = this.newclientcontractinfo;
            axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/client/' + this.clientid + '/contract', clientcontractinfodata)
                .then(response => {
                    iziToast.success({
                        title: 'Success',
                        message: this.$config.CliContraInfoUpSuc,
                        position: 'topRight'
                    });
                    this.$router.push('/client/view/' + this.clientid);
                })
                .catch(error => {
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliContraUpErr,
                        position: 'topRight'
                    });
                });
        }
    }
}

</script>

