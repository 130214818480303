<template>
    <div id="main" class="mt-70">
        <div class="container-fluid recruiter-dashboard">
            <div class="row">
                <nav v-if="false" class="col-md-2 d-none d-md-block sidebar">
                    <div class="sidebar-sticky">
                        <ul class="nav flex-column">
                            <li class="nav-item">
                                <router-link :to="{ name: 'candidatedashboard' }" class="nav-link active">
                                    <i class="ti-dashboard"></i>
                                    Dashboard
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'listjobs' }" class="nav-link">
                                    <i class="value"> <img class="" src="@/assets/icons/working-with-laptop-16.png" alt=""></i>
                                    Profile
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'listcandidates' }" class="nav-link">
                                    <i class="value"> <img class="" src="@/assets/icons/candidate-16.png" alt=""></i>
                                    Candidates
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'viewclients' }" class="nav-link">
                                    <i class="value"> <img class="" src="@/assets/icons/value-16.png" alt=""></i>
                                    Clients
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'candidatelayout' }" class="nav-link">
                                    <i class="value"> <img class="" src="@/assets/icons/meeting-16.png" alt=""></i> Interviewers
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="Javascript:void(0);">
                                    <i class="ti-desktop"></i>
                                    Tool Demo
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <main role="main" class="col-md-12 ml-sm-auto col-lg-12 my-3 pt-2">
                    <div class="row">
                        <div class="card-list">
                            <div class="row dashboard-candidate justify-content-center" style="padding-left:5px;">
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'candidateprofile' }" class="">
                                        <div class="card green">
                                            <div class="value">Profile</div>
                                            <div class="value"> <img class="" src="@/assets/icons/candidate-white.png" alt=""></div>
                                            <div class="stat">Manage Profile</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'candidatejobs' }" class="">
                                        <div class="card lightred">
                                            <div class="value">Jobs</div>
                                            <div class="value"> <img class="" src="@/assets/icons/jobprofile-white.png" alt=""></div>
                                            <div class="stat">Manage Job</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'candidateinterviews' }" class="">
                                        <div class="card blue">
                                            <div class="value">Interviews</div>
                                            <div class="value"><img class="" src="@/assets/icons/candidate-white.png" alt=""></div>
                                            <div class="stat">Manage Interview</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: 'candidatescheduledinterviews' }" class="">
                                        <div class="card orange">
                                            <div class="value">Calendar</div>
                                            <div class="value"><img class="" src="@/assets/icons/meeting-white.png" alt=""></div>
                                            <div class="stat">Manage Calendar</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: '' }" class="">
                                        <div class="card grey">
                                            <div class="value">Settings</div>
                                            <div class="value"> <img class="" src="@/assets/icons/settings-gears.png" alt=""></div>
                                            <div class="stat">Manage Setting</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div v-if="!recownerid" class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: '' }" class="">
                                        <div class="card darkgreen">
                                            <div class="value">Reports</div>
                                            <div class="value"><img class="" src="@/assets/icons/statistics-white.png" alt=""></div>
                                            <div class="stat">Manage Report</div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2 col-sm-2 mb-4">
                                    <router-link :to="{ name: '' }" class="">
                                        <div class="card sanmarino">
                                            <div class="value">Tool Demo</div>
                                            <div class="value"><img class="" src="@/assets/icons/tool-demo-white.png" alt=""></div>
                                            <div class="stat">Tool Demo</div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <h5 style="background-color: #37a0e4;color: #fff;padding: 5px;" class="">Inbox</h5>
                            <div class="box-wrapper p-1 mb-5 rounded">
                                <b-tabs card class="tab-list interviewers-inbox inbox-tab-list driver-tab-list tab-bg">
                                    <b-tab v-if="false" title="Jobs">
                                        <div class="card-media" v-if="appliedJobs.length==0">
                                            <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No records found
                                            </div>
                                        </div>
                                       <!--  <div class="card-media" v-for="(request, index) in appliedJobs">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:0px;">
                                                            <span class="card-media-body-heading job-link float-left p-0">{{ request.title }}</span>
                                                            <span class="card-media-body-heading job-link pull-right pt-0 pb-0">{{request.createdAt | formatDate}}</span>
                                                        </div>
                                                        <span class="card-media-body-heading job-link p-0">Type: {{ request.interviewType }}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <span class="card-media-body-heading job-link p-0">Interviewer: {{request.interviewerName}}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <span class="card-media-body-heading job-link p-0">Date: {{request.interviewDate |formatDate}}</span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <span v-if="checkEmpty(request.interviewStartTime)" title="interviewStartTime" class="badge badgebgdashboard pull-left" style="width: 60px;">{{request.interviewStartTime | formatTime}}</span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <span v-if="checkEmpty(request.interviewEndTime)" title="interviewEndTime" class="badge badgebgdashboard pull-right" style="width: 60px;">{{request.interviewEndTime | formatTime}}</span>
                                                    </div>
                                                </div>
                                                <div class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:8px;">
                                                        <span class="job-link float-left pt-0 pb-0">
                                                            <a title="Reject" href="javascript:void(0);" @click="setInterviewId(request.id)" data-toggle="modal" data-target="#RejectRequest"><i class="ti-close" style="color: red;"></i><span style="color: red; padding-right:10px; font-family: sans-serif;"> Reject</span></a>
                                                        </span>
                                                        <span class="job-link float-right pt-0 pb-0">
                                                            <a title="Accept" href="javascript:void(0);" @click="setInterviewId(request.id)" data-toggle="modal" data-target="#AcceptRequest"><i class="ti-check" style="color: green;"></i><span class="pr-10" style="color: green; font-family: sans-serif;">Accept</span></a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </b-tab>
                                    <b-tab v-if="false" title="Interview Requests">
                                        <div class="card-media" v-if="requestData.length==0">
                                            <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(request, index) in requestData">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:0px;">
                                                            <span class="card-media-body-heading job-link float-left p-0">{{ request.title }}</span>
                                                            <span class="card-media-body-heading job-link pull-right pt-0 pb-0">{{toClientTimeZoneDate(request.createdAt, dateformat) }}</span>
                                                        </div>
                                                        <span class="card-media-body-heading job-link p-0">Type: {{ request.interviewType }}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <span class="card-media-body-heading job-link p-0">Interviewer: {{request.interviewerName}}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <span class="card-media-body-heading job-link p-0">Date: {{toClientTimeZoneDate(request.showInterviewDate, dateformat) }}</span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <span v-if="checkEmpty(request.interviewStartTime)" title="interviewStartTime" class="badge badgebgdashboard pull-left" style="width: 60px;">{{toClientTimeZoneDate(request.interviewStartTime, dateformat) }}</span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <span v-if="checkEmpty(request.interviewEndTime)" title="interviewEndTime" class="badge badgebgdashboard pull-right" style="width: 60px;">{{toClientTimeZoneDate(request.interviewEndTime, dateformat) }}</span>
                                                    </div>
                                                </div>
                                                <div class="card-media-body-top">
                                                    <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:8px;">
                                                        <span class="job-link float-left pt-0 pb-0">
                                                            <a title="Reject" href="javascript:void(0);" @click="setInterviewId(request.id)" data-toggle="modal" data-target="#RejectRequest"><i class="ti-close" style="color: red;"></i><span style="color: red; padding-right:10px; font-family: sans-serif;"> Reject</span></a>
                                                        </span>
                                                        <span class="job-link float-right pt-0 pb-0">
                                                            <a title="Accept" href="javascript:void(0);" @click="setInterviewId(request.id)" data-toggle="modal" data-target="#AcceptRequest"><i class="ti-check" style="color: green;"></i><span class="pr-10" style="color: green; font-family: sans-serif;">Accept</span></a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Interviews" class="scroll-down-inbox">
                                        <div class="card-media" v-if="interviewData.length==0">
                                            <div class="col-md-12 pt-4 pb-4" style="text-align : center;background-color: #ffff">No records found
                                            </div>
                                        </div>
                                        <div class="card-media" v-for="(interview, index) in interviewData">
                                            <div class="card-media-body">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="card-media-body-top u-float-right  w-100 text-right hrline" style="margin-top:0px;">
                                                            <a class="pull-left" href="javascript:void(0);">{{ interview.title }}</a>
                                                            <!-- <span class="card-media-body-heading job-link float-left p-0" @click="viewFeedback(interview.id,interview.candidateId)">{{ interview.title }}</span> -->
                                                            <span class="card-media-body-heading job-link pull-right pt-0 pb-0">{{toClientTimeZoneDate(interview.createdAt, dateformat) }}</span>
                                                        </div>
                                                        <span class="card-media-body-heading job-link p-0">Type: {{ interview.interviewType }}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <span class="card-media-body-heading job-link p-0">Candidate: {{interview.canName}}<span v-if="checkEmpty(interview.candidateCode)">[{{interview.candidateCode}}]</span></span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <span class="card-media-body-heading job-link p-0">Date: {{toClientTimeZoneDate(interview.showInterviewDate, dateformat) }}</span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <span v-if="checkEmpty(interview.interviewStartTime)" title="interviewStartTime" class="badge badgebgdashboard pull-left" style="width: 60px;">{{toClientTimeZoneDate(interview.interviewStartTime, timeformat) }}</span>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <span v-if="checkEmpty(interview.interviewEndTime)" title="interviewEndTime" class="badge badgebgdashboard pull-right" style="width: 60px;">{{toClientTimeZoneDate(interview.interviewEndTime, timeformat) }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                        <div class="col-sm-9 p-4">
                            <h5>Performance Chart</h5>
                            <div style="position:relative;">
                                <div class="" style="position:absolute;top:20px;left:900px; z-index:2000">
                                    <select v-model="year" @change="loadGraph(year)">
                                        <option value="2019">2019</option>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                    </select>
                                </div>
                            </div>
                            <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <div class="modal " id="RejectRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Candidate confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to reject this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('reject')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal " id="AcceptRequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Candidate confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure to accept this request?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="interviewConfirmation('accept')" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { GChart } from "vue-google-charts";
import iziToast from 'izitoast';

export default {

    name: 'app',
    components: {
        GChart
    },
    props: {

    },
    computed: {
        recownerid() {
            return this.$store.getters.currentUser.recruitOwner;
        },
        candidateId(){
            return this.$store.getters.currentUser.profileId;
        }
    },
    data() {
        return {
            interviewId: "",
            currentTab: 1,
            requestData: [],
            interviewData: [],
            joblist: [],
            appliedJobs: [],
            dateformat:this.$store.state.dateformat,
            timeformat:this.$store.state.timeformat,
            browsepagination: this.$store.state.paginationconfig,
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist: this.$store.getters.getIndustries,
            clientlist: this.$store.getters.getClients,
            jobStatuslist: this.$store.getters.getJobStatus,
            elements: {
                showadvancedsearch: false,
            },
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",
                    recruiterId: this.recruiterId,
                    clientId: "",
                    industryId: "",
                    status: ""
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                position: true
            },
            delJobParam: {
                index: -1,
                id: 0
            },
            labels: ['Talents Identified', 'Shortlisted', 'Hired'],
            subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [120, 57, 9],
            colors: ['#37a0e4', '#898a8e'],
            colors1: ['#DA932C', '#66B92E', '#24A0ED', '#A9A9A9'],
            chartColors: ['#DA932C', '#66B92E', '#24A0ED'],

            direction: 'vertical',
            gradientDirection: 'vertical',
            height: 347,
            width: 300,
            chartData: [],
            chartOptions: {
                //title: 'Interview [Month-wise]',
                subtitle: 'Month, Interviews',
                height: 350
                //colors: ['#2298f1', '#898a8e'],
            },
            year: 2023
        }
    },

    mounted() {
        this.loadJobs();
        this.loadInterviews();
        this.loadRequests();
        //this.loadAppliedJobs(this.candidateId);
        if (this.$route.params.jobId)
            iziToast.success({ title: 'Success', message: this.$config.JobApply, position: 'topRight' });
        //this.loadYearlyRecruitments();
        this.loadGraph(2023);

    },
    methods: {
        loadGraph(year) {
            axios.post(this.$store.getters.getAPIBasePath + '/dashboardresource/candidate/dashboard/chart/' + this.candidateId + '/'+ year)
                .then(response => {
                    let result = response.data;
                    this.chartData = result.interviewerPerformance;
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                });
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
        loadJobs() {
            axios.post(this.$store.getters.getAPIBasePath + '/jobresource/inboxjoblist', this.search.params)
                .then(response => {
                    let result = response.data;
                    this.joblist = result.jobs;                   
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadYearlyRecruitments() {

            var currentDate = new Date();
            console.log(currentDate);
            var month = currentDate.getMonth();
            let data = { "createdYear": "2019" };

            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/yearly/recruitment/candidate', data)
                .then(response => {

                    let result = response.data;
                    this.values = result.recruitment;
                    console.log(result);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadRecruitmentsByJob(jobId) {

            var currentDate = new Date();
            console.log(currentDate);
            var month = currentDate.getMonth();
            let data = { "jobId": jobId };

            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/recruitment/job', data)
                .then(response => {

                    let result = response.data;
                    this.values = result.recruitment;

                    console.log(result);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadRequests() {
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/list/interviewrequest/' + this.$store.getters.currentUser.profileId + '?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    let result = response.data;
                    console.log(result);
                    if (!result.request) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.RecNotFou,
                            position: 'topRight'
                        });
                    } else {
                        this.requestData = Array.from(result.request);
                        //this.page_conf=Object.assign({}, result.pagination[0]);
                        this.browsepagination.totalrecords = result.pagination[0].total;
                        this.browsepagination.currentpage = result.pagination[0].currentPage;
                        this.browsepagination.totalpages = result.pagination[0].lastPage;
                    }
                });
        },
        setInterviewId(id) {

            this.interviewId = id;
        },
        interviewConfirmation: function(type) {

            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/confirmrequest/' + this.interviewId, { 'type': type })
                .then(response => {
                    let result = response.data;
                    if (result.status) {
                        iziToast.success({
                            title: 'Confirmation Request',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadInterviews();
                        this.loadRequests();
                    } else {
                        iziToast.error({
                            title: 'Confirmation Request',
                            message: this.$config.ReqConfirmErr,
                            position: 'topRight'
                        });
                    }
                });

        },
        loadInterviews() {
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/list/candidate/interviews/' + this.$store.getters.currentUser.profileId + '?page=' + this.browsepagination.currentpage + '&size=' + this.search.limit, this.search.params)
                .then(response => {
                    //console.log(response);
                    let result = response.data;
                    if (!result.interviews) {
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.RecNotFou,
                            position: 'topRight'
                        });
                    } else {
                        this.interviewData = Array.from(result.interviews);
                        // this.page_conf=Object.assign({}, result.pagination[0]);
                        this.browsepagination.totalrecords = result.pagination[0].total;
                        this.browsepagination.currentpage = result.pagination[0].currentPage;
                        this.browsepagination.totalpages = result.pagination[0].lastPage;
                    }
                });
        },
        loadAppliedJobs(candId) {
            console.log(candId);
            let data = { "candidateId": candId };

            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/candidate/applied/jobs', data)
                .then(response => {
                    let result = response.data;
                    this.appliedJobs = result.jobsApplied;
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
