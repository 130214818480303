<style type="text/css">
.input-group.extended>div {
    width: auto !important;
}

.mce-branding {
    display: none !important;
}
</style>
<template>
    <div :class="{ viewonly: newJobForm.viewonly == 1 }">
        <div>
            <div class="card">
                <div class="card-header p-2">Primary Job Info - [{{ newJobForm.jobCode }}]</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Job Title</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <input type="text" name="jobTitle" maxlength="200" value=""
                                                class="fldboxbig form-control" v-model="newJobForm.jobTitle" />
                                            <span class="required-icon"> </span>
                                        </div>
                                        <label v-if="
                                            $v.newJobForm.jobTitle.$error &&
                                            !$v.newJobForm.jobTitle.required
                                        " class="text-danger">Please enter Job Title</label>
                                        <label v-if="
                                            $v.newJobForm.jobTitle.$error &&
                                            !$v.newJobForm.jobTitle
                                                .minLength
                                        " class="text-danger">Job Title must have at least
                                            {{
                                                $v.newJobForm.jobTitle.$params
                                                    .minLength.min
                                            }}
                                            letters.</label>
                                        <label v-if="
                                            $v.newJobForm.jobTitle.$error &&
                                            !$v.newJobForm.jobTitle
                                                .maxLength
                                        " class="text-danger">Job Title must not exceed
                                            {{
                                                $v.newJobForm.jobTitle.$params
                                                    .maxLength.max
                                            }}
                                            letters.</label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Job Code</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" disabled="disabled" name="jobCode" maxlength="30"
                                                value="" class="fldboxbig form-control" v-model="newJobForm.jobCode" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Client</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="newJobForm.clientId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in clientlist" :value="data.id">
                                                {{ data.client_Name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Industry</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="newJobForm.industryId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in industrylist" :value="data.id">{{
                                                data.industryName }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Country</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group required-field-block">
                                            <select v-model="newJobForm.countryId" @change="
                                                loadState(
                                                    newJobForm.countryId
                                                )
                                                " class="form-control" required>
                                                <option value="">Select</option>
                                                <option v-for="(data, index
                                                ) in countries" :value="data.country_Id">
                                                    {{ data.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <span class="required-icon"> </span>
                                        <label v-if="
                                            $v.newJobForm.countryId.$error &&
                                            !$v.newJobForm.countryId.required
                                        " class="text-danger">Please choose Country</label>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Location</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <!-- <div class="input-group">
                                            <input type="text" name="location" maxlength="100" value="" class="fldboxbig form-control" v-model="newJobForm.location">
                                        </div>
                                        <label v-if="$v.newJobForm.location.$error && !$v.newJobForm.location.minLength" class="text-danger">Location must have at least {{$v.newJobForm.location.$params.minLength.min}} letters.</label> -->
                                        <multiselect style="width: 92%; float: left" v-model="newJobForm.location"
                                            :options="usstates" track-by="id" label="stateName"
                                            name="newJobForm.location" :multiple="true" :taggable="false"
                                            :show-labels="false" placeholder="Select State"></multiselect>
                                        <label v-if="
                                            $v.newJobForm.location.$error &&
                                            !$v.newJobForm.location.required
                                        " class="text-danger">Please choose Job Location</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Qualification</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <!-- <div class="input-group">
                                            <input type="text" name="eligibility" maxlength="500" value="" class="fldboxbig form-control" v-model="newJobForm.eligibility">
                                        </div> -->
                                        <multiselect style="width: 92%; float: left" v-model="newJobForm.eligibility"
                                            :options="qualification" track-by="id" label="qualificationName"
                                            name="newJobForm.eligibility" :multiple="true" :taggable="false"
                                            :show-labels="false" placeholder="Select Qualification"></multiselect>
                                        <label v-if="
                                            $v.newJobForm.eligibility
                                                .$error &&
                                            !$v.newJobForm.eligibility
                                                .minLength
                                        " class="text-danger">Qualification must have at least
                                            {{
                                                $v.newJobForm.eligibility
                                                    .$params.minLength.min
                                            }}
                                            letters.</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Experience</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group extended">
                                            <input type="number" name="experience'" maxlength=2 value=""
                                                class="fldboxbig form-control" v-model="newJobForm.experience"
                                                onKeyPress="if(this.value.length>=2) return false;"
                                                @input="validateExperience" />
                                            <!-- <span v-if="experienceError" style="color: red;">{{ experienceError }}</span> -->
                                            <label v-if="
                                                $v.newJobForm.experience
                                                    .$error &&
                                                !$v.newJobForm.experience
                                                    .isExperienceValid
                                            " class="text-danger">Experience must be less than or
                                                equal to 50
                                            </label>

                                            <div class="input-group-append">
                                                <select v-model="newJobForm.expType" class="form-control p-0">
                                                    <option v-for="(data, index
                                                    ) in expTypeList" :value="data.name">
                                                        {{ data.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Vacancies</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" name="vacancies" class="fldboxbig form-control"
                                                v-model="newJobForm.vacancies" @input="validateVacancies" />
                                        </div>
                                        <span
                                            v-if="newJobForm.vacancies && ![1, 2, 3, 4, 5].includes(Number(newJobForm.vacancies))"
                                            class="text-danger">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Employee Type</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select v-model="newJobForm.employeeType" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index
                                                ) in employeeTypeList" :value="data.id">
                                                    {{ data.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Work Type</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select v-model="newJobForm.workType" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index) in worktypelist" :value="data.id">
                                                    {{ data.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                          
                            </div>



                            <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Due Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group date" data-date-format="mm-dd-yyyy">
                                            <datepicker name="dueDate" maxlength="11" value="" id="dueDate"
                                                input-class="form-control enablefield" wrapper-class="input-group date"
                                                format="MM/dd/yyyy" v-model="newJobForm.dueDate"
                                                :disabledDates="disabledDates"></datepicker>
                                            <span class="calendar-addon"><span v-if="newJobForm.dueDate"
                                                    @click="clearDueDate"
                                                    class="fa fa-close pt-1 pl-1 hover-hand maR_5"></span><span
                                                    class="ti-calendar"></span></span>
                                        </div>
                                        <label v-if="
                                            $v.newJobForm.dueDate.$error &&
                                            !$v.newJobForm.dueDate.required
                                        " class="text-danger">Please enter Due Date</label>

                                    </div>
                                </div>
                            </div> -->

                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Due Date</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group date" data-date-format="mm-dd-yyyy">
                                            <datepicker name="dueDate" maxlength="11" id="dueDate"
                                                input-class="form-control enablefield" wrapper-class="input-group date"
                                                format="MM/dd/yyyy" v-model="newJobForm.dueDate"
                                                :disabledDates="disabledDates" :year-range="[2000, 2050]"
                                                @input="validateDueDate"></datepicker>
                                            <span class="calendar-addon">
                                                <span v-if="newJobForm.dueDate" @click="clearDueDate"
                                                    class="fa fa-close pt-1 pl-1 hover-hand maR_5"></span>
                                                <span class="ti-calendar"></span>
                                            </span>
                                        </div>
                                        <label v-if="dateError" class="text-danger">Year must be between 2000 and
                                            2050</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Scope</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select v-model="newJobForm.accessLevel" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index
                                                ) in jobScopeList" :value="data.id">
                                                    {{ data.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select v-model="newJobForm.status" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="( data, index
                                                    ) in jobStatuslist" :value="data.id">
                                                    {{ data.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Salary </label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group extended">
                                            <select v-model="newJobForm.currency" class="form-control p-0">
                                                <option v-for="(info, index) in currencies" :key="index"
                                                    :value="info.symbol">
                                                    {{ info.symbol }}
                                                </option>
                                            </select>
                                        
                                            <input type="number" class="form-control" maxlength="5"
                                                v-model="newJobForm.salary" @keypress="onlyNumber" />
                                                <span class="required-icon"></span> 
                                            <div class="input-group-append">
                                                <select v-model="newJobForm.payFrequency
                                                    " class="form-control p-0">
                                                    <option v-for="(data, index
                                                    ) in payFrequencyList" :value="data.name">
                                                        {{ data.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <label v-if="
                                            $v.newJobForm.salary.$error &&
                                            !$v.newJobForm.salary.required
                                        " class="text-danger">Please enter Salary </label>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Salary</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group extended">
                                            <select v-model="newJobForm.currency" class="form-control p-0">
                                                <option v-for="(info, index) in currencies" :key="index"
                                                    :value="info.symbol">
                                                    {{ info.symbol }}
                                                </option>
                                            </select>

                                            <input type="number" class="form-control" v-model="newJobForm.salary"
                                                @input="restrictSalary" required />

                                            <span class="required-icon"></span>

                                            <div class="input-group-append">
                                                <select v-model="newJobForm.payFrequency" class="form-control p-0">
                                                    <option v-for="(data, index) in payFrequencyList" :key="index"
                                                        :value="data.name">
                                                        {{ data.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <label v-if="salaryError" class="text-danger">
                                            Salary must not exceed 1,000,000.
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Minimum Salary</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group extended">
                                            <input v-bind:disabled="newJobForm.frequencyPercentage == '' ||
                                                newJobForm.frequencyPercentage == undefined ||
                                                newJobForm.frequencyPercentage == null
                                                ? false : true" type="number" name="minsalary" class="fldboxbig form-control"
                                                v-model="newJobForm.minSalary" @input="validateMinSalary" />
                                        </div>
                                        <label v-if="newJobForm.minSalary > 1000000" class="text-danger">
                                            Minimum Salary cannot exceed 1,000,000.
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Maximum Salary</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group extended">
                                            <input v-bind:disabled="newJobForm.frequencyPercentage == '' ||
                                                newJobForm.frequencyPercentage == undefined ||
                                                newJobForm.frequencyPercentage == null ? false : true" type="number" name="maxsalary" maxlength="7"
                                                class="fldboxbig form-control" v-model="newJobForm.maxSalary"
                                                @input="validateMaxSalary" />
                                        </div>
                                        <label v-if="newJobForm.maxSalary > 1000000" class="text-danger">
                                            Maximum Salary cannot exceed 1,000,000.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Flexibility Percentage</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input v-bind:disabled="(newJobForm.maxSalary == null && newJobForm.minSalary == '') ||
                                                (newJobForm.maxSalary == '' && newJobForm.minSalary == null) ||
                                                (newJobForm.maxSalary == '' && newJobForm.minSalary == '') ||
                                                (newJobForm.maxSalary && newJobForm.minSalary == undefined) ||
                                                (newJobForm.maxSalary == null && newJobForm.minSalary == null)
                                                ? false : true" type="text" name="frepercentage" class="fldboxbig form-control"
                                                v-model="newJobForm.frequencyPercentage" @input="validatePercentage" />
                                        </div>
                                        <label class="whole_no"></label>
                                        <label v-if="newJobForm.frequencyPercentage > 100" class="text-danger">
                                            Value cannot exceed 100.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header p-2">Job Info</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="basic-block">
                            <div class="basic-block clone-education">
                                <div class="row ten-columns pos-relative clone filled3">
                                    <div v-if="workAuthAccess == true" class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row ten-columns">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Work Authorization</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12 lastnote">
                                                <div class="input-group">
                                                    <div>
                                                        <multiselect style="
                                                                width: 92%;
                                                                float: left;
                                                            " v-model="newJobForm.workAuthorization
                                                                " :options="workAuthorization
                                                                    " track-by="id" label="workAuthorizationName"
                                                            name="newJobForm.workAuthorization" :multiple="true"
                                                            :taggable="false" :show-labels="false"
                                                            placeholder="Select Work Authorization"></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row ten-columns">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Documents Required</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12 lastnote">
                                                <div class="input-group">
                                                    <div>
                                                        <multiselect style="
                                                                width: 92%;
                                                                float: left;
                                                            " v-model="newJobForm.jobDocuments
                                                                " :options="jobDocuments
                                                                    " track-by="id" label="documentName"
                                                            name="newJobForm.jobDocuments" :multiple="true"
                                                            :taggable="false" :show-labels="false"
                                                            placeholder="Select Documents"></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row ten-columns">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Tax Term</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12 lastnote">
                                                <div class="input-group">
                                                    <select v-model="newJobForm.taxTermId
                                                        " class="form-control">
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        <option v-for="(data, index
                                                            ) in taxTermsList" :value="data.id">
                                                            {{
                                                                data.taxTermName
                                                            }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <select v-model="newJobForm.workType" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(data, index) in worktypelist" :value="data.id">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header p-2">Skill Details</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="basic-block">
                            <div class="basic-block clone-education">
                                <div class="row ten-columns pos-relative clone filled3">
                                    <div v-if="false" class="col-md-2 col-sm-6 col-2 mb-10-mr">
                                        <div class="row ten-columns">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Skill Type</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group required-field-block">
                                                    <select name="skillType" size="1" id="skillType"
                                                        class="form-control" v-model="newSkillForm.skillType
                                                            ">
                                                        <option value="1" selected>
                                                            Primary
                                                        </option>
                                                        <option value="2">
                                                            Secondary
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-11 col-sm-6 col-12 mb-10-mr">
                                        <div class="row ten-columns">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Industry</label>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-12 lastnote">
                                                <select v-model="industryId" @change="
                                                    loadTechnologyList()
                                                    " class="form-control">
                                                    <option value="" selected>
                                                        Select
                                                    </option>
                                                    <option v-for="(Industry, index
                                                        ) in industries" :value="Industry.id" v-bind:key="index">
                                                        {{
                                                            Industry.industryName
                                                        }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row ten-columns">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Technology/Specialization</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12 lastnote">
                                                <div class="input-group">
                                                    <div>
                                                        <multiselect v-model="technology_value
                                                            " :options="technologies
                                                                " name="technology_value" label="technology"
                                                            track-by="id" select-label="" deselect-label=""
                                                            @select="loadSkills" class="" style="
                                                                width: 92%;
                                                                float: left;
                                                            " :max-height="600"></multiselect>
                                                        <a href="Javascript:void(0);" class="tbl-add-icon"
                                                            style="float: left" data-toggle="modal"
                                                            data-target="#addNewTech"><span class="input-group-addon"><i
                                                                    class="ti-settings"></i></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-9 col-sm-6 col-12 mb-10-mr">
                                        <div class="row ten-columns">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Skill</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <div>
                                                        <multiselect style="
                                                                width: 92%;
                                                                float: left;
                                                            " v-model="newJobForm.jobSkill
                                                                " :options="skills" name="newJobForm.jobSkill"
                                                            label="skillName" track-by="skillId" select-label=""
                                                            deselect-label="" :close-on-select="false
                                                                " :clear-on-select="false
                                                                    " :multiple="true" :taggable="false"></multiselect>
                                                        <a style="float: left" v-if="
                                                            typeof this
                                                                .technology_value ===
                                                            'object'
                                                        " href="Javascript:void(0);" class="tbl-add-icon"
                                                            data-toggle="modal" data-target="#addNewSkill"><span
                                                                class="input-group-addon"><i
                                                                    class="ti-settings"></i></span></a>
                                                        <a v-if="
                                                            skillEditIndex <
                                                            0
                                                        " style="float: left" href="Javascript:void(0);"
                                                            class="tbl-add-icon" @click="addMoreSkillDetails
                                                            "><span class="input-group-addon"><i
                                                                    class="ti-plus"></i></span></a>
                                                        <a v-if="
                                                            skillEditIndex !=
                                                            -1
                                                        " style="float: left" href="Javascript:void(0);"
                                                            class="tbl-add-icon" @click="
                                                                updateSkillDetails(
                                                                    1
                                                                )
                                                                "><span class="input-group-addon"><i
                                                                    class="ti-check"></i></span></a>
                                                        <a v-if="
                                                            skillEditIndex !=
                                                            -1
                                                        " style="float: left" href="Javascript:void(0);"
                                                            class="tbl-add-icon" @click="
                                                                updateSkillDetails(
                                                                    0
                                                                )
                                                                "><span class="input-group-addon"><i
                                                                    class="ti-close"></i></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="skillDetails.length" class="basic-block clone-education">
                                    <div v-for="(item, index) in skillDetails" v-bind:key="index"
                                        class="row ten-columns pos-relative clone filled3">
                                        <div class="col-md-3 col-sm-12 col-12 lb">
                                            <div class="row ten-columns">
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="form-control">
                                                        {{
                                                            item.technology
                                                                .technology
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-9 col-sm-12 col-12 lb">
                                            <div class="row ten-columns">
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <div>
                                                            <div style="
                                                                    width: 92%;
                                                                    float: left;
                                                                " class="form-control skillList">
                                                                <ul>
                                                                    <li v-for="(item1, index1
                                                                        ) in item.skills" :key="index1
                                                                            " class="item rounded">
                                                                        <span class="text">{{
                                                                            item1.skillName
                                                                            }}</span>
                                                                        <a href="javascript:;" @click="
                                                                            removeSkill(
                                                                                index,
                                                                                index1
                                                                            )
                                                                            "><i
                                                                                class="ti-close item-close-icon1"></i></a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <a style="
                                                                    float: left;
                                                                " href="Javascript:void(0);" class="tbl-add-icon"
                                                                @click="
                                                                    removeOneSkillDetail(
                                                                        item
                                                                            .technology
                                                                            .id
                                                                    )
                                                                    "><span class="input-group-addon"><i
                                                                        class="ti-trash"></i></span></a>
                                                            <a style="
                                                                    float: left;
                                                                " href="Javascript:void(0);" class="tbl-add-icon"
                                                                @click="
                                                                    editOneSkillDetail(
                                                                        index
                                                                    )
                                                                    "><span class="input-group-addon"><i
                                                                        class="ti-pencil"></i></span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header p-2">Job Requirement</div>
                <div class="card-body p-2">
                    <div class="basic-block">
                        <div class="row ten-columns">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <template>
                                            <div>
                                                <textarea rows="10" class="form-control no-resize"
                                                    v-model="newJobForm.jobRequirement" @input="limitWords"
                                                    ref="jobRequirementTextarea"></textarea>
                                                <p class="word-count">{{ wordCount }} WORDS </p>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-card v-if="vendorClientInfoAccess">
                <b-tabs card>
                    <b-tab title="Client Info" active>
                        <b-card-text>
                            <!-- <div class="card"> -->
                            <!--   <div class="card-header"></div> -->
                            <div class="card-body p-2">
                                <div class="basic-block">
                                    <div class="row ten-columns">
                                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>Client Name</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="position-relative">
                                                        <input type="text" maxlength="100" class="form-control"
                                                            v-model="newJobForm.clientName"
                                                            @input="validateClientName" />

                                                        <a href="javascript:;" data-toggle="modal"
                                                            data-target="#ClientNameShow">
                                                            <span id="submitLookUp" class="search-addon"
                                                                style="display: inline;">
                                                                <span class="ti-search srcicn"></span>
                                                            </span>
                                                        </a>

                                                        <label v-if="clientNameError" class="text-danger">
                                                            Please enter only letters.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>Account Name(Email
                                                        Id)</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="localRadius" maxlength="100" value=""
                                                            class="fldboxbig form-control" v-model="newJobForm.accountName
                                                                " />
                                                    </div>

                                                    <label v-if="
                                                        $v.newJobForm
                                                            .accountName
                                                            .$error &&
                                                        !$v.newJobForm
                                                            .accountName
                                                            .required
                                                    " class="text-danger">Please enter
                                                        email</label>
                                                    <label v-if="
                                                        $v.newJobForm
                                                            .accountName
                                                            .$error &&
                                                        !$v.newJobForm
                                                            .accountName
                                                            .email
                                                    " class="text-danger">Please enter a valid
                                                        email</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr position-relative">
                                            <a href="javascript:;">
                                                <i class="fa fa-ban position-absolute userplus" aria-hidden="true"
                                                    @click="resetContactInfo()" data-toggle="tooltip"
                                                    title="Clear"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--  </div> -->
                            <div class="card">
                                <div class="card-header p-2">Contact Info</div>
                                <div class="card-body p-2">
                                    <div class="basic-block">
                                        <div class="row ten-columns">
                                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Contact
                                                            Person</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input type="text" name="contactPerson" maxlength="25"
                                                                value="" class="fldboxbig form-control" v-model="newJobForm
                                                                    .contact
                                                                    .contactPerson
                                                                    " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Email</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input type="text" name="email" maxlength="100" value=""
                                                                class="fldboxbig form-control" v-model.lazy="newJobForm
                                                                    .contact
                                                                    .email
                                                                    " @blur="
                                                                        $v
                                                                            .newJobForm
                                                                            .contact
                                                                            .email
                                                                            .$touch
                                                                    " />
                                                        </div>
                                                        <label v-if="
                                                            $v.newJobForm
                                                                .contact
                                                                .email
                                                                .$error &&
                                                            !$v.newJobForm
                                                                .contact
                                                                .email.email
                                                        " class="text-danger">Please enter a
                                                            valid email</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Phone</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <PhoneNumber ref="phoneNumber" :value="newJobForm
                                                                .contact
                                                                .phone1
                                                                " @phone-input="clientphoneControlInput
                                                                " :isValidcheck="isValidCheck
                                                                    " />
                                                            <!-- <input type="text" name="phone" @keypress="onlyNumber" maxlength="15" value="" class="fldboxbig form-control" v-model="newJobForm.contact.phone1"> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Mobile</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <PhoneNumber ref="phoneNumber" :value="newJobForm
                                                            .contact
                                                            .phone2
                                                            " @phone-input="phoneControlInput
                                                            " :isValidcheck="isaltValidCheck
                                                                " />
                                                        <!-- <div class="input-group phone-border">
                                                            <select class="form-control box-none col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0" v-model="newJobForm.contact.countryCode">
                                                                <option value="+1">+1</option>
                                                                <option value="+91">+91</option>
                                                            </select>
                                                            <input-mask class="fldboxbig form-control box-none border-bottom-0" v-model="newJobForm.contact.mobile" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                                <div class="row">
                                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                                        <label>Website</label>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input type="text" name="website" maxlength="25" value=""
                                                                class="fldboxbig form-control" v-model="newJobForm
                                                                    .contact
                                                                    .website
                                                                    " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row ten-columns">
                                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>Address </label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="address1" maxlength="200" value=""
                                                            class="fldboxbig form-control" v-model="newJobForm
                                                                .contact
                                                                .addressLine1
                                                                " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>Address 2</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="address2" maxlength="200" value=""
                                                            class="fldboxbig form-control" v-model="newJobForm
                                                                .contact
                                                                .addressLine2
                                                                " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>City / Place</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="city" maxlength="25" value=""
                                                            class="fldboxbig form-control" v-model="newJobForm
                                                                .contact
                                                                .cityName
                                                                " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>State</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="state" maxlength="25" value=""
                                                            class="fldboxbig form-control" v-model="newJobForm
                                                                .contact
                                                                .stateName
                                                                " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>Country</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <select v-model="newJobForm.contact
                                                        .countryId
                                                        " class="form-control">
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        <option v-for="(
data, index
                                                            ) in countries" :value="data.country_Id
                                                                ">
                                                            {{ data.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label>Zip Code</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="number"
                                                            onKeyPress="if(this.value.length>=6) return false;"
                                                            name="zipCode" value="" class="fldboxbig form-control"
                                                            v-model="newJobForm.contact.zipCode">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Vendor Info">
                        <b-card-text>
                            <!-- <div class="card"> -->
                            <!--  <div class="card-header p-2"></div> -->
                            <div class="card-body p-2">
                                <div class="basic-block">
                                    <div class="row ten-columns">
                                        <table class="table table-borderless all-table">
                                            <thead>
                                                <tr>
                                                    <th class="" scope="col">
                                                        Vendor Name
                                                    </th>
                                                    <th></th>
                                                    <th class="videoprofile" scope="col">
                                                        Account Name(Email Id)
                                                    </th>
                                                    <th class="title" scope="col">
                                                        Contact Person
                                                    </th>
                                                    <th class="recruiter" scope="col">
                                                        Email
                                                    </th>
                                                    <th class="state" scope="col">
                                                        Mobile
                                                    </th>
                                                    <th class="" scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody class="cloned_tr">
                                                <tr v-for="(info, index
                                                ) in newJobForm.vendorDetails" :class="{
                                                        viewonly:
                                                            newJobForm
                                                                .vendorDetails
                                                                .viewonly == 1,
                                                    }">
                                                    <td>
                                                        <input v-model="newJobForm
                                                            .vendorDetails[
                                                            index
                                                        ].clientName
                                                            " maxlength="100" class="form-control" type="text"
                                                            :readonly="newJobForm
                                                                .vendorDetails[
                                                                index
                                                            ]
                                                                .jobVendorContactId >
                                                                0
                                                                " />
                                                    </td>
                                                    <td></td>
                                                    <td class="lastnote">
                                                        <input v-model="newJobForm
                                                            .vendorDetails[
                                                            index
                                                        ]
                                                            .clientAccountName
                                                            " maxlength="100" class="form-control" type="text"
                                                            :readonly="newJobForm
                                                                .vendorDetails[
                                                                index
                                                            ]
                                                                .jobVendorContactId >
                                                                0
                                                                " />
                                                    </td>
                                                    <td class="recruiter">
                                                        <input v-model="newJobForm
                                                            .vendorDetails[
                                                            index
                                                        ].contactPerson
                                                            " maxlength="50" class="form-control" type="text" />
                                                    </td>
                                                    <td class="title">
                                                        <input v-model="newJobForm
                                                            .vendorDetails[
                                                            index
                                                        ].email
                                                            " maxlength="100" class="form-control" type="text" />
                                                    </td>
                                                    <td class="title">
                                                        <div class="input-group phone-border">
                                                            <select
                                                                class="form-control box-none col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0"
                                                                v-model="newJobForm
                                                                    .vendorDetails[
                                                                    index
                                                                ]
                                                                    .countryCode
                                                                    ">
                                                                <option value="+1">
                                                                    +1
                                                                </option>
                                                                <option value="+91">
                                                                    +91
                                                                </option>
                                                            </select>
                                                            <input
                                                                class="form-control box-none border-bottom-0 pl-0 pr-0"
                                                                id="mobileNo" type="text" v-model="newJobForm
                                                                    .vendorDetails[
                                                                    index
                                                                ].mobile
                                                                    " @input="
                                                                        acceptNumber(
                                                                            index
                                                                        )
                                                                        " />
                                                        </div>
                                                        <!-- <input v-model="newJobForm.vendorDetails[index].phone1" maxlength="20"  class="form-control" type="text" /> -->
                                                    </td>
                                                    <td>
                                                        <a :class="{
                                                            removeaddbtn:
                                                                newJobForm
                                                                    .vendorDetails
                                                                    .viewonly ==
                                                                1,
                                                        }" class="removemoretextbox" href="Javascript:void(0);"
                                                            data-toggle="modal" data-target="#removeVendor" @click="
                                                                confirmVendor(
                                                                    index,
                                                                    newJobForm
                                                                        .vendorDetails[
                                                                        index
                                                                    ]
                                                                        .jobVendorContactId
                                                                )
                                                                "><span class="ti-trash"></span></a>
                                                    </td>
                                                </tr>
                                                <tr class="fill">
                                                    <td>
                                                        <input v-model="newvendorInfo.clientName"
                                                            ref="addcandidate_preferenceinfo_add_title"
                                                            class="form-control" maxlength="100" type="text"
                                                            oninput="this.value = this.value.replace(/[^A-Za-z\s]/g, '')"
                                                            title="Only letters are allowed" />

                                                    </td>
                                                    <td>
                                                        <a href="javascript:;" data-toggle="modal"
                                                            data-target="#VendorDetailsShow">
                                                            <!-- @click="showEndClientModal()"-->
                                                            <span id="submitLookUp" class="" style="
                                                                    display: inline;
                                                                ">
                                                                <span class="ti-search srcicn"></span></span>
                                                        </a>
                                                    </td>
                                                    <td class="lastnote">
                                                        <input class="form-control" type="email" maxlength="100"
                                                            v-model.lazy="newvendorInfo.clientAccountName"
                                                            pattern="[a-zA-Z0-9._%+-]+@gmail\.com$"
                                                            title="Please enter a valid Gmail address (e.g., example@gmail.com)"
                                                            required
                                                            :class="{ 'is-invalid': newvendorInfo.clientAccountName && !/^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(newvendorInfo.clientAccountName) }" />

                                                        <label class="text-danger"
                                                            v-if="newvendorInfo.clientAccountName && !/^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(newvendorInfo.clientAccountName)">
                                                            Please enter a valid Gmail address (e.g.,
                                                            example@gmail.com).
                                                        </label>


                                                                   
                                                    </td>
                                                    <td class="recruiter">
                                                        <input class="form-control" type="text" maxlength="20"
                                                            v-model="newvendorInfo.contactPerson"
                                                            @input="newvendorInfo.contactPerson = newvendorInfo.contactPerson.replace(/[^A-Za-z\s]/g, '')" />


                                                    </td>
                                                    <td class="title">
                                                        <input class="form-control" type="email" maxlength="100"
                                                            v-model.lazy="newvendorInfo.email"
                                                            pattern="[a-zA-Z0-9._%+-]+@gmail\.com$"
                                                            title="Please enter a valid Gmail address (e.g., example@gmail.com)"
                                                            required
                                                            :class="{ 'is-invalid': newvendorInfo.email && !/^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(newvendorInfo.email) }" />

                                                        <label class="text-danger"
                                                            v-if="newvendorInfo.email && !/^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(newvendorInfo.email)">
                                                            Please enter a valid Gmail address (e.g.,
                                                            example@gmail.com).
                                                        </label>


                                                                   
                                                    </td>
                                                    <td class="specialityunit">
                                                        <PhoneNumber ref="phoneNumber" :value="newvendorInfo.phone1
                                                            " @phone-input="vendorphoneControlInput
                                                            " :isValidcheck="isvendorValidCheck
                                                                " />
                                                        <!-- <div class="input-group">
                                                            <select class="form-control col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0" v-model="newvendorInfo.countryCode">
                                                                <option value="+1">+1</option>
                                                                <option value="+91">+91</option>
                                                            </select>
                                                            <input class="form-control" id="phoneNo" type="text" maxlength="10" v-model="newvendorInfo.phone1" @keypress="formatUsPhone($event,'#phoneNo')"></input>
                                                        </div> -->
                                                    </td>
                                                    <td>
                                                        <a class="addmoretextbox" href="Javascript:void(0);" @click="addNewVendorInfo
                                                        "><span class="ti-plus"></span></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- </div> -->
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
            <div class="modal" id="ClientNameShow" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                                Client Name
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="card m-b-3">
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="col-sm-12 clientinfo">
                                            <table class="table table-borderless all-table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Client Id</th>
                                                        <th>Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="
                                                        !clientlist.length
                                                    ">
                                                        <td class="text-center" colspan="100%">
                                                            No Client found
                                                        </td>
                                                    </tr>
                                                    <tr v-for="(client, index
                                                        ) in clientlist">
                                                        <td>
                                                            <div class="col-sm-12 col-12">
                                                                <input type="radio" name="jobClient" :id="'cli' +
                                                                    client.id
                                                                    " :value="client.id
                                                                        " v-model="newJobForm.clientId
                                                                            " />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{
                                                                client.clientCode
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                client.client_Name
                                                            }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" @click="getClientContact()" class="btn btn-primary"
                                            data-dismiss="modal">
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal" id="VendorDetailsShow" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                                Vendor / Client Name
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="card m-b-3">
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="col-sm-12 clientinfo">
                                            <table class="table table-borderless all-table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Client Id</th>
                                                        <th>Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="
                                                        !clientlist.length
                                                    ">
                                                        <td class="text-center" colspan="100%">
                                                            No Client found
                                                        </td>
                                                    </tr>
                                                    <tr v-for="(client, index
                                                        ) in clientlist">
                                                        <td>
                                                            <div class="col-sm-12 col-12">
                                                                <input type="radio" name="jobClient" :id="'cli' +
                                                                    client.id
                                                                    " :value="client.id
                                                                        " v-model="newJobForm.vendorClientId
                                                                            " />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{
                                                                client.clientCode
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                client.client_Name
                                                            }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" @click="getVendorClientContact()" class="btn btn-primary"
                                            data-dismiss="modal">
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MultiInputsDialog id="addNewTech" title="Add new technologies" inputPlaceholder="Enter new technology"
            :inputItems="newTechs" :onSave="saveNewTech" />
        <MultiInputsDialog id="addNewSkill" title="Add new skills" inputPlaceholder="Enter new skill"
            :inputItems="newSkills" :onSave="saveNewSkills" />
        <Dialog id="removeVendor" :onYes="deleteVendor" :returnParams="delVendorParam" />
    </div>
</template>
<style scoped>
.no-resize {
    resize: none;
}

.word-count {
    margin-top: 5px;
    font-size: 14px;
    color: #555;
}
</style>
<script>
import MultiInputsDialog from "../../../plugins/MultiInputsDialog";

import iziToast from "izitoast";
import Editor from "@tinymce/tinymce-vue";
import Multiselect from "vue-multiselect";
import {
    required,
    minLength,
    maxLength,
    email,
    requiredIf,
} from "vuelidate/lib/validators";
import Select2 from "v-select2-component";
export default {
    props: {
        newJobForm: Object,
        vendorDetails: Array,
    },
    components: {
        Select2,
        Multiselect,
        Editor,
        MultiInputsDialog,
    },
    computed: {
        dobDates() {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 15);
            return {
                from: date,
            };
        },


        formattedDueDate() {
            return this.newJobForm.dueDate
                ? new Date(this.newJobForm.dueDate).toISOString().split('T')[0]
                : null;
        },
        wordCount() {
            return this.newJobForm.jobRequirement.trim().split(/\s+/).filter(word => word !== "").length;
        }

    },
    data() {
        return {

            newJobForm: {
                dueDate: null
            },
            dateError: false,

            // newJobForm: {
            //     experience: 0,
            // },

            workAuthAccess: Boolean,
            industryId: "",
            skillEditIndex: -1,
            skillDetails: [],
            newSkills: [],
            newTechs: [],
            editorOptions: {
                hideModeSwitch: true,
            },
            recruiters: this.$store.getters.getRecruiters,
            technologylist: this.$store.getters.getTechOptions,
            industrylist: this.$store.getters.getIndustries,
            clientlist: this.$store.getters.getClients,
            worktypelist: this.$store.getters.getWorkType,
            employeeTypeList: this.$store.getters.getEmployeeType,
            jobStatuslist: this.$store.getters.getJobStatus,
            payFrequencyList: this.$store.getters.getPayFrequencyList,
            expTypeList: this.$store.getters.getJobExpTypeOptions,
            countries: this.$store.getters.getCountries,
            countriesUsaInd: this.$store.getters.getUsaIndCountries,
            jobScopeList: this.$store.getters.getJobScopeList,
            usstates: [],
            qualification: this.$store.getters.getQualificationData,
            workAuthorization: this.$store.getters.getWorkAuthorizationData,
            jobDocuments: this.$store.getters.getJobDocumentData,
            currencies: this.$store.getters.getCurrencies,
            taxTermsList: [],
            languages: this.$store.getters.getLanguages,
            vendorClientInfoAccess: Boolean,
            skillList: [],
            clientName: "",
            countryId: "US",
            accountName: "",
            clientId: null,
            //accountName: "",
            //endClientName:"",
            //endAccountName: "",
            vendorClientId: "",
            newvendorInfo: {
                vendorId: null,
                clientName: "",
                clientAccountName: "",
                contactPerson: "",
                email: "",
                phone1: "",
                mobile: "",
                countryCode: "",
                jobVendorContactId: 0,
                jobContactId: 0,
            },
            //vendorFlag:false,
            delVendorParam: {
                index: -1,
                id: 0,
            },
            newSkillForm: {
                technologyId: "",
                skillId: "",
            },
            availableDates: {
                to: new Date(Date.now() - 864000),
            },
            delJobSkillParam: {
                index: -1,
                id: 0,
            },
            technologies: [], //dropdown options
            skills: [], //dropdown options
            technology_value: "", //dropdown value
            // skill_value : [] //dropdown value
            toolbar:
                "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
            initObj: {
                height: 300,
                branding: false,
                menubar: false,
                plugins: [
                    "link",
                    "paste",
                    "wordcount",
                    "preview",
                    "image imagetools",
                    "lists",
                    "advlist",
                ],
                paste_data_images: true,
                images_upload_url: "postAcceptor.php",
                automatic_uploads: false,
                file_picker_types: "image",
                relative_urls: false,
                remove_script_host: false,
            },
            disabledDates: {
                to: new Date(Date.now() - 8640000),
            },
            industries: this.$store.getters.getIndustries,
            isValidCheck: false,
            isaltValidCheck: false,
            isvendorValidCheck: false,
        };
    },
    validations: {
        newJobForm: {
            jobTitle: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(200),
            },
            salary: {
                required,
            },
            dueDate: {
                required,

            },
            position: {
                minLength: minLength(5),
            },
            experience: {

            },
            minSalary: function (minSalary, maxSalary) {
                if (
                    minSalary.minSalary != "" &&
                    minSalary.minSalary != undefined &&
                    minSalary.minSalary != null &&
                    minSalary.maxSalary != "" &&
                    minSalary.maxSalary != undefined &&
                    minSalary.maxSalary != null
                ) {
                    if (
                        Number(minSalary.minSalary) >
                        Number(minSalary.maxSalary)
                    ) {
                        return false;
                    }
                }
                return true;
            },

            maxSalary: function (minSalary, maxSalary) {
                if (
                    minSalary.minSalary != "" &&
                    minSalary.minSalary != undefined &&
                    minSalary.minSalary != null &&
                    minSalary.maxSalary != "" &&
                    minSalary.maxSalary != undefined &&
                    minSalary.maxSalary != null
                ) {
                    if (
                        Number(minSalary.minSalary) >
                        Number(minSalary.maxSalary)
                    ) {
                        return false;
                    }
                }
                return true;
            },
            countryId: {
                required
            },
            location: {
                required
            },
            eligibility: {
                minLength: minLength(1),
            },
            skill_value: required,


            /*       accountName: {
                email
            }, */
            contact: {
                email: {
                    email,
                },
            },
            clientName: {
                required: requiredIf(function () {
                    return (
                        this.newJobForm.accountName != null &&
                        this.newJobForm.accountName.length > 0
                    );
                }),
            },
            accountName: {
                required: requiredIf(function () {
                    console.log(
                        this.newJobForm.clientName != null &&
                        this.newJobForm.clientName.length > 0
                    );
                    return (
                        this.newJobForm.clientName != null &&
                        this.newJobForm.clientName.length > 0
                    );
                }),
                email,
            },
        },
        newvendorInfo: {
            email: {
                email,
            },
            clientAccountName: {
                email,
            },
        },
    },
    watch: {
        industryId(newVal) {
            // If industry gets selected, remove warning in real-time
            if (newVal) {
                this.showIndustryWarning = false;
            }
        },
        newJobForm: {
            handler: function (value) {
                if (value.techIndustryId != null) {
                    this.industryId = value.techIndustryId;
                    this.loadTechnologyList();
                } else if (value.industryId != null) {
                    this.industryId = value.industryId;
                    this.loadTechnologyList();
                }
                this.$nextTick(() => {
                    this.$watch("newJobForm", () => { }, { immediate: false });
                });
            },
            immediate: true,
        },
        industryId: {
            handler: function (value) {
                this.$emit("industryId", value);
                if (value != this.newJobForm.techIndustryId) {
                    this.skillDetails.splice(0, this.skillDetails.length);
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {

        this.loadTechnologyList();
        this.loadSettingsList();
        this.ClientInfoAccess();
        //this.setSkillDetails();
        this.workAuth();
        this.loadTaxTerms();



    },
    methods: {


        validateDueDate() {
            if (this.newJobForm.dueDate) {
                const selectedYear = new Date(this.newJobForm.dueDate).getFullYear();
                if (selectedYear < 2000 || selectedYear > 2050) {
                    this.dateError = true;
                    this.newJobForm.dueDate = null; // Clear invalid date
                } else {
                    this.dateError = false;
                }
            }
        },
        clearDueDate() {
            this.newJobForm.dueDate = null;
            this.dateError = false;
        },

        validateClientName() {
            // Allow only letters (A-Z, a-z) and spaces
            const regex = /^[A-Za-z\s]*$/;
            if (!regex.test(this.newJobForm.clientName)) {
                this.clientNameError = true;
                this.newJobForm.clientName = this.newJobForm.clientName.replace(/[^A-Za-z\s]/g, '');
            } else {
                this.clientNameError = false;
            }
        },
        validatePercentage() {
            // Remove non-numeric characters and ensure the value is between 0 and 100
            this.newJobForm.frequencyPercentage = this.newJobForm.frequencyPercentage.replace(/\D/g, '');
            if (this.newJobForm.frequencyPercentage > 100) {
                this.newJobForm.frequencyPercentage = 100; // Restrict max value to 100
            }
        },
        validateMaxSalary() {
            if (this.newJobForm.maxSalary > 1000000) {
                this.newJobForm.maxSalary = 1000000; // Restrict input to max 1,000,000
            }
        },
        validateMinSalary() {
            if (this.newJobForm.minSalary > 1000000) {
                this.newJobForm.minSalary = 1000000; // Restrict value to 1,000,000
            }
        },
        restrictSalary() {
            if (this.newJobForm.salary > 1000000) {
                this.salaryError = true;
                this.newJobForm.salary = 1000000; // Automatically sets to max allowed value
            } else {
                this.salaryError = false;
            }
        },

        validateVacancies() {
            // Allow only numbers 1 to 5
            const allowedNumbers = ['1', '2', '3', '4', '5'];
            if (!allowedNumbers.includes(this.newJobForm.vacancies)) {
                this.newJobForm.vacancies = '';
            }
        },

        validateExperience() {
            let inputValue = parseFloat(this.newJobForm.experience);


            if (inputValue < 0 || inputValue > 50) {
                this.experienceError =
                    "Please enter a number between 0 and 50.";
            } else {
                this.experienceError = "";
            }
            //   else {

            //   }
        },
        limitWords() {
            let words = this.newJobForm.jobRequirement.trim().split(/\s+/);

            // Limit to 100 words
            if (words.length > 100) {
                words = words.slice(0, 100);
                this.newJobForm.jobRequirement = words.join(" ");
            }
        },
        // updateDueDate(date) {
        //     this.newJobForm.dueDate = date ? new Date(date).toISOString().split('T')[0] : null;
        // },

        // clearDueDate() {
        //     this.newJobForm.dueDate = "";
        // },
        loadTaxTerms() {
            var countryId = localStorage.getItem("countryId");
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/settingsresource/get/country/taxterms/list/" +
                    countryId
                )
                .then((response) => {
                    this.taxTermsList = response.data.recordinfo;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        workAuth() {
            if (localStorage.getItem("workAuthorization") === "1") {
                this.workAuthAccess = true;
            } else {
                this.workAuthAccess = false;
            }
        },
        ClientInfoAccess() {
            if (localStorage.getItem("vendorClientInfoAccess") === "false") {
                this.vendorClientInfoAccess = false;
            } else if (
                localStorage.getItem("vendorClientInfoAccess") === "true"
            ) {
                this.vendorClientInfoAccess = true;
            }
        },
        loadTechnologyList() {
            if (this.industryId != "") {
                axios
                    .get(
                        this.$store.getters.getAPIBasePath +
                        "/technologyresource/get/industry/" +
                        this.industryId
                    )
                    .then((response) => {
                        this.technologies = response.data.technology;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        loadSettingsList() {
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/settingsresource/settingworkauthorization/list"
                )
                .then((response) => {
                    this.workAuthorization =
                        response.data.settingsWorkAuthorization;
                })
                .catch((error) => {
                    console.log(error);
                });
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/settingsresource/settingqualification/list"
                )
                .then((response) => {
                    this.qualification = response.data.settingsQualification;
                })
                .catch((error) => {
                    console.log(error);
                });
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                    "/settingsresource/settingjobdocument/list"
                )
                .then((response) => {
                    this.jobDocuments = response.data.settingsJobDocument;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        addNewVendorInfo() {
            this.newvendorInfo.clientName =
                this.newvendorInfo.clientName.trim();
            //this.newvendorInfo.clientAccountName = this.newvendorInfo.clientAccountName.trim();
            //this.newJobForm.vendorDetails = [];
            if (
                this.newvendorInfo.phone1 != null &&
                this.newvendorInfo.phone1.startsWith("+")
            ) {
                let phoneData = this.newvendorInfo.phone1.split(" ");
                this.newvendorInfo.countryCode = phoneData[0];
                this.newvendorInfo.mobile = phoneData[1];
            } else {
                this.newvendorInfo.mobile = this.newvendorInfo.phone1;
            }
            if (
                this.newvendorInfo.clientName != "" &&
                !this.isvendorValidCheck
            ) {
                //alert(this.newvendorInfo.clientName) ;
                this.newJobForm.vendorDetails.push(this.newvendorInfo);
                this.resetVendorForm();
            }
            //else {
            // iziToast.info({title: 'Information', message: 'Client Name Required', position: 'topRight'});
            //}
        },
        acceptNumber(index) {
            var x = this.newJobForm.vendorDetails[index].mobile
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.newJobForm.vendorDetails[index].mobile = !x[2]
                ? x[1]
                : "(" + x[1] + ")-" + x[2] + (x[3] ? "-" + x[3] : "");
        },
        formatUsPhone: function (evt, idx) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                var curchr = $(idx).val().length;
                var curval = $(idx).val();
                if (curchr == 3 && curval.indexOf("(") <= -1) {
                    $(idx).val("(" + curval + ")" + "-");
                } else if (curchr == 4 && curval.indexOf("(") > -1) {
                    $(idx).val(curval + ")-");
                } else if (curchr == 5 && curval.indexOf(")") > -1) {
                    $(idx).val(curval + "-");
                } else if (curchr == 9) {
                    $(idx).val(curval + "-");
                    $(idx).attr("maxlength", "14");
                }
                return true;
            }
        },
        resetVendorForm() {
            this.newvendorInfo = {
                vendorId: null,
                clientName: "",
                clientAccountName: "",
                contactPerson: "",
                email: "",
                phone1: "",
                mobile: "",
                countryCode: "",
            };
            //this.$refs.addcandidate_preferenceinfo_add_title.focus();
        },
        deleteVendor: function (arg) {
            this.newJobForm.vendorDetails.splice(arg.index, 1);

            if (this.delVendorParam.id != null) {
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                        "/jobresource/delete/job/vendor/" +
                        this.delVendorParam.id
                    )
                    .then((response) => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: "Success",
                                message: result.message,
                                position: "topRight",
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error.data);
                    });
            }
            this.delVendorParam.id = null;
        },
        confirmVendor: function (arg1, arg2) {
            this.delVendorParam.index = arg1;
            this.delVendorParam.id = arg2;
        },
        // showEndClientModal() {
        //    this.vendorFlag = true ;
        //    $('#ClientNameShow').modal('show');
        // },
        //  locationData(location) {
        //     if(this.newJobForm.locationValues == "")
        //        this.newJobForm.locationValues = location.stateName
        //     else
        //        this.newJobForm.locationValues = this.newJobForm.locationValues+ ", " +location.stateName ;

        // },

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                // 46 is dot
                $event.preventDefault();
            }
        },
        setSkillDetails(skillDetails) {
            if (this.notEmptyTech(skillDetails))
                this.skillDetails = skillDetails;
        },
        updateSkillDetails(mode) {
            if (mode == 1) {
                this.skillDetails[this.skillEditIndex].technology =
                    this.technology_value;
                this.skillDetails[this.skillEditIndex].skills =
                    this.newJobForm.jobSkill;
            }

            this.skillEditIndex = -1;
            this.technology_value = "";
            this.newJobForm.jobSkill = [];
            this.skills = [];
        },
        editOneSkillDetail(index) {
            this.skillEditIndex = index;
            this.technology_value = this.skillDetails[index].technology;
            this.skills = [];
            let result = this.filterSkillsByTechnology(
                this.technology_value.id
            );
            if (result[0] != null) {
                this.skills = result[0].settingsTechnologySkill;
            }
            this.newJobForm.jobSkill = this.skillDetails[index].skills;
        },
        filterSkillsByTechnology: function (techId) {
            return this.technologies.filter(function (data) {
                if (data.id == techId) {
                    return data;
                }
            });
        },
        removeSkill(index, index1) {
            this.skillDetails[index].skills.splice(index1, 1);
        },
        getSkillTitle(item) {
            var title = "";
            var seprator = "";
            for (var i = 0; i < item.length; i++) {
                title += seprator + item[i].skillName;
                seprator = ", ";
            }
            return title;
        },
        removeOneSkillDetail(techId) {
            let delIndex = this.$options.filters.findIndexOf(
                this.skillDetails,
                (skillDetail) => skillDetail.technology.id == techId
            );
            this.skillDetails.splice(delIndex, 1);
            this.updateSkillDetails(0);
        },
        addMoreSkillDetails() {
            if (typeof this.technology_value !== "object") return;
            let self = this;
            var validSkills = [];
            validSkills = this.newJobForm.jobSkill.filter(function (skill) {
                if (skill.technologyId == self.technology_value.id)
                    return skill;
            });
            if (!validSkills.length) {
                iziToast.error({
                    title: "Error Occured",
                    message: this.$config.SkillSetErr,
                    position: "topRight",
                });
                return false;
            }
            if (validSkills.length < this.newJobForm.jobSkill.length) {
                iziToast.info({
                    title: "Information",
                    message: validSkills.length + this.$config.SkillSetSuc,
                    position: "topRight",
                });
            }

            var matched = false;
            for (var i = 0; i < this.skillDetails.length; i++) {
                if (
                    this.skillDetails[i].technology.id ==
                    this.technology_value.id
                ) {
                    this.skillDetails[i].skills = validSkills;
                    matched = true;
                    break;
                }
            }

            if (!matched)
                this.skillDetails.push({
                    technology: this.technology_value,
                    skills: validSkills,
                });
            this.technology_value = "";
            this.newJobForm.jobSkill = [];
            this.skills = [];
        },
        saveNewTech() {

            var newItems = [];
            var delItems = [];
            var idx = 0;
            var match = false;
            for (var i = 0; i < this.technologies.length; i++) {
                idx = this.newTechs.indexOf(this.technologies[i].technology);
                if (idx < 0) {
                    delItems.push(this.technologies[i].id);
                }
            }

            for (var i = 0; i < this.newTechs.length; i++) {
                match = false;
                for (var j = 0; j < this.technologies.length; j++) {
                    if (this.technologies[j].technology == this.newTechs[i]) {
                        match = true;
                        break;
                    }
                }

                if (!match) {
                    newItems.push({
                        technology: this.newTechs[i],
                        industryId: this.industryId,
                    });
                }
            }

            if (newItems.length > 0) {
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                        "/technologyresource/add/clienttechnology",
                        { technologyList: newItems }
                    )
                    .then((response) => {
                        if (response.status == 200) {

                            iziToast.success({
                                title: "Success",
                                message: this.$config.TechsAddSuc,
                                position: "topRight",
                            });
                        }
                        this.newTechs = [];
                        this.skills = [];
                        this.newJobForm.jobSkill = [];
                        this.technology_value = "";
                        this.loadTechnologyList();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        saveNewSkills() {

            var skillItems = [];
            var idx = 0;
            var match = false;
            for (var i = 0; i < this.skills.length; i++) {
                idx = this.newSkills.indexOf(this.skills[i].skillName);
                if (idx < 0) {
                    //delItems.push(this.skills[i].skillId);
                    skillItems.push({
                        categoryId: null,
                        technologyId: this.technology_value.id,
                        skillName: this.skills[i].skillName,
                        isDeleted: 0,
                        id: this.skills[i].skillId,
                        industryId: this.industryId,
                    });
                }
            }

            for (var i = 0; i < this.newSkills.length; i++) {
                match = false;
                for (var j = 0; j < this.skills.length; j++) {
                    if (this.skills[j].skillName == this.newSkills[i]) {
                        match = true;
                        break;
                    }
                }

                if (!match) {
                    //newItems.push({technology:this.newSkills[i]});
                    skillItems.push({
                        categoryId: null,
                        technologyId: this.technology_value.id,
                        skillName: this.newSkills[i],
                        isDeleted: 0,
                        id: null,
                        industryId: this.industryId,
                    });
                }
            }
            if (skillItems.length > 0) {
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                        "/technologyresource/add/skill",
                        { technologySkill: skillItems }
                    )
                    .then((response) => {
                        var result = response.data;
                        if (result.status == true) {
                            iziToast.success({
                                title: "Success",
                                message: this.$config.SkillsAddSuc,
                                position: "topRight",
                            });
                        }
                        this.newSkills = [];
                        this.technology_value = "";
                        this.skills = [];
                        this.newJobForm.jobSkill = [];
                        this.loadTechnologyList();

                        // var tempSkills = [...this.skills];

                        // for(var i=0; i<skillItems.length; i++)
                        // {
                        //     if(skillItems[i].isDeleted == 1)
                        //     {
                        //         for(var j=0; j<tempSkills.length; j++)
                        //         {
                        //             if(tempSkills[j].skillName == skillItems[i].skillName)
                        //             {
                        //                 tempSkills.splice(j,1);
                        //                 break;
                        //             }
                        //         }
                        //     }
                        // }
                        // //console.log(this.skills, tempSkills);
                        // this.skills = [...tempSkills];

                        // for(var i=0; i<this.newJobForm.jobSkill.length; i++)
                        // {
                        //     //console.log(this.newJobForm.jobSkill[i].skillName);
                        //     match = false;
                        //     for(var j=0; j<this.skills.length; j++)
                        //     {
                        //         if(this.newJobForm.jobSkill[i].skillName == this.skills[j].skillName)
                        //         {
                        //             match = true;
                        //             break;
                        //         }
                        //     }
                        //     //console.log(match);
                        //     if(!match)
                        //     {
                        //         this.newJobForm.jobSkill.splice(i--,1);
                        //     }
                        // }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        updateHTMLText() {
            this.newJobForm.jobRequirement =
                this.$refs.tuiEditor_jobRequirement.invoke("getHtml");
        },
        loadSkills(selected_technology) {
            this.newJobForm.jobSkill = "";
            this.skills = [];
            this.skills = selected_technology.settingsTechnologySkill;
        },
        vendorphoneControlInput: function (value, isValid) {
            this.newvendorInfo.phone1 = value;
            this.isvendorValidCheck = isValid;
        },
        phoneControlInput: function (value, isValid) {
            this.newJobForm.contact.phone2 = value;
            this.isaltValidCheck = isValid;
        },
        clientphoneControlInput: function (value, isValid) {
            this.newJobForm.contact.phone1 = value;
            this.isValidCheck = isValid;
        },
        validateJobInfo() {
            if (
                this.newJobForm.clientName != null &&
                this.newJobForm.clientName.length == 0 &&
                this.newJobForm.accountName != null &&
                this.newJobForm.accountName.length == 0
            ) {
                this.newJobForm.clientId = null;
            }
            this.$v.newJobForm.$touch();
            if (!this.isaltValidCheck && !this.isValidCheck) {
                if (this.$v.newJobForm.$invalid) {
                    this.newJobForm.blockjobtab = 1;
                    return false;
                }
                this.newJobForm.blockjobtab = 0;
                return true;
            }
        },
        resetNewJobForm() {
            this.skill_value = [];
            this.$v.newJobForm.$reset();
        },
        addSkillInfo() {
            this.newJobForm.jobSkill = [];
            for (var i = 0; i < this.skillDetails.length; i++) {
                for (var j = 0; j < this.skillDetails[i].skills.length; j++)
                    this.newJobForm.jobSkill.push({
                        technologyId: this.skillDetails[i].technology.id,
                        skillId: this.skillDetails[i].skills[j].skillId,
                        industryId: this.industryId,
                    });
            }
            /*let self = this;
            $.each(this.skill_value, function(key,value){
              // value.industryId = self.newJobForm.industryId;
              // value.experience = self.newJobForm.experience;
            });
            this.newJobForm.jobSkill = this.skill_value;*/
        },
        /*showBoxClientName: function(arg) {
          console.log(arg);
          this.tempEmpSkillId = arg;
          if (arg < 0) {
            this.clientName = this.clientNameList;
            console.log(this.clientNameList);
          } else {
            this.clientName = this.clientNameList[arg];
            console.log(this.projectinfo[arg].projectDescription);
          }
        },*/
        getVendorClientContact() {
            if (this.newJobForm.vendorClientId != "") {
                let selfContact = this.newvendorInfo;
                this.newvendorInfo.vendorId = this.newJobForm.vendorClientId;
                axios
                    .get(
                        this.$store.getters.getAPIBasePath +
                        "/clientresource/view/client/" +
                        this.newJobForm.vendorClientId
                    )
                    .then((response) => {
                        this.newvendorInfo.clientName =
                            response.data.clients.clientName;
                        this.newvendorInfo.clientAccountName =
                            response.data.clients.accountName;
                        let clientContact = response.data.clients.clientContact;
                        if (clientContact != null) {
                            this.newvendorInfo.contactPerson =
                                clientContact[0].contactName;
                            this.newvendorInfo.email = clientContact[0].email;
                            this.newvendorInfo.phone1 =
                                clientContact[0].phoneNumber;
                            // if (clientContact[0].phoneNumber!= null && clientContact[0].phoneNumber.startsWith("+")) {
                            //     let phoneData = clientContact[0].phoneNumber.split(" ");
                            //     this.newvendorInfo.countryCode = phoneData[0];
                            //     this.newvendorInfo.phone1 = phoneData[1];
                            // }
                            // else
                            //  this.newvendorInfo.phone1 = clientContact[0].phoneNumber;
                        }
                    })
                    .catch((error) => { });
            }
            this.newJobForm.vendorClientId = "";
        },
        getClientContact() {
            if (this.newJobForm.clientId != "") {
                let selfContact = this.newJobForm.contact;
                let self = this;
                axios
                    .get(
                        this.$store.getters.getAPIBasePath +
                        "/clientresource/view/client/" +
                        this.newJobForm.clientId
                    )
                    .then((response) => {
                        self.newJobForm.clientName =
                            response.data.clients.clientName;
                        self.newJobForm.accountName =
                            response.data.clients.accountName;
                        let clientContact = response.data.clients.clientContact;
                        if (clientContact != null) {
                            selfContact.contactPerson =
                                clientContact[0].contactName;
                            selfContact.email = clientContact[0].email;
                            selfContact.phone1 = clientContact[0].phoneNumber;
                            //selfContact.mobile = clientContact[0].mobile;
                            selfContact.website = clientContact[0].url;
                            selfContact.addressLine1 = clientContact[0].address;
                            selfContact.cityName = clientContact[0].city;
                            selfContact.stateName = clientContact[0].stateName;
                            selfContact.countryId = clientContact[0].countryId;
                            selfContact.zipCode = clientContact[0].zipCode;
                            selfContact.phone2 = clientContact[0].phone2;
                            // let phoneNo = clientContact[0].phone2;
                            // if (phoneNo != null && phoneNo.startsWith("+")) {
                            //     let phoneData = phoneNo.split(" ");
                            //     selfContact.countryCode = phoneData[0];
                            //     selfContact.mobile = phoneData[1];
                            // }
                        }
                    })
                    .catch((error) => { });
            }
        },
        resetContactInfo() {
            this.newJobForm.clientId = null;
            this.newJobForm.clientName = "";
            this.newJobForm.accountName = "";
            this.newJobForm.contact.phone2 = "";
            this.newJobForm.contact.email = "";
            this.newJobForm.contact.phone1 = "";
            this.newJobForm.contact.mobile = "";
            this.newJobForm.contact.website = "";
            this.newJobForm.contact.addressLine1 = "";
            this.newJobForm.contact.cityName = "";
            this.newJobForm.contact.stateName = "";
            this.newJobForm.contact.countryId = "";
            this.newJobForm.contact.zipCode = "";
        },
        notEmptyTech: function (techSkills) {
            let result = false;
            techSkills.map((techSkill) => {
                if (
                    techSkill.skills.length &&
                    techSkill.technology.id &&
                    techSkill.technology.technology != null
                )
                    result = true;
            });
            return result;
        },
        loadState: function (countryId) {
            this.newJobForm.location = "";
            axios
                .get(
                    this.$store.getters.getAPIBasePath +
                    "/commonresource/statelist?countryId=" +
                    countryId
                )
                .then((response) => {
                    this.usstates = response.data.statelist;
                })
                .catch((error) => {
                    console.log("error on state list");
                });
        },
    },
};
</script>
