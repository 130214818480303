<template>
    <div class="add-task-group">
        <b-modal scrollable ref="updateSprintModal" size="lg" id="updateSprintModal" title="Edit Task Group" @ok="updateTaskGroup" @hidden="refreshTaskGroupData" ok-title="Update Task Group">
            <form>
                <div class="row col-md-12 mx-0 justify-content-end">
                    <multiselect v-if="elements.showMembersDropdown" v-model="editTaskGroup.taskGroupMembers" :options="options.recruitersList" name="project" label="recruiterName" track-by="id" select-label="" multiple deselect-label="" class="" :max-height="300" placeholder="Select Members" :searchable="true" :close-on-select="false" @close="elements.showMembersDropdown=false"></multiselect>
                    <span v-if="!elements.showMembersDropdown" v-for="TaskMembers in editTaskGroup.taskGroupMembers" :key="TaskMembers.recruiterName">
                        <span :data-letters="TaskMembers.recruiterName | getAcronym" :title="TaskMembers.recruiterName" class="mx-n2 align-self-center"></span>
                    </span>
                    <i v-if="!elements.showMembersDropdown" class="fa fa-plus circle-icon hover-hand ml-n2 pull-right" @click="elements.showMembersDropdown=!elements.showMembersDropdown" title="Add Members" />
                </div>
                <div class="row col-md-12 mx-0 px-0">
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="projectName" class="col-form-label">Task Group Name</label>
                        <input type="text" name="projectName" id="projectName" v-model="editTaskGroup.title" class="form-control" />
                        <label v-if="$v.editTaskGroup.title.$error && !$v.editTaskGroup.title.required" class="text-danger">Task Group Name is required</label>
                        <label v-if="$v.editTaskGroup.title.$error && !$v.editTaskGroup.title.minLength" class="text-danger">Task Group Name must have at least {{$v.editTaskGroup.title.$params.minLength.min}} letters.</label>
                        <label v-if="$v.editTaskGroup.title.$error && !$v.editTaskGroup.title.maxLength" class="text-danger">Task Group Name must not exceed {{$v.editTaskGroup.title.$params.maxLength.max}} letters.</label>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="projectId" class="col-form-label">Project</label>
                        <select v-model="editTaskGroup.projectId" id="projectId" name="projectId" class="form-control">
                            <option v-for="Option in options.projectList" :value="Option.id">{{ Option.projectName }}</option>
                        </select>
                        <label v-if="$v.editTaskGroup.projectId.$error && !$v.editTaskGroup.projectId.required" class="text-danger">Project is required</label>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="taskRelatedTo" class="col-form-label">Reference</label>
                        <input type="text" name="taskRelatedTo" id="taskRelatedTo" v-model="editTaskGroup.taskRelatedTo" class="form-control" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="addTGStartDate" class="col-form-label">Start Date</label>
                        <input type="date" v-model="editTaskGroup.startDate" class="form-control" id="addTGStartDate" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="addTGDueDate" class="col-form-label">End Date</label>
                        <input type="date" v-model="editTaskGroup.endDate" class="form-control" id="addTGDueDate" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="editTaskGroupStatus" class="col-form-label">Task Group Status</label>
                        <select class="form-control" v-model="editTaskGroup.status">
                            <option value="">Select</option>
                            <option v-for="(status, index) in statusList" :value="status.id">{{status.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-12 col-sm-12">
                        <label for="editTaskGroupDescription" class="col-form-label">Description</label>
                        <editor id="editTaskGroupDescription" api-key="p2he3phm0obj1wc8o56uqbdmb63l8h0gkljqpb1lvmrecw78" :toolbar="editor.toolbarOptions" :init="editor.initOptions" v-model="editTaskGroup.description"></editor>
                        <label v-if="$v.editTaskGroup.description.$error && !$v.editTaskGroup.description.maxLength" class="text-danger">Description must not exceed {{$v.editTaskGroup.description.$params.maxLength.max}} letters.</label>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import Editor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
export default {
    components: {
        Editor,
        Multiselect
    },
    props: {
        options: Object,
        editor: Object,
        editTaskGroup: Object,
        project: Object
    },
    data() {
        return {
            elements: {
                showMembersDropdown: false
            },
            statusList: this.$store.getters.getTaskGroupStatusList,
        }
    },
    validations: {
        editTaskGroup: {
            projectId: { required },
            title: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            description: {
                maxLength: maxLength(200)
            },
        }
    },
    methods: {
        validateSprintInfo: function() {
            this.$v.editTaskGroup.$touch();
            if (this.$v.editTaskGroup.$invalid) {
                return false;
            }
            return true;
        },
        updateTaskGroup: function() {
            this.editTaskGroup.taskGroupMembers.map(function(Members) {
                Members.userId = Members.id;
            });
            if (this.projectId == null && !this.validateSprintInfo()) {
                return false;
            }
            if (this.editTaskGroup.endDate == 'Invalid date') {
                
                this.editTaskGroup.endDate = null;
            }
            console.log(this.editTaskGroup);
            axios.post(this.$store.getters.getAPIBasePath + '/projectresource/update/project/sprint', this.editTaskGroup)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.$refs.updateSprintModal.hide();
                        this.$emit('loadSprints');
                        this.refreshTaskGroupData();
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        refreshTaskGroupData: function() {

            this.editTaskGroup.projectId = null;
            this.editTaskGroup.title = "";
            this.editTaskGroup.description = "";
            this.editTaskGroup.taskRelatedTo = "";
            this.editTaskGroup.startDate = "";
            this.editTaskGroup.dueDate = "";
            this.editTaskGroup.status = "";
            this.elements.showMembersDropdown = false;
            
        }
    }
}

</script>
