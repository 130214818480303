<template>
    <div>
        <div class="card">
            <div class="card-header p-2">Employment References</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table table-border all-table ">
                                    <thead>
                                        <tr>
                                            <th>Company Name</th>
                                            <th style="text-align: center;border-left: 1px solid rgba(248, 248, 248, 0.2);">
                                                Technology/Skills
                                            </th>
                                            <th style="text-align: center;border-left: 1px solid rgba(248, 248, 248, 0.2);">
                                                From
                                            </th>
                                            <th style="text-align: center;border-left: 1px solid rgba(248, 248, 248, 0.2);">
                                                To
                                            </th>
                                            <th colspan="4" width="40%" style="text-align: center;border-right: 1px solid rgba(248, 248, 248, 0.2);border-left: 1px solid rgba(248, 248, 248, 0.2);">
                                                Reference
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(info, index) in referenceinfo" :class="{ 'viewonly' : referenceinfo[index].viewonly == 1}">
                                            <td>{{info.companyName}}</td>
                                            <td style="text-align: center;">{{info.skillSet}}</td>
                                            <td style="text-align: center;">{{info.employedFrom | formatMonYear}}</td>
                                            <td style="text-align: center;">{{info.employedTo | formatMonYear}}</td>
                                            <td style="text-align: center;">
                                            <input class="form-control" placeholder="Name" type="text" maxlength="25" 
                                                v-model="referenceinfo[index].referenceName" 
                                                @input="referenceinfo[index].referenceName = referenceinfo[index].referenceName.replace(/[^A-Za-z\s]/g, '')"
                                            />
                                            </td>

                                            <td style="text-align: center;">
                                            <input class="form-control" placeholder="Phone Number" maxlength="15" type="text" 
                                                v-model="referenceinfo[index].referencePhone" 
                                                @input="referenceinfo[index].referencePhone = referenceinfo[index].referencePhone.replace(/[^0-9]/g, '')"
                                            />
                                            </td>

                                            <td style="text-align: center;">
                                            <input class="form-control" placeholder="Email" type="text" maxlength="50"  
                                                v-model="referenceinfo[index].referenceEmail"
                                                @input="referenceinfo[index].referenceEmail = referenceinfo[index].referenceEmail.replace(/[^A-Za-z0-9@]/g, '')"
                                            />
                                           </td>


                                            <td style="text-align: center;">
                                                <input class="form-control" placeholder="Position" maxlength="100"  type="text" v-model="referenceinfo[index].referenceRelationship"/>
                                            </td>
                                        </tr>
                                        <tr :class="{ 'norecordfound' : referenceinfo.length}" >
                                            <td colspan="8" align="center">Please add Employment info to update Reference Information</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        props: {
            referenceinfo: Array,
        },
        methods: {
  allowOnlyLetters(event, index) {
    this.referenceinfo[index].referenceName = this.referenceinfo[index].referenceName.replace(/[^A-Za-z\s]/g, '');
  },
  allowOnlyNumbers(event, index) {
    this.referenceinfo[index].referencePhone = this.referenceinfo[index].referencePhone.replace(/[^0-9]/g, '');
  },
  allowEmailInput(event, index) {
    this.referenceinfo[index].referenceEmail = this.referenceinfo[index].referenceEmail.replace(/[^A-Za-z0-9@]/g, '');
  }
}
	}
 

</script>