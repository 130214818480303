<template>
    <div class="card">
        <div class="card-header p-2">{{resumeinfo.title}}</div>
        <div class="card-body p-2">
            <div class="basic-block clone-preference">
                <div class="row ten-columns pos-relative clone filled2">
                    <div class="col-md-6 col-sm-6 col-lg=6 mb-10-mr">
                        <div class="form-group" :class="{ 'noaddedmore' : resumeinfo.viewonly == 1}">
                            <div class="col-md-12 col-sm-12 col-12">
                                <input class="upload" ref="addcandidate_resumeattach_resume" id="candidateadd_resume_fileupload" type="file" accept=".rtf,.doc,.docx,.pdf,.DOCX" v-on:change="handleFileUpload" :key="fileInputKey"></input>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                                <span>{{resumeinfo.supportedTypes}}</span>
                            </div>
                        </div>
                        <div v-if="resumeinfo.filename!=undefined && (resumeinfo.downloadResumeCount != 0 || ttClientAccountId == resumeinfo.ttClientAccountId || resumeinfo.isViewed==1) && (resumeinfo.resumeViewCount==null || ttClientAccountId == resumeinfo.ttClientAccountId )" class="row ten-columns">
                            <!-- :href="resumeinfo.fileurl" target="_blank" -->
                            <a :href="resumeinfo.fileurl" target="_blank" @click="addCandidateDownload">{{resumeinfo.filename}}</a>
                            <!-- <span v-if="resumeinfo.filename !=''" class="fa fa-close pt-1 pl-1 hover-hand" @click="deleteResume"></span> -->
                              <span v-if="resumeinfo.filename !=''" class="fa fa-close pt-1 pl-1 hover-hand" @click="setResume"></span>
                            <span v-if="resumeinfo.filename =='' ">No resume found</span>
                        </div>
                            <span v-if="resumeinfo.resumeViewCount==null && resumeinfo.downloadResumeCount == 0 && resumeinfo.isViewed == 0 && ttClientAccountId != resumeinfo.ttClientAccountId" class="max_limit">* Maximum Limit Reached Update Your Account </span>
                           <!--  <span v-if="resumeinfo.downloadResumeCount==null && ttClientAccountId != resumeinfo.ttClientAccountId " class="max_limit">* Can't Download Resume Update Your Account </span> -->
                            <span v-if="resumeinfo.downloadResumeCount!=null && ttClientAccountId != resumeinfo.ttClientAccountId && resumeinfo.isViewed == 0 && resumeinfo.expiredDate == null" class="max_limit">* Your Account Expired </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isResumeCapture" class="card-header p-2">Attachment</div>
        <div v-if="!isResumeCapture" class="card-body p-2">
            <div class="basic-block clone-preference">
                <div>
                    <a href="javascript:" class="float-right button-add pt-4" @click="addInput"><i class="fa fa-plus-circle pr-2 action-link"></i>Add More</a>
                </div>
                <div class="row ten-columns pos-relative clone filled2">

        

                    <div class="col-md-6 col-sm-6 col-lg=6 mb-10-mr">
                        <div class="form-group pt-4" :class="{ 'noaddedmore' : resumeinfo.viewonly == 1}">
                            <div class="col-md-12 col-sm-12 col-12 pt-2" v-for="(input, index) in addMore">
                                <input class="upload" ref="candidate_multiple_attach" id="candidate_multiple_fileupload" type="file" accept=".rtf,.doc,.docx,.pdf,.DOCX,image/*" v-on:change="handleMultiFileUpload($event)" :key="fileInputKey"></input>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                                <span>Supports only PDF, Doc., Docx, PNG, JPG file types</span>
                            </div>
                        </div>
                        <div class="row ten-columns">
                            <div v-if="(resumeinfo.downloadResumeCount != 0 || ttClientAccountId == resumeinfo.ttClientAccountId || resumeinfo.isViewed==1) && (resumeinfo.resumeViewCount==null || ttClientAccountId == resumeinfo.ttClientAccountId )" class="row ten-columns" v-for="(file, index) in multipleFile">
                                <span v-if="file.attachmentTypes != ''">{{file.attachmentTypes}} :</span>
                                <a :href="file.multipleFilePaths" target="_blank">{{file.multipleFileNames}}</a>
                                <span v-if="multipleFile.length > 0" class="fa fa-close pt-1 pl-1 hover-hand" @click="setAttachmentId(file.id)"></span>
                            </div>
                            <!-- <span v-if="multipleFile==undefined || multipleFile.length==0">No attachment found</span> -->
                            <span v-if="resumeinfo.resumeViewCount==null && resumeinfo.downloadResumeCount == 0 && resumeinfo.isViewed == 0 && ttClientAccountId != resumeinfo.ttClientAccountId && multipleFile.length!=0" class="max_limit">* Maximum Limit Reached Update Your Account </span>
                        <span v-if="resumeinfo.downloadResumeCount==null && ttClientAccountId != resumeinfo.ttClientAccountId && multipleFile.length!=0 " class="max_limit">* Can't Download Attachment Update Your Account </span>
                        <span v-if="resumeinfo.downloadResumeCount!=null && ttClientAccountId != resumeinfo.ttClientAccountId && resumeinfo.isViewed == 0 && resumeinfo.expiredDate == null && multipleFile.length!=0 " class="max_limit">* Your Account Expired </span>
                        </div>
                    </div> 
                    

                    <div class="col-md-6 col-sm-6 col-lg=6 mb-10-mr">
                        <div class="form-group pt-4">
                            <div class="row" style="text-align:left;">
                                <div class="col-md-12 pt-2" v-for="(input, index) in addMore">
                                    <select v-model="attachmentTypes[index]" class="form-control" @change="attachmenttypeselects(index)">
                                        <option class="" selected value="">Attachment Type</option>
                                        <!-- <option v-for="(data, index) in jobDocuments" :value="data.documentName"> -->
                                        <option v-for="(data, index) in jobDocuments" :value="{id: data.id, name:data.documentName}">
                                        {{data.documentName}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
    <b-modal ref="my-modal" hide-footer title="Attachment" size="md ">
                    <div class="modal-body">
                        Are you sure to remove attachment?
                    </div>
                    <div class="modal-footer">
                        <button type="button"  class="btn btn-primary" @click="deleteResume" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" @click="hideModal" data-dismiss="modal">No</button>
                    </div>
    </b-modal>

  </div>

    </div>

</template>
<script>
import iziToast from 'izitoast';
export default {
    props: {
        resumeinfo: Object,
        attachments: Array,
        attachmentTypes: Array,
        isResumeCapture: Boolean,
        // attachmenttypeselect: Boolean,
        multipleFile: Array,
        candidateId: [String, Number]
    },
    data() {
        return {
            
            fileInputKey: 0,
            allowedfiletypes: ['pdf', 'doc', 'docx', 'DOCX', 'rtf'],
            specifiedfiletype: ['docx', 'DOCX'],
            addMore: [],
            jobDocuments: [],
            saveindex:'',
            ttClientAccountId: localStorage.getItem('clientAccountId')
        }
    },
    mounted() {
        this.addMore.push(this.addMore.length + 1);
        this.getJobDocuments();
         this.$root.$on('remove_name', () => {
            this.removeAttachname();
        })
    },
    methods: {
        

            
        handleMultiFileUpload(event) {
        const files = event.target.files;
        
        if (files.length > 0) {
            // Store files in multipleFile array
            for (let i = 0; i < files.length; i++) {
                this.multipleFile.push({
                    attachmentTypes: files[i].type,
                    multipleFilePaths: URL.createObjectURL(files[i]), // Creates a temporary URL for preview
                    multipleFileNames: files[i].name,
                    id: Date.now() + i // Unique ID for each file
                });
            }
        }

        // Ensure reactivity updates
        this.$forceUpdate();
    },

    setAttachmentId(fileId) {
        // Remove file by ID
        this.multipleFile = this.multipleFile.filter(file => file.id !== fileId);
    },
        removeAttachname(){
            this.fileInputKey++;
        },
        attachmenttypeselects(index){
            this.saveindex=index;
            // this.attachmenttypesetting();
        },
        attachmenttypesetting(){
       if(this.attachmentTypes[this.saveindex] != null && this.attachmentTypes[this.saveindex].name != undefined)
                return true;
            else if(this.attachmentTypes[this.saveindex] != null && this.attachmentTypes[this.saveindex].name == undefined)
                return false;

        },
        removeAttachmenttype(){
            this.attachmentTypes[this.saveindex] = ""; 
        },
        handleMultiFileUpload(event) {
            for (let file of event.target.files) {
                try {
                    let reader = new FileReader();
                    reader.readAsDataURL(file); // Not sure if this will work in this context.
                    this.attachments.push(file);
                } catch {}
            }
        },
        handleFileUpload() {
            let fileelement = this.$refs.addcandidate_resumeattach_resume.files[0];
            let ext = fileelement.name.substring(fileelement.name.lastIndexOf('.') + 1);
            if (this.resumeinfo.extension != null) {
                this.resumeinfo.extension = ext;
            }
            /* if (resumeinfo.page != null && resumeinfo.page == 1 && $.inArray(ext, this.specifiedfiletype) !== -1) {
                 this.resumeinfo.file = this.$refs.addcandidate_resumeattach_resume.files[0];
             } else */
            if ($.inArray(ext, this.allowedfiletypes) !== -1) {
                this.resumeinfo.file = this.$refs.addcandidate_resumeattach_resume.files[0];
            } else {      
                iziToast.info({
                    message: this.$config.FileExtNotSup,
                    position: 'topRight'
                });
                $("#candidateadd_resume_fileupload").val('');
                this.resumeinfo.file = '';
            }

        },
        showModal() {
        this.$refs['my-modal'].show();

        },
        hideModal() {
        this.$refs['my-modal'].hide()
      },
        setAttachmentId(id) {
            this.resumeinfo.id = id;
            this.showModal();
            // this.deleteResume();
        },
        setResume() {
            //this.resumeinfo.id = id;
            this.showModal();
            // this.deleteResume();
        },
        deleteResume: function() {
            if (this.resumeinfo.id != null) {
                axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/delete/candidate/resume/' + this.resumeinfo.id)
                    .then(response => {
                        let result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                            this.$emit('refresh-attachement');
                            this.resumeinfo.filename = "";
                            this.resumeinfo.file = "";
                            this.resumeinfo.fileurl = "";
                            this.multipleFile.multipleFileNames= "";
                            this.multipleFile.multipleFilePaths= "";
                            this.$refs['my-modal'].hide()
                            this.removeFile();
                        }
                        else {
                            iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                            this.$refs['my-modal'].hide()
                        }
                    })
                    .catch(error => {
                        this.unAuthorized(error);
                         this.$refs['my-modal'].hide()
                    });
            }
        },
        addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        removeFile: function(index) {
            if (this.attachments != null) {
                this.attachments.splice(index, 1);
            }
        },

        getJobDocuments: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/settingjobdocument/list')
                .then(response => {
                    this.jobDocuments = response.data.settingsJobDocument;
                })
        },
        multipleFileUpload: function(id) {

            if (this.attachments != null && this.attachments.length > 0) {
                let formData = new FormData();
                for (const row of Object.keys(this.attachments)) {
                    formData.append('files', this.attachments[row]);
                }
                for (const row of Object.keys(this.attachmentTypes)) {                  
                    formData.append('attachmentTypes', this.attachmentTypes[row]);
                }
                formData.append('id', id);

                axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/uploadresume/multiple', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        let result = response.data;
                        if (result.status == "OK") {
                            this.resumeinfo.filename = "";
                            iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                        } else {
                            iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                        }
                    })
                    .catch(error => {
                        this.unAuthorized(error);
                        console.log(error.data);
                    });
            }

        },
        addCandidateDownload() {
            axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/download', { candidateId: this.candidateId })
                .then(response => {
                    let result = response.data;
                    console.log('result',result);
                    iziToast.success({
                        title: 'Limit Reached',
                        message: result.message,
                        position: 'topRight'
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.unAuthorized(error);
                });
        },
    }
}

</script>