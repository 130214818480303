<template>
    <div id="main" class="reports settings mt-70 resumeExtract">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="wrapper settings-leftbar w-100 px-0">
                <aside class="newcan main-sidebar bg-white">
                    <section class="sidebar">
                        <div
                            class="p-4 header d-flex flex-row justify-content-between"
                        >
                            <i
                                class="fa fa-bars mr-3 fa-2x text-secondary hover-hand"
                                @click="sidebarToggle"
                                data-toggle="push-menu"
                                role="button"
                            ></i>
                            <div class="reports-title">
                                <h5>Resume Extract</h5>
                            </div>
                            <div class="back-link">
                                <h5 class="text-secondary">
                                    <a
                                        href="javascript:"
                                        @click="getPrevUrl()"
                                        title="Back"
                                        class="p-2"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        <span> Back</span>
                                    </a>
                                </h5>
                            </div>
                        </div>
                        <div v-if="filePath != ''">
                            <iframe
                                class="embed-responsive-item"
                                :src="docSrc"
                                allowfullscreen
                                width="500"
                                height="600"
                            ></iframe>
                        </div>
                        <!--                         <div  class="embed-responsive embed-responsive-16by9" >
                            <iframe class="embed-responsive-item" :src="docSrc" allowfullscreen></iframe>
                        </div> -->
                        <!--  <iframe :src="docSrc" frameBorder="0" class="bg-white" width="800" height="800" allowfullscreen ></iframe> -->
                        <!--  <p class="htmlText text-break p-3 newcan-scroll" v-html="parsedData.sbExtractData"></p> -->
                    </section>
                </aside>
                <div class="driver-model basic-block">
                    <div class="newcan content-wrapper">
                        <div class="row no-gutters">
                            <b-tabs
                                v-model="currentCandidateTab"
                                small
                                card
                                class="tab-list driver-tab-list"
                            >
                                <b-tab title="Primary Info">
                                    <PersonalInfo
                                        :personalinfo="personalinfo"
                                        :newAdditionalField="newAdditionalField"
                                        ref="personalinfocomponent"
                                        :candContactAccess="candContactAccess"
                                    ></PersonalInfo>
                                </b-tab>
                                <b-tab title="Address Info">
                                    <ContactInfo
                                        :contactinfo="contactinfo"
                                        ref="contactinfocomponent"
                                    ></ContactInfo>
                                </b-tab>
                                <b-tab title="Emp History">
                                    <EmploymentInfo
                                        :employmentinfo="employmentinfo"
                                        ref="employmentinfocomponent"
                                    ></EmploymentInfo>
                                </b-tab>
                                <b-tab title="Reference Details">
                                    <ReferenceInfo
                                        :referenceinfo="employmentinfo"
                                    ></ReferenceInfo>
                                </b-tab>
                                <b-tab title="Technology/Skills">
                                    <TechnologyInfo
                                        :technologyinfo="technologyinfo"
                                        :techinfo="techinfo"
                                        ref="technologyinfocomponent"
                                    ></TechnologyInfo>
                                </b-tab>
                                <b-tab title="Projects">
                                    <ProjectInfo
                                        :projectinfo="projectinfo"
                                        ref="projectinfocomponent"
                                    ></ProjectInfo>
                                </b-tab>
                                <b-tab title="Preferences">
                                    <PreferenceInfo
                                        :preferenceinfo="preferenceinfo"
                                        ref="preferenceinfocomponent"
                                    ></PreferenceInfo>
                                </b-tab>
                                <b-tab title="Resume">
                                    <ResumeAttach
                                        :resumeinfo="resumeinfo"
                                        :isResumeCapture="isResumeCapture"
                                        :attachments="attachments"
                                        :attachmentTypes="attachmentTypes"
                                        :multipleFile="multipleFile"
                                        ref="resumeattachcomponent"
                                    ></ResumeAttach>
                                </b-tab>
                                <!-- <b-tab title="Screening Results">
                                    <Screening :screeninginfo="screeninginfo" ref="screeningcomponent"></Screening>
                                </b-tab> -->
                                <b-tab title="Settings">
                                    <Settings
                                        :settingsInfo="settingsInfo"
                                        ref="settingscomponent"
                                    ></Settings>
                                </b-tab>
                            </b-tabs>
                            <div class="col-md-12 pt-2 mt-3 text-right">
                                <a
                                    href="Javascript:void(0);"
                                    @click="updateLogActivity()"
                                    class="btn btn-secondary mr-2"
                                >
                                    <router-link
                                        class="text-white"
                                        :to="{ name: 'listcandidates' }"
                                        >Cancel</router-link
                                    >
                                </a>
                                <a
                                    href="Javascript:void(0);"
                                    v-if="currentCandidateTab != 0"
                                    @click="currentCandidate"
                                    class="btn btn-primary necttab mr-2"
                                    >Previous</a
                                >
                                <a
                                    href="Javascript:void(0);"
                                    v-if="currentCandidateTab < maxtabs"
                                    @click="moveNextTab"
                                    class="btn btn-primary necttab mr-2"
                                    >Next</a
                                >
                                <a
                                    href="Javascript:void(0);"
                                    @click="submitInfo"
                                    class="btn btn-primary"
                                    >Submit</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style type="text/css">
/*.disabledTab {
    pointer-events: none;
}
*/
.actionlinks {
    padding: 6px;
    margin-left: 10px;
    text-decoration: none !important;
}

.actionprimary {
    background: rgba(255, 255, 255, 0) !important;
    right: -1px !important;
    border: 0px !important;
    color: #34a1e5 !important;
}

.clearright {
    right: 0px !important;
}

.enablefield {
    background-color: white !important;
    width: 100%;
}

.input-group > div {
    width: 100% !important;
}

.vdp-datepicker__calendar .cell {
    padding: 0px !important;
}
</style>
<script>
import iziToast from "izitoast";
import PersonalInfo from "./blocks/addcandidate/PersonalInfo";
import ContactInfo from "./blocks/addcandidate/ContactInfo";
import EmploymentInfo from "./blocks/addcandidate/EmploymentInfo";
import ReferenceInfo from "./blocks/addcandidate/ReferenceInfo";
import TechnologyInfo from "./blocks/addcandidate/TechnologyInfo";
import PreferenceInfo from "./blocks/addcandidate/PreferenceInfo";
// import AvailabilityInfo from './blocks/addcandidate/AvailabilityInfo';
import ResumeAttach from "./blocks/addcandidate/ResumeAttach";
import Screening from "./blocks/addcandidate/ScreeningResult";
import Settings from "./blocks/addcandidate/Settings";
import ProjectInfo from "./blocks/addcandidate/ProjectInfo";
export default {
    components: {
        PersonalInfo,
        ContactInfo,
        EmploymentInfo,
        ReferenceInfo,
        TechnologyInfo,
        PreferenceInfo,
        // AvailabilityInfo,
        ResumeAttach,
        Screening,
        Settings,
        ProjectInfo,
    },
    data() {
        return {
            currencies: this.$store.getters.getCurrencies,
            languages: this.$store.getters.getLanguages,
            docSrc: "",
            currentCandidateTab: -1,
            maxtabs: 8,
            candidateinfo: {},
            personalinfo: {
                candidateCode: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                countryCode: "+1",
                mobile: "",
                dateOfBirth: "",
                dateAvailable: "",
                position: "",
                industryId: "",
                location: "",
                experience: "",
                expMonth: "",
                expType: "Years",
                referredBy: "",
                payMode: "",
                recruiterId: this.$store.getters.currentUser.profileId,
                visaTypeId: "",
                visaType: "",
                notes: "",
                educationinfo: [],
                blockpersonaltab: 0,
                candidateLanguageSet: [],
                candidateLanguage: [],
                sourcingChannel: "",
                sourcingRefLink: "",
                linkedInUrl: "",
                candidateAdditionalFields: [],
                status: 1,
                extractedResume: "",
                candidateType: "",
                willRelocate: "",
                editIndex: true,
            },
            contactinfo: {
                currentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "",
                },
                permanentaddress: {
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "",
                },
                emergency: [],
            },
            employmentinfo: [],
            technologyinfo: [],
            techinfo: [],
            projectinfo: [],
            preferenceinfo: {
                basepreference: [],
                otherpreference: {
                    timetocall: "",
                    workType: "",
                    expectedSalary: "",
                    currency: "",
                    currencyId: "",
                    blockpreferrancetab: 0,
                    minSalary: "",
                    maxSalary: "",
                    frequencyPercentage: "",
                    payFrequency: "Per Month",
                    parttime: "no",
                    fulltime: "no",
                },
                candidateCommunication: [],
            },
            // availabilityinfo:{},
            resumeinfo: {
                file: "",
                title: "Resume Upload",
                supportedTypes: "Supports only PDF, Doc., Docx, Rtf file types",
            },
            screeninginfo: [],
            settingsInfo: {
                timeZone: "",
                accessLevel: 1,
            },
            newAdditionalField: {
                id: 0,
                nameValueId: 0,
                valueFormatId: "",
                intValue: "",
                stringValue: "",
                dateValue: "",
                booleanValue: "",
            },
            taskId: 0,
            activityId: 0,
            parsedResumeFileName: "",
            parsedData: [],
            isResumeCapture: false,
            attachments: [],
            attachmentTypes: [],
            multipleFile: [],
            logActivityData: {
                userId: "",
                entityName: "",
                entityPk: "",
                actionName: "",
                module: "",
            },
            candContactAccess: 0,
            filePath: localStorage.getItem("parsedResumeFileName"),
        };
    },
    mounted() {
        this.loadCurrency();
        this.bindIframeSrc();
        // for ramesh code
        if (
            localStorage.getItem("resumeCapture") != null &&
            localStorage.getItem("resumeCapture") == "true"
        ) {
            this.parsedData = JSON.parse(localStorage.getItem("parsedData"));
            console.log(this.parsedData);

            console.log(localStorage.getItem("resume"));
            let candName = this.parsedData.name.split(" ");
            if (candName != null && candName[0] != null)
                this.personalinfo.firstName = candName[0];
            if (candName != null && candName[1] != null)
                this.personalinfo.lastName = candName[1];
            if (candName != null && candName[2] != null)
                this.personalinfo.lastName =
                    this.personalinfo.lastName + " " + candName[2];

            this.personalinfo.location = this.parsedData.currentlocation;
            this.personalinfo.email = this.parsedData.email;
            const regex = /(\d)\s+(?=\d)/g;
            const subst = `$1`;
            if (this.parsedData.mobile_number != null) {
                this.personalinfo.mobile =
                    (this.parsedData.isdCode == ""
                        ? "+1"
                        : this.parsedData.isdCode) +
                    " " +
                    this.parsedData.mobile_number.replace(regex, subst);
            }
            this.contactinfo.currentaddress.cityName =
                this.parsedData.addressCity;
            this.contactinfo.currentaddress.stateName =
                this.parsedData.addressState;
            this.contactinfo.currentaddress.countryId =
                this.parsedData.countryCode;
            this.personalinfo.linkedInUrl = this.parsedData.linkdinUrl;
            this.contactinfo.currentaddress.zipCode =
                this.parsedData.addressZipcode;
            this.contactinfo.currentaddress.addressLine1 =
                this.parsedData.addressStreet;

            if (this.parsedData.total_experience != null) {
                let maxYear = 21;
                console.log(
                    parseInt(this.parsedData.total_experience, 10) || ""
                );
                this.personalinfo.experience =
                    parseInt(this.parsedData.total_experience, 10) || "";

                let month =
                    (this.parsedData.total_experience % 1).toFixed(1) * 10;
                if (month > 0) {
                    this.personalinfo.expMonth = month;
                }

                if (this.personalinfo.experience > 20) {
                    this.personalinfo.experience = maxYear;
                    this.personalinfo.expMonth = "";
                    console.log(this.personalinfo.experience);
                }

                if (this.personalinfo.experience == "") {
                    let experience = parseInt(
                        this.parsedData.total_experience.replace(
                            /[^0-9\.]/g,
                            ""
                        ),
                        10
                    );
                    this.personalinfo.experience = experience;
                }
            }
            // commented this resumer parser file content is stored as object - so we got e error as an error while uploading resume
            //this.resumeinfo.file = JSON.parse(localStorage.getItem("resume"));
            if (
                this.parsedData.designation != null &&
                this.parsedData.designation != "None"
            ) {
                let designation = "";
                //console.log(this.parsedData.designation.length);
                /*for (var i = 0; i < this.parsedData.designation.length; i++) {
                    designation  = this.parsedData.designation[i] ;
                }*/
                this.personalinfo.position = this.parsedData.designation;
            }

            var techAndSkill = [];
            if (
                this.parsedData.tech_skills != null &&
                this.parsedData.tech_skills != ""
            ) {
                let skillName = [];
                if (this.parsedData.tech_skills.length > 0) {
                    for (
                        var row = 0;
                        row < this.parsedData.tech_skills.length;
                        row++
                    ) {
                        skillName.push(this.parsedData.tech_skills[row]);
                    }
                    techAndSkill.push({
                        technology: "",
                        skills: "",
                        experience: { value: null, label: null },
                        industry: { industryName: "", id: null },
                        technologyName: "tech_skills",
                        skillName: skillName,
                    });
                }
            }
            if (
                this.parsedData.soft_skills != null &&
                this.parsedData.soft_skills != ""
            ) {
                let skillName = [];
                if (this.parsedData.soft_skills.length > 0) {
                    for (
                        var row = 0;
                        row < this.parsedData.soft_skills.length;
                        row++
                    ) {
                        skillName.push(this.parsedData.soft_skills[row]);
                    }
                    techAndSkill.push({
                        technology: "",
                        skills: "",
                        experience: { value: null, label: null },
                        industry: { industryName: "", id: null },
                        technologyName: "soft_skills",
                        skillName: skillName,
                    });
                }
            }
            if (
                this.parsedData.domain_skills != null &&
                this.parsedData.domain_skills != ""
            ) {
                let skillName = [];
                if (this.parsedData.domain_skills.length > 0) {
                    for (
                        var row = 0;
                        row < this.parsedData.domain_skills.length;
                        row++
                    ) {
                        skillName.push(this.parsedData.domain_skills[row]);
                    }
                    techAndSkill.push({
                        technology: "",
                        skills: "",
                        experience: { value: null, label: null },
                        industry: { industryName: "", id: null },
                        technologyName: "domain_skills",
                        skillName: skillName,
                    });
                }
            }

            if (
                this.parsedData.skills != null &&
                this.parsedData.skills != ""
            ) {
                let skillName = [];
                if (this.parsedData.skills.length > 0) {
                    const operArr = this.parsedData.skills
                        .toString()
                        .replace(/\s*\,\s*/g, ",")
                        .trim()
                        .split(",");
                    const soft = this.parsedData.soft_skills;
                    const tech = this.parsedData.tech_skills;
                    const techsoft = soft.concat(tech);
                    const namesToDeleteSet = new Set(techsoft);
                    const newArr = operArr.filter((name) => {
                        return !namesToDeleteSet.has(name);
                    });
                    for (var row = 0; row < newArr.length; row++) {
                        skillName.push(newArr[row]);
                    }
                    techAndSkill.push({
                        technology: "",
                        skills: "",
                        experience: { value: null, label: null },
                        industry: { industryName: "", id: null },
                        technologyName: "operational_skills",
                        skillName: skillName,
                    });
                }
            }
            this.technologyinfo = techAndSkill;
            var educationInfo = [];
            if (this.parsedData.education != null) {
                let education = [];
                for (
                    var row = 0;
                    row < this.parsedData.education.length;
                    row++
                ) {
                    educationInfo.push({
                        degree: this.parsedData.education[row],
                        schoolName:
                            this.parsedData.instituteInformations[row]
                                .instituteName,
                        countryId:
                            this.parsedData.instituteInformations[row]
                                .instituteCountry == ""
                                ? null
                                : this.parsedData.instituteInformations[row]
                                      .instituteCountry,
                        city: this.parsedData.instituteInformations[row]
                            .instituteCity,
                        stateName:
                            this.parsedData.instituteInformations[row]
                                .instituteState,
                        year: this.parsedData.instituteInformations[
                            row
                        ].completedyear.substring(
                            this.parsedData.instituteInformations[row]
                                .completedyear.length - 4
                        ),
                    });
                }
                this.personalinfo.educationinfo = educationInfo;
            }
            var employmentinfo = [];
            if (
                this.parsedData.employmentHistory != null &&
                this.parsedData.employmentHistory.find(
                    (e) =>
                        e.companyName != "" ||
                        e.city != "" ||
                        e.companyEnddate != "" ||
                        e.companyStartdate != "" ||
                        e.country != "" ||
                        e.emp_skills != ""
                )
            ) {
                var employedFrom = [];
                var employedTo = [];
                for (
                    var row = 0;
                    row < this.parsedData.employmentHistory.length;
                    row++
                ) {
                    let startDate =
                        this.parsedData.employmentHistory[
                            row
                        ].companyStartdate.split("/");
                    if (
                        startDate != null &&
                        startDate[0] != null &&
                        this.parsedData.employmentHistory[row]
                            .companyStartdate != ""
                    )
                        employedFrom = startDate[2] + "-" + startDate[1];

                    let endDate =
                        this.parsedData.employmentHistory[
                            row
                        ].companyEnddate.split("/");
                    if (
                        endDate != null &&
                        endDate[0] != null &&
                        this.parsedData.employmentHistory[row].companyEnddate !=
                            ""
                    )
                        employedTo = endDate[2] + "-" + endDate[1];

                    employmentinfo.push({
                        employedFrom1: employedFrom == "" ? "" : employedFrom,
                        employedTo1: employedTo == "" ? "" : employedTo,
                        companyName:
                            this.parsedData.employmentHistory[row].companyName,
                        jobTitle:
                            this.parsedData.employmentHistory[row].jobTitle,
                        skillSet:
                            this.parsedData.employmentHistory[row].emp_skills,
                        city: this.parsedData.employmentHistory[row].city,
                        countryId:
                            this.parsedData.employmentHistory[row].country,
                    });
                }
                this.employmentinfo = employmentinfo;
            }

            var projectInfo = [];
            if (this.parsedData.projectDetail != null) {
                var projectStartDate = [];
                var projectEndDate = [];
                for (
                    var row = 0;
                    row < this.parsedData.projectDetail.length;
                    row++
                ) {
                    let proStartDate =
                        this.parsedData.projectDetail[row].startDate.split("/");
                    if (
                        proStartDate != null &&
                        proStartDate[0] != null &&
                        this.parsedData.projectDetail[row].startDate != ""
                    )
                        projectStartDate =
                            proStartDate[2] + "-" + proStartDate[1];

                    let proEndDate =
                        this.parsedData.projectDetail[row].endDate.split("/");
                    if (
                        proEndDate != null &&
                        proEndDate[0] != null &&
                        this.parsedData.projectDetail[row].endDate != ""
                    )
                        projectEndDate = proEndDate[2] + "-" + proEndDate[1];
                    if (this.parsedData.projectDetail[row].projectTitle != "") {
                        projectInfo.push({
                            projectTitle:
                                this.parsedData.projectDetail[row].projectTitle,
                            client: this.parsedData.projectDetail[row].client,
                            role: this.parsedData.projectDetail[row].role,
                            technology:
                                this.parsedData.projectDetail[row].techskills,
                            projectDescription:
                                this.parsedData.projectDetail[row].descp,
                            projectStartDate1:
                                projectStartDate == "" ? "" : projectStartDate,
                            projectEndDate1:
                                projectEndDate == "" ? "" : projectEndDate,
                        });
                    }
                }
                // projectInfo.push({
                //     projectDescription: this.parsedData.experience.join(', ')
                // });
                this.projectinfo = projectInfo;
            }
            if (this.parsedData.language != "") {
                for (
                    var row = 0;
                    row < this.parsedData.language.length;
                    row++
                ) {
                    const language = this.languages.find(
                        (obj) => obj.text === this.parsedData.language[row]
                    );
                    this.personalinfo.candidateLanguageSet.push(language.id);
                }
            }
            this.sidebarToggle();
        }
        /* else {
             this.sidebarToggle();
         }*/

        // for technology and skill
        // console.log(this.technologyinfo);

        // return;
        // old tt code
        else if (
            localStorage.getItem("resumeCaptureOld") != null &&
            localStorage.getItem("resumeCaptureOld") == "true"
        ) {
            this.parsedData = JSON.parse(localStorage.getItem("parsedData"));
            this.personalinfo.firstName = this.parsedData.firstName;
            this.personalinfo.lastName = this.parsedData.lastName;
            this.personalinfo.email = this.parsedData.email;
            this.personalinfo.mobile = this.parsedData.mobile;
            this.personalinfo.notes = this.parsedData.summary;
            // commented this resumer parser file content is stored as object - so we got e error as an error while uploading resume
            //this.resumeinfo.file = JSON.parse(localStorage.getItem("resume"));

            // for technology and skill
            var techAndSkill = [];
            if (this.parsedData.techList != null) {
                for (var i = 0; i < this.parsedData.techList.length; i++) {
                    let skillData = this.parsedData.techList[i];

                    if (skillData.technologySkillData.length != 0) {
                        techAndSkill.push({
                            technology: {
                                technology: skillData.technologyName,
                                id: null,
                            },
                            skills: [],
                            experience: { value: null, label: null },
                            industry: { industryName: "", id: null },
                        });
                        for (
                            var j = 0;
                            j < skillData.technologySkillData.length;
                            j++
                        ) {
                            if (j == 0) {
                                techAndSkill[i].technology.id =
                                    skillData.technologySkillData[
                                        j
                                    ].technologyId;
                                techAndSkill[i].experience.value =
                                    skillData.technologySkillData[j].years;
                                techAndSkill[i].experience.label =
                                    skillData.technologySkillData[j].years;
                                techAndSkill[i].experience.label =
                                    techAndSkill[i].experience.label == 0
                                        ? "<1"
                                        : techAndSkill[i].experience.label;
                                techAndSkill[i].industry.industryName =
                                    skillData.technologySkillData[
                                        j
                                    ].industryName;
                                techAndSkill[i].industry.id =
                                    skillData.technologySkillData[j].industryId;
                            }

                            techAndSkill[i].skills.push({
                                skillId: skillData.technologySkillData[j].id,
                                skillName:
                                    skillData.technologySkillData[j].skillName,
                            });
                        }
                    }
                }
            }
            this.technologyinfo = techAndSkill;
            // for technology and skill
            //console.log(localStorage);
        } else {
            this.sidebarToggle();
        }
    },
    methods: {
        loadCurrency() {
            var currencyId = localStorage.getItem("currencyId");
            this.preferenceinfo.otherpreference.currencyId = currencyId;
            var currency = this.currencies.find((c) => c.id == currencyId);
            if (currency) {
                this.preferenceinfo.otherpreference.currency = currency.symbol;
            }
        },
        bindIframeSrc: function () {
            this.filePath = localStorage.getItem("parsedResumeFileName");
            console.log(this.filePath);
            this.docSrc =
                "https://docs.google.com/gview?url=" +
                this.filePath +
                "&embedded=true";
        },
        currentCandidate() {
            switch (this.currentCandidateTab) {
                case 5:
                    this.$refs.projectinfocomponent.addNewProjectInfo();
                    this.saveProjectInformation();
                    this.currentCandidateTab--;
                    break;
                default:
                    this.currentCandidateTab--;
            }
        },
        moveNextTab() {
            switch (this.currentCandidateTab) {
                case 0:
                    //personal info validation
                    this.$refs.personalinfocomponent.validatePersonalInfo();
                    this.$refs.personalinfocomponent.addEducationInfo();
                    if (this.personalinfo.blockpersonaltab == 0) {
                        this.currentCandidateTab++;
                    }
                    break;
                case 1:
                    //contact info tab
                    this.$refs.contactinfocomponent.addNewContact();
                    this.currentCandidateTab++;
                    break;
                case 2:
                    this.$refs.employmentinfocomponent.addNewEmploymentInfo();
                    this.currentCandidateTab++;
                    break;
                case 4:
                    this.$refs.technologyinfocomponent.addMoreTechInfo();
                    this.$refs.technologyinfocomponent.addNewSkillset();
                    this.currentCandidateTab++;
                    break;
                case 5:
                    this.$refs.projectinfocomponent.addNewProjectInfo();
                    this.saveProjectInformation();
                    this.currentCandidateTab++;
                    break;
                case 6:
                    this.$refs.preferenceinfocomponent.validatePreferrenceInfo();
                    this.$refs.preferenceinfocomponent.addNewPreferenceInfo();
                    this.$refs.preferenceinfocomponent.saveCommunicationMode();
                    if (
                        this.preferenceinfo.otherpreference
                            .blockpreferrancetab == 0
                    ) {
                        this.currentCandidateTab++;
                    }
                    // this.$refs.availabilityinfocomponent.refreshCalendar();
                    break;
                // case 6:
                //  //this.$refs.availabilityinfocomponent.addNewAvailabilityInfo();
                //  this.currentCandidateTab++;
                //  break;
                default:
                    this.currentCandidateTab++;
            }
        },
        submitInfo() {
            if (
                this.$refs.personalinfocomponent.validatePersonalInfo() &&
                this.$refs.preferenceinfocomponent.validatePreferrenceInfo()
            ) {
                switch (this.currentCandidateTab) {
                    case 0:
                        //personal info validation
                        if (
                            !this.$refs.personalinfocomponent.validatePersonalInfo()
                        ) {
                            return false;
                        }
                        this.$refs.personalinfocomponent.addEducationInfo();
                        break;
                    case 1:
                        //contact info tab
                        this.$refs.contactinfocomponent.addNewContact();
                        break;
                    case 2:
                        this.$refs.employmentinfocomponent.addNewEmploymentInfo();
                        break;
                    case 4:
                        this.$refs.technologyinfocomponent.addMoreTechInfo();
                        this.$refs.technologyinfocomponent.addNewSkillset();
                        break;
                    case 5:
                        this.$refs.projectinfocomponent.addNewProjectInfo();
                        this.saveProjectInformation();
                        break;
                    case 6:
                        if (
                            !this.$refs.preferenceinfocomponent.validatePreferrenceInfo()
                        ) {
                            return false;
                        }
                        this.$refs.preferenceinfocomponent.addNewPreferenceInfo();
                        this.$refs.preferenceinfocomponent.saveCommunicationMode();
                        //this.$refs.availabilityinfocomponent.refreshCalendar();
                        break;
                }

                let dataType = this.$store.getters.getAdditionalFieldsType;

                let candidateAdditionalFields = [
                    ...this.personalinfo.candidateAdditionalFields,
                ];

                if (
                    this.newAdditionalField.valueFormatId !== "" &&
                    this.newAdditionalField.nameValueId !== ""
                ) {
                    if (
                        this.newAdditionalField.valueFormatId ==
                            dataType.xNumber &&
                        this.newAdditionalField.intValue !== ""
                    ) {
                        candidateAdditionalFields.push({
                            ...this.newAdditionalField,
                        });
                    } else if (
                        this.newAdditionalField.valueFormatId ==
                            dataType.xText &&
                        this.newAdditionalField.stringValue !== ""
                    ) {
                        candidateAdditionalFields.push({
                            ...this.newAdditionalField,
                        });
                    } else if (
                        this.newAdditionalField.valueFormatId ==
                            dataType.xYesNo &&
                        this.newAdditionalField.booleanValue !== ""
                    ) {
                        candidateAdditionalFields.push({
                            ...this.newAdditionalField,
                        });
                    } else if (
                        this.newAdditionalField.valueFormatId ==
                            dataType.xDate &&
                        this.newAdditionalField.dateValue !== ""
                    ) {
                        candidateAdditionalFields.push({
                            ...this.newAdditionalField,
                        });
                    }
                }
                //this.personalinfo.candidateAdditionalFields = [...candidateAdditionalFields];

                this.personalinfo.candidateLanguage = [];
                for (
                    var index = 0;
                    index < this.personalinfo.candidateLanguageSet.length;
                    index++
                ) {
                    // try {
                    //  this.personalinfo.candidateLanguage.push({languageId:parseInt(this.personalinfo.candidateLanguageSet[index])});
                    // } catch(ex){

                    // }
                    this.personalinfo.candidateLanguage.push({
                        languageId:
                            this.personalinfo.candidateLanguageSet[index],
                    });
                }

                var techAndSkill = [];

                /*  Hide the add candidate code
                for (var i = 0; i < this.technologyinfo.length; i++) {
                     if (this.technologyinfo[i].skills.length > 0) {
                         for (var j = 0; j < this.technologyinfo[i].skills.length; j++) {
                             techAndSkill.push({
                                 industryId: this.technologyinfo[i].industry.id,
                                 technologyId: this.technologyinfo[i].technology.id,
                                 years: this.technologyinfo[i].experience.value,
                                 skillId: this.technologyinfo[i].skills[j].skillId,
                                 title: ''
                             });
                         }
                     } else {
                         techAndSkill.push({
                             industryId: this.technologyinfo[i].industry.id,
                             technologyId: this.technologyinfo[i].technology.id,
                             years: this.technologyinfo[i].experience.value,
                             skillId: null,
                             title: ''
                         });
                     }
                 }*/

                for (var i = 0; i < this.technologyinfo.length; i++) {
                    if (this.technologyinfo[i].skills.length > 0) {
                        for (
                            var j = 0;
                            j < this.technologyinfo[i].skills.length;
                            j++
                        ) {
                            techAndSkill.push({
                                industryId: this.technologyinfo[i].industryId,
                                technologyId:
                                    this.technologyinfo[i].technology.id,
                                years: this.technologyinfo[i].experience.value,
                                skillId:
                                    this.technologyinfo[i].skills[j].skillId,
                                title: "",
                                technologyName:
                                    this.technologyinfo[i].technology
                                        .technology, //this.technologyinfo[i].technologyName,
                                skillName:
                                    this.technologyinfo[i].skills[j].skillName, //    this.technologyinfo[i].skillName
                                isPrimary:
                                    this.technologyinfo[i].isPrimary == null
                                        ? false
                                        : this.technologyinfo[i].isPrimary,
                            });
                        }
                    } else {
                        for (
                            var j = 0;
                            j < this.technologyinfo[i].skillName.length;
                            j++
                        ) {
                            techAndSkill.push({
                                industryId: this.technologyinfo[i].industryId,
                                technologyId: null,
                                years: this.technologyinfo[i].experience.value,
                                skillId: null,
                                title: "",
                                technologyName:
                                    this.technologyinfo[i].technologyName,
                                skillName: this.technologyinfo[i].skillName[j],
                                isPrimary:
                                    this.technologyinfo[i].isPrimary == null
                                        ? false
                                        : this.technologyinfo[i].isPrimary,
                            });
                        }
                    }
                }

                if (
                    localStorage.getItem("taskId") != null &&
                    localStorage.getItem("taskId") > 0
                ) {
                    this.taskId = localStorage.getItem("taskId");
                    this.activityId = localStorage.getItem("activityLogId");
                }
                if (localStorage.getItem("parsedResumeFileName") != "") {
                    this.parsedResumeFileName = localStorage.getItem(
                        "parsedResumeFileName"
                    );
                }

                /* let mobile = '';
                if (this.personalinfo.mobile != '') {
                    mobile = this.personalinfo.countryCode + " " + this.personalinfo.mobile;
                } */

                for (var row = 0; row < this.employmentinfo.length; row++) {
                    if (
                        this.employmentinfo[row].employedFrom1 != "" &&
                        this.employmentinfo[row].employedFrom1 != null
                    ) {
                        this.employmentinfo[row].employedFrom =
                            this.employmentinfo[row].employedFrom1 + "-11";
                    }
                    if (
                        this.employmentinfo[row].employedTo1 != "" &&
                        this.employmentinfo[row].employedTo1 != null
                    ) {
                        this.employmentinfo[row].employedTo =
                            this.employmentinfo[row].employedTo1 + "-11";
                    }
                }

                this.contactinfo.emergency.forEach(function (data) {
                    data.phone = data.phoneNo;
                });
                this.candidateinfo = {
                    recruiterId: this.personalinfo.recruiterId,
                    visaTypeId: this.personalinfo.visaType.id,
                    visaTypeText: this.personalinfo.visaType.text,
                    candidateCode: this.personalinfo.candidateCode,
                    firstName: this.personalinfo.firstName,
                    lastName: this.personalinfo.lastName,
                    middleName: this.personalinfo.middleName,
                    email: this.personalinfo.email,
                    mobile: this.personalinfo.mobile,
                    dateOfBirth: this.formatDateString(
                        this.personalinfo.dateOfBirth
                    ),
                    //dateAvailable:this.formatDateString(this.personalinfo.dateAvailable),
                    industryId: this.personalinfo.industryId,
                    position: this.personalinfo.position,
                    location: this.personalinfo.location,
                    experience: this.personalinfo.experience,
                    expMonth: this.personalinfo.expMonth,
                    expType: this.personalinfo.expType,
                    referredBy: this.personalinfo.referredBy,
                    sourcingChannelId: this.personalinfo.sourcingChannel,
                    sourcingRefLink: this.personalinfo.sourcingRefLink,
                    linkedInUrl: this.personalinfo.linkedInUrl,
                    payMode: this.personalinfo.payMode,
                    notes: this.personalinfo.notes,
                    timeToCall: this.preferenceinfo.otherpreference.timetocall,
                    workType: this.preferenceinfo.otherpreference.workType,
                    expectedSalary:
                        this.preferenceinfo.otherpreference.expectedSalary,
                    minSalary: this.preferenceinfo.otherpreference.minSalary,
                    maxSalary: this.preferenceinfo.otherpreference.maxSalary,
                    flexibilityPercentage:
                        this.preferenceinfo.otherpreference.frequencyPercentage,
                    currency: this.preferenceinfo.otherpreference.currency,
                    currencyId: this.preferenceinfo.otherpreference.currencyId,
                    payFrequency:
                        this.preferenceinfo.otherpreference.payFrequency,
                    isPartTime:
                        this.preferenceinfo.otherpreference.parttime == "yes"
                            ? true
                            : false,
                    isFullTime:
                        this.preferenceinfo.otherpreference.fulltime == "yes"
                            ? true
                            : false,
                    candidateAddress: {
                        currentAddress: this.contactinfo.currentaddress,
                        permanentAddress: this.contactinfo.permanentaddress,
                        emergencyContact: this.contactinfo.emergency,
                    },
                    candidateEducation: this.personalinfo.educationinfo,
                    candidateEmployment: this.employmentinfo,
                    candidatePreference: this.preferenceinfo.basepreference,
                    candidateSkill: techAndSkill,
                    timeZone: this.settingsInfo.timeZone,
                    accessLevel: this.settingsInfo.accessLevel,
                    candidateLanguage: this.personalinfo.candidateLanguage,
                    candidateCommunication:
                        this.preferenceinfo.candidateCommunication,
                    candidateAdditionalFields: [...candidateAdditionalFields],
                    status: this.personalinfo.status,
                    taskId: this.taskId,
                    activityId: this.activityId,
                    extractedResume: this.parsedData.sbExtractData,
                    parsedResumeFileName: this.parsedResumeFileName,
                    candidateType: this.personalinfo.candidateType,
                    willRelocate: this.personalinfo.willRelocate,
                    candidateProject: this.projectinfo,
                };
                console.log(this.candidateinfo);

                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                            "/candidateresource/add/candidate",
                        this.candidateinfo
                    )
                    .then((response) => {
                        if (response.data.status == "ACCEPTED") {
                            let candidateId = response.data.id;

                            if (
                                this.attachments != null &&
                                this.attachments.length > 0
                            ) {
                                let formData = new FormData();

                                for (const row of Object.keys(
                                    this.attachments
                                )) {
                                    formData.append(
                                        "files",
                                        this.attachments[row]
                                    );
                                }
                                for (const row of Object.keys(
                                    this.attachmentTypes
                                )) {
                                    formData.append(
                                        "attachmentTypes",
                                        this.attachmentTypes[row].name
                                    );
                                    formData.append(
                                        "attachmentTypesId",
                                        this.attachmentTypes[row].id
                                    );
                                }
                                formData.append("id", candidateId);

                                axios
                                    .post(
                                        this.$store.getters.getAPIBasePath +
                                            "/resumeresource/uploadresume/multiple",
                                        formData,
                                        {
                                            headers: {
                                                "Content-Type":
                                                    "multipart/form-data",
                                            },
                                        }
                                    )
                                    .then((response) => {
                                        let result = response.data;
                                        if (result.status == "ACCEPTED") {
                                            this.resumeinfo.filename = "";
                                            iziToast.success({
                                                title: "Success",
                                                message: result.message,
                                                position: "topRight",
                                            });
                                        } else {
                                            iziToast.error({
                                                title: "Error",
                                                message: result.message,
                                                position: "topRight",
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        this.unAuthorized(error);
                                        console.log(error.data);
                                    });
                            }

                            if (this.resumeinfo.file != "") {
                                let formData = new FormData();
                                formData.append("file", this.resumeinfo.file);
                                formData.append("id", response.data.id);
                                axios
                                    .post(
                                        this.$store.getters.getAPIBasePath +
                                            "/resumeresource/uploadresume",
                                        formData,
                                        {
                                            headers: {
                                                "Content-Type":
                                                    "multipart/form-data",
                                            },
                                        }
                                    )
                                    .then((response) => {
                                        iziToast.success({
                                            title: "Success",
                                            message:
                                                this.$config.CandidProWitRes,
                                            position: "topRight",
                                        });
                                        // this.$router.push('/candidates/edit/'+ response.data.id);
                                        this.$router.push(
                                            "/candidates/view/" + candidateId
                                        );
                                    })
                                    .catch((error) => {
                                        iziToast.error({
                                            title: "",
                                            message:
                                                this.$config.CandidProCreErr,
                                            position: "topRight",
                                        });
                                        this.$router.push(
                                            "/candidates/view/" + candidateId
                                        );
                                    });
                            } else {
                                // resume parser file
                                if (
                                    this.candidateinfo.parsedResumeFileName !=
                                    ""
                                ) {
                                    iziToast.success({
                                        title: "Success",
                                        message: this.$config.CandidProWitRes,
                                        position: "topRight",
                                    });
                                } else {
                                    iziToast.success({
                                        title: "Success",
                                        message:
                                            this.$config.CandidProWitOutRes,
                                        position: "topRight",
                                    });
                                }

                                this.$router.push(
                                    "/candidates/view/" + candidateId
                                );
                            }
                        } else {
                            iziToast.error({
                                title: "Error Occured",
                                message: response.data.message,
                                position: "topRight",
                            });
                        }
                    })
                    .catch((error) => {
                        if (error.response.data.message == "Validation Error") {
                            this.currentCandidateTab = 0;
                            this.$refs.personalinfocomponent.validatePersonalInfo();

                            iziToast.info({
                                title: "Validation Error",
                                message: this.$config.CandEmailValiMsg,
                                position: "topRight",
                            });
                        } else if (
                            error.response.data.message != null &&
                            error.response.data.message.includes(
                                "silently rolled"
                            )
                        ) {
                            iziToast.info({
                                title: "Error Occured",
                                message: this.$config.ValidationErr,
                                position: "topRight",
                            });
                        } else {
                            if (error.response != null) {
                                iziToast.error({
                                    title: "Error Occured",
                                    message: error.response.data.message,
                                    position: "topRight",
                                });
                            }
                        }
                    });
            } else {
                if (
                    this.$refs.preferenceinfocomponent.validatePreferrenceInfo()
                ) {
                    this.currentCandidateTab = 0;
                } else this.currentCandidateTab = 6;
            }
        },
        sidebarToggle: function (event) {
            $(".reports.settings").toggleClass("sidebar-collapse");
            //this.showWord = false;
        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        getResumeExtract: function () {
            /* let formData = new FormData();
             formData.append('file', this.resumeinfo.file);
             //formData.append('id', response.data.id);
             axios.post(this.$store.getters.getAPIBasePath + '/resumeparserresource/readword', formData, {
                     headers: {
                         'Content-Type': 'multipart/form-data'
                     }
                 }).then(response => {
                     console.log(response.data);
                     this.parsedData = response.data.parsedData;

                 })
                 .catch(error => {

                 });*/
        },
        updateLogActivity: function () {
            let candId = localStorage.getItem("candIdForActivity");
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                        "/logresource/update/logactivity"
                )
                .then((response) => {
                    localStorage.setItem("candIdForActivity", null);
                })
                .catch((error) => {});
        },
        saveProjectInformation() {
            for (var i = 0; i < this.projectinfo.length; i++) {
                if (this.projectinfo[i].projectStartDate1 != "") {
                    this.projectinfo[i].projectStartDate =
                        this.projectinfo[i].projectStartDate1 + "-11";
                }
                if (this.projectinfo[i].projectEndDate1 != "") {
                    this.projectinfo[i].projectEndDate =
                        this.projectinfo[i].projectEndDate1 + "-11";
                }
            }

            let projectinfoupdate = {
                candidateProject: this.projectinfo,
            };
            // axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/update/candidate/' + this.candidateid + '/project', projectinfoupdate)
            //     .then(response => {
            //         iziToast.success({
            //             title: 'Success',
            //             message: 'Candidate Project information updated successfully',
            //             position: 'topRight',
            //             timeout: 1000
            //         });
            //         this.loadCandidateById(this.candidateid);
            //         //this.$router.push('/candidates/view/' + this.candidateid);
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         iziToast.error({
            //             title: 'Error Occured',
            //             message: 'Couldnt update candidate information',
            //             position: 'topRight'
            //         });
            //     });
        },
    },
};
</script>
