<template>
    <div id="main" class="mt-70 interviewer interview-schedule">
        <div class="container-fluid mtb-4" id="main-data">
            <div class="row">
                <div class="col-md-7 col-sm-7 pr-0">
                    <div class="row w-100 mb-4">
                        <div class="col-md-12 pr-1">
                            <div class="box-wrapper shadow-sm p-0 bg-white rounded pos-rel ">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <h4 class="page-title p-3 d-inline-block text-white candidate-title">SCHEDULED INTERVIEWS</h4>
                                        
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-sm-12 pr-0">
                            <div id="fc-schedule-interview" class="box-wrapper shadow-sm p-4  bg-white rounded pos-rel">
                                <fullcalendar :calendarEvents="interviews" @event-selected="eventSelected" @date-click="handleDateClick" />
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div class="col-md-5 col-sm-5 schedule-interview">
                    <div class="box-wrapper shadow-sm p-4 mb-4 bg-white rounded pos-rel">
                        <div class="candidate_info">
                            <h4 class="page-title p-3 d-inline-block text-white candidate-title">CANDIDATE INFO</h4>
                            <a href="javascript:" @click="getPrevUrl()" class="btn btn-primary pull-right" style="margin-top: 5px;margin-right: 10px;">Back</a>
                            <hr>
                            <div class="row">
                                <div class="col-md-12 text-left"><span v-if="checkEmpty(selInterview.candidateName)" class="pr-2"><i class="ti-user"></i></span>{{selInterview.candidateName}} <span v-if="checkEmpty(selInterview.candidateCode)" class="pr-5">- [{{selInterview.candidateCode}}]</span></div>
                            </div>
                            <div class="row">
                                <div v-if="checkEmpty(selInterview.candidateEmail)" class="col-md-6 text-left"><span class="pr-2"><i class="ti-email"></i></span>{{selInterview.candidateEmail}}</div>
                                <div v-if="checkEmpty(selInterview.candidatePhone)" class="col-md-6 text-left"><span class="pr-2"><i class="ti-mobile"></i></span>{{selInterview.candidatePhone}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="box-wrapper shadow-sm p-4 mb-4 bg-white rounded pos-rel">
                        <div v-if="todaysBlock" class="key_skills mb-3">
                            <div class="widget-wrapper">
                                <div class="widget-content ">
                                    <div class="header">
                                        <span class="title">Today's Interview</span>
                                        <div style="clear:both"></div>
                                    </div>
                                    <div v-if="todaysInterview.id!=null" class="inner-content">
                                        <div class="data-cell">
                                            <span class="data-output-label">Title</span>
                                            <span class="data-output-value">{{todaysInterview.title}}</span>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div class="data-cell date-time-cell">
                                            <span class="data-output-label">Date & Time</span>
                                            <div class="row">
                                                <span class="data-output-value">{{todaysInterview.interviewDate | formatDate}}</span>
                                                <span v-if="todaysInterview.startTime!=null && todaysInterview.endTime!=null" class="data-output-value">{{todaysInterview.startTime | formatCustomTime("hh:mm a")}} to {{todaysInterview.endTime | formatCustomTime("hh:mm a")}}</span>
                                            </div>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div class="data-cell">
                                            <span class="data-output-label">Interview Type</span>
                                            <span class="data-output-value">{{todaysInterview.interviewType}}</span>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div class="data-cell">
                                            <span class="data-output-label">Status</span>
                                            <span class="data-output-value">{{todaysInterview.interviewStatus}}</span>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div style="clear:both"></div>
                                    </div>
                                    <div v-if="todaysInterview.id==null" class="inner-content">
                                        <div class="key_skills text-center p-4">No interviews scheduled for today</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="interviewDetailsBlock" class="key_skills mb-3">
                            <div class="widget-wrapper">
                                <div class="widget-content ">
                                    <div class="header">
                                        <span class="title">Interview Details</span>
                                        <div style="clear:both"></div>
                                    </div>
                                    <div class="inner-content">
                                        <div class="data-cell">
                                            <span class="data-output-label">Title</span>
                                            <span class="data-output-value">{{selInterview.title}}</span>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div class="data-cell date-time-cell">
                                            <span class="data-output-label">Date & Time</span>
                                            <div class="row">
                                                <span class="data-output-value">{{selInterview.interviewDate | formatDate}}</span>
                                                <span v-if="selInterview.startTime!=null && selInterview.endTime!=null" class="data-output-value">{{selInterview.startTime | formatCustomTime("hh:mm a")}} to {{selInterview.endTime | formatCustomTime("hh:mm a")}}</span>
                                            </div>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div class="data-cell">
                                            <span class="data-output-label">Interview Type</span>
                                            <span class="data-output-value">{{selInterview.interviewType}}</span>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div class="data-cell">
                                            <span class="data-output-label">Status</span>
                                            <span class="data-output-value">{{selInterview.interviewStatus}}</span>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div style="clear:both"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    data() {
        return {
            interviews: [],
            selInterview: {
                id: null,
                title: "NA",
                interviewType: "NA",
                interviewStatus: "NA",
                interviewDate: null,
                startTime: null,
                endTime: null,
                candidateId: "NA",
                candidateName: "",
                candidateCode: "",
                candidateEmail: "",
                candidatePhone: ""
            },
            todaysInterview: {
                id: null,
                title: "NA",
                interviewType: "NA",
                interviewStatus: "NA",
                interviewDate: null,
                startTime: null,
                endTime: null,
                candidateId: "NA"
            },
            interviewer: {
                name: this.$store.getters.currentUser.name,
                email: this.$store.getters.currentUser.email
            },
            todaysBlock: true,
            interviewDetailsBlock: false
        }
    },
    mounted() {
        this.loadInterviewerInterviews();
    },
    methods: {
        eventSelected(event) {
            this.selInterview.id = event.id;
            this.selInterview.title = event.title;
            this.selInterview.interviewType = event.extendedProps.interviewType;
            this.selInterview.interviewStatus = event.extendedProps.interviewStatus;
            this.selInterview.interviewDate = event.extendedProps.interviewStartTime;
            this.selInterview.startTime = event.start;
            this.selInterview.endTime = event.end;
            this.selInterview.candidateId = event.extendedProps.candidateId;
            this.todaysBlock = false;
            this.interviewDetailsBlock = true;
            this.selInterview.candidateName = event.extendedProps.canName;
            this.selInterview.candidateCode = event.extendedProps.candidateCode;
            this.selInterview.candidateEmail = event.extendedProps.email;
            this.selInterview.candidatePhone = event.extendedProps.mobile;
            // this.$router.push({name: 'interviewfeedback', params: { interviewId:event.id, candidateId:event.extendedProps.candidateId }})
        },
        loadInterviewerInterviews() {
            new Promise((resolve, reject) => {
                axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/list/candidate/interviews/' + this.$store.getters.currentUser.profileId, {}).then(response => {
                        this.interviews = response.data.interviews;
                        let self = this;
                        $.each(this.interviews, function(key, value) {
                            //value.start = moment(value.interviewStartTime).format("YYYY-MM-DD HH:mm:ss");
                            //value.end = moment(value.interviewEndTime).format("YYYY-MM-DD HH:mm:ss");
                            //value.start = value.interviewDate + " " + value.interviewStartTime;
                            //value.end = value.interviewDate + " " + value.interviewEndTime;
                            value.start = self.toClientTimeZoneDate(value.interviewStartTime, self.datetimezoneformat);
                            value.end = self.toClientTimeZoneDate(value.interviewEndTime, self.datetimezoneformat);
                        });
                        resolve(true);
                        this.getTodaysInterview(moment().format("YYYY-MM-DD"));
                        //console.log(this.todaysInterview);
                    })
                    .catch(e => {
                        reject(true);
                    });
            });
        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        refreshSelectedInterview() {
            this.selInterview.id = null;
            this.selInterview.title = "NA";
            this.selInterview.interviewType = "NA";
            this.selInterview.interviewStatus = "NA";
            this.selInterview.interviewDate = null;
            this.selInterview.startTime = null;
            this.selInterview.endTime = null;
            this.selInterview.candidateId = "NA";
        },
        handleDateClick(info) {
            this.todaysBlock = true;
            this.interviewDetailsBlock = false;
            this.refreshSelectedInterview();
            this.getTodaysInterview(moment(info.date).format("YYYY-MM-DD"));
        },

        getTodaysInterview(selDate) {
            let self = this
            $.each(this.interviews, function(key, interview) { //console.log(interview);
                // interview.interviewDate=moment().format("YYYY-MM-DD")
                if (moment(interview.interviewDate).format("YYYY-MM-DD") == selDate) {
                    self.todaysInterview.id = interview.id;
                    self.todaysInterview.title = interview.title;
                    self.todaysInterview.interviewType = interview.interviewType;
                    self.todaysInterview.interviewStatus = interview.interviewStatus;
                    self.todaysInterview.interviewDate = interview.interviewDate;
                    self.todaysInterview.startTime = interview.start;
                    self.todaysInterview.endTime = interview.end;
                    self.todaysInterview.candidateId = interview.candidateId;
                    self.selInterview.candidateName = interview.canName;
                    self.selInterview.candidateCode = interview.candidateCode;
                    self.selInterview.candidateEmail = interview.email;
                    self.selInterview.candidatePhone = interview.mobile;
                }
            });
        },
        refreshTodaysInterview() {
            this.todaysInterview.id = null;
            this.todaysInterview.title = "NA";
            this.todaysInterview.interviewType = "NA";
            this.todaysInterview.interviewStatus = "NA";
            this.todaysInterview.interviewDate = null;
            this.todaysInterview.startTime = null;
            this.todaysInterview.endTime = null;
            this.todaysInterview.candidateId = "NA";
        },
        checkEmpty(obj) {
            if (obj != null && isNaN(obj) && obj.trim().length > 0) {
                return true;
            } else if (obj != null && obj > 0) {
                return true;
            }
            return false;
        },
    }
}

</script>
