<template>
    <div id="main" class="mt-70 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a @click="searchMore=!searchMore" href="javascript:;" data-target="main-search" class="boxhide"><span :class="searchMore?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" v-if="searchMore">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Client Code</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-on:keyup.enter="findClients" v-model="search.params.clientCode" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Client Name</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" 
                                            v-on:keyup.enter="findClients" 
                                            v-model="search.params.clientName" 
                                            @input="search.params.clientName = search.params.clientName.replace(/[^A-Za-z\s]/g, '')" 
                                            class="form-control">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>DUNS Number</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" 
                                            v-on:keyup.enter="findClients" 
                                            class="form-control" 
                                            v-model="search.params.dunsNumber" 
                                            @input="search.params.dunsNumber = search.params.dunsNumber.replace(/\D/g, '').slice(0, 9)" 
                                            maxlength="9">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Account Name(Email Id)</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-on:keyup.enter="findClients" class="form-control" v-model="search.params.accountName">
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Website</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <input type="text" v-on:keyup.enter="findClients" class="form-control" v-model="search.params.url">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.status" class="form-control">
                                            <option value="" selected="selected">Select</option>
                                            <option value="true">Active</option>
                                            <option value="false">InActive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr pl-0">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Clients</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <button type="button" @click="findClients" class="btn btn-primary btn-sm float-left" style="margin-left: 10px;">Find</button>
                                        <button type="button" @click="resetClientsSearch" class="btn btn-primary btn-sm float-left" style="margin-left: 10px;">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Clients
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{pagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-6 d-none d-sm-none d-md-block">
                            <button type="button" @click="addLogActivity()" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                <router-link :to="{ name: 'addclient' }" style="color: #fff;">New Client</router-link>
                            </button>
                        </div>
                        <div v-if="false" class="col-md-6 d-none d-sm-none d-md-block">
                            <ul class="ul-horizontal table-fields text-right">
                                <li>
                                    <span>Show / Hide</span>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="clntName" id="clntName" checked="">
                                    <label for="clntName" v-on:click="coldisplay.dunsNumber = !coldisplay.dunsNumber"><span></span> DUNS Number</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="accountManager" id="accountManager" checked="">
                                    <label for="accountManager" v-on:click="coldisplay.accountName = !coldisplay.accountName"><span></span> Email Id</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="physicalAddCity" id="physicalAddCity" checked="">
                                    <label for="physicalAddCity" v-on:click="coldisplay.url = !coldisplay.url"><span></span>Website</label>
                                </li>
                                <li>
                                    <input type="checkbox" class="field-chekbox" name="physicalAddCity" id="physicalAddCity" checked="">
                                    <label for="physicalAddCity" v-on:click="coldisplay.status = !coldisplay.status"><span></span>Status</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th v-if="false" class="" scope="col"></th>
                                            <th class="">Client Code</th>
                                            <th class="clntName" scope="col">Client Name</th>
                                            <th v-if="coldisplay.dunsNumber" class="accountManager" scope="col">DUNS Number</th>
                                            <th v-if="coldisplay.accountName" class="phoneNumber" scope="col">Account Name(email Id)</th>
                                            <th class="phoneNumber" scope="col">Company Phone</th>
                                            <th v-if="coldisplay.url" class="physicalAddCity" scope="col">Website</th>
                                            <th  class="contactPerson" scope="col">Contact Person</th>
                                            <th class="phone" scope="col">Phone Number</th>
                                            <th v-if="coldisplay.status" class="phone" scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="table-length">
                                        <tr v-if=" elements.loadClients==true && clientslist.length==0">
                                            <td colspan="10" align="center">No Clients found</td>
                                        </tr>
                                        <tr v-for="(clientinfo, index) in clientslist">
                                            <td v-if="false">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" id="rowchckbox1" value="option1">
                                                    <label class="form-check-label" for="rowchckbox1"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <router-link class="" :to="{ name: 'viewclient', params: { clientid: clientinfo.id }}">{{ clientinfo.clientCode }}</router-link>
                                            </td>
                                            <td class="clntName">
                                                <router-link class="" :to="{ name: 'viewclient', params: { clientid: clientinfo.id }}">{{ clientinfo.clientName }}</router-link>
                                            </td>
                                            <td v-if="coldisplay.dunsNumber" class="accountManager">{{ clientinfo.dunsNumber }}</td>
                                            <td v-if="coldisplay.accountName" class="accountManager">{{ clientinfo.accountName }}</td>
                                            <td  class="companyPhone">{{ clientinfo.phoneNumber }}</td>
                                            <td v-if="coldisplay.url" class="physicalAddCity">{{ clientinfo.url }}</td>
                                            <td  class="contactPerson">{{ clientinfo.clientContactName }}</td>
                                            <td  class="phoneNumber">{{ clientinfo.contactPhone }}</td>
                                            <td v-if="coldisplay.status" class="physicalAddCity">
                                                <span v-if="clientinfo.status == false">Inactive</span>
                                                <span v-if="clientinfo.status == true">Active</span>
                                            </td>
                                            <td>
                                                <ul class="navbar-nav">
                                                    <li class="nav-item">
                                                        <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name: 'viewclient', params: { clientid: clientinfo.id }}"><span class="ti-eye"></span> View</router-link>
                                                            </li>
                                                            <li>
                                                                <router-link class="dropdown-item" :to="{ name: 'editclient', params: { clientid: clientinfo.id }}"><span class="ti-pencil"></span> Edit</router-link>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item" href="javascript:;" @click="deleteClient(clientinfo.id)" data-toggle="modal" data-target="#removeClient"><span class="ti-close"></span> Deactivate</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <b-pagination v-if="clientslist.length!=0" size="md" class='pull-right' :total-rows="pagination.totalRecords" v-model="pagination.currentPage" :per-page="pagination.rowsPerPage" @input="loadClients()"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
        <Dialog id="removeClient" :onYes="delete_Client" :returnParams="delClientParam" title="Deactivate Confirmation" message="Are you sure to deactivate ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    data() {
        return {
            showOverlay:false,
            clientslist: [],
            pagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            elements: {
                showadvancedsearch: false,
                loadingClients: false,
            },
            search: {
                params: {
                    clientCode: '',
                    clientName: '',
                    dunsNumber: '',
                    accountName: '',
                    url: '',
                    status: ''
                },
            },
            coldisplay: {
                dunsNumber: true,
                accountName: true,
                url: true,
                status: true
            },
            delClientParam: {
                index: -1,
                id: 0
            },
            searchMore: false
        }
    },
    watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loadClients();
        this.updateLogActivity();
    },
    methods: {
        findClients() {
        console.log("Searching for clients:", this.search.params.accountName);
        // Add your search logic here
    },
        
        resetClientsSearch() {
            this.search.params = {
                clientCode: "",
                firstName: "",
                lastName: "",
                email: "",
                cityName: "",
                technologyId: "",
                status: ""
            };
            this.loadClients();
        },
        async loadClients() {
            this.showOverlay=true;
            await this.getClients();
            this.showOverlay=false;
        },
        getClients() {
            this.showOverlay=true;
            return axios.post(this.$store.getters.getAPIBasePath + '/clientresource/clientlist?page=' + this.pagination.currentPage + '&size=' + this.pagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.clientslist = result.clients;
                    this.pagination.totalRecords = result.pagination[0].total;
                    this.pagination.currentPage = result.pagination[0].currentPage;
                    this.elements.loadClients = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
        changeLimit(newlimit) {
            this.pagination.currentPage = 1;
            this.pagination.rowsPerPage = newlimit;
            this.loadClients();
        },
        deleteClient(clientId) {
            this.delClientParam.id = clientId;
            return;

            if (confirm("Do you really want to deactivate the client?")) {
                var params = new URLSearchParams();
                params.append('id', clientId)
                axios.post(this.$store.getters.getAPIBasePath + '/clientresource/delete/client', params)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        }
                        this.loadClients();
                    })
                    .catch(error => {
                        console.log(error.data);
                    });
            }
        },
        delete_Client() {
            var params = new URLSearchParams();
            params.append('id', this.delClientParam.id)
            axios.post(this.$store.getters.getAPIBasePath + '/clientresource/delete/client', params)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.$store.dispatch("reloadClient").then(() => {
                        console.log('Refresh Client');
                    });  
                    this.loadClients();
                })
                .catch(error => {
                    console.log(error.data);
                });
        },
        findClients() {
            this.pagination.currentPage = 1;
            this.loadClients();
        },
        addLogActivity: function(action) {
            this.updateLogActivity();
            this.logActivityData = {
                entityName: 'Client',
                actionName: action,
            };
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/add/logactivity', this.logActivityData)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
