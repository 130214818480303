<template>
    <div id="main" class="mt-15 d-height">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
            <div class="container-fluid" id="quick-search">
                <div class="box-wrapper shadow-sm p-2 mb-4 bg-white rounded">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                            <h4 class="page-title">Search</h4>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                            <ul class="ul-horizontal box-tools text-right mb-0">
                                <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <form id="main-search" :class="isExpand?'d-block':'d-none'">
                        <div class="row form-group ten-columns">
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Job Title</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input type="text" v-on:keyup.enter="findJobs"  name="jobTitle" maxlength="50" value="" id="jobTitle" class="form-control" v-model="search.params.jobTitle">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Job Code</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div class="input-group">
                                            <input 
                                                type="text"
                                                v-model.trim="search.params.jobCode"
                                                v-on:keyup.enter="findJobs"
                                                name="jobId"
                                                maxlength="15"
                                                id="jobId"
                                                class="smallCombo form-control"
                                                placeholder="Enter Job Code"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Client</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.clientId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in clientlist" :value="data.id">{{data.client_Name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Industry</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.industryId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in industrylist" :value="data.id">{{data.industryName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>                  
                            <div class="col-md-1 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.status" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(data, index) in jobStatuslist" :value="data.id">{{data.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label>Hot Jobs</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <select v-model="search.params.priority" class="form-control">
                                                <option value="" >Select</option>
                                                <option value=true>Yes</option>
                                                <option value=false>No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div  class="col-md-2 col-sm-6 col-12 mb-10-mr">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12 lb">
                                        <label style="visibility: hidden;">Recruiter</label>
                                    </div>
                                    <div class="col-md-12">
                                        <button type="button" @click="findJobs" class="btn btn-primary btn-sm" style="margin-left: 10px;">Find</button>
                                        <button type="button" @click="resetJobSearch" class="btn btn-primary btn-sm" style="margin-left: 10px;">Reset</button>
                                    </div>     
                                </div>
                            </div>
                        </div>                  
                    </form>
                    <div class="load-indicator"></div>
                </div>
            </div>
            <div class="container-fluid" id="main-data">
                <div class="box-wrapper shadow-sm p-4 mb-5 bg-white rounded pos-rel">
                    <div class="row" style="margin-bottom: 15px;">
                        <div class="col-md-6 col-sm-12 col-12">
                            <h4 class="page-title">Jobs
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">({{browsepagination.totalRecords}} - Total)</span>
                                <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:12px; margin-right: 20px;">
                                    <div class="dropdown">
                                        <a data-toggle="dropdown" style="color:#000; text-transform: capitalize;" href="" aria-expanded="false">Rows/Pages <span class="ti-angle-down"></span></a>
                                        <div id="rowsPerPage" class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 15px, 0px);">
                                            <a v-for="(value, index) in searchlimit" class="dropdown-item" href="javascript:void(0);" @click="changeLimit(value)">{{value}}</a>
                                        </div>
                                    </div>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-6 d-none d-sm-none d-md-block">
                            <button type="button" @click="ApplyJobs()" class="btn btn-primary btn-sm float-right" style="margin-left: 10px;">
                                Apply
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive" style="min-height: 178px;">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col"></th>
                                            <th v-if="coldisplay.jobTitle" class="" scope="col">Job Title</th>
                                            <th v-if="coldisplay.jobCode" class="" scope="col">Code</th>
                                            <th  class="" scope="col">Client</th>
                                            <th v-if="coldisplay.industry" class="" scope="col">Industry</th>
                                            <th v-if="coldisplay.position" class="" scope="col">Position</th>
                            
                                            <th class="" scope="col">Experience</th>
                                            <th class="" scope="col">Location</th>
                                            <th class="" scope="col">Vacancies</th>
                                            <th class="" scope="col">Due Date</th>
                                            <th class="" scope="col">Status</th>
                                            <th class="" scope="col">Recruiter</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="joblist.length==0 && elements.loadingJobs == true">
                                            <td colspan="26" class="text-center">No data found</td>
                                        </tr>
                                        <tr :style="[jobinfo.priority === true ? {backgroundColor: 'lightblue'}: {backgroundColor: 'white'}]" v-for="(jobinfo, index) in joblist">
                                            <td>
                                                <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" :id="'rowchckbox'+jobinfo.id" v-model="applyJobId" :value="jobinfo.id" checked="" @click="pushJobData(jobinfo);">
                                                <label class="form-check-label" :for="'rowchckbox'+jobinfo.id"></label>
                                            </div>
                                            </td>
                                            <td v-if="coldisplay.jobTitle" class="">
                                                <router-link title="View Job" :to="{ name: 'candidateviewjob', params: { jobid: jobinfo.id, jobinfo: jobinfo},}">{{jobinfo.jobTitle }}</router-link>
                                            </td>
                                            <td v-if="coldisplay.jobCode" class="">{{ jobinfo.jobCode }}</td>  
                                            <td v-if="coldisplay.industry" class="">{{ jobinfo.clientName }}</td>                        
                                            <td v-if="coldisplay.industry" class="">{{ jobinfo.industryName }}</td>
                                            <td v-if="coldisplay.position" class="">{{ jobinfo.position }}</td>                                     
                                            <td class="">{{ jobinfo.experience }}</td>
                                            <td class="">{{ jobinfo.location }}</td>
                                            <td class="">{{ jobinfo.vacancies }}</td>
                                            <td class="endDate" format="MM-dd-yyyy">{{ jobinfo.dueDate | formatDate }}</td>
                                            <td class="">{{ jobinfo.jobStatus}}</td>
                                            <td class="">{{ jobinfo.recruiterName}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <b-pagination v-if="joblist.length!=0" size="md" class="pull-right" :total-rows="browsepagination.totalRecords" v-model="browsepagination.currentPage" :per-page="browsepagination.rowsPerPage" @input="loadJobs()"></b-pagination>                        
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div>
                    <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
                </div>                   
            </template> 
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    computed: {
   
    },
    data() {
        return {
            showOverlay:false,
            isExpand: false,
            joblist: [],
            technologies: this.$store.getters.getTechOptions,
            searchlimit: this.$store.state.searchlimit,
            industrylist: this.$store.getters.getIndustries,
            clientlist: this.$store.getters.getClients,
            jobStatuslist: this.$store.getters.getJobStatus,
            recruiterlist: this.$store.getters.getRecruiterList,
            candidateId: this.$store.getters.currentUser.profileId,
            browsepagination: {
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1
            },
            recCandInfo: {
                talentCount: 0,
                candShortlistedCount: 0,
                clientReviewedCount: 0,
                hiredCount: 0,
                talentIdentified: [],
                htmlMessage: "",
            },
            elements: {
                showadvancedsearch: false,
                loadingJobs: false,
            },
            applyJobId:[],
            applyJobData: [],
            search: {
                limit: this.$store.getters.getActiveSearchLimit,
                params: {
                    jobTitle: "",
                    jobType: "",
                    technologyId: "",
                    startDate: "",
                    endDate: "",                    
                    clientId: "",
                    industryId: "",
                    status: "",
                    recId: "",
                    priority: "",
                    candidateId: this.$store.getters.currentUser.profileId,
                },
            },
            coldisplay: {
                jobTitle: true,
                jobCode: true,
                client: true,
                industry: true,
                //position: true
            },
            delJobParam: {
                index: -1,
                id: 0
            }
        }
    },
    watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loadJobs();
    },
    methods: {
        resetJobSearch() {
            this.search.params = {
                jobTitle: "",
                jobType: "",
                technologyId: "",
                startDate: "",
                endDate: "",
                clientId: "",
                industryId: "",
                recId: "",
                status: "",
                priority: "",
                candidateId: this.$store.getters.currentUser.profileId,
            };
            this.loadJobs();
        },
        pushJobData(jobinfo){
            if(!this.applyJobId.includes(jobinfo.id)){
                if(jobinfo.recruiterEmail == jobinfo.recruiterManagerEmail) {
                    this.applyJobData.push({ "jobId": jobinfo.id, "jobName": jobinfo.jobName,"recruiterMail": jobinfo.recruiterEmail,"recruiterManagerMail":null});
                }else if (jobinfo.recruiterManagerEmail == null) {
                    this.applyJobData.push({ "jobId": jobinfo.id, "jobName": jobinfo.jobName,"recruiterMail": jobinfo.recruiterEmail,"recruiterManagerMail":null});
                }else {
                    this.applyJobData.push({ "jobId": jobinfo.id, "jobName": jobinfo.jobName,"recruiterMail": jobinfo.recruiterEmail,"recruiterManagerMail":jobinfo.recruiterManagerEmail});
                }
            }
            else{
                let i = this.applyJobData.map(applyJobDataa => applyJobDataa.jobId).indexOf(jobinfo.id)
                this.applyJobData.splice(i,1);  
            }     

        },
        async loadJobs() {
            this.showOverlay=true;
            await this.getJobList();
            this.showOverlay=false;
        }, 
        getJobList() {
            this.showOverlay=true;
            return axios.post(this.$store.getters.getAPIBasePath + '/jobresource/candidate/public/joblist?page=' + this.browsepagination.currentPage + '&size=' + this.browsepagination.rowsPerPage, this.search.params)
                .then(response => {
                    let result = response.data;
                    this.joblist = result.jobs;
                    console.log(this.joblist)
                    this.browsepagination.totalRecords = result.pagination[0].total;
                    this.browsepagination.currentPage = result.pagination[0].currentPage;
                    this.browsepagination.totalpages = result.pagination[0].lastPage;
                    this.elements.loadingJobs = true;
                })
                .catch(error => {
                    this.showOverlay=false;
                    console.log(error);
                });
        },
        findJobs() {
            this.browsepagination.currentPage = 1;
            this.loadJobs();
        },
        changeLimit(newlimit) {
            this.browsepagination.currentPage = 1;
            this.browsepagination.rowsPerPage = newlimit;
            this.loadJobs();
        },
        ApplyJobs(){
                let appliedData = this.applyJobData
                if(appliedData != "" && appliedData.length > 0){
                axios.post(this.$store.getters.getAPIBasePath + '/jobresource/candidate/job/applied', { appliedData,"candidateId":this.candidateId , candidateName: this.$store.getters.currentUser.name})
                .then(response => {
                    var result = response.data;
                    if (result.status == "ACCEPTED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position:'topRight'
                        }); 
                         this.applyJobId = []; 
                         this.applyJobData = [];
                         this.loadJobs();
                         this.$root.$emit('appliedjobs')  
                }})
                .catch(error => {
                    console.log(error);
                });
                }
                else{
                    iziToast.info({
                            title: 'Info',
                            message: this.$config.PlsSelJob,
                            position:'topRight'
                        });
                }
        },
       
    }
}

</script>
<style scoped>
.all-table tbody td {
    background-color: transparent;

}

</style>
