<template>
    <div id="content_Personal_Info">
        <div class="card">
            <div class="card-header p-2">Contact Information</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Mobile</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <PhoneNumber
                                        ref="phoneNumber"
                                        :value="ContactInfo.mobileNo"
                                        :mandatory="phoneMandatory"
                                        @phone-input="phoneControlInput"
                                        :isValidcheck="isValidCheck"
                                    />
                                    <!-- <div
                                        v-if="false"
                                        class="input-group required-field-block"
                                    >
                                        <select
                                            class="form-control col-sm-3"
                                            v-model="ContactInfo.countryCode"
                                        >
                                            <option value="+1">+1</option>
                                            <option value="+91">+91</option>
                                        </select>
                                        <input-mask
                                            class="form-control"
                                            v-model="ContactInfo.mobile"
                                            mask="(999)-999-9999"
                                            :alwaysShowMask="false"
                                            maskChar="_"
                                        ></input-mask>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Alternate Mobile</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <PhoneNumber
                                        ref="altPhoneNumber"
                                        :value="ContactInfo.altMobileNo"
                                        @phone-input="altPhoneControlInput"
                                        :isValidcheck="isaltValidCheck"
                                    />
                                    <!-- <div
                                        v-if="false"
                                        class="input-group required-field-block"
                                    >
                                        <select
                                            class="form-control col-sm-3"
                                            v-model="ContactInfo.altcountryCode"
                                        >
                                            <option value="+1">+1</option>
                                            <option value="+91">+91</option>
                                        </select>
                                        <input-mask
                                            class="form-control"
                                            v-model="ContactInfo.altMobile"
                                            mask="(999)-999-9999"
                                            :alwaysShowMask="false"
                                            maskChar="_"
                                        ></input-mask>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address Line #1</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="25"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.addressLine1"
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col4 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address Line #2</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="25"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.addressLine2"
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
    <div class="col-md-12 col-sm-12 col-12 lb">
        <label>Country</label>
    </div>
    <div class="col-md-12 col-sm-12 col-12">
        <div class="input-group required-field-block">
            <select class="form-control" v-model="ContactInfo.countryId" @change="updateStates">
                <option value="" selected="selected">Select</option>
                <option v-for="Country in Countries" :key="Country.country_Id" :value="Country.country_Id">
                    {{ Country.name }}
                </option>
            </select>
        </div>
    </div>
</div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
    <div class="col-md-12 col-sm-12 col-12 lb">
        <label>State</label>
    </div>
    <div class="col-md-12 col-sm-12 col-12">
        <div class="input-group required-field-block">
            <!-- Show dropdown for predefined states -->
            <select class="form-control" v-if="filteredStates.length" v-model="ContactInfo.stateName">
                <option value="">Select State</option>
                <option v-for="State in filteredStates" :key="State.id" :value="State.stateName">
                    {{ State.stateName }}
                </option>
            </select>

            <!-- Show input field for countries without predefined states -->
            <input v-else type="text" maxlength="25" size="30" class="form-control" v-model="ContactInfo.stateName" @input="validateLettersOnly">
        </div>
    </div>
</div>
                         </div>
                          <div class="col col3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>City</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                    <input
                                        type="text"
                                        maxlength="25"
                                        size="30"
                                        class="form-control"
                                        v-model="ContactInfo.cityName"
                                        @input="validateCity"
                                    />

                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>

<div class="col col4 mb-10-mr">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-12 lb">
            <label>Zip Code</label>
        </div>
        <div class="col-md-12 col-sm-12 col-12">
            <div class="input-group required-field-block">
                <input
                    type="text"
                    maxlength="25"
                    size="30"
                    class="form-control"
                    v-model="ContactInfo.pincode"
                    @input="validateNumbersOnly($event)"
                />
                <!-- <span class="required-icon">
                </span> -->
            </div>
        </div>
    </div>
</div>



                    </div>
                </div>
            </div>
            <div class="card-header p-2">Social Connect</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Skype</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.skypeId"
                                            v-bind:style="[
                                                isValid(ContactInfo.skypeId)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.skypeId.$error && !$v.ContactInfo.skypeId.url" class="text-danger">Skype is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Facebook</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.facebookUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.facebookUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.facebookUrl.$error && !$v.ContactInfo.facebookUrl.url" class="text-danger">Facebook is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Twitter</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.twitterUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.twitterUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.twitterUrl.$error && !$v.ContactInfo.twitterUrl.url" class="text-danger">Twitter is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col4 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Linkedin</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.linkedinUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.linkedinUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.linkedinUrl.$error && !$v.ContactInfo.linkedinUrl.url" class="text-danger">Linkedin is not valid</label> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Hangout</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.hangoutId"
                                            v-bind:style="[
                                                isValid(ContactInfo.hangoutId)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.hangoutId.$error && !$v.ContactInfo.hangoutId.url" class="text-danger">Hangout is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Website</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.websiteUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.websiteUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.websiteUrl.$error && !$v.ContactInfo.websiteUrl.url" class="text-danger">Website is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr"></div>
                        <div class="col col4 mb-10-mr"></div>
                    </div>
                </div>
            </div>
            <div class="card-header p-2">
                <a
                    href="Javascript:void(0);"
                    @click="saveContactInfo"
                    class="btn btn-primary float-right"
                    >Save</a
                >
            </div>
        </div>
    </div>
</template>
<script>
import { url, helpers } from "vuelidate/lib/validators";
import iziToast from "izitoast";

const usPhone = helpers.regex("usPhone", /^\(\d{3}\)\s\d{3}-\d{4}$/);
var expression =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
var regex = new RegExp(expression);
export default {
    props: {
        ContactInfo: {},
        profileId: Number,
    },
    data() {
        return {
            ContactInfo: {
            countryId: "",
            stateName: ""
        },
        Countries: [
            { country_Id: "US", name: "United States" },
            { country_Id: "IN", name: "India" },
            { country_Id: "CA", name: "Canada" }
        ],
        StatesByCountry: {
            US: [
                { id: 1, stateName: "Alabama" },
                { id: 2, stateName: "Alaska" },
                { id: 3, stateName: "Arizona" },
                { id: 4, stateName: "Arkansas" },
                { id: 5, stateName: "California" },
                { id: 6, stateName: "Colorado" },
                { id: 7, stateName: "Connecticut" },
                { id: 8, stateName: "Delaware" },
                { id: 9, stateName: "Florida" },
                { id: 10, stateName: "Georgia" },
                { id: 11, stateName: "Hawaii" },
                { id: 12, stateName: "Idaho" },
                { id: 13, stateName: "Illinois" },
                { id: 14, stateName: "Indiana" },
                { id: 15, stateName: "Iowa" },
                { id: 16, stateName: "Kansas" },
                { id: 17, stateName: "Kentucky" },
                { id: 18, stateName: "Louisiana" },
                { id: 19, stateName: "Maine" },
                { id: 20, stateName: "Maryland" },
                { id: 21, stateName: "Massachusetts" },
                { id: 22, stateName: "Michigan" },
                { id: 23, stateName: "Minnesota" },
                { id: 24, stateName: "Mississippi" },
                { id: 25, stateName: "Missouri" },
                { id: 26, stateName: "Montana" },
                { id: 27, stateName: "Nebraska" },
                { id: 28, stateName: "Nevada" },
                { id: 29, stateName: "New Hampshire" },
                { id: 30, stateName: "New Jersey" },
                { id: 31, stateName: "New Mexico" },
                { id: 32, stateName: "New York" },
                { id: 33, stateName: "North Carolina" },
                { id: 34, stateName: "North Dakota" },
                { id: 35, stateName: "Ohio" },
                { id: 36, stateName: "Oklahoma" },
                { id: 37, stateName: "Oregon" },
                { id: 38, stateName: "Pennsylvania" },
                { id: 39, stateName: "Rhode Island" },
                { id: 40, stateName: "South Carolina" },
                { id: 41, stateName: "South Dakota" },
                { id: 42, stateName: "Tennessee" },
                { id: 43, stateName: "Texas" },
                { id: 44, stateName: "Utah" },
                { id: 45, stateName: "Vermont" },
                { id: 46, stateName: "Virginia" },
                { id: 47, stateName: "Washington" },
                { id: 48, stateName: "West Virginia" },
                { id: 49, stateName: "Wisconsin" },
                { id: 50, stateName: "Wyoming" }
            ],
            CA: [
                { id: 51, stateName: "Alberta" },
                { id: 52, stateName: "British Columbia" },
                { id: 53, stateName: "Manitoba" },
                { id: 54, stateName: "New Brunswick" },
                { id: 55, stateName: "Newfoundland and Labrador" },
                { id: 56, stateName: "Nova Scotia" },
                { id: 57, stateName: "Ontario" },
                { id: 58, stateName: "Prince Edward Island" },
                { id: 59, stateName: "Quebec" },
                { id: 60, stateName: "Saskatchewan" },
                { id: 61, stateName: "Northwest Territories" },
                { id: 62, stateName: "Nunavut" },
                { id: 63, stateName: "Yukon" }
            ],
            IN: [
                { id: 64, stateName: "Andhra Pradesh" },
                { id: 65, stateName: "Arunachal Pradesh" },
                { id: 66, stateName: "Assam" },
                { id: 67, stateName: "Bihar" },
                { id: 68, stateName: "Chhattisgarh" },
                { id: 69, stateName: "Goa" },
                { id: 70, stateName: "Gujarat" },
                { id: 71, stateName: "Haryana" },
                { id: 72, stateName: "Himachal Pradesh" },
                { id: 73, stateName: "Jharkhand" },
                { id: 74, stateName: "Karnataka" },
                { id: 75, stateName: "Kerala" },
                { id: 76, stateName: "Madhya Pradesh" },
                { id: 77, stateName: "Maharashtra" },
                { id: 78, stateName: "Punjab" },
                { id: 79, stateName: "Rajasthan" },
                { id: 80, stateName: "Tamil Nadu" },
                { id: 81, stateName: "Telangana" },
                { id: 82, stateName: "Uttar Pradesh" },
                { id: 83, stateName: "West Bengal" }
            ]
        },
        filteredStates: [],
            Countries: this.$store.getters.getCountries,
            USStates: this.$store.getters.getUSStates,
            phoneMandatory: true,
            isValidCheck:false,
            isaltValidCheck:false,
        };
    },
    mounted() {},
    methods: {
        updateStates() {
        this.filteredStates = this.StatesByCountry[this.ContactInfo.countryId] || [];
    },
    validateLettersOnly(event) {
        this.ContactInfo.stateName = event.target.value.replace(/[^a-zA-Z\s]/g, '');
    },
        validateCity(event) {
        this.ContactInfo.cityName = event.target.value.replace(/[^a-zA-Z\s]/g, ''); 
    },
        validateNumbersOnly(event) {
        const value = event.target.value;
        // Remove any characters that are not digits
        event.target.value = value.replace(/[^0-9]/g, '');
        // Update the model with the sanitized value
        this.ContactInfo.pincode = event.target.value;
    },
        validateLettersOnly(event) {
        const value = event.target.value;
        // Remove any characters that are not letters or spaces
        event.target.value = value.replace(/[^a-zA-Z\s]/g, '');
        // Update the model with the sanitized value
        this.ContactInfo.stateName = event.target.value;
    },
        redirectlink(value) {
            if (value) {
                window.open("https://" + value, "_blank");
            }
        },
        isValid(data) {
            if (data != null && data.match(regex)) {
                return true;
            } else {
                return false;
            }
        },
        saveContactInfo: function () {
            /*   if (this.ContactInfo.mobile != null && this.ContactInfo.mobile != '') {
                this.ContactInfo.mobileNo = this.ContactInfo.countryCode + " " + this.ContactInfo.mobile;                
            }

            if (this.ContactInfo.altMobile != null && this.ContactInfo.altMobile != '') {
                this.ContactInfo.altMobileNo = this.ContactInfo.altcountryCode + " " + this.ContactInfo.altMobile;                
            } */
            if (!this.isValidCheck && !this.isaltValidCheck && this.$refs.phoneNumber.validatePhone()) {
                let contactInfo = { referralContact: this.ContactInfo };

                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                            "/referralresource/update/referral/" +
                            this.profileId,
                        contactInfo
                    )
                    .then((response) => {
                        iziToast.success({
                            title: "Success",
                            message: this.$config.IntConInfoUpSuc,
                            position: "topRight",
                        });
                        this.$emit("loadReferral");
                    })
                    .catch((error) => {
                        console.log(error);
                        iziToast.error({
                            title: "Error Occured",
                            message: this.$config.IntConInfoUpErr,
                            position: "topRight",
                        });
                    });
            }
        },
        phoneControlInput: function (value,isValid) {
            this.isValidCheck = isValid;
            this.ContactInfo.mobileNo = value;
        },
        altPhoneControlInput: function (value,isValid) {
            this.isaltValidCheck = isValid;
            this.ContactInfo.altMobileNo = value;
        },
    },
};
</script>
