<template>
    <div class="add-task-group">
        <b-modal scrollable ref="createSprintModal" :no-close-on-backdrop="true" size="lg" id="createSprintModal" title="Create Task Group" @show="getTaskGroupCode" @hidden="refreshSprintData" @ok="addSprint" ok-title="Save Task Group" @cancel="updateLogActivity()">
            <form>
                <div class="row col-md-12 mx-0 justify-content-end">
                    <multiselect v-if="elements.showMembersDropdown" v-model="taskGroup.taskGroupMembers" :options="options.recruitersList" name="project" label="recruiterName" track-by="id" select-label="" multiple deselect-label="" class="" :max-height="300" placeholder="Select Members" :searchable="true" :close-on-select="false" @close="elements.showMembersDropdown=false"></multiselect>
                    <span v-if="!elements.showMembersDropdown" v-for="TaskMembers in taskGroup.taskGroupMembers" :key="TaskMembers.recruiterName">
                        <span :data-letters="TaskMembers.recruiterName | getAcronym" :title="TaskMembers.recruiterName" class="mx-n2 align-self-center"></span>
                    </span>
                    <i v-if="!elements.showMembersDropdown" class="fa fa-plus circle-icon hover-hand ml-n2 pull-right" @click="elements.showMembersDropdown=!elements.showMembersDropdown" title="Add Members" />
                </div>
                <div class="row col-md-12 mx-0 px-0">
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="projectName" class="col-form-label">Task Group Name</label>
                        <input type="text" name="projectName" id="projectName" v-model="taskGroup.title" class="form-control" />
                        <label v-if="$v.taskGroup.title.$error && !$v.taskGroup.title.required" class="text-danger">Task Group Name is required</label>
                        <label v-if="$v.taskGroup.title.$error && !$v.taskGroup.title.minLength" class="text-danger">Task Group Name must have at least {{$v.taskGroup.title.$params.minLength.min}} letters.</label>
                        <label v-if="$v.taskGroup.title.$error && !$v.taskGroup.title.maxLength" class="text-danger">Task Group Name must not exceed {{$v.taskGroup.title.$params.maxLength.max}} letters.</label>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="taskGroupCode" class="col-form-label">Task Group Code</label>
                        <input type="text" disabled="disabled" name="taskGroupCode" id="taskGroupCode" v-model="taskGroup.taskGroupCode" class="form-control" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="projectId" class="col-form-label">Project</label>
                        <select v-model="taskGroup.projectId" id="projectId" name="projectId" class="form-control">
                            <option v-for="Option in options.projectList" :value="Option.id">{{ Option.projectName }}</option>
                        </select>
                        <label v-if="$v.taskGroup.projectId.$error && !$v.taskGroup.projectId.required" class="text-danger">Project is required</label>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="relatedTo" class="col-form-label">Reference</label>
                        <input type="text" name="relatedTo" id="relatedTo" v-model="taskGroup.relatedTo" class="form-control" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="addTGStartDate" class="col-form-label">Start Date</label>
                        <input type="date" v-model="taskGroup.startDate" @click="validDate('#addTGStartDate')" class="form-control" id="addTGStartDate" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="addTGDueDate" class="col-form-label">End Date</label>
                        <input type="date" v-model="taskGroup.endDate" @click="validDate('#addTGDueDate')" class="form-control" id="addTGDueDate" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="taskGroupStatus" class="col-form-label">Task Group Status</label>
                        <select class="form-control" v-model="taskGroup.status">
                            <option value="">Select</option>
                            <option v-for="(status, index) in statusList" :value="status.id">{{status.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-12 col-sm-12">
        <label for="taskGroupDescription" class="col-form-label">Description</label>
        <quill-editor 
            v-model="taskGroup.description" 
            :options="editorOptions" 
            class="quill-editor">
        </quill-editor>
        <label v-if="$v.taskGroup.description.$error && !$v.taskGroup.description.maxLength" class="text-danger">
            Description must not exceed {{$v.taskGroup.description.$params.maxLength.max}} letters.
        </label>
    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import Editor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
import { QuillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css' // Import Quill's styles
export default {
    components: {
        Editor,
        Multiselect
    },
    props: {
        options: Object,
        editor: Object,
        project: Object
    },
    data() {
        return {
            elements: {
                showMembersDropdown: false
            },
            statusList: this.$store.getters.getTaskGroupStatusList,
            taskGroup: {
                projectId: this.project.id,
                title: "",
                taskGroupCode: "",
                description: "",
                relatedTo: "",
                startDate: new Date().toISOString().substr(0, 10),
                endDate: "",
                status: 1,
                taskGroupMembers: []
            }
        }
    },
    validations: {
        taskGroup: {
            projectId: { required },
            title: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            description: {
                maxLength: maxLength(200)
            },
        }
    },
    mounted(){
    	this.loadDate();
    },
    methods: {
        loadDate() {
            this.taskGroup.startDate = new Date().toISOString().substr(0, 10);
            this.taskGroup.endDate = new Date().toISOString().substr(0, 10);
        },
        validateSprintInfo: function() {
            this.$v.taskGroup.$touch();
            if (this.$v.taskGroup.$invalid) {
                return false;
            }
            return true;
        },
        getTaskGroupCode() {
        	this.taskGroup.projectId = this.project.id
        	 this.taskGroup.startDate = new Date().toISOString().substr(0, 10);
            this.taskGroup.endDate = new Date().toISOString().substr(0, 10);
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/taskgroup')
                .then(response => {
                    if (response.data.status) {
                        this.taskGroup.taskGroupCode = response.data.code;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addSprint: function(bvModalEvt) {
            this.taskGroup.taskGroupMembers.map(function(Members) {
                Members.userId = Members.id;
            });
            if (this.projectId == null && !this.validateSprintInfo()) {
                bvModalEvt.preventDefault();
                return false;
            }
            this.taskGroup.projectId = this.project.id;
            axios.post(this.$store.getters.getAPIBasePath + '/projectresource/add/project/sprint', this.taskGroup)
                .then(response => {
                    if (response.data.status == "ACCEPTED") {
                        iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
                        this.$refs.createSprintModal.hide();
                        
                        this.$emit('getSprintList',this.project);
                        this.refreshSprintData();
                    } else {
                        iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
                });
        },
        refreshSprintData: function() {
            this.$v.taskGroup.$reset();
            this.taskGroup.projectId = null;
            this.taskGroup.title = "";
            this.taskGroup.description = "";
            this.taskGroup.relatedTo = "";
            this.taskGroup.startDate = "";
            this.taskGroup.endDate = "";
            this.elements.showMembersDropdown = false;
        },
        taskGroupShown: function() {
            this.refreshSprintData();
            this.getTaskGroupCode();
            this.taskGroup.projectId = this.project.id;
        },
        close: function() {
            console.log("close")
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        },
        validDate(id) {
            var dtToday = new Date();

            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();

            var maxDate = year + '-' + month + '-' + day;
            $(id).attr('min', maxDate);
        }
    }
}

</script>
<style>
.quill-editor {
    height: 200px; /* Adjust editor height */
}
</style>