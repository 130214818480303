<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4" id="main-data">
            <div class="row">
                <div class="row w-100 mb-4">
                    <div class="col-md-7 col-sm-7 pr-0">
                        <div class="col-md-12 pr-0">
                            <div class="box-wrapper shadow-sm p-0 bg-white rounded pos-rel ">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <h4 class="page-title p-3 d-inline-block text-white candidate-title">CANDIDATE</h4>
                                        <ul class="d-inline-block mb-0 pl-4 candidate-details">
                                            <li class="d-inline-block pr-5">
                                                <p class="m-0"><a href="javascript:" @click="viewCandidate()"><span class="pr-2"><i class="ti-user"></i></span>{{ candidateData.candidateName }}</a></p>
                                            </li>
                                            <li v-if="candidateData.technologyName != null && candidateData.technologyName.length >0" class="d-inline-block pr-5">
                                                <p class="m-0"><span class="pr-2"><i class="ti-briefcase"></i></span>
                                                    <span :title="candidateData.technologyName" v-html="gettechsubstring(candidateData.technologyName )"></span>
                                                </p>
                                            </li>
                                            <li v-if="candContactAccess==1" class="d-inline-block pr-5">
                                                <p class="m-0"><span class="pr-2"><i class="ti-email"></i></span>{{ candidateData.email }}</p>
                                            </li>
                                            <li v-if="candidateData.mobile != null && candidateData.mobile.length >0 && candContactAccess==1" class="d-inline-block pr-5">
                                                <p class="m-0"><span class="pr-2"><i class="fa fa-mobile fa-lg"></i></span>{{ candidateData.mobile }}</p>
                                            </li>
                                            <li class="d-inline-block pr-5">
                                                <p v-if="candidateData.timeZone != null && candidateData.timeZone.length > 0" class="m-0 "><span class="pr-2"><i class="fa fa-globe"></i></span>
                                                    <span class="timezone-title pl-1 pr-1 pb-1">{{ candidateData.timeZone }}</span></p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-5 pr-0">
                        <div class="col-md-12 pr-0">
                            <div class="box-wrapper shadow-sm p-0 bg-white rounded pos-rel ">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <h4 class="page-title p-3 d-inline-block text-white candidate-title">INTERVIEW SCHEDULE</h4>
                                        <a href="javascript:" @click="getPrevUrl()" class="btn btn-primary pull-right" style="margin-top: 5px;margin-right: 10px;">Back</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 row-eq-height">
                    <div class="col-md-7 col-sm-7">
                        <div class="row">
                            <div class="col-sm-12">
                                <div id="fc-schedule-interview" class="box-wrapper shadow-sm p-4  bg-white rounded pos-rel">
                                    <!-- <fullcalendar selectable=true @date-click="handleDateClick" @event-selected="eventSelected" @mouse-hover-event="mouseHoverEvent" :calendarEvents="interviewsData" /> -->
                                    <fullcalendar 
  selectable=true 
  :calendarEvents="interviewsData" 
  @date-click.prevent="handleDateClick"
/>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-5 schedule-interview schedule-interview-edit-mode" v-if="showMessage">
                        <div class="box-wrapper shadow-sm p-4  bg-white rounded pos-rel h-auto">
                            <div class="key_skills mb-3">
                                <div class="widget-wrapper">
                                    <div class="widget-content">
                                        <div class=" inner-content errorcolor">
                                            We can't schedule interview for past date
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-5 schedule-interview schedule-interview-edit-mode" v-if="right_panel_mode">
                        <div class="box-wrapper shadow-sm p-4  bg-white rounded pos-rel h-auto">
                            <div class="key_skills mb-3">
                                <div class="widget-wrapper">
                                    <div class="widget-content ">
                                        <div class="header">
                                            <span class="title">Interview Details</span>
                                            <div class="icons">
                                                <i v-if="edit_mode==true" @click="viewMode" title="View" class="ti-eye display-only view-eye" aria-hidden="true"></i>
                                                <i v-if="edit_mode==false && show_pencil==true" @click="this.cantidate_edit" title="Edit" class="ti-pencil display-only edit-pencil" aria-hidden="true"></i>
                                               
                                            </div>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div class="inner-content">
                                            <div v-if="edit_mode && interviewPlan.interviewPlanFlag" class="data-cell text-center p-0">
                                                
                                                <a v-if="false" class="font-weight-bold float-right" style="margin-left: 10px" href="javascript:" @click="interviewPlan.isPlanInterview=true">Existing Interview</a>
                                                <a v-if="false" class="font-weight-bold float-right" href="javascript:" @click="newInterview">New Interview</a>
                                                <button type="button" @click="interviewPlan.isPlanInterview=true" class="btn btn-primary btn-sm filter-button mt-0 mb-1" style="margin-left: 10px;">Existing Interview</button>
                                                <button type="button" @click="newInterview" class="btn btn-primary btn-sm filter-button mt-0 mb-1" style="margin-left: 10px;">New Interview</button>
                                                <div style="clear:both"></div>
                                            </div>
                                            <div class="data-cell">
                                                <span class="data-output-label">Title</span>
                                                <span v-if="edit_mode==false" class="data-output-value">{{ selInterview.title }}</span>
                                                <span v-if="edit_mode" class="data-output-input">
                                                    <select v-if="interviewPlan.interviewPlanFlag && interviewPlan.isPlanInterview" v-model="selInterview.rpInterviewId" @change="changeInterviewPlan(selInterview.rpInterviewId)" class="data-output-input" name="interviewTitle">
                                                        <option v-for="options in interviewPlan.plannedInterviews" :value="options.id">{{options.title}}</option>
                                                    </select>
                                                    <input v-if="!interviewPlan.isPlanInterview" class="data-output-input" type="text" v-model="selInterview.title" name="title">
                                                    <div v-if="submitted && !$v.selInterview.title.required" class="invalid-feedback">Title is required</div>
                                                    <div v-if="submitted && !$v.selInterview.title.minLength" class="invalid-feedback">Title must have atleast 5 characters</div>
                                                    <div v-if="submitted && !$v.selInterview.title.maxLength" class="invalid-feedback">Title shouldn't exceed more than 100 characters</div>
                                                </span>
                                                <div style="clear:both"></div>
                                            </div>
                                            <div class="data-cell date-time-cell">
                                                <span class="data-output-label">Date & Time</span>
                                                <div class="row" v-if="edit_mode==false">
                                                    <span class="data-output-value">{{ selInterview.interviewDate }}</span>
                                                    <span v-if="selInterview.startTime" class="data-output-value">{{selInterview.startTime | formatTime}} to {{selInterview.endTime | formatTime }}</span>
                                                </div>
                                                <div class="row" v-if="edit_mode">
                                                    <div class="col-sm-4 p-0">
                                                        <span class="data-output-input">
                                                            <input class="data-output-input" type="text" v-model="selInterview.interviewDate" name="date" disabled="disabled">
                                                        </span>
                                                    </div>
                                                    
                                                </div>
                                                <div style="clear:both"></div>
                                            </div>

                                            <div v-if="edit_mode==true" class="data-cell date-time-cell">
                                                <span class="data-output-label">Time</span>
                                                <div class="row" v-if="edit_mode">
                                                    <div class="col-sm-6 p-0">
                                                        <timepicker :value="defaultStartTime" ref="StartTimepickercomponent" @selected-value="setStartTime"></timepicker>
                                                        
                                                    </div>
                                                    <div class="col-sm-6 p-0 pl-2">
                                                        <timepicker :value="defaultEndTime" ref="EndTimepickercomponent" @selected-value="setEndTime"></timepicker>
                                                    </div>
                                                    <div class="w-100 d-flex">
                                                        <div v-if="submitted && !$v.selInterview.startTime.required" class="invalid-feedback">Start time is required</div>
                                                        <div v-if="submitted && $v.selInterview.startTime.required && !$v.selInterview.startTime.checkStartTime" class="invalid-feedback">Start time must be greater than current time</div>
                                                        <div v-if="submitted && !$v.selInterview.endTime.required" class="invalid-feedback">End time is required</div>
                                                        <div v-if="submitted && $v.selInterview.endTime.required && !$v.selInterview.endTime.checkEndTime" class="invalid-feedback">End time must be greater than start time</div>
                                                    </div>
                                                </div>
                                                <div style="clear:both"></div>
                                            </div>


                                            <div class="data-cell">
                                                <span class="data-output-label">Interview Type</span>
                                                <span v-if="edit_mode==false" class="data-output-value" v-for="Type in interviewType">
                                                    <span v-if="Type.id==selInterview.interviewType">{{Type.name}} </span>
                                                </span>
                                                <span v-if="edit_mode" class="data-output-input">
                                                    <select v-model="selInterview.interviewType" class="data-output-input" name="interviewType">
                                                        <option v-for="options in interviewType" :value="options.id">{{options.name}}</option>
                                                    </select>
                                                    <div v-if="submitted && !$v.selInterview.interviewType.required" class="invalid-feedback">Interview Type is required</div>
                                                </span>
                                                <div style="clear:both"></div>
                                            </div>
                                            <div class="data-cell">
                                                <span class="data-output-label">Status</span>
                                                <span class="data-output-value" v-for="Status in status">
                                                    <span v-if="Status.id==selInterview.status">{{Status.name}}</span>
                                                </span>
                                               
                                                <div style="clear:both"></div>
                                            </div>
                                            <div style="clear:both"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="key_skills mb-3">
                                <div class="widget-wrapper">
                                    <div class="widget-content display-mode">
                                        <div class="header">
                                            <span class="title">Interviewers</span><span class="count pl-2 font-weight-bold">( {{selInterview.panelMembers.length}} )</span>
                                            <div class="icons" v-if="edit_mode">
                                                <i class="fa fa-user-plus add-icon hover-hand mr-2" title="Add Interviewers" @click="loadInterviewersList()" aria-hidden="true" v-b-modal.interviewers-form-modal></i>
                                                <i class="fa fa-users add-icon hover-hand ml-2" title="Invite Interviewers" @click="inviteInterviewersList()" aria-hidden="true" v-b-modal.interviewers-form-modal></i>
                                            </div>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div class="inner-content min-scroll">
                                            <div class="user-card mb-2" v-for="(panelMember,index) in selInterview.panelMembers">
                                                <div class="user-profile">
                                                    <div class="profile"></div>
                                                </div>
                                                <div class="user-stats d-block">
                                                   
                                                    <div class="w-clearfix">
                                                        <div class="score w-clearfix">
                                                            <p class="my-score">Interviewer Name</p>
                                                            <p class="total-score">{{ panelMember.interviewerName }}</p>
                                                            <i v-if="edit_mode" class="ti-trash text-primary display-only trash" @click="popPanMem(index,panelMember.id)" aria-hidden="true"></i>
                                                        </div>
                                                        
                                                        <div class="score w-clearfix">
                                                            <p class="my-score">Position</p>
                                                            <p class="total-score">{{ panelMember.position }}</p>
                                                        </div>
                                                        <div class="score w-clearfix">
                                                            <p class="my-score">Phone No.</p>
                                                            <p class="total-score">{{ panelMember.phoneNo }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="!isInviteInterview && submitted && !$v.selInterview.panelMembers.required" class="invalid-feedback">Add Interviewers</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="interviewPlan.interviewPlanFlag && interviewPlan.isPlanInterview" class="key_skills">
                                <div class="widget-wrapper">
                                    <div class="widget-content">
                                        <div class="header">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-6">
                                                    <span class="title">Interview Requirement</span>
                                                    <div style="clear:both"></div>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-6">
                                                    <ul class="ul-horizontal box-tools text-right mb-0">
                                                        <li><a href="Javascript:void(0);" v-on:click="isExpand=!isExpand;" data-target="main-search" class="boxhide"><span style="color: white;" :class="isExpand?'ti-angle-up':'ti-angle-down'"></span></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inner-content min-scroll" :class="isExpand?'d-block':'d-none'">
                                            <div class="data-cell">
                                                <span v-if="edit_mode==false" class="data-output-label1">{{selInterview.interviewRequirement}}</span>
                                                <b-form-textarea v-if="edit_mode" class="data-output-input1" v-model="selInterview.interviewRequirement" rows="6" max-rows="6" name="interviewRequirement" disabled></b-form-textarea>
                                                
                                                <div style="clear:both"></div>
                                            </div>
                                            <div style="clear:both"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="key_skills">
                                <div class="widget-wrapper">
                                    <div class="widget-content">
                                        <div class="header">
                                            <span class="title">Notes</span>
                                            <div style="clear:both"></div>
                                        </div>
                                        <div class="inner-content">
                                            <div class="data-cell">
                                                <span class="data-output-label1">Candidate Notes</span>
                                                <b-form-textarea v-if="edit_mode==false" readonly="readonly" class="data-output-input1" v-model="selInterview.candidateNotes" rows="3" max-rows="6" name="candidateNotes"></b-form-textarea>
                                                <span v-if="edit_mode" class="data-output-input1">
                                                    <b-form-textarea class="data-output-input1" v-model="selInterview.candidateNotes" rows="3" max-rows="6" name="candidateNotes"></b-form-textarea>
                                                    
                                                </span>
                                                <div style="clear:both"></div>
                                            </div>
                                            <div class="data-cell">
                                                <span class="data-output-label1">Interviewer Notes</span>
                                                <b-form-textarea v-if="edit_mode==false" readonly="readonly" class="data-output-input1" v-model="selInterview.interviewerNotes" rows="3" max-rows="6" name="interviewerNotes"></b-form-textarea>
                                                <span v-if="edit_mode" class="data-output-input1">
                                                    <b-form-textarea class="data-output-input1" v-model="selInterview.interviewerNotes" rows="3" max-rows="6" name="interviewerNotes"></b-form-textarea>
                                                   
                                                </span>
                                                <div style="clear:both"></div>
                                            </div>
                                            <div style="clear:both"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="edit_mode" class="icons p-2 float-right">
                                <a href="javascript:" @click="saveCandidateAvailabilityEvent()" class="edit-only save-icon btn btn-primary mr-2">Save</a>
                                <a href="javascript:" @click="closeEvent()" class="edit-only close-icon btn btn-danger">Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-if="showModal" size="xl" id="interviewers-form-modal" :no-close-on-backdrop="true" ref="interviewersModal" title="Interviewers" @show="" @hidden="clearFilter" @ok="addInterviewers" :ok-title="buttonTitle" @cancel="" @close="">
            <div class="modal-body">
                <div class="card m-b-3">
                    <div class="card-body p-2">
                        <div class="basic-block">
                            <form id="main-search" v-if="!isInviteInterview">
                                <div class="row form-group ten-columns pb-2">
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Name</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-on:keyup.enter="filterInterviewers"  v-model="params.name" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Technology</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-on:keyup.enter="filterInterviewers"  v-model="params.technologyName" class="form-control">
                                                    <select v-if="false" class="form-control" v-model="params.technology">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in technologies" :value="info.id">{{info.technology}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label>Availability</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <select class="form-control" v-model="params.availability">
                                                        <option value="">Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-12 mb-10-mr mb-1">
                                        <div class="row pull-right">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label></label>
                                            </div>
                                            <div class="input-group ">
                                                <button type="button" class="btn btn-primary btn-sm mr-2" @click="filterInterviewers()">Find</button>
                                                <button type="button" class="btn btn-primary btn-sm" @click="resetFilter()">Reset</button>                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="row scroll-down-list">
                                <div class="col-sm-12">
                                    <div v-if="isInviteInterview && submitted && !$v.panelMem.required" class="errorcolor">Please select an interviewer to invite</div>
                                    <table class="table table-borderless all-table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" @click="checkAll" type="checkbox" id="rowchckboxall" value="option1">
                                                        <label class="form-check-label" for="rowchckboxall"></label>
                                                    </div>
                                                </th>
                                                <th>Name</th>
                                                <th>Position</th>
                                                <th>Technology</th>
                                                <th>Phone No.</th>
                                                <th>Email</th>
                                                <th>
                                                    Available [Date: {{selInterview.interviewDate}}]<br>
                                                    Time: [{{selInterview.cloneStartTime}} - {{selInterview.cloneEndTime}}]
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!recruiters_list.length">
                                                <td class="text-center" colspan="100%">No Interviewers found</td>
                                            </tr>
                                            <tr v-for="Recruiter in recruiters_list">
                                                <td>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" :id="'rowchckbox'+Recruiter.id" v-model="panelMem" :value="Recruiter.id" checked="">
                                                        <label class="form-check-label" :for="'rowchckbox'+Recruiter.id"></label>
                                                    </div>
                                                </td>
                                                <td>{{Recruiter.interviewerName}}</td>
                                                <td>{{Recruiter.position}}</td>
                                                <td>{{Recruiter.technologyName}}</td>
                                                <td>{{Recruiter.phoneNo}}</td>
                                                <td>{{Recruiter.email}}</td>
                                                <td>{{Recruiter.availavility}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div v-if="isInviteInterview" class="row float-right">
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-12 lb">
                                            <label>No. of Interviewers</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-12">
                                            <div class="input-group">
                                                <input type="text" v-model="selInterview.noOfInterviewers" class="form-control">
                                                <!-- <div v-if="isInviteInterview && submitted && !$v.selInterview.noOfInterviewers.required" class="error">No. of Interviewers cannot be empty</div> -->
                                            </div>
                                            <label v-if="isInviteInterview && submitted && !$v.selInterview.noOfInterviewers.required" class="text-danger">No. of Interviewers cannot be empty</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <div class="modal " id="candidateAvailabilityModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Candidate Availability Confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <span id="candidatesAvailableTime"></span><br>
                        Are you sure to schedule interview at this Time?
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="saveEvent()" class="btn btn-primary" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import _ from 'lodash';
import iziToast from 'izitoast';
// import { TooltipPlugin } from 'bootstrap-vue'
export default {
    data() {
        return {
            isExpand: false,
            isInviteInterview: false,
            showModal: false,
            defaultStartTime: "",
            defaultEndTime: "",
            technologies: this.$store.getters.getTechOptions,
            datetimezoneformat:this.$store.state.datetimezoneformat,
            edit_mode: true,
            right_panel_mode: true,
            showMessage: false,
            // showAvailability: false,
            candidateData: [],
            interviewsData: [],
            interviewPlan: {
                interviewPlanFlag: false,
                plannedInterviews: [],
                isPlanInterview: false
            },
            selInterview: {
                title: "",
                interviewDate: "",
                startTime: "",
                endTime: "",
                interviewType: "",
                status: "",
                panelMembers: [],
                inviteMembers: [],
                noOfInterviewers: "",
                candidateNotes: "",
                interviewerNotes: "",
                id: "",
                recPlanId: localStorage.getItem('recPlanId') == "null" ? null : localStorage.getItem('recPlanId'),
                rpInterviewId: null,
                jobId: localStorage.getItem('jobId') == "null" ? null : localStorage.getItem('jobId'),
                cloneStartTime: "",
                cloneEndTime: "",
                interviewRequirement: ""
            },
            selectedDate: null,
            errors: [],
            recruiters_list: [],
            all_recruiters_list: [],
            submitted: false,
            event_params: [],
            params: {
                name: "",
                technology: "",
                availableDate: "",
                startTime: "",
                endTime: "",
                interviewType: "",
                availability: '',
                candidateId: null,
                technologyName: ""
            },
            panelMem: [],
            isCheckAll: false,
            status: this.$store.getters.getInterviewStatus,
            interviewType: this.$store.getters.getInterviewType,
            recruitmentId: localStorage.getItem('recruitmentId') == "null" ? null : localStorage.getItem('recruitmentId'),
            show_pencil: true,
            currentEvent: {},
            buttonTitle: "OK",
             candContactAccess: localStorage.getItem('candidateContactAccess'),
        }
    },
    validations: {
        selInterview: {
            title: { required, minLength: minLength(5), maxLength: maxLength(100) },
            interviewDate: { required },
            startTime: {
                required,
                checkStartTime: function(startTime) {
                    if (this.$options.filters.formatCustomTime(this.selInterview.interviewDate + " " + startTime, "YYYY-MM-DD HH:mm:ss") < this.$options.filters.formatCustomTime(new Date(), "YYYY-MM-DD HH:mm:ss"))
                        return false;
                    else
                        return true;
                }
            },
            endTime: {
                required,
                checkEndTime: function(endTime) {
                    // console.log(moment(this.selInterview.interviewDate+" "+endTime).format("YYYY-MM-DD HH:mm:ss"));
                    // console.log(moment(this.selInterview.interviewDate+" "+this.selInterview.startTime).format("YYYY-MM-DD HH:mm:ss"));
                    if (moment(this.selInterview.interviewDate + " " + endTime).format("YYYY-MM-DD HH:mm:ss") > moment(this.selInterview.interviewDate + " " + this.selInterview.startTime).format("YYYY-MM-DD HH:mm:ss"))
                        return true;
                    else 
                        return false;
                }
            },
            interviewType: { required },
            status: { required },
            panelMembers: { required },
            noOfInterviewers: { required }
        },
        panelMem: { required },
    },
    computed: {
        candidateId() {
            return this.$route.params.candidateid;
        }
    },
    mounted() {
        /*console.log("recId "+this.recruitmentId); // = parseInt(this.$route.params.recruitmentId)?parseInt(this.$route.params.recruitmentId):null;
        console.log("jobId "+this.selInterview.jobId); // = parseInt(this.$route.params.jobId)?parseInt(this.$route.params.jobId):null; 
        console.log("recPlanId
 "+this.selInterview.recPlanId); // = parseInt(this.$route.params.jobId)?parseInt(this.$route.params.jobId):null; */
        // this.loadInterviewersList();
        // console.log(this.$options.filters.customDateFormat('29-06-2019','DD-MM-YYYY','MMMM DD, YYYY'));
        // console.log(this.selInterview.recPlanId);        
    },
    created() {
        this.loadCandidateDetails();
        this.refreshSelInterview();
        // this.selInterview.interviewDate = this.$options.filters.formatDate(moment().add(1,'days'));
        this.selInterview.interviewDate = this.$options.filters.formatDate(moment());
        this.selInterview.startTime = this.$options.filters.formatCustomTime(moment(), "hh:mm A");
        this.selInterview.status = 1;
        if (this.selInterview.recPlanId != "" && this.selInterview.recPlanId != null && this.selInterview.recPlanId != "null" && this.selInterview.recPlanId != 0) {
            this.loadAllPlannedInterviews();
            this.interviewPlan.isPlanInterview = true;
        }
        this.getInterviewersList();
        this.defaultStartTime = this.$options.filters.formatCustomTime(moment(), "hh:mm A");
    },
    methods: {
        setStartTime(value) {
        this.selInterview.startTime = value;
        this.validateTime(); // Validate after setting start time
    },
    setEndTime(value) {
        this.selInterview.endTime = value;
        this.validateTime(); // Validate after setting end time
    },
    validateTime() {
        if (this.selInterview.startTime && this.selInterview.endTime) {
            const startTime = this.convertTo24Hour(this.selInterview.startTime);
            const endTime = this.convertTo24Hour(this.selInterview.endTime);

            if (startTime >= endTime) {
                this.invalidTimeRange = true;
            } else {
                this.invalidTimeRange = false;
            }
        }
    },
    convertTo24Hour(timeStr) {
        // Convert 12-hour format (e.g., "5:00 PM") to 24-hour format (e.g., "17:00")
        let [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');

        if (modifier === 'PM' && hours !== '12') {
            hours = parseInt(hours, 10) + 12;
        } else if (modifier === 'AM' && hours === '12') {
            hours = '00';
        }

        return `${hours}:${minutes}`;
    },
        async getInterviewersList(){
            await this.loadInterviewersList();
            this.all_recruiters_list = this.recruiters_list;
        },
        setStartTime: function(val) {
            this.selInterview.startTime = val;
            if (this.selInterview.interviewDate != null)
                this.selInterview.startTime = moment(this.selInterview.interviewDate + " " + this.selInterview.startTime).format('HH:mm');
        },
        setEndTime: function(val) {
            this.selInterview.endTime = val;
            if (this.selInterview.interviewDate != null)
                this.selInterview.endTime = moment(this.selInterview.interviewDate + " " + this.selInterview.endTime).format('HH:mm');
        },
        cantidate_edit: function() {
            this.edit_mode = true;
            this.interviewPlan.isPlanInterview = false;
            this.selInterview.rpInterviewId = null;
            this.defaultStartTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.startTime).format('hh:mm A');
            this.defaultEndTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.endTime).format('hh:mm A');
        },
        cantidate_save: function() {
            this.edit_mode = false;
        },
        viewMode() {

            if (localStorage.getItem("interviewId") != null && localStorage.getItem("interviewId") > 0) {
                this.loadEventById(parseInt(localStorage.getItem('interviewId')));
            } else {

                if (this.currentEvent)
                    this.eventSelected(this.currentEvent);
            }
            this.edit_mode = false;
            this.show_pencil = true;
            localStorage.removeItem('interviewMode');
            //
        },
        loadCandidateDetails() {
            axios.get(this.$store.getters.getAPIBasePath + '/interviewerresource/candidate/interviewscheduled/' + this.candidateId).then(response => {
                    if (response.data.status) {
                        let self = this;
                        this.candidateData = response.data.recruitment[0];
                        console.log(this.candidateData);
                        //Temporarily commented
                        //this.params.technologyName = this.candidateData.technologyName;
                        this.interviewsData = this.candidateData.interviews;
                        $.each(this.interviewsData, function(key, value) {
                            console.log(value);
                            let s = value.start;
                            let e = value.end;
                            console.log(s, e);         
                            value.start = moment(value.start.replace("T00:00:00.0", "")).format("YYYY-MM-DD HH:mm:ss");
                            value.end = moment(value.end.replace("T00:00:00.0", "")).format("YYYY-MM-DD HH:mm:ss");
                            //value.start = self.toClientTimeZoneDate(value.start, self.datetimezoneformat);
                            //value.end = self.toClientTimeZoneDate(value.end, self.datetimezoneformat);
                        });
                        $.each(this.candidateData.candidateAvailability, function(key, value) {
                            value.start = moment(value.start.replace(" 00:00:00.0", "")).format("YYYY-MM-DD HH:mm:ss");
                            value.end = moment(value.end.replace(" 00:00:00.0", "")).format("YYYY-MM-DD HH:mm:ss");
                            //value.start = self.toClientTimeZoneDate(value.start, self.datetimezoneformat);
                            //value.end = self.toClientTimeZoneDate(value.end, self.datetimezoneformat);
                        });
                        this.interviewsData = this.interviewsData.concat(this.candidateData.candidateAvailability);

                        if (localStorage.getItem("interviewId") != null && localStorage.getItem("interviewId") > 0 && localStorage.getItem("interviewMode") != null && localStorage.getItem("interviewMode") == 'view') {
                            this.viewMode();
                        } else if (localStorage.getItem("interviewId") != null && localStorage.getItem("interviewId") > 0) {
                            this.loadEventById(parseInt(localStorage.getItem('interviewId')));
                        }
                        if (this.event_params.id) {
                            this.loadEventByInterviewId(this.event_params.id);
                        }

                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.LoadingErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    console.log(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.LoadingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        refreshSelInterview() {
            this.selInterview.title = ""; //
            this.selInterview.rpInterviewId = null;
            // this.selInterview.interviewDate = "";
            this.selInterview.startTime = "";
            this.selInterview.endTime = "";
            this.selInterview.interviewType = ""; //
            this.selInterview.status = "";
            this.selInterview.panelMembers = []; //
            this.selInterview.candidateNotes = ""; //
            this.selInterview.interviewerNotes = ""; //
            this.selInterview.id = "";
            this.selInterview.end = "";
            this.selInterview.start = "";
            this.panelMem = [];
            this.show_pencil = true;
            this.isInviteInterview = false;
        },
        changeInterviewPlan(intPlanId) {
            let selInterviewPlan = this.loadPlannedInterviewById(intPlanId)[0];
            this.selInterview.title = selInterviewPlan.title;
            // this.selInterview.interviewDate = selInterviewPlan.interviewDate;
            this.selInterview.interviewType = selInterviewPlan.interviewType;
            this.selInterview.status = selInterviewPlan.status;
            this.selInterview.interviewRequirement = selInterviewPlan.interviewRequirement;
            this.panelMem = [];
            let self = this;
            $.each(selInterviewPlan.panelMembers, function(key, value) {
                self.panelMem.push(value.id);
            });
            this.selInterview.panelMembers = this.loadInterviewersById(this.panelMem);
            this.selInterview.candidateNotes = selInterviewPlan.candidateNotes;
            this.selInterview.interviewerNotes = selInterviewPlan.interviewerNotes;

        },
        loadPlannedInterviewById(intPlanId) {
            return this.interviewPlan.plannedInterviews.filter(function(idata, key) {
                if (idata.id == intPlanId) {
                    return idata;
                }
            });
        },
        loadAllPlannedInterviews() {
            this.loadPlannedInterviewById(this.recPlanId);
            let self = this;
            let data = {
                recruitmentPlanId: this.selInterview.recPlanId,
                candidateId: this.candidateId,
                jobId: this.selInterview.jobId
            };
            axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/rp/interviews', data).then(response => {
                    if (response.data.interviews.length > 0) {
                        /*  console.log("-----------------------------");
                          console.log(response.data.interviews);*/
                        self.interviewPlan.interviewPlanFlag = true;
                        self.interviewPlan.isPlanInterview = true;
                        self.interviewPlan.plannedInterviews = response.data.interviews;
                    } else {
                        self.interviewPlan.interviewPlanFlag = false;
                        self.interviewPlan.isPlanInterview = false;
                        // let errmsg = (error.response.data.message!=undefined)?error.response.data.message:'No Interviews found.. !!';
                        // iziToast.error({title: 'Error', message: errmsg, position:'topRight'});
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    self.interviewPlan.interviewPlanFlag = false;
                    self.interviewPlan.isPlanInterview = false;
                    let errmsg = this.$config.PlanIntLoadErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        handleDateClick: function(info) {
            this.isInviteInterview = false;
            if (localStorage.getItem("interviewId") != null && localStorage.getItem("interviewId") > 0) {
                this.submitted = false;
                if (moment(info.date).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
                    this.right_panel_mode = false;
                    this.showMessage = true;
                    this.edit_mode = false;
                    this.selectedDate = null;
                } else {
                    this.right_panel_mode = true;
                    this.edit_mode = true;
                    this.showMessage = false;
                    this.selInterview.interviewDate = this.$options.filters.formatDate(info.date);
                    this.selInterview.status = 1;
                }
            } else {
                this.refreshSelInterview();
                this.submitted = false;
                if (moment(info.date).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
                    this.right_panel_mode = false;
                    this.showMessage = true;
                    this.edit_mode = false;
                    this.selectedDate = null;
                } else {
                    this.defaultStartTime = "";
                    this.defaultEndTime = "";
                    this.right_panel_mode = true;
                    this.showMessage = false;
                    this.edit_mode = true;
                    this.selInterview.interviewDate = this.$options.filters.formatDate(info.date);
                    this.selInterview.status = 1;
                }
            }
        },
        eventSelected: function(event) {
            console.log('eventSelected');
            console.log(event);
            this.isInviteInterview = false;
            this.showMessage = false;
            //this.showAvailability = false;
            this.currentEvent = event;
            this.refreshSelInterview();
            console.log("event----"+event.start+"=-==="+event.end);
            console.log("aa====="+event.extendedProps.interviewDate+"======"+event.interviewDate) ;
            // console.log("=");
            if (event.extendedProps != undefined && event.extendedProps.availability == "true") {
                this.submitted = false;
                // this.showAvailability = true;
                this.selInterview.interviewDate = this.$options.filters.formatDate(event.extendedProps.availableDate);
                /* if (this.selInterview.interviewDate <= this.$options.filters.formatDate(new Date())) {
                     this.right_panel_mode = false;
                     return false;
                 }*/
                this.selInterview.startTime = this.$options.filters.formatCustomTime(event.start, "HH:mm");
                this.selInterview.endTime = this.$options.filters.formatCustomTime(event.end, "HH:mm");
                this.defaultStartTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.startTime).format('hh:mm A');
                this.defaultEndTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.endTime).format('hh:mm A');
                this.selInterview.status = 1;
                this.right_panel_mode = true;
                this.edit_mode = true;
                this.show_pencil = false;
                this.recruitmentId = event.extendedProps.recruitmentId;
                return false;

            }
                     
            this.edit_mode = false;
            this.right_panel_mode = true;
            this.selInterview.id = event.id;
            this.selInterview.title = event.title.replace("Interview - ", "");;
            this.selInterview.start = event.start;
            this.selInterview.end = event.end;
            this.selInterview.startTime = moment(String(event.start)).format("HH:mm");
            this.selInterview.endTime = moment(String(event.end)).format("HH:mm");
            this.selInterview.cloneStartTime = moment(String(event.start)).format("hh:mm A");
            this.selInterview.cloneEndTime = moment(String(event.end)).format("hh:mm A");

            if (event.extendedProps != null) {
                this.selInterview.interviewType = event.extendedProps.interviewType;
                this.selInterview.status = event.extendedProps.status;
                this.selInterview.panelMembers = event.extendedProps.panelMembers;
                this.selInterview.candidateNotes = event.extendedProps.candidateNotes;
                this.selInterview.interviewerNotes = event.extendedProps.interviewerNotes;
                 console.log("cccc===="+event.extendedProps.interviewDate+"======"+event.interviewDate) 
                this.selInterview.interviewDate = this.$options.filters.formatDate(event.extendedProps.interviewDate);
                this.selInterview.interviewRequirement = event.extendedProps.interviewRequirement;
                this.selInterview.rpInterviewId = event.extendedProps.rpInterviewId;
                this.recruitmentId = event.extendedProps.recruitmentId;
            } else {
                this.selInterview.interviewType = event.interviewType;
                this.selInterview.status = event.status;
                this.selInterview.panelMembers = event.panelMembers;
                this.selInterview.candidateNotes = event.candidateNotes;
                this.selInterview.interviewerNotes = event.interviewerNotes;
                 console.log("bbbb===="+event.extendedProps.availableDate+"======"+event.interviewDate) ;
                this.selInterview.interviewDate = this.$options.filters.formatDate(event.interviewDate);
                this.selInterview.interviewRequirement = event.interviewRequirement;
            }

            if (new Date(this.selInterview.interviewDate + " " + this.selInterview.startTime) <= new Date()) {
                this.show_pencil = false;
            } else {
                this.show_pencil = true;
            }
            console.log(this.selInterview.panelMembers);
            let self = this;
            $.each(this.selInterview.panelMembers, function(key, value) {
                self.panelMem.push(value.id);
            });
        },
        rescheduledInterview: function(event) {
            this.isInviteInterview = false;
            this.refreshSelInterview();
            this.edit_mode = false;
            this.right_panel_mode = true;
            this.selInterview.id = event.id;
            this.selInterview.title = event.title.replace("Interview - ", "");;
            this.selInterview.start = event.start;
            this.selInterview.end = event.end;
            this.selInterview.startTime = moment(String(event.start)).format("HH:mm");
            this.selInterview.endTime = moment(String(event.end)).format("HH:mm");
            this.selInterview.interviewType = event.interviewType;
            this.selInterview.status = event.status;
            this.selInterview.panelMembers = event.panelMembers;
            this.selInterview.candidateNotes = event.candidateNotes;
            this.selInterview.interviewerNotes = event.interviewerNotes;
            this.selInterview.recPlanId = event.rpInterviewId;
            this.selInterview.interviewDate = this.$options.filters.formatDate(event.interviewDate);
            this.selInterview.interviewRequirement = event.interviewRequirement;
            if (new Date(this.selInterview.interviewDate + " " + this.selInterview.startTime) <= new Date()) {
                this.show_pencil = false;
            } else {
                this.show_pencil = true;
            }
            let self = this;
            $.each(this.selInterview.panelMembers, function(key, value) {
                self.panelMem.push(value.id);
            });
            //this.cantidate_edit();
            this.edit_mode = true;
            this.defaultStartTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.startTime).format('hh:mm A');
            this.defaultEndTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.endTime).format('hh:mm A');

            this.$refs.StartTimepickercomponent.setTime(this.defaultStartTime);
            this.$refs.EndTimepickercomponent.setTime(this.defaultEndTime);
        },
        mouseHoverEvent: function(info) {
            let showStatus = info.event.extendedProps.availability ? '' : '<p>Status : ' + this.getStatusName(info.event.extendedProps.status) + '</p>';
            $(info.el).tooltip({ title: '<h6>' + info.event.title + '</h6><p>' + this.$options.filters.formatCustomTime(info.event.start, "hh:mm A") + ' - ' + this.$options.filters.formatCustomTime(info.event.end, "hh:mm A") + '</p>' + showStatus + '</p>', placement: 'top', trigger: 'hover focus', container: 'body', delay: 0, html: true });
        },
        loadInterviewersList() {
            this.buttonTitle = "OK";
            this.showModal = true;
            this.isInviteInterview = false;
            //this.params.technologyName = this.candidateData.technologyName;
            this.params.availableDate = moment(this.selInterview.interviewDate, 'DD-MMM-YYYY').format('YYYY-MM-DD');
            this.params.startTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.startTime).format('HH:mm');
            this.params.endTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.endTime).format('HH:mm');
            this.params.candidateId = this.candidateId;
            this.selInterview.cloneStartTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.startTime).format('hh:mm A');
            this.selInterview.cloneEndTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.endTime).format('hh:mm A');

            return axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/list/all/interviewers', this.params).then(response => {
                    let result = response.data;
                    console.log(result)
                    if (result.status) {
                        this.recruiters_list = result.data;
                    } else {
                        let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.LoadingErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    }
                })
                .catch(error => {
                    this.unAuthorized(error);
                    let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.LoadingErr;
                    iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                });
        },
        loadEventById(id) {
            let self = this;
            return this.interviewsData.filter(function(idata, key) {
                if (idata.id == id) {
                    self.rescheduledInterview(idata);
                    return idata;
                }
            });
        },
        loadEventByInterviewId(id) {
            let self = this;
            return this.interviewsData.filter(function(idata, key) {
                if (idata.id == id) {
                    self.currentEvent = idata;
                    return idata;
                }
            });
        },
        addInterviewers(modalEvt) {
            if (this.buttonTitle == "OK") {
                this.selInterview.panelMembers = this.loadInterviewersById(this.panelMem);
                return this.selInterview.panelMembers;
            } else {
                this.submitted = true;
                this.$v.selInterview.noOfInterviewers.$touch();
                this.$v.panelMem.$touch();
                if (this.$v.selInterview.noOfInterviewers.$invalid || this.$v.panelMem.$invalid) {

                    modalEvt.preventDefault();
                    return;
                }
                this.selInterview.inviteMembers = this.loadInterviewersById(this.panelMem);
                console.log( this.selInterview);
                if (this.selInterview.panelMembers.length == this.selInterview.noOfInterviewers) {
                    this.selInterview.noOfInterviewers = null;
                }
                return this.selInterview.inviteMembers;
            }
            this.clearFilter();
        },
        loadInterviewersById(id = []) {
            return this.all_recruiters_list.filter(function(rdata, key) {
                if (id.includes(rdata.id)) {
                    return rdata;
                }
            });
        },
        saveCandidateAvailabilityEvent() {
            this.submitted = true;            
            if (!this.isInviteInterview) {                
                this.$v.selInterview.title.$touch();
                this.$v.selInterview.interviewDate.$touch();
                this.$v.selInterview.startTime.$touch();
                this.$v.selInterview.endTime.$touch();
                this.$v.selInterview.interviewType.$touch();
                this.$v.selInterview.status.$touch();
                this.$v.selInterview.panelMembers.$touch();
                if (this.$v.selInterview.title.$invalid || this.$v.selInterview.interviewDate.$invalid || this.$v.selInterview.startTime.$invalid || this.$v.selInterview.endTime.$invalid || this.$v.selInterview.interviewType.$invalid || this.$v.selInterview.status.$invalid || this.$v.selInterview.panelMembers.$invalid) {
                    return;
                }
            } else {
                
                this.$v.selInterview.title.$touch();
                this.$v.selInterview.interviewDate.$touch();
                this.$v.selInterview.startTime.$touch();
                this.$v.selInterview.endTime.$touch();
                this.$v.selInterview.interviewType.$touch();
                this.$v.selInterview.status.$touch();
                if (this.$v.selInterview.title.$invalid || this.$v.selInterview.interviewDate.$invalid || this.$v.selInterview.startTime.$invalid || this.$v.selInterview.endTime.$invalid || this.$v.selInterview.interviewType.$invalid || this.$v.selInterview.status.$invalid) {
                    return;
                }
            }
            let interviewStartDate =  moment(this.selInterview.interviewDate + " " + this.selInterview.startTime).format("YYYY-MM-DD HH:mm:ss") ;
          
             let interviewEndDate =  moment(this.selInterview.interviewDate + " " + this.selInterview.endTime).format("YYYY-MM-DD HH:mm:ss") ;

            let interviewDate =  moment(this.selInterview.interviewDate).format("YYYY-MM-DD") ;
            let availableFlag = false ;
            $.each(this.candidateData.candidateAvailability, function(key, value) {
                // if the iterviewdate is exists candidate availability loop 
                if(value.start.includes(interviewDate)) {
                    availableFlag = true ;
                    //console.log(interviewStartDate+"======"+interviewEndDate+"====="+value.start+"------"+value.end) ;
                      if( (interviewStartDate >= value.start && interviewStartDate <= value.end)  && 
                            (interviewEndDate >= value.start && interviewEndDate <= value.end)) {
                            //iterviewdate is candidate availability date and time
                            availableFlag = false ;
                        }
                        else {
                            $("#candidatesAvailableTime").html("Candidates Available Time : "+ value.start+" - "+ value.end) ;
                            $('#candidateAvailabilityModal').modal('show') ;
                        }
                }
            });
            if(availableFlag == false) {
                this.saveEvent() ;
            }
        },     
        saveEvent() {
            this.submitted = true;            
            // if (!this.isInviteInterview) {                
            //     this.$v.selInterview.title.$touch();
            //     this.$v.selInterview.interviewDate.$touch();
            //     this.$v.selInterview.startTime.$touch();
            //     this.$v.selInterview.endTime.$touch();
            //     this.$v.selInterview.interviewType.$touch();
            //     this.$v.selInterview.status.$touch();
            //     this.$v.selInterview.panelMembers.$touch();
            //     if (this.$v.selInterview.title.$invalid || this.$v.selInterview.interviewDate.$invalid || this.$v.selInterview.startTime.$invalid || this.$v.selInterview.endTime.$invalid || this.$v.selInterview.interviewType.$invalid || this.$v.selInterview.status.$invalid || this.$v.selInterview.panelMembers.$invalid) {
            //         return;
            //     }
            // } else {                
            //     this.$v.selInterview.title.$touch();
            //     this.$v.selInterview.interviewDate.$touch();
            //     this.$v.selInterview.startTime.$touch();
            //     this.$v.selInterview.endTime.$touch();
            //     this.$v.selInterview.interviewType.$touch();
            //     this.$v.selInterview.status.$touch();
            //     if (this.$v.selInterview.title.$invalid || this.$v.selInterview.interviewDate.$invalid || this.$v.selInterview.startTime.$invalid || this.$v.selInterview.endTime.$invalid || this.$v.selInterview.interviewType.$invalid || this.$v.selInterview.status.$invalid) {
            //         return;
            //     }
            // }
            this.prepareDataBeforeSubmit();
            console.log(this.event_params); 
            if (this.event_params.id) {
                axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/update/interview/' + this.event_params.id, this.event_params).then(response => {
                        if (response.data.status == "ACCEPTED") {
                            this.loadCandidateDetails();
                            this.loadAllPlannedInterviews();
                            this.edit_mode = false;
                            localStorage.removeItem('interviewId');
                            let successmsg = (response.data.message != undefined) ? response.data.message : this.$config.IntUpSuc;
                            iziToast.success({ title: 'Success', message: successmsg, position: 'topRight' });
                        } else {
                            let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.ProcessErr;
                            iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                        }
                    })
                    .catch(error => {
                        this.unAuthorized(error);
                        console.log(error);
                        this.errors.push(error);
                        let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.ProcessErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    });
            } else {

                axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/add/interview', this.event_params).then(response => {
                        if (response.data.status == "ACCEPTED") {
                            this.loadCandidateDetails();
                            this.loadAllPlannedInterviews();
                            this.event_params.id = response.data.id;
                            this.edit_mode = false;
                            this.show_pencil = false;
                            this.interviewPlan.interviewPlanFlag = false;
                            let successmsg = (response.data.message != undefined) ? response.data.message : this.$config.IntAddSuc;
                            iziToast.success({ title: 'Success', message: successmsg, position: 'topRight' });
                        } else {
                            let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.ProcessErr;
                            iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                        }
                    })
                    .catch(error => {
                        this.unAuthorized(error);
                        let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.ProcessErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    });
            }
            this.right_panel_mode = true;
        },
        closeEvent() {
            this.edit_mode = false;
            this.right_panel_mode = false;
            localStorage.removeItem('interviewId');
        },
        prepareDataBeforeSubmit() {

            let id = this.event_params.id;
            this.event_params = _.cloneDeep(this.selInterview);
            this.event_params.id = id ? id : this.selInterview.id;
            this.event_params.id = this.selInterview.id;
            this.event_params.interviewerId = [];
            this.event_params.inviteInterviewerId = [];
            this.event_params.noOfInterviewers = this.selInterview.noOfInterviewers;
            let self = this;
            $.each(this.selInterview.panelMembers, function(key, value) {
                self.event_params.interviewerId.push({ "interviewerId": value.id, "interviewerName": value.interviewerName, "interviewerEmail": value.email });;
            });
            $.each(this.selInterview.inviteMembers, function(key, value) {
                self.event_params.inviteInterviewerId.push({ "interviewerId": value.id, "interviewerName": value.interviewerName, "interviewerEmail": value.email });;
            });
            this.event_params.interviewStartTime = moment(this.selInterview.interviewDate + " " + this.selInterview.startTime).format('YYYY-MM-DD HH:mm:ss');
            this.event_params.interviewEndTime = moment(this.selInterview.interviewDate + " " + this.selInterview.endTime).format('YYYY-MM-DD HH:mm:ss');
            //this.event_params.interviewStartTime = this.event_params.interviewStartTime.replace(" ", "T");
            //this.event_params.interviewEndTime = this.event_params.interviewEndTime.replace(" ", "T");
            
            console.log(this.event_params.interviewStartTime, this.event_params.interviewEndTime);

            this.event_params.interviewStartTimeUtc = this.toClientTimeToUTC(this.event_params.interviewStartTime,this.datetimezoneformat);
            this.event_params.interviewEndTimeUtc = this.toClientTimeToUTC(this.event_params.interviewEndTime,this.datetimezoneformat);

            //this.event_params.interviewStartTimeUtc = this.event_params.interviewStartTimeUtc.replace(" ", "T");
            //this.event_params.interviewEndTimeUtc = this.event_params.interviewEndTimeUtc.replace(" ", "T");

            console.log(this.event_params.interviewStartTimeUtc, this.event_params.interviewEndTimeUtc)

            this.event_params.candidateId = this.candidateId;
            this.event_params.candidateName = this.candidateData.candidateName;
            this.event_params.candidateEmail = this.candidateData.email;
            this.event_params.candidateTimezone = this.candidateData.timeZone;
            this.event_params.interviewType = this.selInterview.interviewType;
            this.event_params.interviewDate = moment(this.event_params.interviewDate, 'DD-MMM-YYYY').format('YYYY-MM-DD');
            $.each(['startTime', 'endTime', 'panelMembers'], function(key, value) {
                self.$delete(self.event_params, value);
            });
            this.event_params.recruitmentId = this.recruitmentId;
            if (localStorage.getItem("interviewId") != null && localStorage.getItem("interviewId") > 0) {
                this.event_params.mode = 'rescheduling';
            }
            console.log(this.event_params);

        },
        filterInterviewers() {
            this.loadInterviewersList();
        },
        resetFilter() {
            this.params.name = "";
            this.params.technology = "";
            this.params.technologyName = "";  
            this.params.availability = "";
            this.loadInterviewersList();
        },
        clearFilter() {
            this.params.name = "";
            this.params.technology = "";
            this.params.availability = "";
        },
        getPrevUrl() {
            this.$router.go(-1);
        },
        viewCandidate() {
            this.$router.push('/candidates/view/' + this.candidateId);
        },
        checkAll() {
            this.isCheckAll = !this.isCheckAll;
            this.panelMem = [];
            let self = this;
            if (this.isCheckAll) {
                $.each(this.recruiters_list, function(key, value) {
                    self.panelMem.push(value.id);
                });
            }
        },
        popPanMem(index, id) {
            this.$delete(this.selInterview.panelMembers, index);
            this.$delete(this.panelMem, this.panelMem.indexOf(id));
        },
        getStatusName(id) {
            let status_name = "";
            $.each(this.status, function(key, value) {
                if (id == value.id)
                    status_name = value.name;
            });
            return status_name;
        },
        newInterview() {
            this.interviewPlan.isPlanInterview = false;
            this.refreshSelInterview();
            this.selInterview.status = 1;
        },
        inviteInterviewersList(modalEvt) {

            if (this.selInterview.startTime == undefined || this.selInterview.startTime == '' || this.selInterview.endTime == undefined || this.selInterview.endTime == '') {
                // alert("Please select start and end time");
                let errmsg = this.$config.PlsSelTime;
                iziToast.info({ title: 'Info', message: errmsg, position: 'topRight' });

                this.showModal = false;
            } else {
                this.isInviteInterview = true;
                this.showModal = true;
                this.buttonTitle = "Invite";
                this.params.availableDate = moment(this.selInterview.interviewDate, 'DD-MMM-YYYY').format('YYYY-MM-DD');
                this.params.startTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.startTime).format('HH:mm');
                this.params.endTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.endTime).format('HH:mm');
                this.selInterview.cloneStartTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.startTime).format('hh:mm A');
                this.selInterview.cloneEndTime = moment(this.formatDateString(this.selInterview.interviewDate) + " " + this.selInterview.endTime).format('hh:mm A');
                this.params.availability = 'yes';
                axios.post(this.$store.getters.getAPIBasePath + '/interviewerresource/list/all/interviewers', this.params).then(response => {
                        let result = response.data;
                        console.log(result)
                        if (result.status) {
                            this.recruiters_list = result.data;
                        } else {
                            let errmsg = (response.data.message != undefined) ? response.data.message : this.$config.LoadingErr;
                            iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                        }
                    })
                    .catch(error => {
                        this.unAuthorized(error);
                        let errmsg = (error.response.data.message != undefined) ? error.response.data.message : this.$config.LoadingErr;
                        iziToast.error({ title: 'Error', message: errmsg, position: 'topRight' });
                    });
            }
        },
        gettechsubstring(technology) {
            if (technology != null && technology.length > 10) {
                return technology.substring(0, 10) + "...";
            } else
                return technology;

        },

    }
}

</script>
