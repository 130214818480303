<template>
    <div id="wrapper">
        <header id="main-header" class="navbar navbar-expand-md navbar-dark fixed-top">
            <div class="container-fluid">
                <div class="fluid-box d-block w-100">
                    <div class="row">
                        <div class="col-sm-12">
                            <nav class="navbar-expand-lg navbar-light p-0">
                                <div class="row">
                                    <LogoBlock v-if="elements.showLogoBlock" />
                                    <MainMenu v-if="usertype=='recruiter'" />
                                    <InterviewerMenu v-if="usertype=='interviewer'" />
                                    <CandidateMenu v-if="usertype=='candidate'" />
                                    <AdminMenu v-if="usertype=='admin'" />
                                    <ReferralMenu v-if="usertype=='referral'" />
                                    <GuestMenu v-if="usertype=='guest'" />
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <slot v-if="configloaded" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import LogoBlock from '@/layouts/blocks/Logoblock';
import MainMenu from '@/layouts/blocks/MainMenu';
import InterviewerMenu from '@/layouts/blocks/InterviewerMenu';
import CandidateMenu from '@/layouts/blocks/CandidateMenu';
import AdminMenu from '@/layouts/blocks/AdminMenu';
import ReferralMenu from '@/layouts/blocks/ReferralMenu';
import GuestMenu from '@/layouts/blocks/GuestMenu';
export default {
    components: {
        LogoBlock,
        MainMenu,
        InterviewerMenu,
        CandidateMenu,
        AdminMenu,
        ReferralMenu,
        GuestMenu
    },
    data() {
        return {
            elements: {
                showLogoBlock: false
            },
            configloaded: this.$store.getters.isConfigLoaded,
            usertype: '',
            login: {
                authToken: ''
            }
        }
    },
    mounted() {
        let authtoken = this.$store.getters.getAuthToken;
        let userinfo = this.$store.getters.currentUser;
        if (authtoken) {
            if (jQuery.isEmptyObject(userinfo)) {
                this.login.authToken = authtoken;
                axios.post(this.$store.getters.getAPIBasePath + '/userresource/refresh', this.login)
                    .then(response => {
                        let result = response.data;
                        if (result.result) {
                            localStorage.setItem('candidateContactAccess', result.data.candidateContactAccess);
                            localStorage.setItem('salaryNegotiationAccess', result.data.salaryNegotiationAccess);
                            localStorage.setItem('offerLetterAccess', result.data.offerLetterAccess);
                            localStorage.setItem('vendorClientInfoAccess', result.data.vendorClientInfoAccess);
                            localStorage.setItem('currencyId', result.data.currencyId);
                            localStorage.setItem('workAuthorization', result.data.workAuthorization);
                            localStorage.setItem('countryId', result.data.countryId);
                            localStorage.setItem('companyLogo', result.data.companyLogo);
                            localStorage.setItem('accountType', result.data.accountType);
                            const defaultredirects = this.$store.getters.getDefaultRedirects;
                            this.$store.dispatch("setupLogin", result).then(() => {

                                if (this.$route.meta.accessedby != response.data.data.userType) {
                                    this.redirectBacktoLogin();
                                }

                                if (response.data.data.token != authtoken) {
                                    localStorage.setItem(this.$store.getters.getLocalStorageName, response.data.data.token);
                                    window.axios.defaults.headers.common['Authorization'] = response.data.data.token;
                                } else {
                                    window.axios.defaults.headers.common['Authorization'] = authtoken;
                                }
                                this.usertype = response.data.data.userType;
                                this.elements.showLogoBlock = true;
                                this.loadMasterInfo();
                                let data = {
                                    taskId: localStorage.getItem('taskId')
                                }
                                this.$store.commit('setNotificationList', data);
                            });
                        } else {
                            this.redirectBacktoLogin();
                        }
                    })
                    .catch(error => {
                        this.redirectBacktoLogin();
                        return false;
                        if (error.response.status === 401) {
                            this.redirectBacktoLogin();
                        } else {

                            iziToast.error({
                                title: 'Error Occured',
                                message: this.$config.TryAg,
                                position: 'topRight'
                            });
                        }
                    });

            } else {
                if (userinfo.token != authtoken) {
                    localStorage.setItem(this.$store.getters.getLocalStorageName, userinfo.token);
                    window.axios.defaults.headers.common['Authorization'] = userinfo.token;
                } else {
                    window.axios.defaults.headers.common['Authorization'] = authtoken;
                }
                this.usertype = userinfo.userType;
                this.elements.showLogoBlock = true;
                this.loadMasterInfo();
            }
        } else {
            this.redirectBacktoLogin();
        }
    },
    methods: {
        redirectBacktoLogin() {
            this.$store.dispatch("doLogout");
            this.$router.push('/');
        },
        loadMasterInfo() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/list/all')
                .then(response => {
                    if (response.data.status) {
                        // this.$store.commit('updateCountries', response.data.countrylist);
                        this.$store.commit('updateEduCountries', response.data.countrylist);
                        this.$store.commit('updateVisaOptions', response.data.visalist);
                        this.$store.commit('updateTechOptions', response.data.technologylist);
                        this.$store.commit('updateSalaryModes', response.data.paymentmodelist);
                        this.$store.commit('updateRecruiters', response.data.recruiterlist);
                        this.$store.commit('updateIndustries', response.data.industrylist);
                        this.$store.commit('updateShiftOptions', response.data.shiftlist);
                        this.$store.commit('updateClientContactType', response.data.clientcontactlist);
                        this.$store.commit('markAsConfigLoaded', 1);
                        this.$store.commit('setInterviewStatus', response.data.interviewStatus);
                        this.$store.commit('setInterviewType', response.data.interviewType);
                        this.$store.commit('updateTimeZones', response.data.timeZoneList);
                        this.$store.commit('updateLanguages', response.data.languagelist);
                        this.$store.commit('setClients', response.data.clientlist);
                        this.$store.commit('setWorkType', response.data.workTypeList);
                        this.$store.commit('setEmployeeType', response.data.empTypeList);
                        this.$store.commit('setJobStatus', response.data.jobStatus);
                        this.$store.commit('setShortlistedCandidateStatus', response.data.shortlistedCandidateStatus);
                        this.$store.commit('setPayFrequencyList', response.data.payFrequency);
                        this.$store.commit('setInterviewerScopeList', response.data.interviewerAccessLevel);
                        this.$store.commit('setCandidateScopeList', response.data.candidateAccessLevel);
                        this.$store.commit('setJobScopeList', response.data.jobAccessLevel);
                        this.$store.commit('setAccountTypeOptions', response.data.accountType);
                        this.$store.commit('setJobExpTypeOptions', response.data.jobExpType);
                        this.$store.commit('setTemplateTypesList', response.data.templateType);
                        this.$store.commit('setSocialMediaTemplateTypesList', response.data.socialMediaTemplateType);
                        this.$store.commit('setEmailTemplateScope', response.data.emailTemplateScope);
                        this.$store.commit('setWeblinks', response.data.webLink);
                        this.$store.commit('setTagNames', response.data.tagNames);
                        this.$store.commit('setSourcingChannel', response.data.sourcingChannel);
                        this.$store.commit('setRecruiterList', response.data.recruitermanagerlist);
                        this.$store.commit('setCandidatesStatusList', response.data.candidateStatus);
                        this.$store.commit('setCandStatusReport', response.data.candStatusReport);
                        this.$store.commit('setProjectStatusList', response.data.projectStatusList);
                        this.$store.commit('setTaskGroupStatusList', response.data.taskGroupStatusList);
                        this.$store.commit('setRecruiterByCompany', response.data.recruiterByCompany);
                        this.$store.commit('setQualificationData', response.data.settingsQualification);
                        this.$store.commit('setJobDocumentData', response.data.settingsJobDocument);
                        this.$store.commit('setWorkAuthorizationData', response.data.settingsWorkAuthorization);
                        this.$store.commit('setCandidateTypeList', response.data.candidateTypeList);
                        this.$store.commit('setRelocationStatus', response.data.candidateRelocation);
                        this.$store.commit('setTtClients', response.data.ttClients);
                        this.$store.commit('setCandContactAccess', response.data.candContactAccess);
                        this.$store.commit('updateUsaIndCountries', response.data.country_us_in);
                        this.$store.commit('setClientCompanyList', response.data.clientCompanyList);
                        this.$store.commit('setCurrencyList', response.data.currencies);
                        axios.get(this.$store.getters.getAPIBasePath + '/commonresource/statelist?countryId=us')
                            .then(response => {
                                this.$store.commit('updateStatesList', response.data.statelist);
                                this.configloaded = this.$store.getters.isConfigLoaded;
                            })
                            .catch(error => {
                                this.$store.commit('updateStatesList', []);
                            });
                    } else {
                        iziToast.error({
                            title: '',
                            message: this.$config.PageLoadErr,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: '',
                        message: this.$config.PageLoadErr,
                        position: 'topRight'
                    });
                });

            axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/namevalue/list')
                .then(response => {
                    this.$store.commit('setNameValueList', response.data.nameValueList);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
