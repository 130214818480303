<template>
    <div :class="{ viewonly: contactinfo.viewonly == 1 }">
        <div class="card">
            <div class="card-header p-2">Current Address</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold"
                                        >Address Line 1</label
                                    >
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label>{{
                                            contactinfo.currentaddress
                                                .addressLine1
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold"
                                        >Address Line 2</label
                                    >
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label>{{
                                            contactinfo.currentaddress
                                                .addressLine2
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold">City</label>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label>{{
                                            contactinfo.currentaddress.cityName
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold"
                                        >State/Province</label
                                    >
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label>{{
                                            contactinfo.currentaddress.stateName
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold"
                                        >Zip Code</label
                                    >
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label>{{
                                            contactinfo.currentaddress.zipCode
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold"
                                        >Country</label
                                    >
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label
                                            v-if="
                                                contactinfo.currentaddress
                                                    .country != null
                                            "
                                            >{{
                                                contactinfo.currentaddress
                                                    .country.name
                                            }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Permanent Address</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold"
                                        >Address Line 1</label
                                    >
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label>{{
                                            contactinfo.permanentaddress
                                                .addressLine1
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold"
                                        >Address Line 2</label
                                    >
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label>{{
                                            contactinfo.permanentaddress
                                                .addressLine2
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold">City</label>
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label>{{
                                            contactinfo.permanentaddress
                                                .cityName
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold"
                                        >State/Province</label
                                    >
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <label>{{
                                        contactinfo.permanentaddress.stateName
                                    }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold"
                                        >Zip Code</label
                                    >
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <div class="input-group">
                                        <label>{{
                                            contactinfo.permanentaddress.zipCode
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12 lb">
                                    <label class="font-weight-bold"
                                        >Country</label
                                    >
                                </div>
                                <div class="col-md-6 col-sm-12 col-12">
                                    <label
                                        v-if="
                                            contactinfo.permanentaddress
                                                .country != null
                                        "
                                        >{{
                                            contactinfo.permanentaddress.country
                                                .name
                                        }}</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header p-2">Emergency Contact</div>
            <div class="card-body p-2">
                <div class="row ten-columns">
                    <div class="col-sm-12">
                        <div class="table-responsive">
                            <table class="table table-borderless all-table">
                                <thead>
                                    <tr>
                                        <th class="" scope="col">Name</th>
                                        <th class="" scope="col">Phone</th>
                                        <th class="" scope="col">
                                            Relationship
                                        </th>
                                        <th class="" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody class="cloned_tr">
                                    <tr
                                        v-for="(
                                            info, index
                                        ) in contactinfo.emergency"
                                    >
                                        <td>
                                            <label>{{
                                                contactinfo.emergency[index]
                                                    .name
                                            }}</label>
                                        </td>
                                        <td>
                                            <label>{{
                                                contactinfo.emergency[index]
                                                    .phone
                                            }}</label>
                                        </td>
                                        <td>
                                            <label>{{
                                                contactinfo.emergency[index]
                                                    .relation
                                            }}</label>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr
                                        :class="{
                                            norecordfound:
                                                contactinfo.emergency.length,
                                        }"
                                    >
                                        <td colspan="4" align="center">
                                            No item found
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        contactinfo: Object,
    },
};
</script>
