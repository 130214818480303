<template>
    <div>
        <div class="card">
            <div class="card-header p-2"> Physical Address</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address line 1</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input maxlength="100" name="physicalAddress" v-model="addressinfo.physicaladdress.addressLine1" class="fldboxaddress form-control"></input>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Phone Number</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <PhoneNumber ref="phoneNumber" :value="addressinfo.physicaladdress.phoneNumber" :isValidcheck="isValidCheck" @phone-input="phoneControlInput"  />
                                    <div v-if="false" class="input-group">
                                        <select class="form-control box-none col-sm-3 pl-0 pr-0 max-w-45 border-bottom-0" v-model="addressinfo.physicaladdress.countryCode">
                                            <option value="+1">+1</option>
                                            <option value="+91">+91</option>
                                        </select>
                                        <input-mask class="form-control box-none" v-model="addressinfo.physicaladdress.phoneNo" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address line 2</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input name="physicalAddMapURL" maxlength="100" v-model="addressinfo.physicaladdress.addressLine2" class="fldboxaddress form-control"></input>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Fax Number</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="faxNumber" v-model="addressinfo.physicaladdress.faxNumber" @input="handleNumericInput" maxlength="15" value="" class="Submitaddress form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                         <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>City</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input 
                                            type="text" 
                                            name="physicalAddCity" 
                                            v-model="addressinfo.physicaladdress.city" 
                                            maxlength="25" 
                                            class="Submitaddress form-control"
                                            @input="addressinfo.physicaladdress.city = addressinfo.physicaladdress.city.replace(/[^A-Za-z\s]/g, '')"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>


                                    <!-- Country Selection -->
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select v-model="addressinfo.physicaladdress.countryId" 
                                                @change="loadStates()" 
                                                class="form-control">
                                            <option value="">Select Country</option>
                                            <option v-for="(data, index) in countrieslist" 
                                                    :key="index" 
                                                    :value="data.country_Id">
                                                {{ data.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- State Selection -->
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>State</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <!-- Dropdown if states exist -->
                                        <select v-if="statesList.length > 0" v-model="addressinfo.physicaladdress.stateName" class="form-control">
                                            <option value="">Select State</option>
                                            <option v-for="(state, index) in statesList" :key="index" :value="state">
                                                {{ state }}
                                            </option>
                                        </select>
                                        <!-- Input field if no states exist -->
                                        <input v-else v-model="addressinfo.physicaladdress.stateName" placeholder="select state" type="text" maxlength="25" minlength="2" size="23" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Zip Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group required-field-block">
                                        <input type="text" 
                                            name="physicalAddZip" 
                                            v-model="addressinfo.physicaladdress.zipCode" 
                                            id="physicalAddZip" 
                                            maxlength="10" 
                                            class="Submitaddress form-control"
                                            @input="validateZipCode">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">
                Billing Address
                <div class="form-check form-check-inline" style="padding-left: 25px;">
                    <input type="checkbox" name="billType" v-model="addressinfo.physicaladdress.sameAsAbove" @change="copyAddress" value="on" id="billType" class="form-check-input">
                    <label class="form-check-label" for="billType">Same as Physical Address</label>
                </div>
            </div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address line 1</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group ">
                                        <input name="billingAddress" maxlength="100" v-model="addressinfo.billingaddress.addressLine1" class="fldboxaddress form-control"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address line 2</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input name="billingAddMapURL" maxlength="100" v-model="addressinfo.billingaddress.addressLine2" class="fldboxaddress form-control"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>City</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" name="billingAddCity"
                                            v-model="addressinfo.billingaddress.city"
                                            maxlength="25"
                                            class="Submitaddress form-control"
                                            @input="addressinfo.billingaddress.city = addressinfo.billingaddress.city.replace(/[^A-Za-z\s]/g, '')">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>State</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select v-if="addressinfo.billingaddress.countryId=='US'" v-model="addressinfo.billingaddress.stateName" class="form-control">
                                            <option value="">--</option>
                                            <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                        </select>
                                        <input v-if="addressinfo.billingaddress.countryId!='US'" v-model="addressinfo.billingaddress.stateName" type="text" maxlength="25" minlength="2" size="23" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div> -->
              <!-- Country Selection -->
<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-12 lb">
            <label>Country</label>
        </div>
        <div class="col-md-12 col-sm-12 col-12">
            <div class="input-group">
                <select v-model="addressinfo.billingaddress.countryId" 
                        @change="loadBillingStates()" 
                        class="form-control">
                    <option value="">Select Country</option>
                    <option v-for="(data, index) in countrieslist" 
                            :key="index" 
                            :value="data.country_Id"
                            >
                        {{ data.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>

<!-- State Selection -->
<div class="col-md-2 col-sm-6 col-12 mb-10-mr">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-12 lb">
            <label>State</label>
        </div>
        <div class="col-md-12 col-sm-12 col-12">
            <div class="input-group">
                <!-- Dropdown if states exist -->
                <select v-if="billingStatesList.length > 0" v-model="addressinfo.billingaddress.stateName" class="form-control">
                    <option value="">Select State</option>
                    <option v-for="(state, index) in billingStatesList" :key="index" :value="state">
                        {{ state }}
                    </option>
                </select>
                <!-- Input field if no states exist -->
                <input v-else v-model="addressinfo.billingaddress.stateName" placeholder="select state" type="text" maxlength="25" minlength="2" size="23" class="form-control">
            </div>
        </div>
    </div>
</div>

              
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Zip Code</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input type="text" 
                                            name="billingAddZip" 
                                            v-model="addressinfo.billingaddress.zipCode" 
                                            maxlength="10" 
                                            class="Submitaddress form-control"
                                            @input="validateBillingZipCode">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select v-model="addressinfo.billingaddress.countryId" @change="addressinfo.billingaddress.stateName=''" class="form-control">
                                            <option value="">--</option>
                                            <option v-for="(data, index) in countrieslist" :value="data.country_Id">{{data.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        addressinfo: Object
    },
    data() {
        return {
            addressinfo: {
                billingaddress: {
                    countryId: "", // Default to empty to show "Select Country"
                    stateName: ""
                }
            },
            countrieslist: [
                { country_Id: "US", name: "United States" },
                { country_Id: "IN", name: "India" },
                { country_Id: "CA", name: "Canada" }
            ],
            billingStatesList: [], // Stores states dynamically based on country selection
            allStates: {
    US: [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware",
        "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas",
        "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
        "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York",
        "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
        "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia",
        "Wisconsin", "Wyoming"
    ],
    IN: [
        "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana",
        "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya",
        "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana",
        "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh",
        "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", "Delhi", "Puducherry", "Ladakh", "Jammu and Kashmir"
    ],
    CA: [
        "Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Northwest Territories",
        "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon"
    ]
}
,

            addressinfo: {
                physicaladdress: {
                    countryId: "", // Default to empty to show "Select Country"
                    stateName: ""
                }
            },
            countrieslist: [
                { country_Id: "US", name: "United States" },
                { country_Id: "IN", name: "India" },
                { country_Id: "CA", name: "Canada" }
            ],
            statesList: [], // Stores states dynamically based on country selection
            allStates: {
    US: [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware",
        "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas",
        "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
        "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York",
        "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
        "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia",
        "Wisconsin", "Wyoming"
    ],
    IN: [
        "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana",
        "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya",
        "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana",
        "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh",
        "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", "Delhi", "Puducherry", "Ladakh", "Jammu and Kashmir"
    ],
    CA: [
        "Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Northwest Territories",
        "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon"
    ]
},

            

        }
    },

    methods: {
        loadBillingStates() {
            // Load states dynamically based on selected country
            this.billingStatesList = this.allStates[this.addressinfo.billingaddress.countryId] || [];
            this.addressinfo.billingaddress.stateName = ""; // Reset state when country changes
        },
        loadStates() {
            // Load states dynamically based on selected country
            this.statesList = this.allStates[this.addressinfo.physicaladdress.countryId] || [];
            this.addressinfo.physicaladdress.stateName = ""; // Reset state when country changes
        },
        validateBillingZipCode(event) {
        this.addressinfo.billingaddress.zipCode = event.target.value.replace(/\D/g, ''); // Removes all non-numeric characters
    },
        
        validateZipCode(event) {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        this.addressinfo.physicaladdress.zipCode = event.target.value;
    },
        copyAddress(){
            if (this.addressinfo.physicaladdress.sameAsAbove) {
                this.addressinfo.billingaddress.addressLine1 = this.addressinfo.physicaladdress.addressLine1;
                this.addressinfo.billingaddress.addressLine2 = this.addressinfo.physicaladdress.addressLine2;
                this.addressinfo.billingaddress.city = this.addressinfo.physicaladdress.city;
                this.addressinfo.billingaddress.stateName = this.addressinfo.physicaladdress.stateName;
                this.addressinfo.billingaddress.zipCode  = this.addressinfo.physicaladdress.zipCode;
                this.addressinfo.billingaddress.countryId = this.addressinfo.physicaladdress.countryId;
            } else {
                this.addressinfo.billingaddress.addressLine1 = "";
                this.addressinfo.billingaddress.addressLine2 = "";
                this.addressinfo.billingaddress.city = "";
                this.addressinfo.billingaddress.stateName = "";
                this.addressinfo.billingaddress.zipCode = "";
                this.addressinfo.billingaddress.countryId = "US";

            }
        },
        phoneControlInput: function (value,isValid) {
             this.addressinfo.physicaladdress.phoneNumber = value;
             this.isValidCheck = isValid;
        },
        validatePhoneNo:function(){
            return this.isValidCheck;
        },
        handleNumericInput() {
         this.addressinfo.physicaladdress.faxNumber = this.addressinfo.physicaladdress.faxNumber.replace(/\D/g, '');
        },
    }
}

</script>
