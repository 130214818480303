<template>
  <div class="card email-tools">
    <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" justify="center" class="h-100">
      <div class="card-header">
        <h5>
          <span>Offer Letter</span>
          <span @click="$emit('showOfferLetter')" class="ti-close text-blue pull-right hover-hand"></span>
        </h5>
      </div>
      <div class="row col-md-12 py-1 mx-0">
        <div class="col-md-1 px-0">
          <h6 class="text-center py-3" style="background-color: #effbfc;">Template</h6>
        </div>
        <div class="col-md-3 p-2">
          <multiselect v-model="template" :searchable="false" :allow-empty="false" :options="options.templatesList" label="templateName" track-by="id" placeholder="Select Existing template" select-label="" deselect-label="" @select="loadTemplateContent"></multiselect>
        </div>
        <div class="col-md-7 d-flex align-items-center justify-content-center px-0">
          <div style="background-color: #eff6fc;" class="col-md-3 py-2"><h6 class="">Joining Date</h6></div>
          <div class="col-md-5">
            <div>
              <div class="input-group ">
                <datepicker :disabledDates="disabledendDates" placeholder="Joining Date" name="startDate" value="" id="startDate" input-class="form-control enablefield" wrapper-class="input-group date" format="MM/dd/yyyy" v-model="joiningDate"></datepicker>
                <span class="calendar-addon"><span class="ti-calendar"></span></span>
              </div>
              <div v-if="message">
                <span class="text-danger">{{message}}</span>
              </div>
            </div>
          </div>  
          <div><button :disabled="disableOffer" data-toggle="modal" data-target="#counterOffers" class="btn btn-primary">Add Counter Offers</button></div>
        </div>  
      </div>
      <div class="row col-md-12 py-1 mx-0"> 
        <div class="col-md-4 d-flex  px-0">
          <div style="background-color: #eff6fc;" class="col-md-3 py-2"><h6 class="">Salary</h6></div>
            <div class="col-md-8 p-0 ml-7">
              <div class="input-group extended">
                <select class="form-control" v-model="currency" disabled>
                  <option v-for="(info, index) in currencies" :value="info.symbol">{{info.symbol}}</option>
                </select>
                <input type="number" class="form-control" maxlength="25" v-model="salary" :disabled="disableSalary">
                <div class="input-group-append"> 
                  <select  v-model="payFrequency" class="form-control p-0" :disabled="disableSalary">
                      <option value="Per Month">Per Month</option>
                      <option value="Per Year">Per Year</option>
                      <option value="Hourly">Hourly</option>
                  </select>
                </div>
              </div>
          </div>
        </div> 
      </div>
        <div v-if="!$v.jobSalary" class="col-md-12 jc-space">
          <span class="text-danger">* There is no salary mentioned in the Job. kindly fill the correct salary details</span>
        </div>           
        <quill-editor v-model="offerLetter.content" :options="editorOptions"></quill-editor> <!--Added quill editor on 2025-03-06 by Akash-->
      <div class="row col-md-12 mx-0">
        <div class="icons col-md-1 offset-md-11 pull-right">
          <button @click="handleSend" class="btn btn-primary">Send</button>
        </div>
      </div>
      <template #overlay>
          <div>
            <img alt="images" class="loader-img-height"  src="/images/loading.gif"> 
          </div>                   
      </template> 
      </b-overlay>
      <div class="modal" id="counterOffers" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog" style="width:34%" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">Counter Offers</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                </div>
                <div class="modal-body">
                  <table class="table table-borderless all-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Counter Offers</th>
                        <th>Old Value</th>
                        <th>New Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(Offer, index) in offerData" :key="index">
                        <td>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" :id="Offer.id" :value="Offer.id" v-model="checkedOffers" >
                              <label class="form-check-label" :for="Offer.id"></label>
                            </div>
                        </td>
                        <td>
                          <label >{{ Offer.name }}</label>
                        </td>
                        <td>
                          <input type="text" :id="'select-' + index" maxlength="45" size="3" v-model="Offer.value" class="form-control" disabled>
                        </td>
                        <td>
                          <input type="text"  :id="'select-' + index" maxlength="45" size="3" v-model="Offer.newValue" class="form-control">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="modal-footer">                                            
                  <button type="submit" @click="setValue()" class="btn btn-primary">Ok</button>
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </div>
          </div>
        </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import Vue from 'vue';
import Quill from 'quill';
import VueQuillEditor from 'vue-quill-editor';
Vue.use(VueQuillEditor);  // Added imports on 2025-03-06 by Akash
import TinymceEditor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
import jsPDF from 'jspdf';
import moment from 'moment';
import { required } from "vuelidate/lib/validators";
export default {
  name: 'offerLetter',
  props: {  
    setdata: Object,
    candCounterOffer: Array,
    jobCandInfo: Array,
    jobInfo: {
            type: Object,
            default: () => {}
        }
  },
  components: {
    TinymceEditor,
    Multiselect
  },
  data() {
    let self = this;
    return {
      editorOptions: {      //quill editor Added  on 2025-03-06 by Akash
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            ['clean'],
          ],
        },
      },
      offerLetter: {
        content: '',
      }, // quill Added on 2025-03-06 by Akash
      disableSalary: false,
      disableOffer: false,
      newOfferValue: [],
      newOffers: [],
      currencies: this.$store.getters.getCurrencies,
      currency: '',
      salary: '',
      payFrequency: '',
      Salary: '',
      payFreq: '',
      selectedOffers: [],
      checkedOffers: [],
      offerData: [],
      counterData: [],
      showOverlay:false,
      offerLetter: {
        content: ''
      },
      companyName:this.$store.getters.currentUser.companyName,
      toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | tagNames',
      tagNames: [
					{
						"id": "##NAME##",
						"name": "Name"
					},
					{
						"id": "##COMPANYNAME##",
						"name": "Company Name"
					},
					{
						"id": "##JOBTITLE##",
						"name": "Job Title"
					},
					{
						"id": "##JOININGDATE##",
						"name": "Joinining Date"
					},
          {
						"id": "##SALARY##",
						"name": "Salary"
					},
          {
						"id": "##BENEFITS##",
						"name": "Benefits"
					},
			],
      initObj: {
					height: 400,
					branding: false,
					plugins: ['link','paste', 'wordcount', 'preview', 'image imagetools'],
					paste_data_images: true,
					automatic_uploads: false,
					file_picker_types: 'image',
					relative_urls : false,
					remove_script_host : false,
          setup: function(editor) {
                    editor.ui.registry.addMenuButton('tagNames', {
                        text: "Tag Names",
                        icon: "link",
                        fetch: function(callback) {
                        var items = [];
                        self.tagNames.map(function(value, key) {
                            items.push({
                                type: 'menuitem',
                                text: value.name,
                                onAction: function() {
                                    editor.insertContent('<span>' + value.id + '</span>');
                                }
                            })
                        });
                        callback(items);
                        }
                        });
          		}
			},
      options: { 
        templatesList: [],
      },
      template: null,
      joiningDate: '',
      newJoiningDate: '',
      message: '',
      newList: '',
      disabledendDates:{
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
      }
    }
  },
  watch: {
        'showOverlay': {
            handler: function(value) { 
                if(value == true){
                    $('#globalapploader').css('visibility','hidden');
                }
            },
            deep: true,
            immediate: true
        },
        'joiningDate': {
          handler: function(value) { 
            if(value){
              this.message = '';
              const joiningDate = moment(value).format('DD/MM/YYYY');
              if(this.offerLetter.content.includes('##JOININGDATE##')){
              this.offerLetter.content = this.offerLetter.content.replace(/##JOININGDATE##/gi, joiningDate);
              this.newJoiningDate = joiningDate;
              }else{
                this.offerLetter.content = this.offerLetter.content.replace(this.newJoiningDate, joiningDate);
                this.newJoiningDate = joiningDate;
              }
            }
          },
          deep: true,
          immediate: true
        },
        'selectedOffers': {
          handler: function(value) {
            if (value) {
              const selectedNames = value.map((offer) => offer);
              const listItems = selectedNames.map((offer) => `<li>${offer.name} - ${offer.recruiterValue}</li>`);
              let list = "";
              if (listItems.length > 0) {
                list = `<h4>Additional Benefits</h4><ul>${listItems.join("")}</ul>`;
              }
              if(this.offerLetter.content.includes('##BENEFITS##')){
                this.offerLetter.content = this.offerLetter.content.replace(/##BENEFITS##/gi, list);
                this.newList = list;
              }else{
                this.offerLetter.content = this.offerLetter.content.replace(this.newList, list); 
                this.newList = list;
              }
            }
          },
          deep: true,
          immediate: true
        },
        'salary': {
          handler: function(value) { 
            if(value){
              this.offerLetter.content = this.offerLetter.content.replace(this.Salary, value );
              this.Salary = value;
            }
          },
          deep: true,
          immediate: true
        },
        'payFrequency': {
          handler: function(value) { 
            if(value){
              this.offerLetter.content = this.offerLetter.content.replace(this.payFreq, value );
              this.payFreq = value;
            }
          },
          deep: true,
          immediate: true
        },
  },
  validations: {
    joiningDate: {
       required
    }, 
    jobSalary:function() {
      if ((this.jobInfo.salary || this.jobInfo.minSalary) != null && (this.jobInfo.salary || this.jobInfo.minSalary) != undefined && (this.jobInfo.salary || this.jobInfo.minSalary) != ""){
        return true;        
      } 
      else{
        return false;
      }
      },
  },
  computed: {
    finalOffers() {
      return this.offerData.filter(offer => this.checkedOffers.includes(offer.id));
    }
  },
  mounted() {
    this.listCounterOffers();
    this.getOfferLetterTemplatesList();
    this.loadTemplateByType(); 
  },
  methods: {
    checkMatchingIds() {
      this.offerData.forEach(offer => {
        if (this.newOffers.some(newOffer => newOffer.id === offer.id)) {
          this.checkedOffers.push(offer.id);
        }
      });
    },
    matchingValue() {
      this.offerData.forEach(offer => {
      const matchingOffer = this.newOfferValue.find(newOffer => newOffer.id === offer.id);
      if (matchingOffer) {
        offer.newValue = matchingOffer.recruiterValue;
      }
    });
    },
    listCounterOffers() {
      axios.post(this.$store.getters.getAPIBasePath + '/settingsresource/conteroffer/list')
        .then(response => {
            if (response.data) {
              this.offerData = response.data.recordinfo;
            }
        })
        .catch(error => {
          console.log(error);
        });
    },
    setValue(){
      const templateOfferList = this.finalOffers.map((Offer) => {
        return {
          name: Offer.name,
          recruiterValue:((Offer.newValue=="" || Offer.newValue==undefined) ? Offer.value:Offer.newValue)
        };
      });
      this.selectedOffers = templateOfferList;
      const selectedOfferNames = this.finalOffers.map((Offer) => {
        return {
          counterOfferId: Offer.id,
          recruiterValue:((Offer.newValue=="" || Offer.newValue==undefined) ? Offer.value:Offer.newValue),
          candidateId: this.setdata.candidateId
        };
      });
      this.counterData = selectedOfferNames;
      $('#counterOffers').modal('hide');
      },
    closeModal(bvModalEvt) {
      this.$bvModal.hide(offerLetr);
    },
    getOfferLetterTemplatesList: function(paginationParams) {
      this.showOverlay=true;
      axios.post(this.$store.getters.getAPIBasePath + '/templateresource/list/template/by/type', { "templateType": "11"})
          .then(response => {
              this.showOverlay=false;
              let result = response.data;
              if (!response.status) {
                  iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
              }
              this.options.templatesList = result.data;
          })
          .catch(error => {
              this.showOverlay=false;
              iziToast.error({ title: 'Error', message: this.$config.LoadTempListErr, position: 'topRight' });
          });
    },
    loadTemplateByType() {
      this.showOverlay=true;
      axios.post(this.$store.getters.getAPIBasePath + '/templateresource/view/template/by/type', { "templateType": 11 })
          .then(response => {
              this.showOverlay=false;
              let template = response.data;
              this.offerLetter.content = template.content;
              if (this.setdata != null) {
                  this.offerLetter.content = this.offerLetter.content.replace(/##JOBPOSITION##/gi, this.jobInfo.jobTitle);
                  this.offerLetter.content = this.offerLetter.content.replace(/##NAME##/gi, this.setdata.candidateName);
                  this.offerLetter.content = this.offerLetter.content.replace(/##COMPANYNAME##/gi, this.companyName);
              }
              if((this.jobInfo.salary || this.jobInfo.minSalary ) && (this.setdata.offerSent == '0' || this.setdata.offerSent == null)){
                this.Salary = (this.setdata.offerAmount!= null ? this.setdata.offerAmount : (this.jobInfo.salary? this.jobInfo.salary : this.jobInfo.minSalary));
                this.payFreq = this.jobInfo.payFrequency;
                this.salary = (this.setdata.offerAmount!= null ? this.setdata.offerAmount : (this.jobInfo.salary?this.jobInfo.salary : this.jobInfo.minSalary));
                this.currency = this.jobInfo.currency;
                this.payFrequency = this.jobInfo.payFrequency;
                this.offerLetter.content = this.offerLetter.content.replace(/##SALARY##/gi, this.jobInfo.currency + " " + this.Salary + " " + this.payFreq);
              }
              if(this.setdata.offerAmount!= null || this.jobInfo.salary != null || this.jobInfo.minSalary != null){
                this.disableSalary = true;
              }
              if(this.jobCandInfo!="" && this.jobCandInfo!=undefined && this.setdata.offerSent == '1'){
                const amount = this.jobCandInfo.salary.split(" ");
                var newSalary = amount[1]; 
                var newPayFreq = amount[2] + " " + amount[3]
                this.offerLetter.content = this.offerLetter.content.replace(/##SALARY##/gi, this.jobInfo.currency + " " + newSalary + " " + newPayFreq);
                this.Salary = newSalary;
                this.salary = newSalary;
                this.payFreq = newPayFreq;
                this.payFrequency = newPayFreq;
                this.currency = this.jobInfo.currency;
                this.offerLetter.content = this.offerLetter.content.replace(/##JOININGDATE##/gi, this.jobCandInfo.joiningDate);
                this.newJoiningDate = this.jobCandInfo.joiningDate;
                const dateString = this.jobCandInfo.joiningDate;
                const date = new Date(dateString);
                const options = {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  timeZoneName: 'long'
                };
                const formattedDate = date.toLocaleString('en-US', options);
                const moment = require('moment');
                const dateStrings = formattedDate;
                const dates = moment(dateStrings, 'ddd, MMM DD, YYYY, h:mm:ss A [India Standard Time]');
                const formattedDates = dates.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (India Standard Time)');
                this.joiningDate = formattedDates;
              }
              if(this.candCounterOffer!="" && this.candCounterOffer!=undefined && (this.setdata.offerSent != '0' && this.setdata.offerSent != null)){
                var newOffer = this.candCounterOffer.map((Offer) => {
                return {
                  rpCandShortlistId: Offer.rpCandShortlistId,
                  counterOfferId: Offer.counterOfferId,
                  candidateId: Offer.candidateId,
            		  recruiterValue: Offer.recruiterValue,
                  candidateValue: Offer.candidateValue,
                };
              });
              this.counterData = newOffer;
              this.disableOffer = true;
              var newOfferValue = this.candCounterOffer.map((Offer) => {
                return {
                  id: Offer.settingsCounterOffer.id,
                  recruiterValue: Offer.recruiterValue
                };
              });
              this.newOfferValue =newOfferValue;
              this.matchingValue();

              var newOffers = this.candCounterOffer.map((Offer) => {
                return {
                  id: Offer.settingsCounterOffer.id
                };
              });
              this.newOffers =newOffers;
              this.checkMatchingIds();
              
              const selectedNames = this.candCounterOffer.map((offer) => offer);
              const listItems = selectedNames.map((offer) => `<li>${offer.settingsCounterOffer.name} - ${offer.recruiterValue}</li>`);
              let list = "";
              if (listItems.length > 0) {
                list = `<h4>Additional Benefits</h4><ul>${listItems.join("")}</ul>`;
              }
              if(this.offerLetter.content.includes('##BENEFITS##')){
                this.offerLetter.content = this.offerLetter.content.replace(/##BENEFITS##/gi, list);
                this.newList = list;
              }else{
                this.offerLetter.content = this.offerLetter.content.replace(this.newList, list); 
                this.newList = list;
              }
            }
            if (!template.status && template.message) {
                iziToast.error({ title: 'Error', message: template.message, position: 'topRight' });
                }
            })
            .catch(error => {
              this.showOverlay=false;
              /*iziToast.error({ title: 'Error', message: 'Unable to load templatessssss. Please try again', position: 'topRight' });*/
            });
    },
    loadTemplateContent: function(template) {
      this.showOverlay=true;
      axios.post(this.$store.getters.getAPIBasePath + '/templateresource/view/template', { "id": template.id })
          .then(response => {
              this.showOverlay=false;
              let result = response.data;
              this.offerLetter.content = result.emailTemplate.content;
              if(this.offerLetter.content != null ){ 
               // this.offerLetter.content = this.offerLetter.content.replace(/##COMPANYNAME##/gi, this.companyName);               
                  if (this.setdata != null) {
                    this.offerLetter.content = this.offerLetter.content.replace(/##JOBPOSITION##/gi, this.jobInfo.jobTitle);
                    this.offerLetter.content = this.offerLetter.content.replace(/##NAME##/gi, this.setdata.candidateName);
                    this.offerLetter.content = this.offerLetter.content.replace(/##COMPANYNAME##/gi, this.companyName);
                  }
                  if(this.jobInfo.salary || this.jobInfo.minSalary){
                    this.Salary = (this.setdata.offerAmount!= null ? this.setdata.offerAmount : (this.jobInfo.salary? this.jobInfo.salary : this.jobInfo.minSalary));
                    this.payFreq = this.jobInfo.payFrequency;
                    this.salary = (this.setdata.offerAmount!= null ? this.setdata.offerAmount : (this.jobInfo.salary? this.jobInfo.salary : this.jobInfo.minSalary));
                    this.currency = this.jobInfo.currency;
                    this.payFrequency = this.jobInfo.payFrequency;
                    this.offerLetter.content = this.offerLetter.content.replace(/##SALARY##/gi, this.jobInfo.currency + " " + this.Salary + " " + this.payFreq);
                  }
              }
              if (!result.status) {
                iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
              }
          })
          .catch(error => {
              this.showOverlay=false;
              iziToast.error({ title: 'Error', message: this.$config.TempLoadErr, position: 'topRight' });
          });
    },
    handleSend() {
      if (!this.joiningDate) {
        this.message = "*Joining Date is required"
        return;
      }
      if(!this.$v.joiningDate.$invalid && this.$v.jobSalary){
      this.showOverlay=true;
      const doc = new jsPDF();
      const fontSize = 12;
      doc.setFontSize(fontSize);
      const maxWidth = 180;
      const contentWithoutTags = this.offerLetter.content.replace(/##BENEFITS##/g, '')
      .replace(/(<([^>]+)>)/gi, (match, tag) => {
        if (tag.startsWith("<h4>")) {
          return "";
        } else if (tag.startsWith("<ul>")) {
          return "";
        } else if (tag.startsWith("<li>")) {
          return " " + tag.replace("<li>", "").replace("</li>", "") + "\n";
        }
        return "";
      }).replace(/&nbsp;/gi, '').replace(/₹/g, 'Rs').replace(/Additional Benefits/g, '\nAdditional Benefits');
      
      const lines = contentWithoutTags.split("\n").filter(line => line.trim() !== "");
      let y = 10;
      lines.forEach((line) => {
      const splittedLines = doc.splitTextToSize(line, maxWidth);
      splittedLines.forEach((splittedLine) => {
        if (splittedLine.includes("Additional Benefits")) {
          doc.setFont("helvetica", "bold");
        } else {
          doc.setFont("helvetica", "normal");
        }
        doc.text(splittedLine, 10, y);
        y += 10;
      });
      });
      let formData = new FormData();
      let fileName = "offer-letter_" + this.setdata.candidateName.replace(/\s+/g, '').toLowerCase();
      formData.append('file', new Blob([doc.output('blob')]), `${fileName}.pdf`);
      formData.append('id', this.setdata.candidateId);
      formData.append('jobId', this.setdata.jobId);
      formData.append('shortlistId', this.setdata.rpCandShortlistId);
      formData.append('joiningDate', this.joiningDate);
      formData.append('salary', this.currency + " " + this.salary + " " + this.payFrequency);
      axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/upload/offerletter', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }).then(response => {
              iziToast.success({
                  title: 'Success',
                  message: this.$config.offerSuc,
                  position: 'topRight'
              });
             this.$emit('showOfferLetter');
             this.$root.$emit('viewCanShortList');
             this.showOverlay=false;
             var shortListId = this.setdata.rpCandShortlistId;
             var params = this.counterData.map(function(obj) {
                  return { ...obj, "rpCandShortlistId": shortListId };            
              });
              console.log(params);
              axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/add/candidate/counteroffer', params)
                  .then(response => {
                      console.log("counterOffer added");
                  })
                  .catch(error => {
                    console.log(error)
                  });
              var offerSent = ((this.setdata.offerSent==null || this.setdata.offerSent==0 ) ? 1:2)
                axios.post(this.$store.getters.getAPIBasePath + '/recruitmentplanresource/update/rp/candshortlist/status/' + this.setdata.rpCandShortlistId, { "status": 5,"candidateId":this.setdata.candidateId,"jobId": this.setdata.jobId,"offerSent": offerSent})
                    .then(response => {
                      this.$root.$emit('viewCanShortList');
                      console.log("status updated");
                  })
                  .catch(error => {
                    console.log(error)
                  });
          })
          .catch(error => {     
              iziToast.error({
                  title: 'Error occured',
                  message: this.$config.offerErr,
                  position: 'topRight'
              });
              this.showOverlay=false;
          });
      }
    },


  }
}
</script>
