<template>
	<div class="update-project">
		<b-modal scrollable ref="updateProjectModal" size="lg" id="updateProjectModal" title="Update Project" @show="getProjectCode" @ok="updateProject" @cancel="refreshProjectData" @close="refreshProjectData" ok-title="Update Project">
			<form>
				<div class="row col-md-12 mx-0 px-0">
					<div class="row col-md-12 mx-0 justify-content-end">
						<multiselect v-if="elements.showMembersDropdown" v-model="project.projectMembers" :options="options.recruitersList" name="project" label="recruiterName" track-by="id" select-label="" multiple deselect-label="" class="" :max-height="300" placeholder="Select Members" :searchable="true" :close-on-select="false" @close="elements.showMembersDropdown=false"></multiselect>
						<span v-if="!elements.showMembersDropdown" v-for="TaskMembers in project.projectMembers" :key="TaskMembers.recruiterName">
							<span :data-letters="TaskMembers.recruiterName | getAcronym" :title="TaskMembers.recruiterName" class="mx-n2 align-self-center"></span>
						</span>
						<i v-if="!elements.showMembersDropdown" class="fa fa-plus circle-icon hover-hand ml-n2 pull-right" @click="elements.showMembersDropdown=!elements.showMembersDropdown"  title="Add Members" />
					</div>
					<div class="form-group col-md-4 col-sm-12">
						<label for="projectName" class="col-form-label">Project Name</label>
						<input type="text" name="projectName" id="projectName" v-model="project.projectName" class="form-control" />
						<label v-if="$v.project.projectName.$error && !$v.project.projectName.required" class="text-danger">Project Name is required</label>
						<label v-if="$v.project.projectName.$error && !$v.project.projectName.minLength" class="text-danger">Project Name must have at least {{$v.project.projectName.minLength.min}} letters.</label>
						<label v-if="$v.project.projectName.$error && !$v.project.projectName.maxLength" class="text-danger">Project Name must not exceed {{$v.project.projectName.maxLength.max}} letters.</label>
					</div>
					<div class="form-group col-md-4 col-sm-12">
						<label for="keyName" class="col-form-label">Project Code</label>
						<label name="keyName" id="keyName" class="form-control">{{ project.keyName }}</label>
					</div>
					<div class="form-group col-md-4 col-sm-12">
						<label for="clientName" class="col-form-label">Client</label>
						<select class="form-control" v-model="project.clientId">
							<option value="">Select</option>
							<option v-for="(client, index) in clientList" :value="client.id">{{client.client_Name}}</option>
						</select>
					</div>
					<div class="form-group col-md-4 col-sm-12">
						<label for="editProjectStartDate" class="col-form-label">Start Date</label>
						<input type="date" v-model="project.startDate" class="form-control" id="editProjectStartDate" />
						
					</div>
					<div class="form-group col-md-4 col-sm-12">
						<label for="editProjectEndDate" class="col-form-label">End Date</label>
						<input type="date" v-model="project.endDate" class="form-control" id="editProjectEndDate" />
						
					</div>
					<div class="form-group col-md-4 col-sm-12">
						<label for="projectStatus" class="col-form-label">Project Status</label>
						<select class="form-control" v-model="project.status">
							<option value="">Select</option>
							<option v-for="(status, index) in statusList" :value="status.id">{{status.name}}</option>
						</select>
					</div>
					<div class="form-group col-md-12 col-sm-12">
						<label for="projectDescription" class="col-form-label">Description</label>
						<editor id="editProjectDescription" api-key="p2he3phm0obj1wc8o56uqbdmb63l8h0gkljqpb1lvmrecw78" :toolbar="editor.toolbarOptions" :init="editor.initOptions" v-model="project.description"></editor>
						<label v-if="$v.project.description.$error && !$v.project.description.maxLength" class="text-danger">Description must not exceed {{$v.project.description.maxLength.max}} letters.</label>
					</div>
					<div class="taskAttachments col-md-12 mb-4">
						<div class="card">
							<div class="card-body">
								<label><b>Attachments</b></label>
								<div class="d-flex flex-row">
									<input type="file" class="form-control mx-2 h-auto flex-fill" placeholder="Add Attachments">
									<b-btn variant="primary" class="ml-2 flex-fill">Add</b-btn>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</b-modal>
	</div>
</template>
<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import Editor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
export default {
	props: {
		options: Object,
		editor: Object,
		project: Object
	},
	components: {
		Editor,
		Multiselect
	},
	data() {
		return {
			elements: {
				showMembersDropdown: false
			},
			statusList: this.$store.getters.getProjectStatusList,
			clientList: this.$store.getters.getClients,
		}
	},
	validations: {
		project: {
			projectName: {
				required,
				minLength: minLength(3),
				maxLength: maxLength(50)
			},
			
			description: {
				maxLength: maxLength(200)
			}
		}
	},
	methods: {
		validateProjectData: function() {
			this.$v.project.$touch();
			if (this.$v.project.$invalid) {
				return false;
			}
			return true;
		},
		refreshProjectData: function() {
			this.project.projectName = "";
			this.project.keyName = "";
			this.project.clientId = "";
			this.project.startDate = "";
			this.project.endDate = "";
			this.project.description = "";
			this.project.status = "";
			this.elements.showMembersDropdown = false;
		},
		getProjectCode() {
			axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/project')
			.then(response => {
				if (response.data.status) {
					this.project.keyName = response.data.code;
				}
			})
			.catch(error => {
				console.log(error);
			});
		},
		updateProject: function() {
			this.project.projectMembers.map(function(Members){
				Members.userId = Members.id;
			});
			if (!this.validateProjectData()) {
				return false;
			}
			if(this.project.startDate == 'Invalid date' && this.project.endDate == 'Invalid date'){
				this.project.startDate = null;
				this.project.endDate = null;
			}
			console.log(this.project)			
			axios.post(this.$store.getters.getAPIBasePath + '/projectresource/update/project', this.project)
			.then(response => {
				if (response.data.status == "ACCEPTED") {
					iziToast.success({ title: 'Success', message: response.data.message, position: 'topRight' });
					this.$refs.updateProjectModal.hide();
					this.refreshProjectData();
					this.$emit('loadAddProject');
					this.$emit('getProjectList');
				} else {
					iziToast.error({ title: 'Error', message: response.data.message, position: 'topRight' });
				}
			})
			.catch(function(error) {
				console.log(error);
				iziToast.error({ title: 'Error', message: this.$config.SomeTryAg, position: 'topRight' });
			});
		},
		generateKey: function(name) {
			let keywords = [];
			this.project.keyName = "";
			keywords = name.split(" ");
			let self = this;
			keywords.map(function(keyword) {
				self.project.keyName += keyword.charAt(0);
			});
			this.project.keyName = "PRJ_" + this.project.keyName.toUpperCase();
		}
	}
}

</script>
