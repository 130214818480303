<template>
    <div class="settings">
        <div class="row col-md-12 py-3">
            <div class="col-md-4">
                <div @click="$emit('setSubTab',1)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/technology-skills-icons.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Technologies & Skills</b></h6>
                        <p>Set Technology & Skills</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div @click="$emit('setSubTab',2)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/domain-working-in-lap.png" width="100%" height="90%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Domain</b></h6>
                        <p>Set Job Domain</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div @click="$emit('setSubTab',3)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/sourcing-channel.png" width="100%" height="80%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Sourcing Channel</b></h6>
                        <p>Manage Sourcing Channel Effectiveness</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12 py-3">
            <div class="col-md-4">
                <div class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <router-link :to="{ name: 'templatesettings' }">
                            <img src="@/assets/icons/template-management.png" width="100%" height="90%" />
                        </router-link>
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <router-link :to="{ name: 'templatesettings' }" class="text-body">
                            <h6><b>Template Management</b></h6>
                            <p>Manage Templates for various accounts</p>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div @click="$emit('setSubTab',4)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/additional-settings.png" width="100%" height="60%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Additional Candidate Field Settings</b></h6>
                        <p>Manage additional candidate settings</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div @click="$emit('setSubTab',5)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/email-settings.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Email Account Settings</b></h6>
                        <p>Manage Email Account</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12 py-3">
            <div class="col-md-4">
                <div class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <router-link :to="{ name: 'projectsettings' }">
                            <img src="@/assets/icons/project-setting.png" width="100%" height="100%" />
                        </router-link>
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <router-link :to="{ name: 'projectsettings' }" class="text-body">
                            <h6><b>Project Settings</b></h6>
                            <p>Manage Projects & Task Group</p>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div @click="$emit('setSubTab',6)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/task-activity.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Task/Activity Settings</b></h6>
                        <p>Manage Task Type & Activity</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <router-link :to="{ name: 'generalsettings' }">
                            <img src="@/assets/icons/template-management.png" width="100%" height="90%" />
                        </router-link>
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <router-link :to="{ name: 'generalsettings' }" class="text-body">
                            <h6><b>General Settings</b></h6>
                            <p>Manage General</p>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12 py-3">
            <div v-if="false" class="col-md-4">
                <div @click="$emit('setSubTab',10)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/sourcing-channel.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Work Authorization</b></h6>
                        <p>Manage Work Authorization</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div @click="$emit('setSubTab',11)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/job-doc.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Job Documents</b></h6>
                        <p>Job Documents</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div @click="$emit('setSubTab',14)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/template-types.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Template Types</b></h6>
                        <p>Set Template Types</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div @click="$emit('setSubTab',15)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/Setting-industry.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Industries</b></h6>
                        <p>Set Industries</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12 py-3">
        <div class="col-md-4">
                <div @click="$emit('setSubTab',12)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/qualification.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Qualification</b></h6>
                        <p>Manage Qualification</p>
                    </div>
                </div>
            </div>
            <div v-if="false" class="row col-md-12 py-3">
                <div class="col-md-4">
                    <div @click="$emit('setSubTab',13)" class="row col-md-12 ml-0 left-bar-tab p-2">
                        <div class="col-md-3 p-3">
                            <img src="@/assets/icons/sourcing-channel.png" width="100%" height="100%" />
                        </div>
                        <div class="col-md-8 text-left align-self-center p-2">
                            <h6><b>Tax Terms</b></h6>
                            <p>Manage Tax Terms</p>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="col-md-4">
                <div @click="$emit('setSubTab',16)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/counter.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Counter Offers</b></h6>
                        <p>Manage Counter Offers</p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div @click="$emit('setSubTab',17)" class="row col-md-12 ml-0 left-bar-tab p-2">
                    <div class="col-md-3 p-3">
                        <img src="@/assets/icons/reward.png" width="100%" height="100%" />
                    </div>
                    <div class="col-md-8 text-left align-self-center p-2">
                        <h6><b>Referral Reward Settings</b></h6>
                        <p>Manage Reward Settings</p>
                    </div>
                </div>
            </div>
                
        </div>
    </div>
</template>
<script>
export default {

    data() {
        return {
            userInfo: this.$store.getters.currentUser
        }
    },
    mounted() {}
}

</script>
