<template>
    <div :class="{ viewonly: preferenceinfo.viewonly == 1 }">
        <div class="card">
            <div class="card-header p-2">Preference</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col">Title</th>
                                            <th
                                                class="videoprofile"
                                                scope="col"
                                            >
                                                State
                                            </th>
                                            <th class="title" scope="col">
                                                City
                                            </th>
                                            <th class="recruiter" scope="col">
                                                Technology/Skills
                                            </th>
                                            <th class="state" scope="col">
                                                Travel
                                            </th>
                                            <th class="" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <!-- <tbody class="cloned_tr">
                                        <tr v-for="(info, index) in preferenceinfo.basepreference">
                                            <td>
                                                <input v-model="preferenceinfo.basepreference[index].title" maxlength="100" class="form-control" type="text" />
                                            </td>
                                            <td class="lastnote">
                                                <input v-model="preferenceinfo.basepreference[index].stateName" maxlength="25" class="form-control" type="text" />
                                            </td>
                                            <td class="recruiter">
                                                <input v-model="preferenceinfo.basepreference[index].city" maxlength="20" class="form-control" type="text" />
                                            </td>
                                            <td class="title">
                                                <input v-model="preferenceinfo.basepreference[index].skills" maxlength="500" class="form-control" type="text" />
                                            </td>
                                            <td class="specialityunit">
                                                <select v-model="preferenceinfo.basepreference[index].canTravel" class="form-control">
                                                    <option value="">Select</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </td>
                                            <td>
                                                <a :class="{ 'removeaddbtn' : preferenceinfo.viewonly == 1}" class="removemoretextbox" href="Javascript:void(0);" data-toggle="modal" data-target="#removPrefer" @click="confirmPrefer(index,info.id)"><span class="ti-trash"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="fill" :class="{ 'noaddedmore' : preferenceinfo.viewonly == 1}">
                                            <td>
                                                <input v-model="newpreference.title" ref="addcandidate_preferenceinfo_add_title" class="form-control" maxlength="100" type="text" />
                                            </td>
                                            <td class="lastnote">
                                                <input class="form-control" type="text" maxlength="25" v-model="newpreference.stateName" />
                                            </td>
                                            <td class="recruiter">
                                                <input class="form-control" type="text" maxlength="20" v-model="newpreference.city" />
                                            </td>
                                            <td class="title">
                                                <input class="form-control" type="text" maxlength="500" v-model="newpreference.skills" />
                                            </td>
                                            <td class="specialityunit">
                                                <select v-model="newpreference.canTravel" class="form-control">
                                                    <option value="">Select</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </td>
                                            <td>
                                                <a class="addmoretextbox" href="Javascript:void(0);" @click="addNewPreferenceInfo"><span class="ti-plus"></span></a>
                                            </td>
                                        </tr>
                                    </tbody> -->
                                    <tbody class="cloned_tr">
                                        <tr
                                            v-for="(
                                                info, index
                                            ) in preferenceinfo.basepreference"
                                        >
                                            <td>
                                                <input
                                                    v-model="
                                                        preferenceinfo
                                                            .basepreference[
                                                            index
                                                        ].title
                                                    "
                                                    maxlength="100"
                                                    class="form-control"
                                                    type="text"
                                                />
                                            </td>

                                            <td class="lastnote">
                                                <input
                                                    v-model="
                                                        preferenceinfo
                                                            .basepreference[
                                                            index
                                                        ].stateName
                                                    "
                                                    maxlength="25"
                                                    class="form-control"
                                                    type="text"
                                                    @input="
                                                        preferenceinfo.basepreference[
                                                            index
                                                        ].stateName =
                                                            preferenceinfo.basepreference[
                                                                index
                                                            ].stateName.replace(
                                                                /[^A-Za-z\s]/g,
                                                                ''
                                                            )
                                                    "
                                                />
                                            </td>

                                            <td class="recruiter">
                                                <input
                                                    v-model="
                                                        preferenceinfo
                                                            .basepreference[
                                                            index
                                                        ].city
                                                    "
                                                    maxlength="20"
                                                    class="form-control"
                                                    type="text"
                                                    @input="
                                                        preferenceinfo.basepreference[
                                                            index
                                                        ].city =
                                                            preferenceinfo.basepreference[
                                                                index
                                                            ].city.replace(
                                                                /[^A-Za-z\s]/g,
                                                                ''
                                                            )
                                                    "
                                                />
                                            </td>

                                            <td class="title">
                                                <input
                                                    v-model="
                                                        preferenceinfo
                                                            .basepreference[
                                                            index
                                                        ].skills
                                                    "
                                                    maxlength="500"
                                                    class="form-control"
                                                    type="text"
                                                />
                                            </td>

                                            <td class="specialityunit">
                                                <select
                                                    v-model="
                                                        preferenceinfo
                                                            .basepreference[
                                                            index
                                                        ].canTravel
                                                    "
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        Select
                                                    </option>
                                                    <option value="yes">
                                                        Yes
                                                    </option>
                                                    <option value="no">
                                                        No
                                                    </option>
                                                </select>
                                            </td>

                                            <td>
                                                <a
                                                    :class="{
                                                        removeaddbtn:
                                                            preferenceinfo.viewonly ==
                                                            1,
                                                    }"
                                                    class="removemoretextbox"
                                                    href="Javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#removPrefer"
                                                    @click="
                                                        confirmPrefer(
                                                            index,
                                                            info.id
                                                        )
                                                    "
                                                    ><span
                                                        class="ti-trash"
                                                    ></span
                                                ></a>
                                            </td>
                                        </tr>

                                        <tr
                                            class="fill"
                                            :class="{
                                                noaddedmore:
                                                    preferenceinfo.viewonly ==
                                                    1,
                                            }"
                                        >
                                            <td>
                                                <input
                                                    v-model="
                                                        newpreference.title
                                                    "
                                                    class="form-control"
                                                    maxlength="100"
                                                    type="text"
                                                />
                                            </td>

                                            <td class="lastnote">
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    maxlength="25"
                                                    v-model="
                                                        newpreference.stateName
                                                    "
                                                    @input="
                                                        newpreference.stateName =
                                                            newpreference.stateName.replace(
                                                                /[^A-Za-z\s]/g,
                                                                ''
                                                            )
                                                    "
                                                />
                                            </td>

                                            <td class="recruiter">
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    maxlength="20"
                                                    v-model="newpreference.city"
                                                    @input="
                                                        newpreference.city =
                                                            newpreference.city.replace(
                                                                /[^A-Za-z\s]/g,
                                                                ''
                                                            )
                                                    "
                                                />
                                            </td>

                                            <td class="title">
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    maxlength="500"
                                                    v-model="
                                                        newpreference.skills
                                                    "
                                                />
                                            </td>

                                            <td class="specialityunit">
                                                <select
                                                    v-model="
                                                        newpreference.canTravel
                                                    "
                                                    class="form-control"
                                                >
                                                    <option value="">
                                                        Select
                                                    </option>
                                                    <option value="yes">
                                                        Yes
                                                    </option>
                                                    <option value="no">
                                                        No
                                                    </option>
                                                </select>
                                            </td>

                                            <td>
                                                <a
                                                    class="addmoretextbox"
                                                    href="Javascript:void(0);"
                                                    @click="
                                                        addNewPreferenceInfo
                                                    "
                                                    ><span
                                                        class="ti-plus"
                                                    ></span
                                                ></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Mode of Communication</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="col-md-12 col-sm-12 col-12 lb">
                        <b-form-group label="Preferred Communication Mode">
                            <b-form-checkbox-group
                                id="communicationmode"
                                v-model="communicationMode.selected"
                                :options="communicationMode.options"
                                name="communicationmode"
                                value-field="id"
                                text-field="name"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header p-2">Other Preference</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div
                            v-if="false"
                            class="col-md-2 col-sm-6 col-12 mb-10-mr"
                        >
                            <div class="row ten-columns">
                                <div
                                    class="col-md-12 col-sm-12 col-12 lb mt-minus-5"
                                >
                                    <label for="tc_type"
                                        >Best Time to Call</label
                                    >
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group date"
                                        data-date-format="mm-dd-yyyy"
                                    >
                                        <b-form-input
                                            id="startTime"
                                            v-model="
                                                preferenceinfo.otherpreference
                                                    .timetocall
                                            "
                                            type="time"
                                            class="form-control"
                                            name="timetocall"
                                        ></b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-2 col-12 mb-10-mr p-r">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Work Type</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select
                                            v-model="
                                                preferenceinfo.otherpreference
                                                    .workType
                                            "
                                            class="form-control"
                                        >
                                            <option value="">Select</option>
                                            <option
                                                v-for="(
                                                    data, index
                                                ) in worktypelist"
                                                :value="data.id"
                                            >
                                                {{ data.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-4 col-sm-4 col-12 mb-10-mr p-r">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Expected Salary </label>
                                </div>

                                
                                <div class="col-md-8 col-sm-8 col-8">
                                    <div class="input-group extended" >
                                        <select v-model="preferenceinfo.otherpreference.currency" class="form-control">     
                                            <option value="$">$</option>
                                            <option value="Rs">&#x20B9;</option>
                                        </select>
                                        <input type="text" class="form-control" maxlength="20" v-model="preferenceinfo.otherpreference.expectedSalary">
                                        <div class="input-group-append">
                                            <select v-model="preferenceinfo.otherpreference.payFrequency" class="form-control">
                                                <option value="Per Month">Per Month</option>
                                                <option value="Per Year">Per Year</option>
                                                <option value="Hourly">Hourly</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Expected Salary</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group extended">
                                        <!-- Currency Dropdown (Dollar & Rupees) -->
                                        <select
                                            class="form-control"
                                            v-model="preferenceinfo.otherpreference.currency"
                                            :disabled="isCurrencyDisabled"
                                        >
                                            <option value="$">$</option>
                                            <option value="₹">₹</option>
                                        </select>

                                        <!-- Expected Salary Input (Numbers Only) -->
                                        <input
                                            type="number"
                                            class="form-control"
                                            v-model="preferenceinfo.otherpreference.expectedSalary"
                                            @input="validateSalary"
                                            min="1"
                                            max="2000000"
                                        />

                                    <div class="input-group-append">
                                        <!-- Pay Frequency Dropdown -->
                                        <select
                                            v-model="
                                                preferenceinfo.otherpreference
                                                    .payFrequency
                                            "
                                            class="form-control p-0"
                                        >
                                            <option value="Per Month">
                                                Per Month
                                            </option>
                                            <option value="Per Year">
                                                Per Year
                                            </option>
                                            <option value="Hourly">
                                                Hourly
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Minimum Salary </label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group extended">
                                        <input
                                            v-bind:disabled="
                                                preferenceinfo.otherpreference
                                                    .frequencyPercentage ==
                                                    '' ||
                                                preferenceinfo.otherpreference
                                                    .frequencyPercentage ==
                                                    undefined ||
                                                preferenceinfo.otherpreference
                                                    .frequencyPercentage == null
                                                    ? false
                                                    : true
                                            "
                                            type="number"
                                            name="minsalary"
                                            maxlength="25"
                                            value=""
                                            class="fldboxbig form-control"
                                            v-model="
                                                preferenceinfo.otherpreference
                                                    .minSalary
                                            "
                                            @keypress="onlyNumber"
                                        />
                                    </div>
                                    <label
                                        v-if="
                                            !$v.preferenceinfo.otherpreference
                                                .minSalary
                                        "
                                        class="text-danger"
                                        >Minimum Salary must be less than
                                        Maximum Salary</label
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Maximum Salary </label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group extended">
                                        <input
                                            v-bind:disabled="
                                                preferenceinfo.otherpreference
                                                    .frequencyPercentage ==
                                                    '' ||
                                                preferenceinfo.otherpreference
                                                    .frequencyPercentage ==
                                                    undefined ||
                                                preferenceinfo.otherpreference
                                                    .frequencyPercentage == null
                                                    ? false
                                                    : true
                                            "
                                            type="number"
                                            name="maxsalary"
                                            maxlength="25"
                                            value=""
                                            class="fldboxbig form-control"
                                            v-model="
                                                preferenceinfo.otherpreference
                                                    .maxSalary
                                            "
                                            @keypress="onlyNumber"
                                        />
                                    </div>
                                    <label
                                        v-if="
                                            !$v.preferenceinfo.otherpreference
                                                .maxSalary
                                        "
                                        class="text-danger"
                                        >Maximum Salary must be greater than
                                        Minimum Salary</label
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 col-12 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Flexibility <span class="fW_bold">%</span></label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group extended">
                                        <input
                                            v-bind:disabled="
                                                (preferenceinfo.otherpreference.maxSalary == '' &&
                                                    preferenceinfo.otherpreference.minSalary == null) ||
                                                (preferenceinfo.otherpreference.maxSalary == null &&
                                                    preferenceinfo.otherpreference.minSalary == '') ||
                                                (preferenceinfo.otherpreference.maxSalary == '' &&
                                                    preferenceinfo.otherpreference.minSalary == '') ||
                                                (preferenceinfo.otherpreference.maxSalary &&
                                                    preferenceinfo.otherpreference.minSalary ==
                                                        undefined) ||
                                                (preferenceinfo.otherpreference.maxSalary == null &&
                                                    preferenceinfo.otherpreference.minSalary == null)
                                                    ? false
                                                    : true
                                            "
                                            type="number"
                                            name="frepercentage"
                                            class="fldboxbig form-control"
                                            v-model="preferenceinfo.otherpreference.frequencyPercentage"
                                            @input="validateFlexibility"
                                            @keydown="blockDecimal"
                                            min="0"
                                            max="100"
                                        />
                                    </div>
                                    <label class="whole_no">*Whole numbers only</label>
                                </div>
                            </div>


                        </div>
                        <div
                            v-if="false"
                            class="col-md-2 col-sm-2 col-12 mb-10-mr p-r"
                        >
                            <div class="row p-a ten-columns">
                                <div class="col-sm-12 col-12">
                                    <b-form-group label=" ">
                                        <b-form-radio
                                            v-model="
                                                preferenceinfo.otherpreference
                                                    .parttime
                                            "
                                            @input="
                                                preferenceinfo.otherpreference.fulltime =
                                                    preferenceinfo
                                                        .otherpreference
                                                        .parttime == 'yes'
                                                        ? 'no'
                                                        : 'yes'
                                            "
                                            name="parttime_fulltime"
                                            value="yes"
                                            >Part Time?</b-form-radio
                                        >
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="false"
                            class="col-md-2 col-sm-2 col-12 mb-10-mr p-r"
                        >
                            <div class="row p-a ten-columns">
                                <div class="col-sm-12 col-12">
                                    <b-form-group label=" ">
                                        <b-form-radio
                                            v-model="
                                                preferenceinfo.otherpreference
                                                    .fulltime
                                            "
                                            @input="
                                                preferenceinfo.otherpreference.parttime =
                                                    preferenceinfo
                                                        .otherpreference
                                                        .fulltime == 'yes'
                                                        ? 'no'
                                                        : 'yes'
                                            "
                                            name="parttime_fulltime"
                                            value="yes"
                                            >Full Time?</b-form-radio
                                        >
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog
            id="removPrefer"
            :onYes="deletePrefer"
            :returnParams="delPreferParam"
        />
    </div>
</template>
<script>
import iziToast from "izitoast";
export default {
    props: {
        preferenceinfo: Object,
        candStatus: String,
    },
    data() {
        return {
            preferenceinfo: {
                otherpreference: {
                    frequencyPercentage: null,
                    maxSalary: null,
                    minSalary: null
                }
            },
            
            preferenceinfo: {
                otherpreference: {
                    currency: "$",
                    expectedSalary: null
                }
            },
            worktypelist: this.$store.getters.getWorkType,
            currencies: this.$store.getters.getCurrencies,
            newpreference: {
                title: "",
                stateName: "",
                city: "",
                skills: "",
                canTravel: "",
                zipCode: "",
                countryId: "US",
            },
            delPreferParam: {
                index: -1,
                id: 0,
            },
            communicationMode: {
                selected: [],
                options: [],
            },
        };
    },
    computed: {
        isCurrencyDisabled() {
            return this.candStatus !== "Public Post" ? false : true;
        },
    },
    validations: {
        preferenceinfo: {
            otherpreference: {
                minSalary: function (minSalary, maxSalary) {
                    if (
                        minSalary.minSalary != "" &&
                        minSalary.minSalary != undefined &&
                        minSalary.minSalary != null &&
                        minSalary.maxSalary != "" &&
                        minSalary.maxSalary != undefined &&
                        minSalary.maxSalary != null
                    ) {
                        if (
                            Number(minSalary.minSalary) >
                            Number(minSalary.maxSalary)
                        ) {
                            return false;
                        }
                    }
                    return true;
                },

                maxSalary: function (minSalary, maxSalary) {
                    if (
                        minSalary.minSalary != "" &&
                        minSalary.minSalary != undefined &&
                        minSalary.minSalary != null &&
                        minSalary.maxSalary != "" &&
                        minSalary.maxSalary != undefined &&
                        minSalary.maxSalary != null
                    ) {
                        if (
                            Number(minSalary.minSalary) >
                            Number(minSalary.maxSalary)
                        ) {
                            return false;
                        }
                    }
                    return true;
                },
            },
        },
    },
    watch: {
        preferenceinfo: function (newVal, oldVal) {
            this.setCandidateCommunication();
        },
    },
    mounted() {
        this.loadCommunicationModeList();
        this.setCandidateCommunication();
    },
    methods: {
        validateFlexibility() {
            let value = this.preferenceinfo.otherpreference.frequencyPercentage;

            // Ensure value is a whole number and within the range
            if (value < 0) {
                this.preferenceinfo.otherpreference.frequencyPercentage = 0;
            } else if (value > 100) {
                this.preferenceinfo.otherpreference.frequencyPercentage = 100;
            } else {
                this.preferenceinfo.otherpreference.frequencyPercentage = Math.floor(value);
            }
        },
        blockDecimal(event) {
            // Prevent decimal point (.)
            if (event.key === "." || event.key === "," || event.key === "e") {
                event.preventDefault();
            }
        },
        
        validateSalary() {
            if (this.preferenceinfo.otherpreference.expectedSalary > 2000000) {
                this.preferenceinfo.otherpreference.expectedSalary = 2000000;
            }
        },
        onlyNumber($event) {
            //console.log($event.keyCode); //keyCodes value
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                // 46 is dot
                $event.preventDefault();
            }
        },
        resetNewForm() {
            this.newpreference = {
                title: "",
                stateName: "",
                city: "",
                skills: "",
                canTravel: "",
                zipCode: "",
                countryId: "US",
            };
            this.$refs.addcandidate_preferenceinfo_add_title.focus();
        },
        addNewPreferenceInfo() {
            this.newpreference.title = this.newpreference.title.trim();
            this.newpreference.stateName = this.newpreference.stateName.trim();
            this.newpreference.city = this.newpreference.city.trim();
            this.newpreference.skills = this.newpreference.skills.trim();
            if (
                this.newpreference.title != "" ||
                this.newpreference.stateName != "" ||
                this.newpreference.city != "" ||
                this.newpreference.skills != "" ||
                this.newpreference.canTravel != ""
            ) {
                this.preferenceinfo.basepreference.push(this.newpreference);
                this.resetNewForm();
            }
        },
        deletePrefer: function (arg) {
            this.preferenceinfo.basepreference.splice(arg.index, 1);
            axios
                .post(
                    this.$store.getters.getAPIBasePath +
                        "/candidateresource/delete/candidate/preference/" +
                        this.delPreferParam.id
                )
                .then((response) => {
                    let result = response.data;
                    if (result.status == "ACCEPTED") {
                        iziToast.success({
                            title: "Success",
                            message: result.message,
                            position: "topRight",
                            timeout: 1000,
                        });
                    } else {
                        iziToast.error({
                            title: "Error",
                            message: this.$config.CandDelPreErr,
                            position: "topRight",
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        confirmPrefer: function (arg1, arg2) {
            this.delPreferParam.index = arg1;
            this.delPreferParam.id = arg2;
        },
        loadCommunicationModeList: function () {
            axios
                .get(
                    this.$store.getters.getAPIBasePath +
                        "/commonresource/communicationmode/list"
                )
                .then((response) => {
                    if (response.data.status) {
                        this.communicationMode.options =
                            response.data.settingsCommunicationList;
                    } else {
                        let errmsg =
                            response.data.message != undefined
                                ? response.data.message
                                : this.$config.LoadingErr;
                        iziToast.error({
                            title: "Error",
                            message: errmsg,
                            position: "topRight",
                        });
                    }
                })
                .catch((error) => {
                    let errmsg = error.response
                        ? error.response.data.message
                        : this.$config.LoadingErr;
                    iziToast.error({
                        title: "Error",
                        message: errmsg,
                        position: "topRight",
                    });
                });
        },
        validatePreferrenceInfo() {
            if (this.$v.preferenceinfo.otherpreference.$invalid) {
                this.preferenceinfo.otherpreference.blockpreferrancetab = 1;
                return false;
            }
            this.preferenceinfo.otherpreference.blockpreferrancetab = 0;
            return true;
        },
        saveCommunicationMode: function () {
            let communicationmodeData = [];
            this.communicationMode.selected.map(function (communicationmodeid) {
                communicationmodeData.push({
                    communicationId: communicationmodeid,
                });
            });
            this.preferenceinfo.candidateCommunication = communicationmodeData;
        },
        setCandidateCommunication: function () {
            let self = this;
            if (this.preferenceinfo.candidateCommunication != undefined)
                this.preferenceinfo.candidateCommunication.map(function (
                    communication
                ) {
                    self.communicationMode.selected.push(
                        communication.communicationId
                    );
                });
        },
    },
};
</script>
