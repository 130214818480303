<template>
    <div class="edit-task">
        <b-modal scrollable ref="editTaskModal" size="lg" no-enforce-focus id="editTaskModal" :title="'Edit Task - '+editTask.title" @ok="updateTask" @cancel="cancelEdit" @close="cancelEdit" ok-title="Update Task">
            <form @submit.stop.prevent="updateTask">
                <div class="row col-md-12 mx-0 px-0">
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="editTaskTitle" class="col-form-label">Title</label>
                        <input type="text" v-model="editTask.title" class="form-control" id="editTaskTitle">
                        <label v-if="$v.editTask.title.$error && !$v.editTask.title.required" class="text-danger">Please enter Task Title</label>
                        <label v-if="$v.editTask.title.$error && !$v.editTask.title.minLength" class="text-danger">Task Title must have at least {{$v.editTask.title.$params.minLength.min}} letters.</label>
                        <label v-if="$v.editTask.title.$error && !$v.editTask.title.maxLength" class="text-danger">Task Title must not exceed {{$v.editTask.title.$params.maxLength.max}} letters.</label>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="editTaskSprint" class="col-form-label">Task Group</label>
                        <select v-model="editTask.taskGroupId" name="editTaskPriority" class="form-control">
                            <option v-for="Option in options.sprintList" :value="Option.id">{{ Option.name }}</option>
                        </select>
                        <label v-if="$v.editTask.taskGroupId.$error && !$v.editTask.taskGroupId.required" class="text-danger">Please choose the Task Group</label>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="taskType" class="col-form-label">Task Type</label>
                        <select v-model="editTask.taskType" name="taskType" id="taskType" class="form-control">
                            <option value="">Select</option>
                            <option v-for="Option in options.taskTypeList" :value="Option.id">{{ Option.name }}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="editTaskPriority" class="col-form-label">Priority</label>
                        <select v-model="editTask.priorityId" name="editTaskPriority" class="form-control">
                            <option value="">Select</option>
                            <option v-for="Option in options.priorityList" :value="Option.id">{{ Option.name }}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="editTaskAssignedTo" class="col-form-label">Assigned To</label>
                        <select v-model="editTask.assignedTo" id="editTaskAssignedTo" name="editTaskAssignedTo" class="form-control">
                            <option value="">Select</option>
                            <option v-for="Option in options.recruitersList" :value="Option.id">{{ Option.recruiterName }}</option>
                        </select>
                        <label v-if="$v.editTask.assignedTo.$error && !$v.editTask.assignedTo.required" class="text-danger">Assignee is required</label>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="editTaskStartDate" class="col-form-label">Start Date</label>
                        <input type="date" v-model="editTask.startDate" class="form-control" id="editTaskStartDate" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="editTaskDueDate" class="col-form-label">Due Date</label>
                        <input type="date" v-model="editTask.dueDate" class="form-control" id="editTaskDueDate" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="editTaskCompletedDate" class="col-form-label">Completed Date</label>
                        <input type="date" v-model="editTask.completedDate" class="form-control" id="editTaskCompletedDate" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="taskReference" class="col-form-label">Reference</label>
                        <input type="text" name="taskReference" id="taskReference" v-model="editTask.taskReference" class="form-control" />
                    </div>
                    <div class="form-group col-md-3 col-sm-12 slideinputcontainer">
                        <label for="progressPercentage" class="col-form-label">Progress</label>
                        <input v-model="editTask.progressPercentage" type="range" min="5" max="100" class="slider-input" id="progressPercentage">
                        <span>{{ editTask.progressPercentage }}%</span>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="editTaskStatus" class="col-form-label">Status</label>
                        <select v-model="editTask.statusId" id="editTaskStatus" name="editTaskStatus" class="form-control">
                            <option v-for="Option in options.statusList" :value="Option.id">{{ Option.name }}</option>
                        </select>
                        <label v-if="$v.editTask.statusId.$error && !$v.editTask.statusId.required" class="text-danger">Status is required</label>
                    </div>
                </div>
                <div class="form-group col-md-12 col-sm-12">
                    <label for="editTaskDescription" class="col-form-label">Description</label>
                    <editor id="editTaskDescription" api-key="p2he3phm0obj1wc8o56uqbdmb63l8h0gkljqpb1lvmrecw78" :toolbar="editor.toolbarOptions" :init="editor.initOptions" v-model="editTask.description"></editor>
                    <label v-if="$v.editTask.description.$error && !$v.editTask.description.maxLength" class="text-danger">Description must not exceed {{$v.editTask.description.$params.maxLength.max}} letters.</label>
                </div>
                <div class="taskAttachments col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <label><b>Attachments
                            <span style="display: inline-block; padding: 5px 0px; color:#777; font-size:11px; margin-right: 20px;">({{editTask.taskAttachment.length}})</span></b></label>
                            <div class="col-md-12 col-sm-12 col-12 task_attachment_flx">
                                <div v-for="(taskAttachments,index) in editTask.taskAttachment" class="col-md-2 col-sm-2 col-2 task_mb_pd">
                                    <div v-if="taskAttachments.filename !='' && taskAttachments.fileType == 'png' || taskAttachments.fileType == 'jpeg' || taskAttachments.fileType == 'jpg'" class="col-md-12 col-sm-12 col-12 pd_right">
                                        <img width="141" height="100" v-bind:src="taskAttachments.filePath" @click="Open(taskAttachments.filePath)" class="br_5px" />
                                        <div class="taskname" @click="Open(taskAttachments.filePath)">
                                            <a  :href="taskAttachments.filePath" class="color-black" target="_blank"> {{taskAttachments.fileName}}</a>
                                        </div>
                                        <label class="attach_delete">
                                            <i class="ti-close fs_ml" v-b-modal.deleteTaskAttach @click="removeTask(taskAttachments.id,index)" aria-hidden="true" title="Remove"></i>
                                        </label>
                                    </div>
                                    <div v-if="taskAttachments.filename !='' && taskAttachments.fileType == 'docx' || taskAttachments.fileType == 'doc'"  class="col-md-12 col-sm-12 col-12 pd_right">
                                        <img src="@/assets/images/word.jpg" alt="images" height="100px" width="141px" @click="Open(taskAttachments.filePath)" class="br_5px">
                                            <div class="taskname" @click="Open(taskAttachments.filePath)">
                                                <a  :href="taskAttachments.filePath" class="color-black" target="_blank"> {{taskAttachments.fileName}}</a>
                                            </div>
                                        </img>
                                        <label class="attach_delete">
                                            <i class="ti-close fs_ml" v-b-modal.deleteTaskAttach @click="removeTask(taskAttachments.id,index)" aria-hidden="true" title="Remove"></i>
                                        </label>
                                    </div>
                                    <div v-if="taskAttachments.filename !='' && taskAttachments.fileType == 'pdf'"  class="col-md-12 col-sm-12 col-12 pd_right">
                                        <img src="@/assets/images/Pdf.jpg" alt="images" height="100px" width="141px" @click="Open(taskAttachments.filePath)" class="br_5px">
                                            <div class="taskname" @click="Open(taskAttachments.filePath)">
                                                <a  :href="taskAttachments.filePath" class="color-black" target="_blank"> {{taskAttachments.fileName}}</a>
                                            </div>
                                        </img>
                                        <label class="attach_delete">
                                            <i class="ti-close fs_ml" v-b-modal.deleteTaskAttach @click="removeTask(taskAttachments.id,index)" aria-hidden="true" title="Remove"></i>
                                        </label>
                                    </div>
                                    <div v-if="taskAttachments.filename !='' && taskAttachments.fileType == 'xlsx'" class="col-md-12 col-sm-12 col-12 pd_right">
                                        <img src="@/assets/images/microsoftexcel.jpg" alt="images" height="100px" width="141px" @click="Open(taskAttachments.filePath)" class="br_5px">
                                            <div class="taskname" @click="Open(taskAttachments.filePath)">
                                                <a  :href="taskAttachments.filePath" class="color-black" target="_blank"> {{taskAttachments.fileName}}</a>
                                            </div>
                                        </img>
                                        <label class="attach_delete">
                                            <i class="ti-close fs_ml" v-b-modal.deleteTaskAttach @click="removeTask(taskAttachments.id,index)" aria-hidden="true" title="Remove"></i>
                                        </label>
                                    </div>
                                    <div v-if="taskAttachments.filename !='' && taskAttachments.fileType != 'png' && taskAttachments.fileType != 'jpeg' && taskAttachments.fileType != 'jpg' && taskAttachments.fileType != 'xlsx' && taskAttachments.fileType != 'pdf' && taskAttachments.fileType != 'docx' && taskAttachments.fileType != 'doc'"  class="col-md-12 col-sm-12 col-12 pd_right">
                                        <img width="141" height="100" src="@/assets/images/file2.png"  @click="Open(taskAttachments.filePath)"  class="br_5px" >
                                            <div class="taskname" @click="Open(taskAttachments.filePath)">
                                                <a  :href="taskAttachments.filePath" class="color-black" target="_blank"> {{taskAttachments.fileName}}</a>
                                            </div>
                                        </img>
                                        <label class="attach_delete">
                                            <i class="ti-close fs_ml" v-b-modal.deleteTaskAttach @click="removeTask(taskAttachments.id,index)" aria-hidden="true" title="Remove"></i>
                                        </label>   
                                    </div>
                                    <!-- <div class="attach_fileName_edit">
                                        <a :href="taskAttachments.filePath" target="_blank">{{taskAttachments.fileName}} </a>
                                    </div> -->
                                </div>
                            </div>
                            <div style="margin-bottom: 18px;" class="col-md-12 col-sm-12 col-12" v-if="editTask.taskAttachment == null || editTask.taskAttachment==undefined || editTask.taskAttachment.length==0">No attachment found</div>
                            <div>
                                <a href="javascript:" class="float-right button-add" @click="addInput"><i class="fa fa-plus-circle pr-2 action-link"></i>Add More</a>
                            </div>
                            <div class="d-flex flex-row col-md-11 col-sm-11 col-lg-11 pt-2" v-for="(input, index) in addMore">
                                <input type="file" class="form-control mx-2 h-auto flex-fill upload" ref="file" id="file" placeholder="Add Attachments"  v-on:change="handleMultiFileUpload($event)" :key="fileInputKey">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="taskCheckList col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <label><b>Checklist</b></label>
                            <div class="d-flex flex-column">
                                <label v-for="taskCheckList in editTask.taskCheckList" class="checklist px-2 m-0">
                                    <input @change="$emit('setTaskCheckList',taskCheckList)" type="checkbox" v-model="taskCheckList.isCompleted"> {{ taskCheckList.title }}
                                    <!-- <span></span> -->
                                </label>
                            </div>
                            <div class="d-flex flex-row">
                                <input v-model="elements.checkListTag" type="text" class="form-control mx-2 flex-fill" placeholder="Add an item..." @keyup.enter="addChecklistTag">
                                <b-btn variant="primary" @click="addChecklistTag" class="ml-2 flex-fill">Add</b-btn>
                            </div>
                            <label v-if="$v.elements.checkListTag.$error && !$v.elements.checkListTag.minLength" class="text-danger">Checklist must have at least {{$v.elements.checkListTag.$params.minLength.min}} letters.</label>
                            <label v-if="$v.elements.checkListTag.$error && !$v.elements.checkListTag.maxLength" class="text-danger">Checklist must not exceed {{$v.elements.checkListTag.$params.maxLength.max}} letters.</label>
                        </div>
                    </div>
                </div>
                <div class="taskComments col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <label><b>Comments</b></label>
                            <div v-for="(taskComments,index) in editTask.taskComment" class="comments my-2 col-md-10 offset-md-1 p-3">
                                <div class="d-flex flex-row">
                                    <span :data-letters="taskComments.userName | getAcronym" :title="taskComments.userName"></span>
                                    <div class="d-flex flex-column w-100">
                                        <div class="d-flex userName py-1 justify-content-between">
                                            <span>{{ taskComments.userName }}</span>
                                            <span class="">{{ taskComments.updatedAt | formatCustomTime('DD-MMM-YYYY hh:mm A') }}</span>
                                        </div>
                                        <div class="task-comment-view-edit py-1">
                                            <span v-if="modeInfo.mode=='view' || modeInfo.id!=taskComments.id" class="comment-box" v-html="taskComments.comment"></span>
                                            <div v-if="modeInfo.mode=='edit' && modeInfo.type=='comments' && modeInfo.id==taskComments.id" class="d-flex flex-row">
                                                <!-- <input type="text" v-model="taskComments.comment" class="form-control flex-fill mx-1" /> -->
                                                <div class="flex-fill d-flex flex-column">
                                                    <div class="flex-row py-1"> 
                                                <editor class="form-control flex-fill mx-1" api-key="p2he3phm0obj1wc8o56uqbdmb63l8h0gkljqpb1lvmrecw78" :init="{menubar: false,toolbar:false,statusbar: false,height:100,content_style: 'body { line-height:0.4}'}" v-model="taskComments.comment"></editor>
                                               </div></div>
                                            </div>
                                        </div>
                                        <div v-if="modeInfo.mode=='view' && taskComments.userId==elements.currentUser.accountId" class="d-flex flex-row taskCommentActions py-1">
                                            <a href="javascript:" @click="$emit('changeCMode','edit','comments',taskComments.id);refreshedit(index)" class="mr-2">Edit</a>
                                            <a href="javascript:" class="ml-2" @click="removecomment(taskComments.id)" v-b-modal.deleteComment aria-hidden="true" title="Remove">Delete</a>
                                        </div>
                                    </div>
                                     <i v-if="modeInfo.mode=='edit' && modeInfo.type=='comments' && modeInfo.id==taskComments.id" class="fa fa-check text-tturbo  mx-1 align-self-center" title="Update" style="font-size: 1.5rem" @click="$emit('updateTaskComment',taskComments)"></i>
                                    <i v-if="modeInfo.mode=='edit' && modeInfo.type=='comments' && modeInfo.id==taskComments.id" @click="cancelTaskComment(index)" class="fa fa-times text-danger mx-1 align-self-center" title="Cancel" style="font-size: 1.5rem"></i>
                                </div>
                            </div>
                            <div class="d-flex flex-row add-comments py-3">
                                <span :data-letters="elements.currentUser.name | getAcronym" :title="elements.currentUser.name" class="mr-3"></span>
                                <div class="flex-fill d-flex flex-column">
                                    <div class="flex-row py-1"> 
                                     <editor api-key="p2he3phm0obj1wc8o56uqbdmb63l8h0gkljqpb1lvmrecw78" :init="{menubar: false,toolbar:false,statusbar: false,height:100,content_style: 'body { line-height:0.4}'}" v-model="elements.commentTag"></editor>
                                    <!-- <editor id="editTaskDescription" api-key="yn4wx6bfcohfz6bwlu5k4urno027lgfkcpxolz0f6k4kku01" :toolbar="editor.toolbarOptions" :init="editor.initOptions" v-model="elements.commentTag" @keyup.enter="addCommentTag"></editor> -->
                                        <!-- <input type="text" class="form-control" v-model="elements.commentTag" placeholder="Add a comment..." @keyup.enter="addCommentTag"> -->
                                        <label v-if="$v.elements.commentTag.$error && !$v.elements.commentTag.required" class="text-danger">Comment is required</label>
                                        <label v-if="$v.elements.commentTag.$error && !$v.elements.commentTag.minLength" class="text-danger">Comment must have at least {{$v.elements.commentTag.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.elements.commentTag.$error && !$v.elements.commentTag.maxLength" class="text-danger">Comment must not exceed {{$v.elements.commentTag.$params.maxLength.max}} letters.</label>
                                     </div> 
                                </div> 
                            </div>
                             <b-btn variant="primary" @click="addCommentTag" class="ml-4 float-right">Add</b-btn>
                        </div>
                    </div>
                </div>
            </form>

            <BDialog id="deleteComment" :onYes="deletecomment" :returnParams="taskdeleteParam" message="Are you sure to delete the comment?" />
            <BDialog id="deleteTaskAttach" :onYes="deleteTaskAttach" :returnParams="delParam" message="Are you sure to delete the attachment?" />
            
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import Editor from '@tinymce/tinymce-vue';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
    props: {
        options: Object,
        editor: Object,
        editTask: Object,
        modeInfo: Object
    },
    components: {
        Editor,
        Multiselect
    },
    data() {
        return {
            file:'',
            addMore: [],
            allowedfiletypes: ['pdf', 'doc', 'docx', 'DOCX', 'rtf'],
            fileInputKey: 0,
            attachments: [],
            refresheditdata: "",
            elements: {
                currentUser: this.$store.getters.currentUser,
                commentTag: "",
                checkListTag: "",
                taskattachment: "",
            },
            delParam: {
                index: -1,
                id: 0
            },
            taskdeleteParam:{
                id: 0
            }
        }
    },
    validations: {
        editTask: {
            taskGroupId: { required },
            statusId: { required },
            title: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(50)
            },
            description: {
                maxLength: maxLength(5000)
            },
            assignedTo: { required }
        },
        elements: {
            commentTag: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(100)
            },
            checkListTag: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(2000)
            }
        }
    },
    mounted() {
         this.addMore.push(this.addMore.length + 1);
         this.$root.$on('refreshAttach', () => {
            this.refreshTaskAttach();
        })
    },
    methods: {
        addInput: function() {
            this.addMore.push(this.addMore.length + 1);   
        },
        handleMultiFileUpload(event) {
            for (let file of event.target.files) {
                try {
                    let reader = new FileReader();
                    reader.readAsDataURL(file); // Not sure if this will work in this context.
                    this.attachments.push(file);
                    let attachmentData =  this.attachments 
                    this.$emit('addTaskAttachments', attachmentData);
                } catch {}
            }
        },
        removeTask:function(arg1, arg2){   
            this.delParam.id=arg1;
            this.delParam.index=arg2;
        },
        removecomment:function(arg1){
            this.taskdeleteParam.id=arg1;
        },
        deletecomment:function(){
           this.$emit('deleteTaskComment',this.taskdeleteParam.id)
        },
        deleteTaskAttach:function(arg){
            this.$emit('deleteTaskAttachment',this.delParam.id,this.delParam.index)
        },
        Open(url){
            window.open(url, '_blank')
        },
        addChecklistTag: function() {
            this.$v.elements.checkListTag.$touch();
            if (this.$v.elements.checkListTag.$invalid) {
                return false;
            }
            let checklistData = { taskId: this.editTask.id, title: this.elements.checkListTag }
            this.$emit('addTaskChecklist', checklistData);
            this.elements.checkListTag = "";
            this.$v.elements.checkListTag.$reset();
        },
        addCommentTag: function() {
            this.$v.elements.commentTag.$touch();
            if (this.$v.elements.commentTag.$invalid) {
                return false;
            }
            let comments= this.elements.commentTag;
            let commentData = { taskId: this.editTask.id, comment: comments }
            this.$emit('addTaskComments', commentData);
            this.elements.commentTag = "";
            this.$v.elements.commentTag.$reset();
        },
        updateTask: function(bvModalEvt) {
            if (!this.validateEditTaskData()) {
                bvModalEvt.preventDefault();
                return false;
            }
            this.$emit('updateTask', this.editTask);
        },
        refreshTaskAttach: function() {
             this.addMore.length = 1;
             this.attachments = [];
             this.fileInputKey++;
         },
        validateEditTaskData: function() {
            this.$v.editTask.$touch();
            if (this.$v.editTask.$invalid) {
                return false;
            }
            return true;
        },
        refreshedit: function(index){
            this.refresheditdata=this.editTask.taskComment[index].comment;
        },
        cancelTaskComment: function(index) {
            this.editTask.taskComment[index].comment = this.refresheditdata;
            this.$emit('changeCMode','view')
        },
        cancelEdit: function() {
            this.$refs.editTaskModal.hide();
            this.updateLogActivity();
            this.$emit('cancelTaskAttachments');
            this.addMore.length = 1;
            this.attachments = [];
            this.fileInputKey++;
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
