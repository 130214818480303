<template>
    <div>
        <div class="card">
            <div class="card-header p-2">Add Client Contact</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-6 text-right">
                            <!-- <button type="button" class="btn btn-primary btn-sm pull-right" @click="isEditMode=0"  data-toggle="modal" data-target="#clientcontact" style="margin-left: 10px;">Add</button> -->
                            <button type="button" class="btn btn-primary btn-sm pull-right" @click="resetNewClientContactForm" data-toggle="modal" data-target="#clientcontact" style="margin-left: 10px;">Add</button>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table table-borderless all-table">
                                    <thead>
                                        <tr>
                                            <th class="" scope="col"> Name</th>
                                            <th class="recruiter" scope="col">Default Contact</th>
                                            <th class="title" scope="col">Title</th>
                                            <th class="state" scope="col">Phone</th>
                                            <th class="lastnote" scope="col">Mobile</th>
                                            <th class="email" scope="col"> Email</th>
                                            <th class="gpm" scope="col"> Department</th>
                                            <th class="" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="">
                                            <td colspan="8" v-if="clientcontactinfo.length==0" class="text-center">No Client Contact List Found</td>
                                        </tr>
                                        <tr v-for="(info, index) in clientcontactinfo">
                                            <td> {{ clientcontactinfo[index].contactName }} </td>
                                            <td class="lastnote">
                                                <input :disabled="true" class="form-check-input" id="isdefaultContact" type="checkbox" v-model="clientcontactinfo[index].isDefault">
                                                <label class="form-check-label" for="isdefaultContact"></label>
                                                </input>
                                            </td>
                                            <td class="recruiter"> {{ clientcontactinfo[index].title }} </td>
                                            <td class="title"> {{ clientcontactinfo[index].phoneNumber }} </td>
                                            <td class="specialityunit"> {{ clientcontactinfo[index].mobile }} </td>
                                            <td class="specialityunit"> {{ clientcontactinfo[index].email }} </td>
                                            <td class="specialityunit"> {{ clientcontactinfo[index].department }} </td>
                                            <td>
                                                <ul class="navbar-nav">
                                                    <li class="nav-item dropdown">
                                                        <a class="nav-link dropdown-toggle p-0 font-weight-bold" href="Javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</a>
                                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                            <li>
                                                                <a class="dropdown-item" data-toggle="modal" data-target="#clientcontact" @click="loadClientContact(clientcontactinfo[index].id)"><span class="ti-pencil"></span> Edit</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item" href="#" @click="deleteClientContact(clientcontactinfo[index].id)" data-toggle="modal" data-target="#removeClient"><span class="ti-close"></span> Remove</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="clientcontact" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" v-if="isEdit==0">ADD CLIENT CONTACT</h5>
                        <h5 class="modal-title" id="exampleModalLabel" v-if="isEdit==1">EDIT CLIENT CONTACT</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card m-b-3">
                            <div class="card-body p-2">
                                <div class="basic-block">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <!-- <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">First Name</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group required-field-block">
                                                        <input type="text" name="firstName" ref="addclientcontactinfo_add_name" maxlength="25" value="" id="firstName" v-model="newclientcontactinfo.firstName" class="Submitaddress txtwidth form-control" :class="{ 'is-invalid': $v.newclientcontactinfo.firstName.$error }">
                                                        <span class="required-icon">
                                                        </span>
                                                    </div>
                                                    <label v-if="$v.newclientcontactinfo.firstName.$error && !$v.newclientcontactinfo.firstName.minLength" class="text-danger">First Name must have at least {{$v.newclientcontactinfo.firstName.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.newclientcontactinfo.firstName.$error && !$v.newclientcontactinfo.firstName.required" class="text-danger">Please enter First Name</label>
                                                    <label v-if="$v.newclientcontactinfo.firstName.$error && !$v.newclientcontactinfo.firstName.maxLength" class="text-danger">First Name must not exceed {{$v.newclientcontactinfo.firstName.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div> -->
                                          <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">First Name</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group required-field-block">
                                                        <input type="text" 
                                                            name="firstName" 
                                                            ref="addclientcontactinfo_add_name" 
                                                            maxlength="25" 
                                                            id="firstName" 
                                                            v-model="newclientcontactinfo.firstName" 
                                                            class="Submitaddress txtwidth form-control" 
                                                            :class="{ 'is-invalid': $v.newclientcontactinfo.firstName.$error }"
                                                            @input="validateFirstName">
                                                        <span class="required-icon"></span>
                                                    </div>
                                                    <label v-if="$v.newclientcontactinfo.firstName.$error && !$v.newclientcontactinfo.firstName.minLength" class="text-danger">
                                                        First Name must have at least {{$v.newclientcontactinfo.firstName.$params.minLength.min}} letters.
                                                    </label>
                                                    <label v-if="$v.newclientcontactinfo.firstName.$error && !$v.newclientcontactinfo.firstName.required" class="text-danger">
                                                        Please enter First Name
                                                    </label>
                                                    <label v-if="$v.newclientcontactinfo.firstName.$error && !$v.newclientcontactinfo.firstName.maxLength" class="text-danger">
                                                        First Name must not exceed {{$v.newclientcontactinfo.firstName.$params.maxLength.max}} letters.
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <!-- <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Middle Name</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="middleName" v-model="newclientcontactinfo.middleName" maxlength="25" value="" id="middleName" class="Submitaddress txtwidth form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-sm-4">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-12 lb">
            <label for="tc_type">Middle Name</label>
        </div>
        <div class="col-md-12 col-sm-12 col-12">
            <div class="input-group">
                <input type="text" 
                       name="middleName" 
                       v-model="newclientcontactinfo.middleName" 
                       maxlength="25" 
                       id="middleName" 
                       class="Submitaddress txtwidth form-control" 
                       @input="validateMiddleName">
            </div>
        </div>
    </div>
</div>

                                       
                                        <!-- <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Last Name</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="lastName" v-model="newclientcontactinfo.lastName" maxlength="25" value="" id="lastName" class="Submitaddress txtwidth form-control">                                                      
                                                    </div>
                                                     <label v-if="$v.newclientcontactinfo.lastName.$error && !$v.newclientcontactinfo.lastName.minLength" class="text-danger">Last Name must have at least {{$v.newclientcontactinfo.lastName.$params.minLength.min}} letters.</label>
                                                     <label v-if="$v.newclientcontactinfo.lastName.$error && !$v.newclientcontactinfo.lastName.required" class="text-danger">Please enter Last Name</label>
                                                     <label v-if="$v.newclientcontactinfo.lastName.$error && !$v.newclientcontactinfo.lastName.maxLength" class="text-danger">Last Name must not exceed {{$v.newclientcontactinfo.lastName.$params.maxLength.max}} letters.</label> 

                                                    
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="col-sm-4">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-12 lb">
                                                <label for="tc_type">Last Name</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12">
                                                <div class="input-group">
                                                    <input type="text" 
                                                        name="lastName" 
                                                        v-model="newclientcontactinfo.lastName" 
                                                        id="lastName" 
                                                        maxlength="25" 
                                                        class="Submitaddress txtwidth form-control" 
                                                        @input="validateLastName">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Title</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="title" maxlength="50" v-model="newclientcontactinfo.title" id="title" class="Submitaddress txtwidth form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Type</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <select name="selectedDivision" id="selectedDivision" v-model="newclientcontactinfo.contactType" class="form-control">
                                                            <option value="">Select Type</option>
                                                            <option v-for="(data, index) in contacttypes" :value="data.id">{{data.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Department</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="department" v-model="newclientcontactinfo.department" maxlength="20" size="30" value="" id="department" class="Submitaddress txtwidth form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-12 lb">
            <label for="tc_type">Email</label>
        </div>
        <div class="col-md-12 col-sm-12 col-12">
            <div class="input-group">
                <input type="text" 
                       name="email" 
                       v-model="newclientcontactinfo.email" 
                       maxlength="50" 
                       size="30" 
                       id="email" 
                       class="Submitaddress txtwidth form-control" 
                       :class="{ 'is-invalid': $v.newclientcontactinfo.email.$error }">
            </div>
            <label v-if="$v.newclientcontactinfo.email.$error" class="text-danger">Please enter a valid email</label>
        </div>
    </div>
</div>

                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Phone</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <PhoneNumber ref="phoneNumber" :value="newclientcontactinfo.phoneNumber" :isValidcheck="isValidCheck" @phone-input="phoneControlInput" />
                                                    <!-- <div v-if="false" class="input-group required-field-block">
                                                        <select class="form-control col-sm-3" v-model="newclientcontactinfo.countryCodePhone">
                                                            <option value="+1">+1</option>
                                                            <option value="+91">+91</option>
                                                        </select>
                                                        <input-mask class="form-control" v-model="newclientcontactinfo.phoneNo" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" :class="{ 'is-invalid': $v.newclientcontactinfo.phoneNo.$error }" maskChar="_"></input-mask>
                                                        <span class="required-icon">
                                                        </span>
                                                    </div> -->
                                                    <!-- <label v-if="$v.newclientcontactinfo.phoneNo.$error && !$v.newclientcontactinfo.phoneNo.required" class="text-danger">Please enter Phone Number</label>
                                                    <label v-if="$v.newclientcontactinfo.phoneNo.$error && !$v.newclientcontactinfo.phoneNo.maxLength" class="text-danger">Phone Number must not exceed {{$v.newclientcontactinfo.phoneNo.$params.maxLength.max}} letters.</label> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Mobile</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <PhoneNumber ref="altPhoneNumber" :value="newclientcontactinfo.mobile" :isValidcheck="isaltValidCheck" @phone-input="altPhoneControlInput"  />
                                                    <!-- <div v-if="false" class="input-group">
                                                        <select class="form-control col-sm-3" v-model="newclientcontactinfo.countryCode">
                                                            <option value="+1">+1</option>
                                                            <option value="+91">+91</option>
                                                        </select>
                                                        <input-mask class="form-control" v-model="newclientcontactinfo.mobileNo" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Address</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="address" v-model="newclientcontactinfo.address" maxlength="200" value="" id="address" class="Submitaddress form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">City</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" 
                                                            name="city" 
                                                            v-model="newclientcontactinfo.city" 
                                                            maxlength="25" 
                                                            id="city" 
                                                            class="Submitaddress form-control" 
                                                            @input="validateCity">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">State</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <select v-if="newclientcontactinfo.countryId=='US'" 
                                                                v-model="newclientcontactinfo.stateName" 
                                                                class="form-control">
                                                            <option value="">Select State</option>
                                                            <option v-for="(data, index) in usstates" :value="data.stateName">
                                                                {{ data.stateName }}
                                                            </option>
                                                        </select>
                                                        <input v-if="newclientcontactinfo.countryId!='US'" 
                                                            v-model="newclientcontactinfo.stateName" 
                                                            type="text" 
                                                            maxlength="25" 
                                                            minlength="2" 
                                                            size="23" 
                                                            placeholder="select state"
                                                            class="form-control" 
                                                            @input="validateState">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">City</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="city" v-model="newclientcontactinfo.city" maxlength="25" id="city" class="Submitaddress form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">State</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <select v-if="newclientcontactinfo.countryId=='US'" v-model="newclientcontactinfo.stateName" class="form-control">
                                                            <option value="">Select State</option>
                                                            <option v-for="(data, index) in usstates" :value="data.stateName">{{data.stateName}}</option>
                                                        </select>
                                                        <input v-if="newclientcontactinfo.countryId!='US'" v-model="newclientcontactinfo.stateName" type="text" maxlength="25" minlength="2" size="23" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Zip Code</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="zip" v-model="newclientcontactinfo.zipCode" maxlength="10" value="" id="zip" class="Submitaddress form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Zip Code</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" 
                                                            name="zip" 
                                                            v-model="newclientcontactinfo.zipCode" 
                                                            maxlength="10" 
                                                            id="zip" 
                                                            class="Submitaddress form-control" 
                                                            @input="validateZipCode">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Country</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group required-field-block">
                                                        <select v-model="newclientcontactinfo.countryId" @change="newclientcontactinfo.stateName=''" class="form-control">
                                                            <option value="">Select Country</option>
                                                            <option v-for="(data, index) in countrieslist" :value="data.country_Id">{{data.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Fax</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <input type="text" name="fax2" v-model="newclientcontactinfo.faxNumber" @input="handleNumericInput" maxlength="15" size="10" value="" id="fax2" class="Submitaddress fldboxbig_nowidth form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <label for="tc_type">Notes</label>
                                                </div>
                                                <div class="col-md-12 col-sm-12 col-12">
                                                    <div class="input-group">
                                                        <textarea name="notes" rows="3" id="notes" v-model="newclientcontactinfo.notes" class="txtboxbig Submitaddress form-control"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-sm-4">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-12 lb">
                                                    <div class="form-check form-check-inline">
                                                        <input type="checkbox" name="defaultContact" value="on" v-model="newclientcontactinfo.isDefault" id="defaultContact" class="form-check-input form-control">
                                                        <label class="form-check-label" for="defaultContact">Default contact</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="resetNewClientContactForm"> Reset</button>
                        <button type="button" class="btn btn-primary" @click="saveClientContactInfo"> Save</button>
                    </div>
                </div>
            </div>
        </div>
        
        <Dialog id="removeClient" :onYes="delClientContacts" :returnParams="delClientParam" title="Delete Confirmation" message="Are you sure to delete?" />
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
    computed: {
        clientid() {
            return this.$route.params.clientid;
        }
    },
    props: {
        clientcontactinfo: Array,
    },
    data() {
        return {
            newclientcontactinfo: {
                email: ""
            },
    validations: {
        newclientcontactinfo: {
            email: { required, email } // ✅ Ensures valid email format
        }
    },
            isEdit: 0,
            countrieslist: this.$store.getters.getCountries,
            usstates: this.$store.getters.getUSStates,
            contacttypes: this.$store.getters.getClientContactType,
            newclientcontactinfo: {
                id: "",
                department: "",
                city: "",
                stateName: "",
                firstName: "",
                middleName: "",
                lastName: "",
                countryId: "US",
                title: "",
                contactType: "",
                email: "",
                // phoneNo: "",
                phoneNumber:"",
                address: "",
                zipCode: "",
                faxNumber: "",
                // countryCode:"+1",
                // countryCodePhone:"+1",
                mobile: "",
                // mobileNo:"",
                notes: "",
                isDefault: 0

            },
            delClientParam: {
                index: -1,
                id: 0
            },
            formErrors: {},
            isValidCheck:false,
            isaltValidCheck:false,
        }
    },
    validations: {
        newclientcontactinfo: {
            firstName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
/*             lastName: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(25)
            }, */
/*             phoneNo: {
                required,
                maxLength: maxLength(15)
            }, */
            email: {
                email
            }
        }
    },
    methods: {
        validateZipCode(event) {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        this.newclientcontactinfo.zipCode = event.target.value;
    },
        validateCity(event) {
        event.target.value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        this.newclientcontactinfo.city = event.target.value;
    },
    validateState(event) {
        event.target.value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        this.newclientcontactinfo.stateName = event.target.value;
    },
        validateMiddleName(event) {
        event.target.value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        this.newclientcontactinfo.middleName = event.target.value;
    },
        validateLastName(event) {
        event.target.value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        this.newclientcontactinfo.lastName = event.target.value;
    },
        validateFirstName(event) {
        event.target.value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        this.newclientcontactinfo.firstName = event.target.value;
    },
        resetNewClientContactForm() {
            this.newclientcontactinfo = {
                id: "",
                department: "",
                city: "",
                stateName: "",
                firstName: "",
                middleName: "",
                lastName: "",
                countryId: "",
                title: "",
                contactType: "",
                email: "",
                phoneNumber: "",
                // phoneNo:"",
                address: "",
                zipCode: "",
                faxNumber: "",
                // countryCode:"+1",
                // countryCodePhone:"+1",
                mobile: "",
                // mobileNo:"",
                notes: "",
                isDefault: 0
            };
            this.$refs.addclientcontactinfo_add_name.focus();
            this.$v.newclientcontactinfo.$reset();
        },
        loadClientContact(contactId) {
            axios.get(this.$store.getters.getAPIBasePath + '/clientresource/view/clientcontact/' + contactId)
                .then(response => {   
                    let result = response.data;
                    this.newclientcontactinfo = {
                        id: result.clientContact.id,
                        department: result.clientContact.department,
                        city: result.clientContact.city,
                        stateName: result.clientContact.stateName,
                        firstName: result.clientContact.firstName,
                        middleName: result.clientContact.middleName,
                        lastName: result.clientContact.lastName,
                        countryId: result.clientContact.countryId,
                        title: result.clientContact.title,
                        contactType: result.clientContact.contactType,
                        email: result.clientContact.email,
                        phoneNumber: result.clientContact.phoneNumber,
                        mobile: result.clientContact.mobile,
                        address: result.clientContact.address,
                        zipCode: result.clientContact.zipCode,
                        faxNumber: result.clientContact.faxNumber,
                        notes: result.clientContact.notes,
                        isDefault: result.clientContact.isDefault,
                    };
                   /*  let phoneNo = result.clientContact.mobile;
                    if (phoneNo != null && phoneNo.startsWith("+")) {
                        let phoneData = phoneNo.split(" ");
                        this.newclientcontactinfo.countryCode = phoneData[0];
                        this.newclientcontactinfo.mobileNo = phoneData[1];           
                    }
                    phoneNo = "";
                    phoneNo = result.clientContact.phoneNumber;
                    if (phoneNo != null && phoneNo.startsWith("+")) {
                        let phoneData = phoneNo.split(" ");
                        this.newclientcontactinfo.countryCodePhone = phoneData[0];
                        this.newclientcontactinfo.phoneNo = phoneData[1];           
                    } */
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: this.$config.CliConLoadErr,
                        position: 'topRight'
                    });
                });
        },
        saveClientContactInfo() {
            if(!this.isValidCheck && !this.isaltValidCheck){
                this.$v.newclientcontactinfo.$touch();
                if (this.$v.newclientcontactinfo.$invalid) {
                    return false;
                }

                /*if (this.newclientcontactinfo.mobileNo != null && this.newclientcontactinfo.mobileNo != '') {
                    this.newclientcontactinfo.mobile = this.newclientcontactinfo.countryCode + " " + this.newclientcontactinfo.mobileNo;                
                }

                if (this.newclientcontactinfo.phoneNo != null && this.newclientcontactinfo.phoneNo != '') {
                    this.newclientcontactinfo.phoneNumber = this.newclientcontactinfo.countryCodePhone + " " + this.newclientcontactinfo.phoneNo;                
                } */

                axios.post(this.$store.getters.getAPIBasePath + '/clientresource/update/client/' + this.clientid + '/contact', this.newclientcontactinfo)
                    .then(response => {
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.CliConInfoUpSuc,
                            position: 'topRight'
                        });
                        $('#clientcontact').modal('hide');
                        this.resetNewClientContactForm();
                        this.$emit('refresh-client');
                    })
                    .catch(error => {
                        console.log(error);                   
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.CliConUpErr,
                            position: 'topRight'
                        });
                    });
            }
        },
        deleteClientContact(clientContactId) {
            this.delClientParam.id = clientContactId;
        },
        delClientContacts() {
                var params = new URLSearchParams();
                params.append('id', this.delClientParam.id)
                axios.post(this.$store.getters.getAPIBasePath + '/clientresource/delete/clientcontact', params)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('refresh-client');
                        }
                    })
                    .catch(error => {
                        console.log(error.data);
                    });
        },
        phoneControlInput:function(value,isValid){
            this.newclientcontactinfo.phoneNumber = value;
            this.isValidCheck = isValid;
        },
        altPhoneControlInput:function(value,isValid){
            this.newclientcontactinfo.mobile = value;
            this.isaltValidCheck = isValid;
        },
        handleNumericInput() {
         this.newclientcontactinfo.faxNumber = this.newclientcontactinfo.faxNumber.replace(/\D/g, '');
        },
    }
}

</script>
