<template>
    <div id="content_Personal_Info">
        <div class="card">
            <div class="card-header p-2">Contact Information</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Mobile</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <PhoneNumber
                                        ref="phoneNumber"
                                        :value="ContactInfo.mobileNo"
                                        :mandatory="phoneMandatory"
                                        @phone-input="phoneControlInput"
                                        :isValidcheck="isValidCheck"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Alternate Mobile</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <PhoneNumber
                                        ref="altPhoneNumber"
                                        :value="ContactInfo.altMobileNo"
                                        @phone-input="altPhoneControlInput"
                                        :isValidcheck="isaltValidCheck"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address Line #1</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            maxlength="25"
                                            class="form-control"
                                            v-model="ContactInfo.addressLine1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col4 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Address Line #2</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            maxlength="25"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.addressLine2"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-12 lb">
                                <label>Country</label>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                                <div class="input-group">
                                    <select class="form-control" v-model="ContactInfo.countryId" @change="updateStates">
                                        <option value="" selected="selected">Select</option>
                                        <option v-for="Country in Countries" :key="Country.country_Id" :value="Country.country_Id">
                                            {{ Country.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>State</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div class="input-group">
                                        <select class="form-control" v-if="filteredStates.length" v-model="ContactInfo.stateName">
                                            <option value="">Select State</option>
                                            <option v-for="State in filteredStates" :key="State.id" :value="State.stateName">
                                                {{ State.stateName }}
                                            </option>
                                        </select>
                                        <input v-else type="text" maxlength="25" size="30" class="form-control" v-model="ContactInfo.stateName" pattern="[A-Za-z ]*" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '')" title="Only alphabets and spaces are allowed"/>
                                    </div>
                                    <small class="text-danger" v-if="!filteredStates.length && /[^A-Za-z ]/.test(ContactInfo.stateName)">
                                        Only alphabets and spaces are allowed
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr">
                            <div class="row">
                            <div class="col-md-12 col-sm-12 col-12 lb">
                                <label>City</label>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                                <div class="input-group">
                                    <input
                                        type="text"
                                        maxlength="25"
                                        size="30"
                                        class="form-control"
                                        v-model="ContactInfo.cityName"
                                        @input="validateCity"
                                    />
                                </div>
                                <!-- <label v-if="cityError" class="text-danger">{{ cityError }}</label> -->
                            </div>
                        </div>

                        </div>
                        <div class="col col4 mb-10-mr">
                            <div class="row">
                            <div class="col-md-12 col-sm-12 col-12 lb">
                                <label>Zip Code</label>
                            </div>
                            <div class="col-md-12 col-sm-12 col-12">
                                <div class="input-group k">
                                    <input
                                        type="text"
                                        @keypress="onlyNumber"
                                        @input="validateZip"
                                        maxlength="6"
                                        class="form-control"
                                        v-model="ContactInfo.pincode"
                                    />
                                </div>
                                <!-- <label v-if="zipError" class="text-danger">{{ zipError }}</label> -->
                            </div>
                        </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card-header p-2">Social Connect</div>
            <div class="card-body p-2">
                <div class="basic-block">
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Skype</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.skypeId"
                                            v-bind:style="[
                                                isValid(ContactInfo.skypeId)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.skypeId.$error && !$v.ContactInfo.skypeId.url" class="text-danger">Skype is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Facebook</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.facebookUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.facebookUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.facebookUrl.$error && !$v.ContactInfo.facebookUrl.url" class="text-danger">Facebook is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Twitter</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.twitterUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.twitterUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.twitterUrl.$error && !$v.ContactInfo.twitterUrl.url" class="text-danger">Twitter is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col4 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Linkedin</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.linkedinUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.linkedinUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.linkedinUrl.$error && !$v.ContactInfo.linkedinUrl.url" class="text-danger">Linkedin is not valid</label> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ten-columns">
                        <div class="col col1 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Hangout</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.hangoutId"
                                            v-bind:style="[
                                                isValid(ContactInfo.hangoutId)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.hangoutId.$error && !$v.ContactInfo.hangoutId.url" class="text-danger">Hangout is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col2 mb-10-mr">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-12 lb">
                                    <label>Website</label>
                                </div>
                                <div class="col-md-12 col-sm-12 col-12">
                                    <div
                                        class="input-group required-field-block"
                                    >
                                        <input
                                            type="text"
                                            maxlength="100"
                                            size="30"
                                            class="form-control"
                                            v-model="ContactInfo.websiteUrl"
                                            v-bind:style="[
                                                isValid(ContactInfo.websiteUrl)
                                                    ? {
                                                          'text-decoration':
                                                              'underline',
                                                          color: 'blue',
                                                          cursor: 'pointer',
                                                      }
                                                    : '',
                                            ]"
                                            @click="
                                                redirectlink(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <!-- <span class="required-icon">
                                        </span> -->
                                    </div>
                                    <!-- <label v-if="$v.ContactInfo.websiteUrl.$error && !$v.ContactInfo.websiteUrl.url" class="text-danger">Website is not valid</label> -->
                                </div>
                            </div>
                        </div>
                        <div class="col col3 mb-10-mr"></div>
                        <div class="col col4 mb-10-mr"></div>
                    </div>
                </div>
            </div>
            <div class="card-header p-2">
                <a
                    href="Javascript:void(0);"
                    @click="saveContactInfo"
                    class="btn btn-primary float-right"
                    >Save</a
                >
            </div>
        </div>
    </div>
</template>
<script>
import { url, helpers } from "vuelidate/lib/validators";
import iziToast from "izitoast";

const usPhone = helpers.regex("usPhone", /^\(\d{3}\)\s\d{3}-\d{4}$/);
var expression =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
var regex = new RegExp(expression);
export default {
    props: {
        ContactInfo: {},
        profileId: Number,
    },
    data() {
        return {
            ContactInfo: {
            countryId: "",
            stateName: ""
        },
        Countries: [
            { country_Id: "US", name: "United States" },
            { country_Id: "IN", name: "India" },
            { country_Id: "CA", name: "Canada" }
        ],
        StatesByCountry: {
            US: [
                { id: 1, stateName: "Alabama" },
                { id: 2, stateName: "Alaska" },
                { id: 3, stateName: "Arizona" },
                { id: 4, stateName: "Arkansas" },
                { id: 5, stateName: "California" },
                { id: 6, stateName: "Colorado" },
                { id: 7, stateName: "Connecticut" },
                { id: 8, stateName: "Delaware" },
                { id: 9, stateName: "Florida" },
                { id: 10, stateName: "Georgia" },
                { id: 11, stateName: "Hawaii" },
                { id: 12, stateName: "Idaho" },
                { id: 13, stateName: "Illinois" },
                { id: 14, stateName: "Indiana" },
                { id: 15, stateName: "Iowa" },
                { id: 16, stateName: "Kansas" },
                { id: 17, stateName: "Kentucky" },
                { id: 18, stateName: "Louisiana" },
                { id: 19, stateName: "Maine" },
                { id: 20, stateName: "Maryland" },
                { id: 21, stateName: "Massachusetts" },
                { id: 22, stateName: "Michigan" },
                { id: 23, stateName: "Minnesota" },
                { id: 24, stateName: "Mississippi" },
                { id: 25, stateName: "Missouri" },
                { id: 26, stateName: "Montana" },
                { id: 27, stateName: "Nebraska" },
                { id: 28, stateName: "Nevada" },
                { id: 29, stateName: "New Hampshire" },
                { id: 30, stateName: "New Jersey" },
                { id: 31, stateName: "New Mexico" },
                { id: 32, stateName: "New York" },
                { id: 33, stateName: "North Carolina" },
                { id: 34, stateName: "North Dakota" },
                { id: 35, stateName: "Ohio" },
                { id: 36, stateName: "Oklahoma" },
                { id: 37, stateName: "Oregon" },
                { id: 38, stateName: "Pennsylvania" },
                { id: 39, stateName: "Rhode Island" },
                { id: 40, stateName: "South Carolina" },
                { id: 41, stateName: "South Dakota" },
                { id: 42, stateName: "Tennessee" },
                { id: 43, stateName: "Texas" },
                { id: 44, stateName: "Utah" },
                { id: 45, stateName: "Vermont" },
                { id: 46, stateName: "Virginia" },
                { id: 47, stateName: "Washington" },
                { id: 48, stateName: "West Virginia" },
                { id: 49, stateName: "Wisconsin" },
                { id: 50, stateName: "Wyoming" }
            ],
            CA: [
                { id: 51, stateName: "Alberta" },
                { id: 52, stateName: "British Columbia" },
                { id: 53, stateName: "Manitoba" },
                { id: 54, stateName: "New Brunswick" },
                { id: 55, stateName: "Newfoundland and Labrador" },
                { id: 56, stateName: "Nova Scotia" },
                { id: 57, stateName: "Ontario" },
                { id: 58, stateName: "Prince Edward Island" },
                { id: 59, stateName: "Quebec" },
                { id: 60, stateName: "Saskatchewan" },
                { id: 61, stateName: "Northwest Territories" },
                { id: 62, stateName: "Nunavut" },
                { id: 63, stateName: "Yukon" }
            ],
            IN: [
                { id: 64, stateName: "Andhra Pradesh" },
                { id: 65, stateName: "Arunachal Pradesh" },
                { id: 66, stateName: "Assam" },
                { id: 67, stateName: "Bihar" },
                { id: 68, stateName: "Chhattisgarh" },
                { id: 69, stateName: "Goa" },
                { id: 70, stateName: "Gujarat" },
                { id: 71, stateName: "Haryana" },
                { id: 72, stateName: "Himachal Pradesh" },
                { id: 73, stateName: "Jharkhand" },
                { id: 74, stateName: "Karnataka" },
                { id: 75, stateName: "Kerala" },
                { id: 76, stateName: "Madhya Pradesh" },
                { id: 77, stateName: "Maharashtra" },
                { id: 78, stateName: "Manipur" },
                { id: 79, stateName: "Meghalaya" },
                { id: 80, stateName: "Mizoram" },
                { id: 81, stateName: "Nagaland" },
                { id: 82, stateName: "Odisha" },
                { id: 83, stateName: "Punjab" },
                { id: 84, stateName: "Rajasthan" },
                { id: 85, stateName: "Sikkim" },
                { id: 86, stateName: "Tamil Nadu" },
                { id: 87, stateName: "Telangana" },
                { id: 88, stateName: "Tripura" },
                { id: 89, stateName: "Uttar Pradesh" },
                { id: 90, stateName: "Uttarakhand" },
                { id: 91, stateName: "West Bengal" },
                { id: 92, stateName: "Andaman and Nicobar Islands" },
                { id: 93, stateName: "Chandigarh" },
                { id: 94, stateName: "Dadra and Nagar Haveli and Daman and Diu" },
                { id: 95, stateName: "Lakshadweep" },
                { id: 96, stateName: "Delhi" },
                { id: 97, stateName: "Puducherry" },
                { id: 98, stateName: "Ladakh" },
                { id: 99, stateName: "Jammu and Kashmir" }
            ]
        },
        filteredStates: [],
            ContactInfo: {
        cityName: "",
      },
      cityError: "",
      ContactInfo: {
        pincode: "",
      },
      zipError: "",

            Countries: this.$store.getters.getCountries,
            USStates: this.$store.getters.getUSStates,
            phoneMandatory: true,
            isValidCheck:false,
            isaltValidCheck:false,
        };
    },

    validations: {
        ContactInfo: {},
    },
    methods: {
        updateStates() {
        this.filteredStates = this.StatesByCountry[this.ContactInfo.countryId] || [];
        if (!this.filteredStates.length) {
            this.ContactInfo.stateName = ""; // Reset state if no predefined options exist
        }
    },
        onlyNumber(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault(); // Prevents non-numeric input
      }
    },
    validateZip() {
      const regex = /^[0-9]*$/; // Allows only numbers
      if (!regex.test(this.ContactInfo.pincode)) {
        this.zipError = "Only numbers are allowed!";
        this.ContactInfo.pincode = this.ContactInfo.pincode.replace(/\D/g, ""); // Removes non-numeric characters
      } else {
        this.zipError = "";
      }
    },
        validateCity(event) {
      const regex = /^[A-Za-z\s]*$/; // Allows only letters and spaces
      if (!regex.test(event.target.value)) {
        this.cityError = "Only letters are allowed!";
        this.ContactInfo.cityName = event.target.value.replace(/[^A-Za-z\s]/g, ""); // Removes invalid characters
      } else {
        this.cityError = "";
      }
    },
        redirectlink(value) {
            if (value != "" && value != null) {
                window.open("https://" + value, "_blank");
            }
        },
        isValid(data) {
            if (data != null && data.match(regex)) {
                return true;
            } else {
                return false;
            }
        },
        onlyNumber($event) {
            //console.log($event.keyCode); //keyCodes value
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                // 46 is dot
                $event.preventDefault();
            }
        },

        saveContactInfo: function () {
            if (!this.isValidCheck && !this.isaltValidCheck  && this.$refs.phoneNumber.validatePhone()) {
                this.$v.ContactInfo.$touch();
                if (this.$v.ContactInfo.$invalid) {
                    return false;
                }
                axios
                    .post(
                        this.$store.getters.getAPIBasePath +
                            "/interviewerresource/update/interviewer/" +
                            this.profileId +
                            "/contactinfo",
                        this.ContactInfo
                    )
                    .then((response) => {
                        this.$emit("loadPersInfo");
                        iziToast.success({
                            title: "Success",
                            message: this.$config.IntConInfoUpSuc,
                            position: "topRight",
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        iziToast.error({
                            title: "Error Occured",
                            message: this.$config.IntConInfoUpErr,
                            position: "topRight",
                        });
                    });
            }
        },
        phoneControlInput:function(value,isValid){
		    this.isValidCheck = isValid;
			this.ContactInfo.mobileNo = value;
		},
		altPhoneControlInput:function(value,isValid){
			this.isaltValidCheck = isValid;
			this.ContactInfo.altMobileNo = value;
		}
    },
};
</script>
