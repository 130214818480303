<template>
    <div id="main" class="mt-70">
        <div class="container-fluid mtb-4 bg-white p-tb-15" id="quick-search">
            <div class="driver-model" style="padding:0px 0px 10px;">
                <div class="row no-gutters">
                    <b-tabs v-model="currentJobTab" small card class="tab-list driver-tab-list">
                        <b-tab title="Job Details">
                            <JobInfo :newJobForm="newJobForm" ref="jobinfocomponent"></JobInfo>
                        </b-tab>
                        <!-- <b-tab title="Client Details">
                            <ClientInfo :clientinfo="clientinfo" ref="clientinfocomponent"></ClientInfo>
                        </b-tab> -->
                    </b-tabs>
                    <!-- <div class="col-md-12">
                        <a href="Javascript:void(0);" @click="saveJobInfo" class="btn btn-success btn-sm float-right" style="margin-left: 10px;color: #fff;">Save</a>
                    </div> -->
                </div>
                <div class="mt-2 pull-right">
                    <a href="Javascript:void(0);" class="btn btn-secondary mr-2" @click="updateLogActivity">
                        <router-link :to="{ name: 'listjobs' }" class="text-white">Cancel</router-link>
                    </a>
                    <a href="Javascript:void(0);" class="btn btn-primary" @click="saveJobInfo">Save</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ClientInfo from './blocks/addjob/ClientInfo';
import JobInfo from './blocks/addjob/JobInfo';
export default {
    components: {
        ClientInfo,
        JobInfo
    },
    computed: {
        recruiterId() {
            if (this.$store.getters.currentUser.profileId)
                return this.$store.getters.currentUser.profileId;
            else return 0;
        }
    },
    data() {
        return {
            currencies: this.$store.getters.getCurrencies,
            currentJobTab: 0,
            maxtabs: 3,
            newJobForm: {
                jobTitle: "",
                jobCode: "",
                clientId: "",
                clientName: "",
                accountName: "",
                industryId: "",
                taxTermId: "",
                position: "",
                location: "",
                eligibility: "",
                workAuthorization: "",
                jobDocuments: "",
                experience: "",
                salary: "",
                vacancies: "",
                workType: "",
                employeeType: "",
                payFrequency: "Per Month",
                dueDate: "",
                minSalary: "",
                maxSalary: "",
                frequencyPercentage: "",
                status: 1,
                accessLevel: "",
                jobRequirement: "",
                jobSkill: [],
                technologyList: [],
                expType: "Years",
                currency: "",
                currencyId: "",
                countryId:"",
                contact: {
                    contactPerson: "",
                    email: "",
                    // countryCode: "+1",
                    phone1: "",
                    phone2: "",
                    website: "",
                    addressLine1: "",
                    addressLine2: "",
                    cityName: "",
                    stateName: "",
                    zipCode: "",
                    countryId: "",
                    // mobile:""
                },
                // for intermediate clients
                vendorDetails: [],
                blockjobtab: 0
            },
            clientinfo: {
                dunsNumber: "",
                clientName: "",
                clientGroup: "",
                accountManager: "",
                url: "",
                backupaccountManager: "",
                jobUrl: "",
                accountExecutive: "",
                status: "",
                description: "",
                accountName: ""
            },
            taskId: 0,
            activityId: 0
        }
    },
    mounted() {
        this.getJobCode();
        this.loadCurrency();

    },
    methods: {
        loadCurrency(){
            var currencyId = localStorage.getItem('currencyId');
            this.newJobForm.currencyId = currencyId;
            var currency = this.currencies.find((c) => c.id == currencyId);
            if (currency) {
                this.newJobForm.currency = currency.symbol;
            }
        },
        getJobCode() {
            axios.post(this.$store.getters.getAPIBasePath + '/commonresource/autoincrementcode/job')
                .then(response => {
                    if (response.data.status) {
                        this.newJobForm.jobCode = response.data.code;
                        // this.elements.showShortListComponents = true;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveCurrentTab() {
            switch (this.currentJobTab) {
                case 0:
                    //Job info validation
                    this.$refs.jobinfocomponent.validateJobInfo();
                    this.$refs.jobinfocomponent.addMoreSkillDetails();
                    this.$refs.jobinfocomponent.addSkillInfo();
                    // this.saveJobInfo();                  
                    break;
                default:
                    break;
            }
        },
        saveJobInfo() {
            if (this.$refs.jobinfocomponent.validateJobInfo()) {
                this.$refs.jobinfocomponent.addMoreSkillDetails();
                this.$refs.jobinfocomponent.addSkillInfo();
                this.$refs.jobinfocomponent.addNewVendorInfo();
                let locationValues = "";
                for (var i = 0; i < this.newJobForm.location.length; i++) {
                    if (locationValues == "")
                        locationValues = this.newJobForm.location[i].id
                    else
                        locationValues = locationValues + "," + this.newJobForm.location[i].id;
                }
                // for eligibility
                let eligibilityData = "";
                for (var j = 0; j < this.newJobForm.eligibility.length; j++) {
                    if (eligibilityData == "")
                        eligibilityData = this.newJobForm.eligibility[j].id
                    else
                        eligibilityData = eligibilityData + "," + this.newJobForm.eligibility[j].id;
                }
                // for workAuthorization
                let workAuthData = "";
                for (var j = 0; j < this.newJobForm.workAuthorization.length; j++) {
                    if (workAuthData == "")
                        workAuthData = this.newJobForm.workAuthorization[j].id
                    else
                        workAuthData = workAuthData + "," + this.newJobForm.workAuthorization[j].id;
                }

                // for documents required
                let documentData = "";
                for (var j = 0; j < this.newJobForm.jobDocuments.length; j++) {
                    if (documentData == "")
                        documentData = this.newJobForm.jobDocuments[j].id
                    else
                        documentData = documentData + "," + this.newJobForm.jobDocuments[j].id;
                }

                if (this.newJobForm.contact.countryId == "")
                    this.newJobForm.contact.countryId = null;

                //if(localStorage.getItem("taskActivity") == 'add job' || localStorage.getItem("taskActivity") == 'sourcing' ){
                if (localStorage.getItem("taskId") != null && localStorage.getItem("taskId") > 0) {
                    this.taskId = localStorage.getItem("taskId");
                    this.activityId = localStorage.getItem("activityLogId");
                }
                let jobInfoData = {
                    recruiterId: this.recruiterId,
                    jobTitle: this.newJobForm.jobTitle,
                    jobCode: this.newJobForm.jobCode,
                    clientId: this.newJobForm.clientId,
                    clientName: this.newJobForm.clientName,
                    accountName: this.newJobForm.accountName,
                    industryId: this.newJobForm.industryId,
                    position: this.newJobForm.position,
                    location: locationValues, //this.newJobForm.location,
                    eligibility: eligibilityData, //this.newJobForm.eligibility,
                    workAuthorization: workAuthData,
                    jobDocuments: documentData,
                    experience: this.newJobForm.experience,
                    salary: this.newJobForm.salary,
                    minSalary:this.newJobForm.minSalary,
                    maxSalary:this.newJobForm.maxSalary,
                    frequencyPercentage:this.newJobForm.frequencyPercentage,
                    vacancies: this.newJobForm.vacancies,
                    workType: this.newJobForm.workType,
                    employeeType: this.newJobForm.employeeType,
                    status: this.newJobForm.status,
                    payFrequency: this.newJobForm.payFrequency,
                    expType: this.newJobForm.expType,
                    currency: this.newJobForm.currency,
                    currencyId: this.newJobForm.currencyId,
                    dueDate: this.formatDateString(this.newJobForm.dueDate),
                    jobRequirement: this.newJobForm.jobRequirement,
                    jobSkill: this.newJobForm.jobSkill,
                    contact: this.newJobForm.contact,
                    accessLevel: this.newJobForm.accessLevel,
                    taskId: this.taskId,
                    activityId: this.activityId,
                    jobVendorInfo: this.newJobForm.vendorDetails,
                    taxTermId: this.newJobForm.taxTermId,
                    countryId: this.newJobForm.countryId
                };
 
                // if (jobInfoData.contact.mobile != null && jobInfoData.contact.mobile != '') {
                //     jobInfoData.contact.phone2 = jobInfoData.contact.countryCode + " " + jobInfoData.contact.mobile;                
                // }
              
                axios.post(this.$store.getters.getAPIBasePath + '/jobresource/add/job', jobInfoData)
                    .then(response => {
                        //clientid = response.data.id;
                        iziToast.success({
                            title: 'Success',
                            message: this.$config.JobInfoCreSuc,
                            position: 'topRight'
                        });
                        this.$store.dispatch("reloadClient").then(() => {
                            console.log('Refresh Client');
                        });
                        this.$router.push('/jobs');
                    })
                    .catch(error => {
                        console.log(error);
                        iziToast.error({
                            title: 'Error Occured',
                            message: this.$config.JobCreErr,
                            position: 'topRight'
                        });
                    });
            }
        },
        updateLogActivity: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/logresource/update/logactivity')
                .then(response => {})
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

</script>
