<template>
    <div class="candidate register public-portal mt-80">
        <div v-if="showForm" class="col-md-4 offset-md-4">
            <div class="candidate-register-block box-wrapper p-5 mb-4 bg-white rounded pos-rel">
                <h3 class="text-color text-left">Candidate Registration</h3>
                <hr class="hrline-border">
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0 required-field-block">
                        <input type="text" name="firstName" placeholder="First Name" maxlength="45" size="30" v-model="candidateInfo.firstName" class="form-control box-none" @blur="$v.candidateInfo.firstName.$touch" @input="candidateInfo.firstName = $event.target.value.replace(/[^a-zA-Z .]/g, '')" :class="{ 'is-invalid': $v.candidateInfo.firstName.$error }"><span class="required-icon"></span>
                        </div>
                        <div v-if="$v.candidateInfo.firstName.$error && !$v.candidateInfo.firstName.required" class="col-md-12 px-0 text-danger">Please enter First name</div>
                        <div v-if="$v.candidateInfo.firstName.$error && !$v.candidateInfo.firstName.minLength" class="col-md-12 px-0 text-danger">First Name must have atleast {{$v.candidateInfo.firstName.$params.minLength.min}} letters</div>
                        <div v-if="$v.candidateInfo.firstName.$error && !$v.candidateInfo.firstName.maxLength" class="col-md-12 px-0 text-danger">First Name must not exceed {{$v.candidateInfo.firstName.$params.maxLength.min}} letters</div>
                        <div v-if="$v.candidateInfo.firstName.$error && !$v.candidateInfo.firstName.format" class="col-md-12 px-0 text-danger">First Name must contain only letters </div>

                    </div>
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0 required-field-block">
                            <input type="text" name="lastName" placeholder="Last Name" maxlength="45" size="30" v-model="candidateInfo.lastName" class="form-control box-none" @blur="$v.candidateInfo.lastName.$touch" @input="candidateInfo.lastName = $event.target.value.replace(/[^a-zA-Z .]/g, '')" :class="{ 'is-invalid': $v.candidateInfo.lastName.$error }"><span class="required-icon"></span>
                            <!-- <input type="text" name="lastName" placeholder="Last Name" maxlength="45" size="30" v-model="candidateInfo.lastName" class="form-control box-none" @blur="$v.candidateInfo.lastName.$touch" :class="{ 'is-invalid': $v.candidateInfo.lastName.$error }"><span class="required-icon"></span> -->
                        
                        </div>
                        <div v-if="$v.candidateInfo.lastName.$error && !$v.candidateInfo.lastName.required" class="col-md-12 px-0 text-danger">Please enter Last name</div>
                        <div v-if="$v.candidateInfo.lastName.$error && !$v.candidateInfo.lastName.format" class="col-md-12 px-0 text-danger">Last Name must contain only letters </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0 required-field-block">
                            <input type="text" name="email" placeholder="Email" maxlength="80" v-model.lazy="candidateInfo.email" class="Submitaddress form-control box-none" :class="{ 'is-invalid': $v.candidateInfo.email.$error }" @blur="$v.candidateInfo.email.$touch"><span class="required-icon"></span>
                        </div>
                        <div v-if="$v.candidateInfo.email.$error && !$v.candidateInfo.email.required" class="col-md-12 px-0 text-danger">Email is required</div>
                        <div v-if="$v.candidateInfo.email.$error && !$v.candidateInfo.email.email" class="col-md-12 px-0 text-danger">Email is not valid</div>
                        <!-- <div v-if="$v.candidateInfo.email.$error && !$v.candidateInfo.email.emailexists" class="col-md-12 px-0 text-danger">Email already exists</div> -->
                        <div v-if="$v.candidateInfo.email.$error && !$v.candidateInfo.email.emailexists && $v.candidateInfo.email.email && $v.candidateInfo.email.required" class="text-danger">Email already exists</div>
                    </div>
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0">
                             <PhoneNumber class="phoneborder" ref="phoneNumber" :value="candidateInfo.mobile" @phone-input="phoneControlInput" :isValidcheck="isValidCheck"/>
                                <!-- <select class="form-control box-none col-sm-3 max-w-45 pl-0 pr-0 " v-model="candidateInfo.countryCode">
                                    <option value="+1">+1</option>
                                    <option value="+91">+91</option>
                                </select>
                                <input-mask class="form-control box-none" v-model="candidateInfo.mobile" mask="(999)-999-9999" placeholder="Phone No" :alwaysShowMask="false" maskChar="_"></input-mask> -->
                        </div>
                    </div>
                </div>
                 <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0 required-field-block">
                            <input type="password" name="password" placeholder="Password" maxlength="45" size="30" v-model.lazy="candidateInfo.password" class="Submitaddress form-control box-none":class="{ 'is-invalid': $v.candidateInfo.password.$error }" @blur="$v.candidateInfo.password.$touch">
                        </div>
                         <div class="error-block">
                             <label v-if="$v.candidateInfo.password.$error && !$v.candidateInfo.password.required" class="col-md-12 px-0 text-danger">Please enter the password</label>
                             <label v-if="$v.candidateInfo.password.$error && !$v.candidateInfo.password.minLength" class="col-md-12 px-0 text-danger">Password must have 6 characters</label>
                         </div>

                    </div>
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0 required-field-block">
                          <!-- <input type="password" name="confirmPassword" placeholder="Confirm Password" maxlength="45" size="30" v-model="activation.confirmPassword" class="form-control box-none"> -->

                            <input type="password" name="confirmPassword" placeholder="Confirm Password" maxlength="45" size="30" v-model.lazy="candidateInfo.confirmPassword" class="Submitaddress form-control box-none":class="{ 'is-invalid': $v.candidateInfo.confirmPassword.$error }" @blur="$v.candidateInfo.confirmPassword.$touch">
                        </div>
                         <div class="error-block">
                             <label v-if="$v.candidateInfo.confirmPassword.$error && !$v.candidateInfo.confirmPassword.required" class="ol-md-12 px-0 text-danger">Please enter confirm password</label>
                             <label v-if="$v.candidateInfo.confirmPassword.$error && !$v.candidateInfo.confirmPassword.sameAsPassword" class="text-danger">Passwords must match</label>
                         </div>
                        <!-- <div v-if="$v.candidateInfo.email.$error && !$v.candidateInfo.email.required" class="col-md-12 px-0 text-danger">Email is required</div>
                        <div v-if="$v.candidateInfo.email.$error && !$v.candidateInfo.email.email" class="col-md-12 px-0 text-danger">Email is not valid</div>
                        <div v-if="$v.candidateInfo.email.$error && !$v.candidateInfo.email.emailexists" class="col-md-12 px-0 text-danger">Email already exists</div> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div v-if="isIndustryDropDown" class="col-md-12 px-0">
                            <select v-model="candidateInfo.industryId" class="form-control box-none">
                                <option value="">Industry</option>
                                <option v-for="Industry in options.industryList" :value="Industry.id">{{Industry.industryName}}</option>
                            </select>
                        </div>
                        <div v-if="!isIndustryDropDown" class="col-md-12 px-0">
                            <input type="text" name="industry" placeholder="Industry" maxlength="100" v-model="candidateInfo.industryName" class="form-control box-none">
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0">
                            <input type="text" name="position" placeholder="Position" maxlength="45" size="30" v-model="candidateInfo.position" class="form-control box-none"@input="candidateInfo.position = $event.target.value.replace(/[^a-zA-Z ]/g, '')">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0">
                            <div v-if="isWorkAuthDropDown">
                                <select v-model="candidateInfo.visaTypeId" class="form-control box-none">
                                    <option value="">Work Authorization</option>
                                    <option v-for="(data, index) in options.workAuthorizationList" :value="data.id">{{data.workAuthorizationName}}</option>
                                </select>
                            </div>
                            <div v-if="!isWorkAuthDropDown" class="col-md-12 px-0">
                                <input type="text" name="workAuth" placeholder="Work Authorization" maxlength="100" v-model="candidateInfo.visaTypeText" class="form-control box-none">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0">
                            <input type="text" name="years" placeholder="Years of Experience" maxlength="2" size="30" v-model="candidateInfo.experience" class="form-control box-none" @keypress="onlyNumber" min=0 oninput="validity.valid||(value='');">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0">
                            <input type="text" name="addressLine" placeholder="Address Line" maxlength="45" size="30" v-model="contactinfo.addressLine1" class="form-control box-none">
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0">
                            <input type="text" name="city" placeholder="City" maxlength="45" size="30" v-model="contactinfo.cityName" class="form-control box-none" @input="contactinfo.cityName = $event.target.value.replace(/[^a-zA-Z ]/g, '')">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0">
                            <select v-if="contactinfo.countryId==''" v-model="contactinfo.stateName" class="form-control box-none" required>
                                <option value="">State</option>
                                <option v-for="(data, index) in options.stateList" :value="data.stateName">{{data.stateName}}</option>
                            </select>
                            <input v-if="contactinfo.countryId!=''" v-model="contactinfo.stateName" type="text" maxlength="25" class="form-control box-none" placeholder="State">
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <div class="col-md-12 px-0">
                            <!-- <input type="text" name="country" placeholder="Country" maxlength="45" size="30" v-model="candidateInfo.country" class="form-control box-none"> -->
                            <select v-model="contactinfo.countryId" @change="contactinfo.stateName=''" class="form-control box-none">
                                <option value="">Select a Country </option>
                                <option v-for="(data, index) in options.countryList" :value="data.country_Id">{{data.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 form-group">
                        <div class="row ten-columns" :class="{ 'noaddedmore' : resumeinfo.viewonly == 1}">
                            <div class="px-0 col-md-3">
                                <label class="pl-2" for="candidateadd_resume_fileupload">Resume</label>
                            </div>
                            <div class="col-md-9 offset-md-0">
                                <div class="col-md-12 px-0">
                                    <input class="upload" ref="addcandidate_resumeattach_resume" id="candidateadd_resume_fileupload" type="file" accept=".doc,.docx,.pdf" v-on:change="handleFileUpload"></input>
                                </div>
                                <div class="col-md-12 px-0">
                                    Supports only PDF, Doc.,Docx file types { Max Size: 2MB }
                                </div>
                            </div>
                        </div>
                        <div class="row ten-columns" v-if="resumeinfo.filename!=undefined">
                            <a :href="resumeinfo.fileurl" target="_blank">{{resumeinfo.filename}}</a>
                            <span v-if="resumeinfo.filename =='' ">No resume found</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <vue-recaptcha ref="recaptcha" sitekey="6Lfjqf8dAAAAAHzJiArCATMNfpq-4csnpbjahfk5" secretkey="6Lfjqf8dAAAAAPx8rjDSlh-gxY_ad9Oe1ZeHJEjr" :loadRecaptchaScript="true" badge="inline" @verify="markRecaptchaAsVerified">
                    </vue-recaptcha>
                    <div v-if="!this.form.robot" class="errorcolor">{{form.errorMessage}}</div>
                </div>
                <hr />
                <div class="row pt-1">
                <div class="col-md-8 text-left">
                        <label>Already Candidate?<a href="javascript:" @focus="candidatelogin"> Sign In</a></label>
                    </div>
                    <div class="col-md-4 text-right">
                        <a href="javascript:" class="btn btn-primary" @click="saveCandidateInfo()">Register</a>
                    </div>
                     
                </div>
            </div>
        </div>
        <div v-if="!showForm" align="center" style="width:50%;" class="col-md-8 offset-md-3 mt-80 p-5 bg-white rounded pos-rel text-center">
            <div class="card-body p-2">
                <div class="row">
                    <div class="col-sm-12 ">
                        <h3 class="text-color" style="text-align: center;">Welcome to Talent Turbo !</h3>
                        <p style="text-align: center;">Candidate Registered successfully</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import VueRecaptcha from 'vue-recaptcha';
import { required, email, minLength, maxLength, sameAs, helpers } from "vuelidate/lib/validators";

const usPhone = helpers.regex('usPhone', /^\(\d{3}\)\s\d{3}-\d{4}$/)

export default {
    components: {
        VueRecaptcha
    },
    computed: {
        dobDates() {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 15);
            return {
                from: date
            };
        }
    },
    data() {
        return {
            showForm: true,
            options: {
                industryList: [],
                countryList: [],
                stateList: [],
                workAuthorizationList: []
            },
            candidateInfo: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmpassword: "",
                industryId: "",
                industryName: "",
                position: "",
                experience: "",
                dateOfBirth: "",
                mobile: "",
                visaTypeId: "",
                visaTypeText: ""
            },
            contactinfo: {
                addressLine1: "",
                cityName: "",
                stateName: "",
                countryId: "",
            },
            resumeinfo: {
                file: ''
            },
            allowedfiletypes: ['pdf', 'doc', 'docx'],
            form: {
                robot: false,
                errorMessage: ""
            },
            isIndustryDropDown: true,
            isWorkAuthDropDown: true,
            visatypes: this.$store.getters.getVisaOptions,
            isValidCheck:false,
        }
    },
    validations: {
        candidateInfo: {
            firstName: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(45),
                format(value){
                    return /^[a-zA-Z .]*$/.test(value);
                }
            },
            lastName:{
                required,
                format(value){
                    return /^[a-zA-Z .]*$/.test(value);
                }
            },
            email: {
                required,
                email,
                emailexists(email) {
                    if (email === '') return true;
                    return axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/public/checkemailavailability', { email: email })
                        .then(response => {console.log(response);
                            if (response.data) {
                                return true;
                            }
                            return false;
                        })
                        .catch(error => {
                            return false;
                        });
                }
            },
             password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted() {
        this.loadIndustryList();
        this.loadCountryList();
        this.loadStateList();
        this.loadWorkAuthorizationList();
        console.log(this.$route.params.key)
    },
    methods: {
        handleFileUpload(event) {
        const file = event.target.files[0];
            const maxSize = 2 * 1024 * 1024;

            if (file && file.size > maxSize) {
            alert("File size exceeds 2MB. Please upload a smaller file.");
            event.target.value = ""; 
            return;
            }
            console.log("File uploaded:", file.name);
        },
        markRecaptchaAsVerified(response) {
            if (response)
                this.form.robot = true;
        },
        candidatelogin(){
            //window.location.href = process.env.VUE_APP_MAIN_URL;
            window.open(process.env.VUE_APP_MAIN_URL+"login?key="+this.$route.params.key+"&cid=","_self");
        },
        onlyNumber($event) {
            //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        // formatUsPhone: function(evt, idx) {
        //     evt = (evt) ? evt : window.event;
        //     var charCode = (evt.which) ? evt.which : evt.keyCode;
        //     if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        //         evt.preventDefault();
        //     } else {
        //         var curchr = $(idx).val().length;
        //         var curval = $(idx).val();
        //         if (curchr == 3 && curval.indexOf("(") <= -1) {
        //             $(idx).val("(" + curval + ")" + " ");
        //         } else if (curchr == 4 && curval.indexOf("(") > -1) {
        //             $(idx).val(curval + ")-");
        //         } else if (curchr == 5 && curval.indexOf(")") > -1) {
        //             $(idx).val(curval + "-");
        //         } else if (curchr == 9) {
        //             $(idx).val(curval + "-");
        //             $(idx).attr('maxlength', '14');
        //         }
        //         return true;
        //     }
        // },
         phoneControlInput:function(value,isValid){
            this.isValidCheck = isValid;
            this.candidateInfo.mobile = value;
        },
        handleFileUpload() {
            let fileelement = this.$refs.addcandidate_resumeattach_resume.files[0];
            let ext = fileelement.name.substring(fileelement.name.lastIndexOf('.') + 1);
            if ($.inArray(ext, this.allowedfiletypes) !== -1) {
                this.resumeinfo.file = this.$refs.addcandidate_resumeattach_resume.files[0];
            } else {
                alert('Selected file extension is not supported');
                $("#candidateadd_resume_fileupload").val('');
                this.resumeinfo.file = '';
            }
        },
        loadIndustryList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/industrylist')
                .then(response => {
                    this.options.industryList = response.data.industries;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadCountryList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/countrylist')
                .then(response => {
                    this.options.countryList = response.data.countrylist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadStateList() {
            axios.get(this.$store.getters.getAPIBasePath + '/commonresource/statelist?countryId=us')
                .then(response => {
                    this.options.stateList = response.data.statelist;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadWorkAuthorizationList() {
            axios.get(this.$store.getters.getAPIBasePath + '/settingsresource/settingworkauthorization/list/all')
                .then(response => {
                    this.options.workAuthorizationList = response.data.settingsWorkAuthorization;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveCandidateInfo() {
            console.log("Public candidate add");
            //  this.validateCandidateInfo();
            this.$v.candidateInfo.$touch();
            if(!this.isValidCheck){
            if (this.form.robot) {
                if (this.$v.candidateInfo.$invalid) {
                    return false;
                } 
                else {
                    if (this.candidateInfo.industryName != '')
                        this.candidateInfo.industryId = null;
                    if(this.candidateInfo.visaTypeText != '')
                        this.candidateInfo.visaTypeId = null;
                    // let mobile = '';
                    // if (this.candidateInfo.mobile != '' && this.candidateInfo.mobile != null) {
                    //     mobile = this.candidateInfo.countryCode + " " + this.candidateInfo.mobile;
                    // }
                    let candidateInfoData = {
                        firstName: this.candidateInfo.firstName,
                        lastName: this.candidateInfo.lastName,
                        email: this.candidateInfo.email,
                        password: this.candidateInfo.password,
                        industryId: this.candidateInfo.industryId,
                        industryName: this.candidateInfo.industryName,
                        position: this.candidateInfo.position,
                        experience: this.candidateInfo.experience,
                        dateOfBirth: this.formatDateString(this.candidateInfo.dateOfBirth),
                        mobile: this.candidateInfo.mobile,
                        visaTypeId: this.candidateInfo.visaTypeId,
                        visaTypeText: this.candidateInfo.visaTypeText,  
                        candidateAddress: {
                            currentAddress: this.contactinfo
                        },
                        jobId: this.$route.params.jobId ? this.$route.params.jobId : 0,
                        key: this.$route.params.key
                    };
                    console.log(candidateInfoData);
                    axios.post(this.$store.getters.getAPIBasePath + '/candidateresource/public/add/candidate', candidateInfoData)
                        .then(response => {
                            //clientid = response.data.id;
                            if (response.data.status == 'ACCEPTED') {
                                if (this.resumeinfo.file != '') {
                                    let formData = new FormData();
                                    formData.append('file', this.resumeinfo.file);
                                    formData.append('id', response.data.id);
                                    axios.post(this.$store.getters.getAPIBasePath + '/resumeresource/public/uploadresume', formData, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }
                                        }).then(response => {
                                            iziToast.success({
                                                title: 'Success',
                                                message: this.$config.CandidProWitRes,
                                                position: 'topRight'
                                            });
                                            // this.$router.push('/candidates/edit/'+ response.data.id);

                                        })
                                        .catch(error => {
                                            iziToast.error({
                                                title: '',
                                                message: this.$config.CandidProCreErr,
                                                position: 'topRight'
                                            });
                                            //this.$router.push('/candidates/edit/'+ r  this.$router.push('/candidates/');
                                        });
                                } else {
                                    iziToast.success({
                                        title: 'Success',
                                        message: this.$config.CandidProWitOutRes,
                                        position: 'topRight'
                                    });
                                    //this.$router.push('/candidates/edit/'+ response.data.id);

                                }
                                this.showForm = false;
                            } else {
                                iziToast.error({
                                    title: 'Error Occured',
                                    message: response.data.message,
                                    position: 'topRight'
                                });
                            }
                        })
                        .catch(error => {
                            if (error.response.data.message == 'Validation Error') {
                                this.$v.candidateInfo.$touch();

                                iziToast.info({
                                    title: 'Validation Error',
                                    message: this.$config.CandEmailValiMsg,
                                    position: 'topRight'
                                });
                            } else if (error.response.data.message != null && error.response.data.message.includes("silently rolled")) {
                                iziToast.info({
                                    title: 'Error Occured',
                                    message: this.$config.ValidationErr,
                                    position: 'topRight'
                                });
                            } else {
                                iziToast.error({
                                    title: 'Error Occured',
                                    message: this.$config.CreCandInfoErr,
                                    position: 'topRight'
                                });
                            }
                        });
                }
            } 
            else {
                this.form.errorMessage = "Please check the ReCAPTCHA";
            }
            }
        }
    }
}

</script>
